module.exports=`
{
  event_type: 'order_batch',
  batch_id: 'ns_order_batch_RjzdO1729173660',
  data: [
    {
      success: true,
      statusCode: 200,
      order_id: 20693,
      order_unique_id: 'ns_SLZDG1729173660',
      tracking_id: '92055901854286997361193596',
      service_price: 6,
      timestamp: 'Thu, 17 Oct 2024 14:01:02 GMT',
      label_url: 'undefined/get-pdf/usps-order20693-1729173662124.pdf'
    },
    {
      success: true,
      statusCode: 200,
      order_id: 20693,
      order_unique_id: 'ns_SLZDG1729173660',
      tracking_id: '92055901854286997361193596',
      service_price: 6,
      timestamp: 'Thu, 17 Oct 2024 14:01:02 GMT',
      label_url: 'undefined/get-pdf/usps-order20693-1729173662124.pdf'
    }
  ]
}
`