// src/components/filter.
import React, { useEffect, useState, useRef, useCallback } from "react"
import PropTypes from "prop-types"
//import components
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { post, get, postFile, baseUrl } from "../../../helpers/api_helper"
import DataTable, { createTheme } from "react-data-table-component"
import DataTableExtensions from "react-data-table-component-extensions"
import { toast } from "react-hot-toast"
import moment from "moment-timezone"
import templateCsv from "../../../assets/templete/Order bulk import template.csv"
import templateItemCsv from "../../../assets/templete/Order with items bulk import template.csv"
import { saveAs } from "file-saver"
import customStyles from "../../../assets/css/customTableStyle"
import {
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Label,
  Input,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  ButtonDropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap"
import OrderViewModal from "components/OrderViewModal"
import fedex from "../../../assets/images/fedex.png"
import ups from "../../../assets/images/ups-logo.png"
import usps from "../../../assets/images/usps-logo.png"
import dhl from "../../../assets/images/dhl-logo.png"
import { amountFormat } from "../../../assets/js/numberFormatter"
import CustomFilters from "../../../components/CustomFilters/index"
import CustomMultiselectFilters from "../../../components/CustomMultiselectFilters/index"
import {
  MergedPackingSlip,
  MergedPackingSlipTemplate2,
  PackingSlip,
  PackingSlipTemplate2,
} from "./templates/index"
import { PDFDownloadLink, View, StyleSheet } from "@react-pdf/renderer"
import customDarkStyles from "../../../assets/css/customTableDarkStyle"

let selectedOrders = []
let selectedOrderJson = []

let selectedTags = []

let sideBarData = []

let clickedSideBarJson = []

let selectedGroupBy = ""

let validationTimeout = 3

let picklistFilter = []
import { socket } from "../../../assets/js/initializeSocket"
import NotesModal from "./Modals/NotesModal"
import TagsModal from "./Modals/TagsModal"
import ImportOrderModal from "./Modals/ImportOrder"
import JSZip from "jszip"
import axios from "axios"
import { PDFDocument } from "pdf-lib"
import { omit } from "lodash"
import { createRoot } from "react-dom/client"

function AwaitingShipment(props) {
  let fileInput = useRef(null)
  let fileInputItems = useRef(null)
  const headers = { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.getItem("token")}`, }

  const [mergedPackingSlip, setMergedPackingSlip] = useState([])
  const [mergedPackingSlipTemplete2, setMergedPackingSlipTemplete2] = useState([])
  const [id] = useState(JSON.parse(localStorage.getItem("authUser")).role_id == 4 ? JSON.parse(localStorage.getItem("authUser")).parent_id : JSON.parse(localStorage.getItem("authUser")).id)
  const [renderTable, setRenderTable] = useState(false)
  const [showHide, setShowHide] = useState({ itemName: false, itemNames: false, serviceName: false, fromName: false, toName: false, notes: false, tags: false, orderNumber: false, packageNumber: false, orderDate: false, updatedAt: false, dimension: false, weight: false, status: false, productIds: false, productSkus: false, zenventory: false, picklists: false, channelNumber: false, assignedUser: false })

  let hideShowZenventory = true
  let hideShowChannelNumber = false
  const [reload, setReload] = useState(false)

  const [responseSuccess, setResponseSuccess] = useState(null)
  const [responseMessage, setResponseMessage] = useState("")

  const [failedOrders, setFailedOrders] = useState([])
  const [isRetry, setIsRetry] = useState(false)

  const [allData, setAllData] = useState([])

  const [selectedOrdersCount, setSelectedOrdersCount] = useState(0)

  const [filteredData, setFilteredData] = useState([])
  const [tableData, setTableData] = useState(null)
  const [modal, setModal] = useState(false)
  const [modalItems, setModalItems] = useState(false)
  const [viewModal, setViewModal] = useState(false)
  const [orderDetailsModal, setOrderDetailsModal] = useState(false)

  const [services, setServices] = useState([])
  const [selectedService, setSelectedService] = useState(null)
  const [selectedServiceName, setSelectedServiceName] = useState(null)
  const [selectAvailableService, setSelectAvailableService] = useState(false)

  const [carriers, setCarriers] = useState([])
  const [selectedCarrier, setSelectedCarrier] = useState(null)
  const [selectedCarrierName, setSelectedCarrierName] = useState(null)
  const [selectAvailableCarrier, setSelectAvailableCarrier] = useState(false)

  const [selectedMeasurement, setSelectedMeasurement] = useState("pounds")

  const [balance, setBalance] = useState("-")
  const [is_credit_enable, setIsCreditEnable] = useState("")
  const [max_credit_amount, setMaxCreditAmount] = useState("")

  const [calculatedAmounts, setCalculatedAmounts] = useState([])
  const [calculatedAmountsAboveFiveLbs, setCalculatedAmountsAboveFiveLbs] = useState([])
  const [calculatedAmountsUnderOneLbs, setCalculatedAmountsUnderOneLbs] = useState([])
  const [calculatedAmountsAboveTwentyLbs, setCalculatedAmountsAboveTwentyLbs] = useState([])
  const [totalAmount, setTotalAmount] = useState(0)

  // const [filterType, setFilterType] = useState(localStorage.getItem("order-filter") ? localStorage.getItem("order-filter") : "UPS")
  const [filterType, setFilterType] = useState('USPS')

  const [pending, setPending] = useState(true)



  const [drp_link, setdrp_link] = useState(false)
  const [print_dropdown, setPrintDropdown] = useState(false)

  const [verificationModal, setVerificationModal] = useState(false)
  const [customVerificationModal, setCustomVerificationModal] = useState(false)

  const [orderErrors, setOrderErrors] = useState([])
  const [orderErrorModal, setOrderErrorModal] = useState(false)

  const [tagsModal, setTagsModal] = useState(false)
  const [tags, setTags] = useState([])
  const [tagsForFilter, setTagsForFilter] = useState([])
  const [picklists, setPicklists] = useState([])
  const [tag, setTag] = useState("")
  const [tagColor, setTagColor] = useState("#e3e3e3")

  const [rowId, setRowId] = useState()

  const [stores, setStores] = useState([])
  const [selectedStore, setSelectedStore] = useState(null)
  const [clickedJson, setClickedJson] = useState([])
  const [aboveFiveLbsOrders, setAboveFiveLbsOrders] = useState([])
  const [belowFiveLbsOrders, setBelowFiveLbsOrders] = useState([])
  const [underOneLbsOrders, setUnderOneLbsOrders] = useState([])
  const [aboveTwentyLbsOrders, setAboveTwentyLbsOrders] = useState([])
  const [singleOrderWeight, setSingleOrderWeight] = useState("")
  const [SingleOrderServiceId, setSingleOrderServiceId] = useState("")
  const [singleOrderWidth, setSingleOrderWidth] = useState("")
  const [singleOrderHeight, setSingleOrderHeight] = useState("")
  const [singleOrderLength, setSingleOrderLength] = useState("")
  const [singleOrderRate, setSingleOrderRate] = useState("")
  const [singleIsDimenssion, setSingleIsDimenssion] = useState(false)
  const [enableSingleUpdateToPhone, setEnableSingleUpdateToPhone] = useState(false)
  const [singleUpdateToPhone, setSingleUpdateToPhone] = useState("")

  const [serviceRateBelowFiveLbs, setServiceRateBelowFiveLbs] = useState("")
  const [serviceRateAboveFiveLbs, setServiceRateAboveFiveLbs] = useState("")
  const [serviceRateAboveTwentyLbs, setServiceRateAboveTwentyLbs] = useState("")
  const [serviceRateUnderOneLbs, setServiceRateUnderOneLbs] = useState("")

  const [underOneLbsService, setUnderOneLbsService] = useState("")
  const [belowFiveLbsService, setBelowFiveLbsService] = useState("")
  const [aboveFiveLbsService, setAboveFiveLbsService] = useState("")
  const [aboveTwentyLbsService, setAboveTwentyLbsService] = useState("")

  const [bulkUpdateServiceId, setBulkUpdateServiceId] = useState("")
  const [enableBulkUpdateToPhone, setEnableBulkUpdateToPhone] = useState(false)
  const [bulkUpdateToPhone, setBulkUpdateToPhone] = useState("")
  const [clickedBulkUpdateJson, setClickedBulkUpdateJson] = useState([])

  const [nameSort, setNameSort] = useState("")
  const [weightSort, setWeightSort] = useState("")
  const [quantitySort, setQuantitySort] = useState("")
  const [filterShipment, setFilterShipment] = useState("")
  const [orderDate, setOrderDate] = useState("")
  const [tagFilter, setTagFilter] = useState("")
  const [validationFilter, setValidationFilter] = useState("")

  const [unassignedOrderCount, setUnassignedOrderCount] = useState("-")
  const [fedexOrderCount, setFedexOrderCount] = useState("-")
  const [upsOrderCount, setUpsOrder] = useState("-")
  const [upsv2OrderCount, setUpsv2Order] = useState("-")
  const [dhlOrderCount, setDhlOrder] = useState("-")
  const [uspsOrderCount, setUspsOrder] = useState("-")

  const [fromBuyerNotesModal, setFromBuyerNotesModal] = useState(false)
  const [toBuyerNotesModal, setToBuyerNotesModal] = useState(false)
  const [internalNotesModal, setInternalBuyerNotesModal] = useState(false)

  const [orderItemInfoModal, setOrderItemInfoModal] = useState(false)
  const [orderItemType, setOrderItemType] = useState("")
  const [orderItemData, setOrderItemData] = useState("")

  const [duplicateOrderModal, setDuplicateOrderModal] = useState(false)
  const [duplicateOrderId, setDuplicateOrderId] = useState("")
  const [duplicateOrderUniqueId, setDuplicateOrderUniqueId] = useState("")
  const [duplicateOrderCount, setDuplicateOrderCount] = useState("")

  const [notesOrderId, setNotesOrderId] = useState("")
  const [fromBuyerNotes, setFromBuyerNotes] = useState(false)
  const [toBuyerNotes, setToBuyerNotes] = useState(false)
  const [internalNotes, setInternalBuyerNotes] = useState(false)
  const [customerRef1, setCustomerRef1] = useState(null)
  const [customerRef2, setCustomerRef2] = useState(null)


  const [hideShowColumns, setHideShowColumns] = useState(false)

  const [isFullTextVisible, setIsFullTextVisible] = useState(false)
  const [isFullTextVisibleBelow, setIsFullTextVisibleBelow] = useState(false)
  const [isFullTextVisibleUnderOne, setIsFullTextVisibleUnderOne] = useState(false)
  const [isFullTextVisibleAboveTwenty, setIsFullTextVisibleAboveTwenty] = useState(false)
  const [isFullTextVisible1, setIsFullTextVisible1] = useState(false)

  const [hideShowGroupBy, setHideShowGroupBy] = useState(false)
  const [groupByDataTables, setGroupByDataTables] = useState([])
  const [groupByDataTablesAll, setGroupByDataTablesAll] = useState([])
  const [pendingGroupBy, setPendingGroupBy] = useState(false)
  const [displayOption, setDisplayOption] = useState(0)

  const [showSideBar, setShowSideBar] = useState(false)

  const [valTime, setValTime] = useState(3)
  const [currentStore, setCurrentStore] = useState([])
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)


  const [subUser, setSubUser] = useState([JSON.parse(localStorage.getItem("authUser"))])
  const [selectedsubUserLabel, SetSelectedSubUserLabel] = useState(JSON.parse(localStorage.getItem("authUser")).first_name)
  const [selectedsubUser, SetSelectedSubUser] = useState([
    // JSON.parse(localStorage.getItem("authUser")).role_id== 4 ? JSON.parse(localStorage.getItem("authUser")).parent_id :JSON.parse(localStorage.getItem("authUser")).id
    JSON.parse(localStorage.getItem("authUser")).role_id == 4 ? JSON.parse(localStorage.getItem("authUser")).parent_id : JSON.parse(localStorage.getItem("authUser")).role_id == 4 ? JSON.parse(localStorage.getItem("authUser")).parent_id : JSON.parse(localStorage.getItem("authUser")).id
  ])
  const [toggleUser, setToggleUser] = useState(false)

  const getItemNames = (data, storeType) => {
    switch (storeType) {
      case "ebay":
        return JSON.parse(data).map(el => el.title).join(", ");
      case "shopify":
        return JSON.parse(data).map(el => el.name).join(", ");
      case "walmart":
        return JSON.parse(data).map(el => el.item.productName).join(", ");
      case "zenventory":
        return JSON.parse(data).map(el => el.itemmaster.description).join(", ");
      case "sellercloud":
        return JSON.parse(data).map(el => el.DisplayName).join(", ");
      case "shipstation":
        return JSON.parse(data).map(el => el.name).join(", ");
      case "veeqo":
        return JSON.parse(data).map(el => el.sellable.product_title).join(", ");
      case "shiphero":
        return JSON.parse(data).map(el => el?.sellable?.product_title).join(", ");
      case "orderdesk":
        return JSON.parse(data).map(el => el?.name).join(", ");
      default:
        return "-";
    }
  };

  function convertWeight(weightInLbs) {
    const pounds = Math.floor(weightInLbs);
    const remainingLbs = weightInLbs - pounds;
    const ounces = Math.round(remainingLbs * 16);

    return `${pounds} lb ${ounces} oz`;
  }

  const calculateItemPrice = (carrier, weight, items) => {
    switch (carrier) {
      case "USPS Priority":
        if (weight < 1) {
          return 4
        }
        else if (weight >= 1 && weight <= 5) {
          return 5
        }
        else if (weight > 5 && weight <= 20) {
          return 6
        }
        else if (weight > 20) {
          return 20
        }

      case "Priority Express":
        if (weight < 1) {
          return 1
        }
        else if (weight >= 1 && weight <= 5) {
          return 1
        }
        else if (weight > 5 && weight <= 20) {
          return 2
        }
        else if (weight > 20) {
          return 9
        }

      case "3 DAY":
        if (weight < 1) {
          return 1
        }
        else if (weight >= 1 && weight <= 5) {
          return 1
        }
        else if (weight > 5 && weight <= 20) {
          return 2
        }
        else if (weight > 20) {
          return "-"
        }

      case "USPS Firstclass":
        if (weight < 1) {
          return 4
        }
        else if (weight >= 1 && weight <= 5) {
          return 5
        }
        else if (weight > 5 && weight <= 20) {
          return 6
        }
        else if (weight > 20) {
          return "-"
        }

      case "UPS NT Service":
        if (weight < 1) {
          return 8
        }
        else if (weight >= 1 && weight <= 5) {
          return 10
        }
        else if (weight > 5 && weight <= 20) {
          return 12
        }
        else if (weight > 20) {
          return 100
        }

      case "FedEx Ground":
        if (weight < 1) {
          return 6
        }
        else if (weight >= 1 && weight <= 5) {
          return 2
        }
        else if (weight > 5 && weight <= 20) {
          return 4
        }
        else if (weight > 20) {
          return "-"
        }

      case "Break Bulk Economy":
        if (weight < 1) {
          return 40
        }
        else if (weight >= 1 && weight <= 5) {
          return 50
        }
        else if (weight > 5 && weight <= 20) {
          return 60
        }
        else if (weight > 20) {
          return "-"
        }

      case "UPS Ground V2":
        if (weight < 1) {
          return 8
        }
        else if (weight >= 1 && weight <= 5) {
          return 10
        }
        else if (weight > 5 && weight <= 20) {
          return 11
        }
        else if (weight > 20) {
          return "-"
        }

      case "UPS Next Day Air V2":
        if (weight < 1) {
          return 15
        }
        else if (weight >= 1 && weight <= 5) {
          return 20
        }
        else if (weight > 5 && weight <= 20) {
          return 23
        }
        else if (weight > 20) {
          return "-"
        }

      case "FedEx Priority Overnight":
        if (weight < 1) {
          return 90
        }
        else if (weight >= 1 && weight <= 5) {
          return 100
        }
        else if (weight > 5 && weight <= 20) {
          return 110
        }
        else if (weight > 20) {
          return "-"
        }

      case "UPS® Ground":
        if (weight < 1) {
          return 4
        }
        else if (weight >= 1 && weight <= 5) {
          return 5
        }
        else if (weight > 5 && weight <= 20) {
          return 8
        }
        else if (weight > 20) {
          return "-"
        }
    }
  }

  const handleClick = (row, finalData) => {
    setOrderItemType("Item Names" + (row.order_number ? " of " + row.order_number : ""));
    setOrderItemData(finalData);
    setOrderItemInfoModal(true);
  };
  const columns = [
    {
      name: (
        <input
          type={"checkbox"}
          title={"Select All"}
          className={"select-all-checkbox"}
          id={"select-all-checkbox"}
          onChange={checkAll}
        />
      ),
      // width: "",
      selector: row => (
        <input
          type={"checkbox"}
          className={"order-checkbox"}
          id={`order-${row.id}`}
          value={row.id}
          name="order_id"
          onChange={handleOrderCheck}
        />
      ),
    },
    {
      name: <span style={{ visibility: "hidden" }}>...</span>,
      // selector: "created_at",
      sortable: true,
      width: "80px",
      minWidth: "80px",
      maxWidth: "140px",
      reorder: true,
      style: {
        color: "#FF6600",
        fontWeight: "500",
        cursor: "pointer",
      },
      selector: row => {
        const date1 = moment(row.created_at.toString().substring(0, 10))
          .tz(moment.tz.guess())
          .toDate()
        const date2 = moment(new Date())
        // console.log("date1",date1);
        // console.log("date2",date2);
        // console.log("")
        return date2.diff(date1, "days") + " days"
      },
    },
    {
      name: "Items",
      // selector: "custom_items_count",
      sortable: true,
      width: "90px",
      reorder: true,
      selector: row =>
        row.custom_items_count
          ? row.custom_items_count +
          (parseInt(row.custom_items_count) > 1 ? " Items" : " Item")
          : "0 Items",
      omit: showHide.itemName,
    },

    {
      name: "Item Names",
      width: "350px",
      reorder: true,
      selector: row => {
        if (row.custom_items_data) {
          const finalData = getItemNames(row.custom_items_data, row.store_type);
          return (
            <span
              onClick={() => handleClick(row, finalData)}
              style={{
                cursor: "pointer",
                display: "block",
                maxWidth: "100%",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "normal", // Allows multiline text
                lineHeight: "1.5em", // Adjust this value based on your font size and desired line height
                maxHeight: "3em", // Adjust based on how many lines you want to show
              }}

            >
              {finalData}
            </span>
          );
        } else {
          return "-";
        }
      },
      omit: showHide.itemNames,
    },
    {
      name: "Product IDs",
      // sortable: true,
      width: "200px",
      reorder: true,
      selector: row => {
        if (
          row.custom_items_data &&
          JSON.parse(
            row.store_type == "ebay"
              ? JSON.parse(row.custom_items_data)
              : row.custom_items_data
          ).length > 0
        ) {
          if (row.store_type == null) {
            let data = JSON.parse(row.custom_items_data)
            let finalData = data.map(
              (el, id) =>
                el.product_identifier + (id != data.length - 1 ? ", " : "")
            )
            return (
              <span
                onClick={() => {
                  setOrderItemType(
                    "Product IDs" +
                    (row.order_number ? " of " + row.order_number : "")
                  )
                  setOrderItemData(finalData)
                  setOrderItemInfoModal(true)
                }}
                style={{ cursor: "pointer" }}
              >
                {finalData}
              </span>
            )
          } else if (row.store_type == "shopify") {
            let data = JSON.parse(row.custom_items_data)
            let finalData = data.map(
              (el, id) => el.id + (id != data.length - 1 ? ", " : "")
            )
            return (
              <span
                onClick={() => {
                  setOrderItemType(
                    "Product IDs" +
                    (row.order_number ? " of " + row.order_number : "")
                  )
                  setOrderItemData(finalData)
                  setOrderItemInfoModal(true)
                }}
                style={{ cursor: "pointer" }}
              >
                {finalData}
              </span>
            )
          } else if (row.store_type == "ebay") {
            let data = JSON.parse(JSON.parse(row.custom_items_data))
            let finalData = data.map(
              (el, id) => el.lineItemId + (id != data.length - 1 ? ", " : "")
            )
            return (
              <span
                onClick={() => {
                  setOrderItemType(
                    "Product IDs" +
                    (row.order_number ? " of " + row.order_number : "")
                  )
                  setOrderItemData(finalData)
                  setOrderItemInfoModal(true)
                }}
                style={{ cursor: "pointer" }}
              >
                {finalData}
              </span>
            )
          } else if (row.store_type == "walmart") {
            let data = JSON.parse(row.custom_items_data)
            let finalData = data.map(
              (el, id) => el.item.sku + (id != data.length - 1 ? ", " : "")
            )
            return (
              <span
                onClick={() => {
                  setOrderItemType(
                    "Product IDs" +
                    (row.order_number ? " of " + row.order_number : "")
                  )
                  setOrderItemData(finalData)
                  setOrderItemInfoModal(true)
                }}
                style={{ cursor: "pointer" }}
              >
                {finalData}
              </span>
            )
          } else if (row.store_type == "zenventory") {
            let data = JSON.parse(row.custom_items_data)
            let finalData = data.map(
              (el, id) =>
                el.customerorderitemid + (id != data.length - 1 ? ", " : "")
            )
            return (
              <span
                onClick={() => {
                  setOrderItemType(
                    "Product IDs" +
                    (row.order_number ? " of " + row.order_number : "")
                  )
                  setOrderItemData(finalData)
                  setOrderItemInfoModal(true)
                }}
                style={{ cursor: "pointer" }}
              >
                {finalData}
              </span>
            )
          } else if (row.store_type == "sellercloud") {
            let data = JSON.parse(row.custom_items_data)
            let finalData = data.map(
              (el, id) => el.ProductID + (id != data.length - 1 ? ", " : "")
            )
            return (
              <span
                onClick={() => {
                  setOrderItemType(
                    "Product IDs" +
                    (row.order_number ? " of " + row.order_number : "")
                  )
                  setOrderItemData(finalData)
                  setOrderItemInfoModal(true)
                }}
                style={{ cursor: "pointer" }}
              >
                {finalData}
              </span>
            )
          } else if (row.store_type == "shipstation") {
            let data = JSON.parse(row.custom_items_data)
            let finalData = data.map(
              (el, id) => el.orderItemId + (id != data.length - 1 ? ", " : "")
            )
            return (
              <span
                onClick={() => {
                  setOrderItemType(
                    "Product IDs" +
                    (row.order_number ? " of " + row.order_number : "")
                  )
                  setOrderItemData(finalData)
                  setOrderItemInfoModal(true)
                }}
                style={{ cursor: "pointer" }}
              >
                {finalData}
              </span>
            )
          } else if (row.store_type == "veeqo") {
            let data = JSON.parse(row.custom_items_data)
            let finalData = data.map(
              (el, id) => el.sellable.id + (id != data.length - 1 ? ", " : "")
            )
            return (
              <span
                onClick={() => {
                  setOrderItemType(
                    "Product IDs" +
                    (row.order_number ? " of " + row.order_number : "")
                  )
                  setOrderItemData(finalData)
                  setOrderItemInfoModal(true)
                }}
                style={{ cursor: "pointer" }}
              >
                {finalData}
              </span>
            )
          } else if (row.store_type == "shiphero") {
            let data = JSON.parse(row.custom_items_data)
            let finalData = data.map(
              (el, id) => el.line_item_id + (id != data.length - 1 ? ", " : "")
            )
            return (
              <span
                onClick={() => {
                  setOrderItemType(
                    "Product IDs" +
                    (row.order_number ? " of " + row.order_number : "")
                  )
                  setOrderItemData(finalData)
                  setOrderItemInfoModal(true)
                }}
                style={{ cursor: "pointer" }}
              >
                {"--"}
              </span>
            )
          } else if (row.store_type == "orderdesk") {
            let data = JSON.parse(row.custom_items_data)
            let finalData = data.map(
              (el, id) => el.id + (id != data.length - 1 ? ", " : "")
            )
            return (
              <span
                onClick={() => {
                  setOrderItemType(
                    "Product IDs" + (row.id ? " of " + row.id : "")
                  )
                  setOrderItemData(finalData)
                  setOrderItemInfoModal(true)
                }}
                style={{ cursor: "pointer" }}
              >
                {data?.map(
                  (el, id) => el.id + (id != data.length - 1 ? ", " : "")
                )}
                {/* {"--"} */}
              </span>
            )
          }
        } else {
          return "-"
        }
      },
      omit: showHide.productIds,
    },
    {
      name: "Product SKU",
      sortable: true,
      width: "150px",
      reorder: true,
      selector: row => {
        if (
          row.custom_items_data &&
          JSON.parse(
            row.store_type == "ebay"
              ? JSON.parse(row.custom_items_data)
              : row.custom_items_data
          ).length > 0
        ) {
          // if(row.store_type == null) {
          //   let data = JSON.parse(row.custom_items_data);
          //   let finalData = data.map((el, id) => el.product_identifier + (id != (data.length - 1) ? ", ": ""));
          //   return <span onClick={() => {
          //     setOrderItemType("Product IDs" + (row.order_number?" of " + row.order_number:""));
          //     setOrderItemData(finalData);
          //     setOrderItemInfoModal(true);
          //   }} style={{cursor:"pointer"}}>{finalData}</span>
          // }
          // else if(row.store_type == "shopify") {
          //   let data = JSON.parse(row.custom_items_data);
          //   let finalData = data.map((el, id) => el.id + (id != (data.length - 1) ? ", ": ""))
          //   return <span onClick={() => {
          //     setOrderItemType("Product IDs" + (row.order_number?" of " + row.order_number:""));
          //     setOrderItemData(finalData);
          //     setOrderItemInfoModal(true);
          //   }} style={{cursor:"pointer"}}>{finalData}</span>
          // }
          // else if(row.store_type == "ebay") {
          //   let data = JSON.parse(JSON.parse(row.custom_items_data));
          //   let finalData = data.map((el, id) => el.lineItemId + (id != (data.length - 1) ? ", ": ""))
          //   return <span onClick={() => {
          //     setOrderItemType("Product IDs" + (row.order_number?" of " + row.order_number:""));
          //     setOrderItemData(finalData);
          //     setOrderItemInfoModal(true);
          //   }} style={{cursor:"pointer"}}>{finalData}</span>
          // }
          // else if(row.store_type == "walmart") {
          //   let data = JSON.parse(row.custom_items_data);
          //   let finalData = data.map((el, id) => el.item.sku + (id != (data.length - 1) ? ", ": ""))
          //   return <span onClick={() => {
          //     setOrderItemType("Product IDs" + (row.order_number?" of " + row.order_number:""));
          //     setOrderItemData(finalData);
          //     setOrderItemInfoModal(true);
          //   }} style={{cursor:"pointer"}}>{finalData}</span>
          // }
          // else if(row.store_type == "zenventory") {
          //   let data = JSON.parse(row.custom_items_data);
          //   let finalData = data.map((el, id) => el.customerorderitemid + (id != (data.length - 1) ? ", ": ""))
          //   return <span onClick={() => {
          //     setOrderItemType("Product IDs" + (row.order_number?" of " + row.order_number:""));
          //     setOrderItemData(finalData);
          //     setOrderItemInfoModal(true);
          //   }} style={{cursor:"pointer"}}>{finalData}</span>
          // }
          // else if(row.store_type == "sellercloud") {
          //   let data = JSON.parse(row.custom_items_data);
          //   let finalData = data.map((el, id) => el.ProductID + (id != (data.length - 1) ? ", ": ""))
          //   return <span onClick={() => {
          //     setOrderItemType("Product IDs" + (row.order_number?" of " + row.order_number:""));
          //     setOrderItemData(finalData);
          //     setOrderItemInfoModal(true);
          //   }} style={{cursor:"pointer"}}>{finalData}</span>
          // }
          // else
          if (row.store_type == "shipstation") {
            let data = JSON.parse(row.custom_items_data)
            let skus = []
            for (let i = 0; i < data.length; i++) {
              // console.log("data[i].sku",data[i].sku)
              if (data[i].sku) {
                skus.push(data[i].sku)
              }
            }
            // console.log("skus",skus);
            return skus.length > 0 ? skus.toString() : "-"
          } else if (row.store_type == "veeqo") {
            let data = JSON.parse(row.custom_items_data)
            let skus = []
            for (let i = 0; i < data.length; i++) {
              // console.log("data[i].sku",data[i].sku)
              if (data[i].sellable?.sku_code) {
                skus.push(data[i].sellable.sku_code)
              }
            }
            // console.log("skus",skus);
            return skus.length > 0 ? skus.toString() : "-"
          } else if (row.store_type == "shiphero") {
            let data = JSON.parse(row.custom_items_data)
            let skus = []
            for (let i = 0; i < data.length; i++) {
              // console.log("data[i].sku",data[i].sku)
              if (data[i].sku) {
                skus.push(data[i].sku)
              }
            }
            // console.log("skus",skus);
            return skus.length > 0 ? skus.toString() : "-"
          } else {
            return "-"
          }
        } else {
          return "-"
        }
      },
      omit: showHide.productSkus,
    },
    {
      name: "Service Name",
      // selector: "name",
      sortable: true,
      width: "150px",
      reorder: true,
      selector: row =>
        row.name ? (
          row.name
        ) : (
          <span style={{ color: "red" }}>Not Assigned</span>
        ),
      omit: showHide.serviceName,
    },
    {
      name: "From Name",
      // selector: ["from_name","is_from_address_verified"],
      sortable: true,
      width: "200px",
      reorder: true,
      selector: row => (row.from_name ? row.from_name : "-"),
      cell: row =>
        row.from_name ? (
          <>
            {row.is_from_address_verified == 0 ? (
              <i
                title={"Address Validation Failed"}
                className={"bx bx-info-circle"}
                style={{ color: "red", marginRight: "5px", cursor: "pointer" }}
              />
            ) : row.is_from_address_verified ==
              3 ? null : row.is_from_address_verified == 1 ? ( // <i title={"Validation In Progress"} className={"bx bx-loader-alt spin"} style={{marginRight:"5px",cursor:"pointer"}}/>
                <i
                  title={"Address Validation Passed"}
                  className={"bx bx-check-circle"}
                  style={{
                    color: "#02ad30",
                    marginRight: "5px",
                    cursor: "pointer",
                  }}
                />
              ) : null}{" "}
            {row.from_name}
          </>
        ) : (
          "-"
        ),
      omit: showHide.fromName,
    },
    {
      name: "To Name",
      // selector: ["to_name", "is_to_address_verified"],
      sortable: true,
      width: "200px",
      reorder: true,
      selector: row => (row.to_name ? row.to_name : "-"),
      cell: row =>
        row.to_name ? (
          <>
            {row.is_to_address_verified == 0 ? (
              <i
                title={"Address Validation Failed"}
                className={"bx bx-info-circle"}
                style={{ color: "red", marginRight: "5px", cursor: "pointer" }}
              />
            ) : row.is_to_address_verified ==
              3 ? null : row.is_to_address_verified == 1 ? ( // <i title={"Validation In Progress"} className={"bx bx-loader-alt spin"} style={{marginRight:"5px",cursor:"pointer"}}/>
                <i
                  title={"Address Validation Passed"}
                  className={"bx bx-check-circle"}
                  style={{
                    color: "#02ad30",
                    marginRight: "5px",
                    cursor: "pointer",
                  }}
                />
              ) : null}{" "}
            {row.to_name}
          </>
        ) : (
          "-"
        ),
      omit: showHide.toName,
    },
    {
      name: "Notes",
      // selector: ["id", "from_buyer_notes", "to_buyer_notes", "internal_notes"],
      //  sortable: true,
      width: "100px",
      reorder: true,
      selector: row => (
        <div style={{ width: "100%", display: "flex" }}>
          <span title={"From Buyer Notes"}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setNotesOrderId(row.id)
                setFromBuyerNotes(row.from_buyer_notes)
                setFromBuyerNotesModal(true)
                setToBuyerNotesModal(false)
                setInternalBuyerNotesModal(false)
              }}
            >
              <g opacity={row.from_buyer_notes ? "1" : "0.2"}>
                <path
                  d="M15.4166 3.425L11.2582 1.86667C10.5666 1.60834 9.43322 1.60834 8.74155 1.86667L4.58322 3.425C3.62489 3.78334 2.84155 4.91667 2.84155 5.93334V12.125C2.84155 13.1083 3.49155 14.4 4.28322 14.9917L7.86655 17.6667C9.04155 18.55 10.9749 18.55 12.1499 17.6667L15.7332 14.9917C16.5249 14.4 17.1749 13.1083 17.1749 12.125V5.93334C17.1582 4.91667 16.3749 3.78334 15.4166 3.425ZM9.94155 5.85834C10.9249 5.85834 11.7249 6.65834 11.7249 7.64167C11.7249 8.60834 10.9666 9.38334 10.0082 9.41667H9.92489C8.92489 9.38334 8.17489 8.60834 8.17489 7.64167C8.16655 6.65834 8.96655 5.85834 9.94155 5.85834ZM11.8249 13.6333C11.3166 13.9667 10.6582 14.1417 9.99989 14.1417C9.34155 14.1417 8.67489 13.975 8.17489 13.6333C7.69989 13.3167 7.44155 12.8833 7.43322 12.4083C7.43322 11.9417 7.69989 11.4917 8.17489 11.175C9.18322 10.5083 10.8249 10.5083 11.8332 11.175C12.3082 11.4917 12.5749 11.925 12.5749 12.4C12.5666 12.8667 12.2999 13.3167 11.8249 13.6333Z"
                  fill={
                    localStorage.getItem("theme") == "dark"
                      ? "white"
                      : "#393E46"
                  }
                />
              </g>
            </svg>
          </span>
          <span title={"To Buyer Notes"}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              style={{ cursor: "pointer", marginLeft: "2px" }}
              onClick={() => {
                setNotesOrderId(row.id)
                setToBuyerNotes(row.to_buyer_notes)
                setFromBuyerNotesModal(false)
                setToBuyerNotesModal(true)
                setInternalBuyerNotesModal(false)
              }}
            >
              <g opacity={row.to_buyer_notes ? "1" : "0.2"}>
                <path
                  d="M14.1667 1.66666H5.83341C3.53341 1.66666 1.66675 3.525 1.66675 5.81666V11.6333C1.66675 13.925 3.53341 15.7833 5.83341 15.7833H7.08341C7.30841 15.7833 7.60841 15.9333 7.75008 16.1167L9.00008 17.775C9.55008 18.5083 10.4501 18.5083 11.0001 17.775L12.2501 16.1167C12.4084 15.9083 12.6584 15.7833 12.9167 15.7833H14.1667C16.4667 15.7833 18.3334 13.925 18.3334 11.6333V5.81666C18.3334 3.525 16.4667 1.66666 14.1667 1.66666ZM6.66675 10C6.20008 10 5.83341 9.625 5.83341 9.16666C5.83341 8.70833 6.20841 8.33333 6.66675 8.33333C7.12508 8.33333 7.50008 8.70833 7.50008 9.16666C7.50008 9.625 7.13341 10 6.66675 10ZM10.0001 10C9.53341 10 9.16675 9.625 9.16675 9.16666C9.16675 8.70833 9.54175 8.33333 10.0001 8.33333C10.4584 8.33333 10.8334 8.70833 10.8334 9.16666C10.8334 9.625 10.4667 10 10.0001 10ZM13.3334 10C12.8667 10 12.5001 9.625 12.5001 9.16666C12.5001 8.70833 12.8751 8.33333 13.3334 8.33333C13.7917 8.33333 14.1667 8.70833 14.1667 9.16666C14.1667 9.625 13.8001 10 13.3334 10Z"
                  fill={
                    localStorage.getItem("theme") == "dark"
                      ? "white"
                      : "#393E46"
                  }
                />
              </g>
            </svg>
          </span>
          <span title={"Others"}>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" style={{ cursor: "pointer", marginLeft: "2px" }} onClick={() => {
              setNotesOrderId(row.id);
              setInternalBuyerNotes(row.internal_notes);
              setCustomerRef1(row?.custom_reference_1 || null);
              setCustomerRef2(row?.custom_reference_2 || null);
              setFromBuyerNotesModal(false);
              setToBuyerNotesModal(false);
              setInternalBuyerNotesModal(true);
            }}
            >
              <g opacity={(row?.internal_notes || row?.custom_reference_1 || row?.custom_reference_2) ? "1" : "0.2"}>
                <path
                  d="M10.5633 16.6325C10.7925 16.6858 10.8133 16.9858 10.59 17.06L9.27333 17.4933C5.965 18.56 4.22333 17.6683 3.14833 14.36L2.08166 11.0683C1.015 7.76 1.89833 6.01 5.20666 4.94334L5.64333 4.79834C5.97916 4.6875 6.30583 5.02417 6.21 5.365C6.16333 5.53334 6.11833 5.71 6.07333 5.89334L5.25666 9.385C4.34 13.31 5.68166 15.4767 9.60666 16.41L10.5633 16.6325Z"
                  fill={
                    localStorage.getItem("theme") == "dark"
                      ? "white"
                      : "#393E46"
                  }
                />
                <path
                  d="M14.3083 2.67417L12.9167 2.34917C10.1333 1.69084 8.47499 2.2325 7.49999 4.24917C7.24999 4.7575 7.04999 5.37417 6.88332 6.0825L6.06665 9.57417C5.24999 13.0575 6.32499 14.7742 9.79999 15.5992L11.2 15.9325C11.6833 16.0492 12.1333 16.1242 12.55 16.1575C15.15 16.4075 16.5333 15.1908 17.2333 12.1825L18.05 8.69917C18.8667 5.21584 17.8 3.49084 14.3083 2.67417ZM12.7417 11.1075C12.6667 11.3908 12.4167 11.5742 12.1333 11.5742C12.0833 11.5742 12.0333 11.5658 11.975 11.5575L9.54999 10.9408C9.39962 10.8913 9.27367 10.7864 9.19759 10.6476C9.12152 10.5087 9.10098 10.3462 9.14013 10.1927C9.17927 10.0393 9.27519 9.90649 9.4085 9.82106C9.54181 9.73563 9.70258 9.70398 9.85832 9.7325L12.2833 10.3492C12.625 10.4325 12.825 10.7742 12.7417 11.1075ZM15.1833 8.29084C15.1083 8.57417 14.8583 8.7575 14.575 8.7575C14.525 8.7575 14.475 8.74917 14.4167 8.74084L10.375 7.71584C10.2246 7.66625 10.0987 7.56144 10.0226 7.42258C9.94652 7.28372 9.92598 7.12116 9.96513 6.96775C10.0043 6.81433 10.1002 6.68149 10.2335 6.59606C10.3668 6.51063 10.5276 6.47898 10.6833 6.5075L14.725 7.5325C15.0667 7.6075 15.2667 7.94917 15.1833 8.29084Z"
                  fill={
                    localStorage.getItem("theme") == "dark"
                      ? "white"
                      : "#393E46"
                  }
                />
              </g>
            </svg>
          </span>
        </div>
      ),
      omit: showHide.notes,
    },
    {
      name: "Tags",
      minWidth: "90px",
      maxWidth: "90px",
      reorder: true,
      selector: row => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {row.tags.length > 0 ? (
            row.tags.map((el, index) => (
              <span
                key={index}
                title={el.tag}
                style={{
                  backgroundColor: el.color,
                  width: "8px",
                  height: "20px",
                  display: "block",
                  marginLeft: index > 0 ? "5px" : "0px",
                  borderRadius: "5px",
                  cursor: "pointer"
                }}
              ></span>
            ))
          ) : (
            "-"
          )}
        </div>
      ),
      omit: showHide.tags,
    },
    {
      name: "Order #",
      // selector: "order_number",
      sortable: true,
      width: "140px",
      conditionalCellStyles: [
        {
          when: row => row.order_number,
          style: {
            color: "#FF6600",
            fontWeight: "500",
            cursor: "pointer",
          },
        },
      ],
      button: true,
      reorder: true,
      selector: row => {
        const storeOrderJson = row.store_order_json
          ? JSON.parse(row.store_order_json)
          : null
        return row.store_type == "shiphero"
          ? storeOrderJson.order_number
          : row.order_number
      },
      cell: row => {
        const storeOrderJson = row.store_order_json
          ? JSON.parse(row.store_order_json)
          : null
        return row.order_number ? (
          <span
            onClick={() => {
              setRowId(row.id)
              setViewModal(true)
            }}
          >
            {row.store_type == "shiphero" || row.store_type == "orderdesk"
              ? row.store_type == "shiphero"
                ? storeOrderJson.order_number
                : storeOrderJson.source_id
              : row.order_number}
          </span>
        ) : (
          "-"
        )
      },
      omit: showHide.orderNumber,
    },
    {
      name: "Package #",
      // selector: "package_number",
      sortable: true,
      width: "120px",
      // conditionalCellStyles: [
      //   {
      //     when: row => row.package_number,
      //     style: {
      //       color: "#FF6600", fontWeight: "500", cursor: "pointer"
      //     }
      //   }],
      // button: true,
      reorder: true,
      selector: row =>
        row.package_number ? (
          <span>
            {row.package_number +
              (row.package_index ? " (" + row.package_index + ")" : "")}
          </span>
        ) : (
          "-"
        ),
      omit: showHide.packageNumber,
    },
    {
      name: "Zenventory Order #",
      // selector: ["store_type", "store_order_json"],
      sortable: true,
      reorder: true,
      width: "200px",
      selector: row =>
        row.store_type == "zenventory"
          ? row.store_order_json
            ? JSON.parse(row.store_order_json)?.customerorder?.ordernumber
            : "-"
          : "-",
      omit: hideShowZenventory,
    },
    {
      name: "Picklist",
      // selector: "picklists",
      sortable: true,
      reorder: true,
      width: "130px",
      button: true,
      selector: row =>
        row.store_type == "sellercloud" ? (
          row.picklists ? (
            <div
              style={{
                width: "170px",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
              }}
            >
              {row.picklists.split(",").map((el, index) => (
                <span
                  key={index}
                  className={"badge border border-dark text-dark mt-1"}
                  style={{ marginLeft: "5px" }}
                >
                  {el}
                </span>
              ))}
            </div>
          ) : (
            "-"
          )
        ) : (
          "-"
        ),
      omit: showHide.picklists,
    },
    {
      name: "Channel Order #",
      // selector: "store_order_json",
      sortable: true,
      reorder: true,
      width: "240px",
      selector: row =>
        row.store_type == "sellercloud"
          ? JSON.parse(row.store_order_json).OrderSourceOrderID
            ? JSON.parse(row.store_order_json).OrderSourceOrderID
            : "-"
          : "-",
      omit: hideShowChannelNumber,
    },
    {
      name: "Dimension (L x W x H)",
      // selector: ["length", "width", "height"],
      // sortable: true,
      minWidth: "170px",
      reorder: true,
      selector: row =>
        row.length || row.width || row.height ? (
          <span>
            {row.length ? row.length : "-"} x {row.width ? row.width : "-"} x{" "}
            {row.height ? row.height : "-"}
          </span>
        ) : (
          "-"
        ),
      omit: showHide.dimension,
    },
    {
      name: "Weight",
      // selector: "weight",
      sortable: true,
      width: "190px",
      reorder: true,
      selector: row =>
        row.weight ?
          // <div title={parseFloat(row.weight).toFixed(2) + " lb or" + " " + parseFloat(row.weight * 16).toFixed(2) + " oz."}>
          <span>{convertWeight(row.weight)}</span>
          // <span style={{ marginLeft: 2 }}>{parseFloat(row.weight * 16).toFixed(2) + " oz."}</span>
          // </div> 
          : "-",
      omit: showHide.weight,
    },
    {
      name: "Rate",
      // selector: "weight",
      sortable: true,
      reorder: true,
      selector: row => <span>${calculateItemPrice(row?.name, Number(row?.weight), row?.custom_items_count)}</span>,
      omit: showHide.weight,
    },
    {
      name: "Store Order Date",
      // selector: "store_order_json",
      sortable: true,
      minWidth: "180px",
      reorder: true,
      selector: row => {
        if (row.store_order_json) {
          let order = JSON.parse(row.store_order_json)
          if (row.store_type == "shopify") {
            return (
              <span>{moment(order.created_at).format("MMM DD, YYYY")}</span>
            )
          } else if (row.store_type == "ebay") {
            return (
              <span>{moment(order.updated_at).format("MMM DD, YYYY")}</span>
            )
          } else if (row.store_type == "walmart") {
            return <span>{moment(order.orderDate).format("MMM DD, YYYY")}</span>
          } else if (row.store_type == "zenventory") {
            return (
              <span>{moment(order.ordereddate).format("MMM DD, YYYY")}</span>
            )
          } else if (row.store_type == "sellercloud") {
            return (
              <span>
                {moment(order.TimeOfOrder).format("MMM DD, YYYY hh:mm A")}
              </span>
            )
          } else if (row.store_type == "shipstation") {
            return (
              <span>
                {moment(order.createDate).format("MMM DD, YYYY hh:mm A")}
              </span>
            )
          } else if (row.store_type == "veeqo") {
            return (
              <span>
                {moment(order.created_at).format("MMM DD, YYYY hh:mm A")}
              </span>
            )
          } else if (row.store_type == "shiphero") {
            return (
              <span>
                {moment(order.order_history[0].created_at).format(
                  "MMM DD, YYYY hh:mm A"
                )}
              </span>
            )
          } else if (row.store_type == "orderdesk") {
            return (
              <span>
                {moment(order.date_added).format("MMM DD, YYYY hh:mm A")}
              </span>
            )
          }
        } else {
          return "-"
        }
      },
      omit: localStorage.getItem("store") == 0,
    },
    {
      name: "Order Date",
      // selector: "created_at",
      sortable: true,
      minWidth: "180px",
      reorder: true,
      selector: row =>
        moment(row.created_at)
          .tz(moment.tz.guess())
          .format("MMM DD, YYYY hh:mm A"),
      omit: showHide.orderDate,
    },
    {
      name: "Updated At",
      // selector: "updated_at",
      sortable: true,
      minWidth: "180px",
      reorder: true,
      selector: row =>
        moment(row.updated_at)
          .tz(moment.tz.guess())
          .format("MMM DD, YYYY hh:mm A"),
      omit: showHide.updatedAt,
    },
    {
      name: "Status",
      // selector: ["id", "error_message", "is_verified"],
      //  sortable: true,
      maxWidth: "100px",
      width: "75px",
      button: true,
      reorder: true,
      // cell: (row) => row.error_message ? <span className={"badge badge-danger"}>{row.error_message}</span> : "-"
      selector: row =>
        row.is_verified == 1 && row.error_message != "Try Again" ? (
          <span className={"badge badge-success"}>Passed</span>
        ) : row.error_message ? (
          row.is_verified == 1 && row.error_message == "Try Again" ? (
            <span className={"badge badge-warning"}>Try Again</span>
          ) : (
            <>
              <span
                className={"badge badge-danger"}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  handleFailedOrderErrorMessage(row.id)
                }}
              >
                Failed
              </span>
              {/*<span className={"text-danger fw-medium text-center"} title={row.error_message.toString().replaceAll("null,","")}><i style={{cursor:"pointer",fontSize:"15px"}} className={"bx bx-error"}></i></span>*/}
            </>
          )
        ) : (
          "-"
        ),
      omit: showHide.status,
    },
    // {
    //   name: "User Name",
    //   sortable: true,
    //   width: "150px",
    //   reorder: true,
    //   selector: row => `${row.first_name} ${row.last_name}`
    // },
    {
      name: "Assigned User",
      reorder: true,
      selector: row => {
        const assignedUsers = subUser?.filter(subUser => { const accessStore = subUser?.access_store ? JSON.parse(subUser.access_store) : []; return accessStore.includes(row.store_id) || accessStore.includes(0); });

        return assignedUsers.length > 0 && JSON.parse(localStorage.getItem("authUser")).role_id != 4 ? (
          <div style={{ display: "flex", gap: "5px" }}>
            {assignedUsers.map((user, index) => (
              <div title={user?.first_name + " " + user?.last_name} key={index} style={{ backgroundColor: "#2CA67A", color: "white", minWidth: "1.5rem", minHeight: "1.5rem", borderRadius: "10rem", display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }}>
                <span>{user.first_name.charAt(0) + user.last_name.charAt(0)}</span>
              </div>
            ))}
          </div>
        ) : "-";
      },
      omit: showHide.assignedUser
    },
    {
      name: "Actions",
      // selector: "id",
      width: "170px",
      button: true,
      selector: row => (
        <>
          <button
            className={"btn btn-success btn-sm"}
            onClick={() => {
              setRowId(row.id)
              setViewModal(true)
            }}
            style={{ marginRight: "10px" }}
            title="View Order Details"
          >
            <i className={"bx bx-list-ul"}></i>
          </button>
          <button
            className={"btn btn-light btn-sm"}
            data-id={row["id"]}
            onClick={() => {
              props.history.push("/order/edit/" + row["id"])
            }}
            style={{ marginRight: "10px" }}
            title="Edit Order"
          >
            <i className={"bx bx-edit"}></i>
          </button>
          {localStorage.getItem("store") == 0 ? (
            <>
              <button
                className={"btn btn-danger btn-sm"}
                onClick={() => {
                  handleDelete(row.id)
                }}
                title="Delete Order"
              >
                <i className={"bx bx-trash"}></i>
              </button>
              <button
                title={"Duplicate Order"}
                className={"btn btn-info btn-sm"}
                onClick={() => handleDuplicate(row.id, row.order_unique_id)}
                style={{ marginLeft: "10px" }}
              >
                <i className={"bx bx-duplicate"}></i>
              </button>
            </>
          ) : (
            ""
          )}
        </>
      ),
    },
    {
      name: "",
      selector: row => row.order_unique_id,
      omit: true,
    },
    {
      name: "",
      selector: row => row.reference_number,
      omit: true,
    },
    {
      name: "",
      selector: row => row.custom_reference_1,
      omit: true,
    },

    {
      name: "",
      selector: row => row.custom_reference_2,
      omit: true,
    },

  ]

  const handleDuplicate = async (order_id, order_unique_id) => {
    try {
      setDuplicateOrderCount(1)
      setDuplicateOrderId(order_id)
      setDuplicateOrderUniqueId(order_unique_id)
      setDuplicateOrderModal(true)
    } catch (e) { }
  }

  const handleSubmitDuplicateOrder = async e => {
    e.preventDefault()
    try {
      if (confirm("Are you sure! you want to duplicate the order?")) {
        let loading = toast.loading("Duplicating Order...")

        let body = {
          id: JSON.parse(localStorage.getItem("authUser")).role_id == 4 ? JSON.parse(localStorage.getItem("authUser")).parent_id : JSON.parse(localStorage.getItem("authUser")).role_id == 4 ? JSON.parse(localStorage.getItem("authUser")).parent_id : JSON.parse(localStorage.getItem("authUser")).id,
          order_id: duplicateOrderId,
          order_count: duplicateOrderCount,
          type: filterType,
        }

        post("/user/duplicate-order", body, { headers }).then(
          async response => {
            toast.remove(loading)
            if (response.status == 200) {
              toast.success(response.message)
              await retrieveOrdersByFilterType()
              setDuplicateOrderModal(false)
            } else {
              toast.error(response.message)
            }
          }
        )
      }
    } catch (e) {
      console.log("duplicate order error", e)
    }
  }
  // Calculate brightness
  const getBrightness = color => {
    const hexColor = color.replace("#", "")
    const r = parseInt(hexColor.slice(0, 2), 16)
    const g = parseInt(hexColor.slice(2, 4), 16)
    const b = parseInt(hexColor.slice(4, 6), 16)
    return 0.299 * r + 0.587 * g + 0.114 * b
  }

  const handleFailedOrderErrorMessage = async id => {
    try {
      get(`/user/get-order-errors/${id}`, { headers }).then(response => {
        // console.log("get-order-errors", response)
        if (response.status === 200) {
          // console.log(response.data[0].error_message.split(","))
          setOrderErrors(response.data[0].error_message)
          setOrderErrorModal(true)
        } else {
          toast.error(response.message)
        }
      })
    } catch (e) {
      // console.log(e)
    }
  }

  const handleOrderCheck = async e => {
    const { value, checked } = e.target
    // console.log("value",value);
    // console.log("allData[index]",sideBarData);
    let arr = clickedSideBarJson
    if (checked) {
      // console.log("value",JSON.parse(value));
      if (!selectedOrders.includes(value)) {
        selectedOrders.push(value)
        let index = sideBarData.findIndex(el => el.id == value)
        arr.push(sideBarData[index])
        clickedSideBarJson = arr
      }

      // // console.log("handleOrderCheck selectedOrders", selectedOrders);
      // // console.log("data",data);
      // selectedOrderJson.push(JSON.parse(value));
    } else {
      let index = selectedOrders.findIndex(el => el == value)
      selectedOrders.splice(index, 1)

      let indexToRemove = arr.findIndex(el => el.id == value)
      arr.splice(indexToRemove, 1)
      clickedSideBarJson = arr
      // let i = selectedOrderJson.findIndex(el => el.id == parseInt(JSON.parse(value).id));
      // selectedOrderJson.splice(i, 1);
    }
    // console.log("selectedOrders",selectedOrders);
    setSelectedOrdersCount(selectedOrders.length)

    // setSingleOrderRate("");
    // setSingleOrderWeight("")
    // setSingleOrderServiceId("")
    // setSingleOrderWidth("")
    // setSingleOrderHeight("")
    // setSingleOrderLength("")
    // setSingleUpdateToPhone("");
    // setEnableSingleUpdateToPhone(false)

    // // console.log("selectedOrderJson", selectedOrderJson);
    // await setClickedJson(selectedOrderJson);
    // if(selectedOrders.length == 1) {
    //   retrieveOrder(parseInt(JSON.parse(value).id));
    // }
    // else {
    //   setSingleOrderWeight("")
    //   setSingleOrderServiceId("")
    //   setSingleOrderWidth("")
    //   setSingleOrderHeight("")
    //   setSingleOrderLength("")
    // }
    // document.body.click();
    document.getElementsByClassName("right-bar-enabled")[0].click()
  }

  const handleTagCheck = async e => {
    const { value, checked } = e.target
    if (checked) {
      selectedTags.push(value)
    } else {
      let index = selectedTags.findIndex(el => el == value)
      selectedTags.splice(index, 1)
    }
    document.getElementsByClassName("right-bar-enabled")[0].click()
  }

  const handleBulKRate = async () => {
    document.body.style.pointerEvents = "none"
    setClickedJson([])
    setAboveFiveLbsOrders([])
    setBelowFiveLbsOrders([])
    setUnderOneLbsOrders([])
    setAboveTwentyLbsOrders([])
    setAboveFiveLbsService("")
    setAboveTwentyLbsService("")
    setBelowFiveLbsService("")
    setUnderOneLbsService("")
    setServiceRateAboveFiveLbs("")
    setServiceRateAboveTwentyLbs("")
    setServiceRateBelowFiveLbs("")
    setServiceRateUnderOneLbs("")

    if (selectedOrders.length > 0) {
      let underOneLbs = []
      let belowFiveLbs = []
      let aboveFiveLbs = []
      let aboveTwentyLbs = []
      let arr = []
      for (let i = 0; i < selectedOrders.length; i++) {
        let index = allData.findIndex(el => el.id == selectedOrders[i])
        arr.push(allData[index])
        selectedOrderJson.push(allData[index])
        // const order = await retrieveOrderWeight(selectedOrders[i]);
        const order = allData[index]
        if (order.weight) {
          if (parseFloat(order.weight) > 5 && parseFloat(order.weight) <= 20) {
            aboveFiveLbs.push(order)
          } else if (parseFloat(order.weight) > 20) {
            aboveTwentyLbs.push(order)
          } else if (parseFloat(order.weight) < 1) {
            underOneLbs.push(order)
          } else {
            belowFiveLbs.push(order)
          }
        } else {
          underOneLbs.push(order)
        }
      }

      // console.log("belowFiveLbs",belowFiveLbs);
      // console.log("aboveFiveLbs",aboveFiveLbs);
      setClickedJson(arr)
      setAboveFiveLbsOrders(aboveFiveLbs)
      setBelowFiveLbsOrders(belowFiveLbs)
      setUnderOneLbsOrders(underOneLbs)
      setAboveTwentyLbsOrders(aboveTwentyLbs)
      setIsFullTextVisible(false)
      setIsFullTextVisibleBelow(false)
      setIsFullTextVisibleUnderOne(false)
      setIsFullTextVisibleAboveTwenty(false)
      document.body.style.pointerEvents = "auto"

      //   setSingleOrderRate("");
      //   let arr = [];
      //   for (let i=0;i<selectedOrders.length;i++) {
      //     let index = allData.findIndex(el => el.id == selectedOrders[i]);
      //     arr.push(allData[index]);
      //     selectedOrderJson.push(allData[index]);
      //   }
      // // console.log("setClickedJson",arr)
      //   setClickedJson(arr);
      //
      //   if(selectedOrders.length == 1) {
      //     retrieveOrder(selectedOrders[0]);
      //   }
      //   else {
      //     setSingleOrderWeight("")
      //     setSingleOrderServiceId("")
      //     setSingleOrderWidth("")
      //     setSingleOrderHeight("")
      //     setSingleOrderLength("")
      //   }
    } else {
      toast.error("Please select at least one order")
    }
  }

  const handleBulkUpdate = () => {
    if (selectedOrders.length > 0) {
      let arr = []
      for (let i = 0; i < selectedOrders.length; i++) {
        let index = allData.findIndex(el => el.id == selectedOrders[i])
        arr.push(allData[index])
      }
      console.log("setClickedJson", arr)
      setClickedBulkUpdateJson(arr)
    } else {
      toast.error("Please select at least one order")
    }
  }

  const retrieveTags = async () => {
    let id = JSON.parse(localStorage.getItem("authUser")).role_id == 4 ? JSON.parse(localStorage.getItem("authUser")).parent_id : JSON.parse(localStorage.getItem("authUser")).role_id == 4 ? JSON.parse(localStorage.getItem("authUser")).parent_id : JSON.parse(localStorage.getItem("authUser")).id
    get(`/user/get-tags/${id}`, { headers }).then(response => {
      // console.log("get-order-errors", response)
      if (response.status === 200) {
        // console.log(response.data[0].error_message.split(","))
        let arr = [{ name: "Remove Filter", value: "none" }]
        for (let i = 0; i < response.data.length; i++) {
          arr.push({ name: response.data[i].tag, value: response.data[i].id })
        }
        setTagsForFilter(arr)
        setTags(response.data)
      } else {
        toast.error(response.message)
      }
    })
  }

  const retrievePicklist = async () => {
    let id = JSON.parse(localStorage.getItem("authUser")).role_id == 4 ? JSON.parse(localStorage.getItem("authUser")).parent_id : JSON.parse(localStorage.getItem("authUser")).role_id == 4 ? JSON.parse(localStorage.getItem("authUser")).parent_id : JSON.parse(localStorage.getItem("authUser")).id
    const body = { user_id: id, store_id: localStorage.getItem("store"), }
    post(`/user/get-picklists`, body, { headers }).then(response => {
      // console.log("get-order-picklists", response)
      if (response.status === 200) {
        // console.log(response.data[0].error_message.split(","))
        let arr = [{ name: "Remove Filter", value: "none" }]
        for (let i = 0; i < response.data.length; i++) {
          arr.push({
            name: response.data[i].picklists,
            value: response.data[i].picklists,
          })
        }
        setPicklists(arr)
      } else {
        toast.error(response.message)
      }
    })
  }

  const handleTags = async () => {
    if (selectedOrders.length > 0) {
      await retrieveTags()
      setTag("")
      setTagColor("#e3e3e3")
      setTagsModal(true)
    } else {
      toast.error("Please select at least one order")
    }
  }

  const handleUpdateTag = () => {
    if (selectedOrders.length > 0) {
      if (selectedTags.length > 0) {
        let body = {
          id: JSON.parse(localStorage.getItem("authUser")).role_id == 4 ? JSON.parse(localStorage.getItem("authUser")).parent_id : JSON.parse(localStorage.getItem("authUser")).role_id == 4 ? JSON.parse(localStorage.getItem("authUser")).parent_id : JSON.parse(localStorage.getItem("authUser")).id,
          order_ids: selectedOrders.toString(),
          tag: selectedTags.toString(),
        }
        post("/user/update-order-tag", body, { headers }).then(
          async response => {
            uncheckAll()
            selectedOrders = []
            setSelectedOrdersCount(selectedOrders.length)
            clickedSideBarJson = []
            selectedOrderJson = []
            setBulkUpdateServiceId("")
            setBulkUpdateToPhone("")
            setEnableBulkUpdateToPhone(false)
            selectedTags = []
            uncheckAllTags()
            if (selectedGroupBy) {
              await handleGroupByOrders(selectedGroupBy)
            } else {
              await retrieveOrdersByFilterType()
            }

            if (response.status == 200) {
              toast.success(response.message)
              setTag("")
              setTagColor("#e3e3e3")
              setTagsModal(false)
            } else {
              toast.error(response.message)
            }
          }
        )
      } else {
        toast.error("Please select at least one tag")
      }
    } else {
      toast.error("Please select at least one order")
    }
  }

  const handleCreateTag = () => {
    if (parseFloat(getBrightness(tagColor)) < 250) {
      let body = {
        id: JSON.parse(localStorage.getItem("authUser")).role_id == 4 ? JSON.parse(localStorage.getItem("authUser")).parent_id : JSON.parse(localStorage.getItem("authUser")).id,
        tag: tag,
        tagColor: tagColor,
      }
      post("/user/create-tag", body, { headers }).then(async response => {
        if (response.status == 200) {
          await retrieveTags()
          setTag("")
          setTagColor("#e3e3e3")
          toast.success(response.message)
        } else {
          toast.error(response.message)
        }
      })
    }
    else {
      toast.error("Tag color must be brighter than white")
    }
  }

  const handleDeleteTag = async id => {
    if (confirm("Are you sure! you want to delete this tag?")) {
      let body = {
        id: id,
      }
      post("/user/delete-tag", body, { headers }).then(async response => {
        if (response.status == 200) {
          await retrieveTags()
          toast.success(response.message)
        } else {
          toast.error(response.message)
        }
      })
    }
  }

  const getDiscounts = async () => {
    try {
      const response = await post(
        "/user/get-discounts-all",
        {
          user_id: id,
        },
        { headers }
      )
      // console.log("discounts", response)
      return response
    } catch (error) { }
  }
  const retrieveServices = async () => {
    try {
      const response = await get("/admin/get-services", { headers })
      // console.log("service", response)
      const discounts = await getDiscounts()
      if (discounts.data.userDiscount.length > 0) {
        discounts?.data.userDiscount.map(dis => {
          response?.data.map(res => {
            if (dis.service_id == res.id) {
              res.price = res.price - dis.amount
              res.above_five_lbs_price =
                res.above_five_lbs_price - dis.amount_above_five_lbs
              res.above_twenty_lbs_price =
                res.above_twenty_lbs_price - dis.amount_above_twenty_lbs
              res.under_one_lbs_price =
                res.under_one_lbs_price - dis.amount_under_one_lbs
              res.discount = "0"
            }
            setServices(response.data)
            const selectedService = response.data.find(
              service =>
                service.id === 26 &&
                service.name === "USPS Priority" &&
                service.is_active === 1
            )
            if (selectedService) {
              setSelectedServiceName(selectedService.name)
              // console.log("selectedService", selectedService)
              setSelectedService(selectedService.id)
            } else {
              console.log("No matching service found")
            }
          })
        })
      } else {
        setServices(response.data)
        const selectedService = response.data.find(
          service =>
            service.id === 26 &&
            service.name === "USPS Priority" &&
            service.is_active === 1
        )
        setSelectedServiceName(selectedService.name)
        // console.log("selectedService", selectedService)
        setSelectedService(selectedService.id)
      }

      // get("/admin/get-services", { headers }).then(response => {
      //   setServices(response.data)
      //   const selectedService = response.data.find(
      //     service =>
      //       service.id === 26 &&
      //       service.name === "USPS Priority" &&
      //       service.is_active === 1
      //   )
      //   setSelectedServiceName(selectedService.name)
      //   console.log("selectedService", selectedService)
      //   setSelectedService(selectedService.id)
      // })
    } catch (e) {
      // console.log(e)
    }
  }

  const retrieveCarriers = async () => {
    try {
      get("/admin/get-carriers", { headers }).then(response => {
        // console.log("carriers", response)
        setCarriers(response.data)
        const selectedCarrier = response.data.find(carrier => carrier.id === 6 && carrier.name === "USPS" && carrier.is_active === 1)
        // console.log("selectedCarrier", selectedCarrier)
        setSelectedCarrierName(selectedCarrier.name)
        setSelectedCarrier(selectedCarrier.id)
      })
    } catch (e) {
      // console.log(e)
    }
  }

  const retrieveOrderCounts = async () => {
    console.log("im hite");
    setUnassignedOrderCount("-")
    setFedexOrderCount("-")
    setUpsOrder("-")
    setUpsv2Order("-")
    setDhlOrder("-")
    setUspsOrder("-")
    let body = { id: selectedsubUser, store_id: localStorage.getItem("store"), }
    post("/user/get-order-counts", body, { headers }).then(response => {
      console.log("response", response);
      if (response.status == 200) {
        setUnassignedOrderCount(response.data.unsassigned_order_count)
        setFedexOrderCount(response.data.fedex_order_count)
        setUpsOrder(response.data.ups_order_count)
        setUpsv2Order(response.data.upsv2_order_count)
        setDhlOrder(response.data.dhl_order_count)
        setUspsOrder(response.data.usps_order_count)
      }
    })
  }

  const handleTableData = async responseData => {
    if (selectedGroupBy) {
      await handleSort(
        nameSort,
        weightSort,
        quantitySort,
        orderDate,
        tagFilter,
        filterShipment,
        validationFilter,
        picklistFilter,
        responseData
      )
      setGroupByDataTablesAll(responseData)
      // console.log("responseData",responseData);

      let mergedArray = []
      for (let i = 0; i < responseData.length; i++) {
        mergedArray = mergedArray.concat(responseData[i].data)
      }
      // console.log("after concat",mergedArray);
      setAllData(mergedArray)
      sideBarData = mergedArray
    } else {
      await handleSort(
        nameSort,
        weightSort,
        quantitySort,
        orderDate,
        tagFilter,
        filterShipment,
        validationFilter,
        picklistFilter,
        responseData
      )
      setAllData(responseData)
      sideBarData = responseData
    }
  }

  const retrieveUnassignedOrders = async () => {
    try {
      // refresh filter on carrier change
      // setNameSort("");
      // setWeightSort("");
      // setQuantitySort("");
      // setFilterShipment("All");

      localStorage.setItem("order-filter", "Unassigned")
      setPending(true)
      let body = {
        id: JSON.parse(localStorage.getItem("authUser")).role_id == 4 ? JSON.parse(localStorage.getItem("authUser")).parent_id : JSON.parse(localStorage.getItem("authUser")).id,
        store_id: localStorage.getItem("store"),
      }
      post("/user/get-unassigned-orders", body, { headers }).then(
        async response => {
          // // console.log("response", response);
          // if(response.data.length > 0) {
          await handleTableData(
            response.data.filter(el => el.error_message != "In Process")
          )
          // setFilteredData(response.data.filter(el => el.error_message != "In Process"))
          // data = response.data.filter(el => el.error_message != "In Process")
          // }

          // setTableData({ columns, data })
        }
      )

      await retrieveOrderCounts()
    } catch (e) {
      // console.log(e)
    }
  }

  const retrieveFedexOrders = async () => {
    try {
      // refresh filter on carrier change
      // setNameSort("");
      // setWeightSort("");
      // setQuantitySort("");
      // setFilterShipment("All");

      localStorage.setItem("order-filter", "FedEx")
      setPending(true)
      let body = {
        id: selectedsubUser,
        store_id: localStorage.getItem("store"),
      }
      post("/user/get-fedex-orders", body, { headers }).then(async response => {
        // // console.log("response", response);
        // if(response.data.length > 0) {
        await handleTableData(
          response.data.filter(el => el.error_message != "In Process")
        )
        // setFilteredData(response.data.filter(el => el.error_message != "In Process"))
        // data = response.data.filter(el => el.error_message != "In Process")
        // }

        // setTableData({ columns, data })
        setPending(false)
      })
      await retrieveOrderCounts()
    } catch (e) {
      // console.log(e)
    }
  }

  const retrieveUpsOrders = async () => {
    try {
      // refresh filter on carrier change
      // setNameSort("");
      // setWeightSort("");
      // setQuantitySort("");
      // setFilterShipment("All");

      localStorage.setItem("order-filter", "UPS")
      setPending(true)
      let body = {
        id: selectedsubUser,
        store_id: localStorage.getItem("store"),
      }
      post("/user/get-ups-orders", body, { headers }).then(async response => {
        // // console.log("response", response);
        // if(response.data.length > 0) {
        await handleTableData(
          response.data.filter(el => el.error_message != "In Process")
        )
        // setFilteredData(response.data.filter(el => el.error_message != "In Process"))
        // data = response.data.filter(el => el.error_message != "In Process")
        // }

        // setTableData({ columns, data })
        setPending(false)
      })
      await retrieveOrderCounts()
    } catch (e) {
      // console.log(e)
    }
  }

  const retrieveUpsv2Orders = async () => {
    try {
      // refresh filter on carrier change
      // setNameSort("");
      // setWeightSort("");
      // setQuantitySort("");
      // setFilterShipment("All");

      localStorage.setItem("order-filter", "UPSv2")
      setPending(true)
      let body = {
        id: selectedsubUser,
        store_id: localStorage.getItem("store"),
      }
      post("/user/get-ups-v2-orders", body, { headers }).then(
        async response => {
          // // console.log("response", response);
          // if(response.data.length > 0) {
          await handleTableData(
            response.data.filter(el => el.error_message != "In Process")
          )
          // setFilteredData(response.data.filter(el => el.error_message != "In Process"))
          // data = response.data.filter(el => el.error_message != "In Process")
          // }

          // setTableData({ columns, data })
          setPending(false)
        }
      )
      await retrieveOrderCounts()
    } catch (e) {
      // console.log(e)
    }
  }

  const retrieveDhlOrders = async () => {
    try {
      // refresh filter on carrier change
      // setNameSort("");
      // setWeightSort("");
      // setQuantitySort("");
      // setFilterShipment("All");

      localStorage.setItem("order-filter", "DHL")
      setPending(true)
      let body = {
        id: selectedsubUser,
        store_id: localStorage.getItem("store"),
      }
      post("/user/get-dhl-orders", body, { headers }).then(async response => {
        // // console.log("response", response);
        // if(response.data.length > 0) {
        await handleTableData(
          response.data.filter(el => el.error_message != "In Process")
        )
        // setFilteredData(response.data.filter(el => el.error_message != "In Process"))
        // data = response.data.filter(el => el.error_message != "In Process")
        // }

        // setTableData({ columns, data })
        setPending(false)
      })
      await retrieveOrderCounts()
    } catch (e) {
      // console.log(e)
    }
  }


  const retrieveUspsOrders = async () => {
    try {
      setFilteredData([])
      localStorage.setItem("order-filter", "USPS")
      setPending(true)

      let body = {
        id: selectedsubUser,
        store_id: localStorage.getItem("store"),
      }
      // Await the response from the API call
      const response = await post("/user/get-usps-orders", body, { headers })

      // Handle table data
      await handleTableData(response.data.filter(el => el.error_message != "In Process"))
      // setFilteredData(response.data.filter(el => el.error_message != "In Process"));
      // data = response.data.filter(el => el.error_message != "In Process");
      setPending(false)
      await retrieveOrderCounts()
    } catch (e) {
      console.log(e)
    }
  }

  const getUserBalance = async () => {
    setBalance("-")
    const body = {
      id: JSON.parse(localStorage.getItem("authUser")).role_id == 4 ? JSON.parse(localStorage.getItem("authUser")).id : JSON.parse(localStorage.getItem("authUser")).id,
    }
    await post("/user/get-balance", body, { headers }).then(response => {
      // console.log("response", response)
      if (response.status === 200) {
        localStorage.setItem("balance", response.balance)
        setBalance(response.balance)
        setIsCreditEnable(response.is_credit_enable)
        setMaxCreditAmount(response.max_credit_amount)
      } else {
        // setResponseSuccess(false);
        // setResponseMessage(response.message);
      }
    })
  }
  const getUserPreferences = async () => {
    try {
      const authUser = JSON.parse(localStorage.getItem("authUser"))
      const userId = authUser.id

      // First POST request
      const body1 = {
        user_id: userId,
        store_id: selectedStore,
        type: "awaiting-shipment-columns",
      }
      const response1 = await post("/user/get-user-preferences", body1, {
        headers,
      })

      if (response1.status === 200) {
        if (response1.data.length > 0) {
          const data = JSON.parse(response1.data[0].data)
          setShowHide({
            dimension: data.hideShowDimension,
            weight: data.hideShowWeight,
            status: data.hideShowStatus,
            itemName: data.hideShowItemName,
            serviceName: data.hideShowServiceName,
            fromName: data.hideShowFromName,
            toName: data.hideShowToName,
            notes: data.hideShowNotes,
            tags: data.hideShowTags,
            orderNumber: data.hideShowOrderNumber,
            packageNumber: data.hideShowPackageNumber,
            orderDate: data.hideShowOrderDate,
            updatedAt: data.hideShowUpdatedAt,
            itemNames: data.hideShowItemNames,
            productIds: data.hideShowProductIds,
            productSkus: data.hideShowProductSKU,
            picklists: data.hideShowPicklists,
            assignedUser: data.hideShowAssignedUser
          })
        }
        setRenderTable(true)
      }

      const body2 = {
        user_id: userId,
        store_id: selectedStore,
        type: "display-options",
      }
      const response2 = await post("/user/get-user-preferences", body2, {
        headers,
      })
      if (response2.status === 200 && response2.data.length > 0) {
        const data = JSON.parse(response2.data[0].data)
        setDisplayOption(data.displayOptions)
      }
      if (response2.data.length === 0) {
        setDisplayOption(0)
      }
    } catch (e) {
      console.error("Error in getUserPreferences:", e)
      // Handle error appropriately
    }
  }
  const fetchSubUser = async () => {
    try {
      const userid = JSON.parse(localStorage.getItem("authUser")).role_id == 4 ? JSON.parse(localStorage.getItem("authUser")).parent_id : JSON.parse(localStorage.getItem("authUser")).id;
      // Await the response from the API call
      const response = await get(`/user/fetch-sub-user/${userid}`, { headers });

      if (response.status === 200) {
        // Append the new subUsers to the existing state while keeping the first element intact
        setSubUser((prev) => [...prev, ...response.subUsers]);
      }

      console.log("response in the get", response);
    } catch (error) {
      console.log("error fetching Sub User", error);
    }
  };

  useEffect(() => {
    if (renderTable) {
      retrieveOrdersByFilterType()
    }
  }, [showHide, renderTable, toggleUser])

  useEffect(() => {
    // // console.log("here");
    getUserPreferences()
    selectedGroupBy = ""
    setGroupByDataTablesAll([])
    setGroupByDataTables([])
    setIsRetry(false)
    setFailedOrders([])
    setResponseSuccess(null)
    selectedOrders = []
    getUserBalance()
    retrieveStores()
    retrieveTags()
    retrievePicklist()
    // retrieveOrders();
    retrieveCarriers().then(() => {
      retrieveServices()
    })

    // setInterval(() => {
    //   if(responseSuccess == true || responseSuccess == false) {
    //     setTimeout(() => {
    //       setResponseSuccess(null)
    //       setResponseMessage("")
    //     }, 2000);
    //   }
    // },1000);
  }, [])
  useEffect(() => {
    fetchSubUser()

  }, [])

  useEffect(() => {
    if (responseSuccess == true || responseSuccess == false) {
      setTimeout(() => {
        setResponseSuccess(null)
        setResponseMessage("")
      }, 5000)
    }
  }, [responseSuccess])


  const orderDebounce = (func, delay) => {
    let debounceTimer;
    return function () {
      const context = this;
      const args = arguments;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  };

  useEffect(() => {
    const refreshOrders = orderDebounce(async () => {
      console.log("emit called");
      retrieveOrdersByFilterType(localStorage.getItem("order-filter"));
    }, 300);

    socket.on("refreshOrders", refreshOrders);

    return () => {
      socket.off("refreshOrders", refreshOrders);
    };
  }, []);



  const handleChange = event => {
    // console.log(fileInput.current.files)
  }

  const retrieveOrdersByFilterType = async (filter = filterType) => {
    if (filter == "Unassigned") {
      await retrieveUnassignedOrders()
    }
    if (filter == "FedEx") {
      await retrieveFedexOrders()
    } else if (filter == "UPS") {
      await retrieveUpsOrders()
    } else if (filter == "UPSv2") {
      await retrieveUpsv2Orders()
    } else if (filter == "DHL") {
      await retrieveDhlOrders()
    } else if (filter == "USPS") {
      await retrieveUspsOrders()
    }

    const checkCheckboxExist = setInterval(() => {
      if (document.getElementById("select-all-checkbox")) {
        for (
          let i = 0;
          i < document.getElementsByClassName("select-all-checkbox").length;
          i++
        ) {
          document.getElementsByClassName("select-all-checkbox")[
            i
          ].style.display = "block"
        }
        clearInterval(checkCheckboxExist)
      }
    }, 100)
  }

  const handleImportSubmit = event => {
    event.preventDefault()
    setModal(false)
    uncheckAll()
    selectedOrders = []
    setSelectedOrdersCount(selectedOrders.length)
    clickedSideBarJson = []
    selectedOrderJson = []
    setBulkUpdateServiceId("")
    setBulkUpdateToPhone("")
    setEnableBulkUpdateToPhone(false)

    document.body.style.pointerEvents = "none"
    let loading = toast.loading("Importing orders...")
    // if(selectedService !== "") {
    const formData = new FormData()
    formData.append("file", fileInput.current.files[0])
    formData.append("id", JSON.parse(localStorage.getItem("authUser")).role_id == 4 ? JSON.parse(localStorage.getItem("authUser")).parent_id : JSON.parse(localStorage.getItem("authUser")).id)
    formData.append("service_id", selectedService)
    formData.append("measurement", selectedMeasurement)
    console.log("formData", formData)
    postFile("/user/import-orders", formData).then(response => {
      // // console.log("response", response);
      toast.remove(loading)
      if (response.status === 200) {
        setSelectedMeasurement("pounds")
        if (selectedGroupBy) {
          handleGroupByOrders(selectedGroupBy).then(() => {
            document.body.style.pointerEvents = "auto"
            toast.success(response.message)
          })
        } else {
          retrieveOrdersByFilterType().then(() => {
            document.body.style.pointerEvents = "auto"
            toast.success(response.message)
          })
        }
      } else {
        toast.error(response.message)
      }
    })
  }

  const handleImportWithItemsSubmit = event => {
    event.preventDefault()
    setModalItems(false)
    uncheckAll()
    selectedOrders = []
    setSelectedOrdersCount(selectedOrders.length)
    clickedSideBarJson = []
    selectedOrderJson = []
    setBulkUpdateServiceId("")
    setBulkUpdateToPhone("")
    setEnableBulkUpdateToPhone(false)

    document.body.style.pointerEvents = "none"
    let loading = toast.loading("Importing orders...")
    // if(selectedService !== "") {
    const formData = new FormData()
    formData.append("file", fileInputItems.current.files[0])
    formData.append("id", JSON.parse(localStorage.getItem("authUser")).role_id == 4 ? JSON.parse(localStorage.getItem("authUser")).parent_id : JSON.parse(localStorage.getItem("authUser")).id)
    formData.append("service_id", selectedService)
    formData.append("measurement", selectedMeasurement)

    postFile("/user/import-orders-with-items", formData).then(response => {
      // // console.log("response", response);
      toast.remove(loading)
      if (response.status === 200) {
        setSelectedMeasurement("pounds")
        if (selectedGroupBy) {
          handleGroupByOrders(selectedGroupBy).then(() => {
            document.body.style.pointerEvents = "auto"
            toast.success(response.message)
          })
        } else {
          retrieveOrdersByFilterType().then(() => {
            document.body.style.pointerEvents = "auto"
            toast.success(response.message)
          })
        }
      } else {
        toast.error(response.message)
      }
    })
  }

  const handleDelete = async id => {
    // const id = e.target.getAttribute("data-id");
    let confrm = confirm("Are you sure! you want to delete this order?")
    if (confrm) {
      const body = {
        id: id,
      }
      let loading = toast.loading("Deleting Order...")
      document.body.style.pointerEvents = "none"
      post("/user/delete-order", body, { headers }).then(response => {
        // // console.log("delete-order", response);
        document.body.style.pointerEvents = "auto"
        uncheckAll()
        selectedOrders = []
        setSelectedOrdersCount(selectedOrders.length)
        clickedSideBarJson = []
        selectedOrderJson = []
        setBulkUpdateServiceId("")
        setBulkUpdateToPhone("")
        setEnableBulkUpdateToPhone(false)
        if (response.status === 200) {
          toast.remove(loading)
          if (selectedGroupBy) {
            handleGroupByOrders(selectedGroupBy)
          } else {
            retrieveOrdersByFilterType()
          }
          // toast.success(response.message);
          // retrieveOrders();
        } else {
          toast.remove(loading)
          toast.success(response.message)
        }
      })
    }
  }

  function uncheckAll() {
    let checkboxes = document.getElementsByClassName("order-checkbox")
    selectedOrders = []
    let selectAllCheckbox = document.getElementsByClassName(
      "select-all-checkbox"
    )
    // console.log("checkboxes",checkboxes);
    if (checkboxes.length > 0) {
      selectAllCheckbox[0].checked = false
      for (let i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false
      }
    }
  }

  function uncheckAllTags() {
    let checkboxes = document.getElementsByClassName("tag")
    selectedTags = []
    if (checkboxes.length > 0) {
      for (let i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false
      }
    }
  }

  function checkAll(e) {
    const { checked } = e.target
    let checkboxes = document.getElementsByClassName("order-checkbox")
    selectedOrders = []
    let arr = clickedSideBarJson
    for (let i = 0; i < checkboxes.length; i++) {
      if (!checked) {
        checkboxes[i].checked = false
        selectedOrders = []
        arr = []
      } else {
        checkboxes[i].checked = true
        if (!selectedOrders.includes(checkboxes[i].value)) {
          selectedOrders.push(checkboxes[i].value)
        }
        let index = sideBarData.findIndex(el => el.id == checkboxes[i].value)
        arr.push(sideBarData[index])
      }
    }

    clickedSideBarJson = arr

    setSelectedOrdersCount(selectedOrders.length)
    // console.log("selectedOrders all",selectedOrders)
  }

  const handleCalculatedPrice = async () => {
    document.body.style.pointerEvents = "none"
    if (selectedGroupBy) {
      console.log("response getting")
      await getUserBalance()
      handleGroupByOrders(selectedGroupBy, false).then(() => {
        setVerificationModal(false)
        let body = {
          user_id: JSON.parse(localStorage.getItem("authUser")).role_id == 4 ? JSON.parse(localStorage.getItem("authUser")).parent_id : JSON.parse(localStorage.getItem("authUser")).id,
          ids: selectedOrders.toString(),
        }
        post("/user/calculate-order-amount", body, { headers }).then(
          async response => {
            console.log("response", response)
            if (response.status === 200) {
              // console.log("calculate-order-amount data", response);
              setCalculatedAmounts(response.data)
              setCalculatedAmountsAboveFiveLbs(response.dataAboveFiveLbs)
              setCalculatedAmountsUnderOneLbs(response.dataUnderOneLbs)
              setCalculatedAmountsAboveTwentyLbs(response.dataAboveTwentyLbs)

              let price = 0
              let above5lbsPrice = 0
              let under1lbsPrice = 0
              let above20lbsPrice = 0

              if (response.dataUnderOneLbs.length > 0) {
                under1lbsPrice = await response.dataUnderOneLbs.map(item => item.price).reduce((prev, next) => prev + next)
              }

              if (response.data.length > 0) {
                price = await response.data
                  .map(item => item.price)
                  .reduce((prev, next) => prev + next)
              }

              if (response.dataAboveFiveLbs.length > 0) {
                above5lbsPrice = await response.dataAboveFiveLbs
                  .map(item => item.price)
                  .reduce((prev, next) => prev + next)
              }

              if (response.dataAboveTwentyLbs.length > 0) {
                above20lbsPrice = await response.dataAboveTwentyLbs
                  .map(item => item.price)
                  .reduce((prev, next) => prev + next)
              }

              // console.log("price", price)
              // console.log("above5lbsPrice", above5lbsPrice)

              setTotalAmount(
                parseFloat(price) +
                parseFloat(above5lbsPrice) +
                parseFloat(under1lbsPrice) +
                parseFlat(above20lbsPrice)
              )
              setOrderDetailsModal(!orderDetailsModal)
            }
          }
        )
        document.body.style.pointerEvents = "auto"
      })
    } else {
      retrieveOrdersByFilterType().then(() => {
        setVerificationModal(false)
        let body = {
          user_id: JSON.parse(localStorage.getItem("authUser")).role_id == 4 ? JSON.parse(localStorage.getItem("authUser")).parent_id : JSON.parse(localStorage.getItem("authUser")).id,
          ids: selectedOrders.toString(),
        }
        post("/user/calculate-order-amount", body, { headers }).then(
          async response => {
            console.log("rrs", response)
            if (response.status === 200) {
              // console.log("calculate-order-amount data", response);
              setCalculatedAmounts(response.data)
              setCalculatedAmountsAboveFiveLbs(response.dataAboveFiveLbs)
              setCalculatedAmountsUnderOneLbs(response.dataUnderOneLbs)
              setCalculatedAmountsAboveTwentyLbs(response.dataAboveTwentyLbs)

              let price = 0
              let above5lbsPrice = 0
              let under1lbsPrice = 0
              let above20lbsPrice = 0

              if (response.dataUnderOneLbs.length > 0) {
                under1lbsPrice = await response.dataUnderOneLbs
                  .map(item => item.price)
                  .reduce((prev, next) => prev + next)
              }

              if (response.data.length > 0) {
                price = await response.data
                  .map(item => item.price)
                  .reduce((prev, next) => prev + next)
              }

              if (response.dataAboveFiveLbs.length > 0) {
                above5lbsPrice = await response.dataAboveFiveLbs
                  .map(item => item.price)
                  .reduce((prev, next) => prev + next)
              }

              if (response.dataAboveTwentyLbs.length > 0) {
                above20lbsPrice = await response.dataAboveTwentyLbs
                  .map(item => item.price)
                  .reduce((prev, next) => prev + next)
              }

              // console.log("price", price)
              // console.log("above5lbsPrice", above5lbsPrice)

              setTotalAmount(
                parseFloat(price) +
                parseFloat(above5lbsPrice) +
                parseFloat(under1lbsPrice) +
                parseFloat(above20lbsPrice)
              )
              setOrderDetailsModal(!orderDetailsModal)
            }
          }
        )

        document.body.style.pointerEvents = "auto"
      })
    }
  }

  const handleBulkDelete = async () => {
    if (selectedOrders.length > 0) {
      let confirmation = confirm(
        "Are you sure! you want to delete these orders?"
      )
      if (confirmation) {
        let loading = toast.loading("Deleting Orders...")
        document.body.style.pointerEvents = "none"
        let body = {
          ids: selectedOrders.toString(),
        }
        post("/user/delete-bulk-orders", body, { headers }).then(
          async response => {
            if (response.status === 200) {
              toast.remove(loading)
              document.body.style.pointerEvents = "auto"
              uncheckAll()
              selectedOrders = []
              setSelectedOrdersCount(selectedOrders.length)
              clickedSideBarJson = []
              selectedOrderJson = []
              setBulkUpdateServiceId("")
              setBulkUpdateToPhone("")
              setEnableBulkUpdateToPhone(false)

              if (selectedGroupBy) {
                handleGroupByOrders().then(() => {
                  toast.success(response.message)
                })
              } else {
                retrieveOrdersByFilterType().then(() => {
                  toast.success(response.message)
                })
              }
            } else {
              toast.remove(loading)
              toast.error(response.message)
            }
          }
        )
      }
    } else {
      toast.error("Please select at least one order")
    }
  }

  const handleExport = async () => {
    if (selectedOrders.length > 0) {
      let loading = toast.loading("Exporting orders...")
      document.body.style.pointerEvents = "none"
      const header =
        "FromCountry,FromName,FromCompany,FromPhone,FromStreet1,FromStreet2,FromCity,FromZip,FromState,ToCountry,ToName,ToCompany,ToPhone,ToStreet1,ToStreet2,ToCity,ToZip,ToState,Length,Height,Width,Weight"
      let orders = ""

      let body = {
        ids: selectedOrders.toString(),
      }
      post("/user/get-bulk-orders-by-ids", body, { headers })
        .then(response => {
          // console.log("bulk orders export", response)
          if (response.status === 200) {
            let ordersByApi = response.data
            for (let i = 0; i < ordersByApi.length; i++) {
              let order = ordersByApi[i]
              orders =
                orders +
                `${order.from_country},${order.from_name},${order.from_company},${order.to_phone},${order.from_street1},${order.from_street2},${order.from_city},${order.from_zip},${order.from_state},${order.to_country},${order.to_name},${order.to_company},${order.to_phone},${order.to_street1},${order.to_street2},${order.to_city},${order.to_zip},${order.to_state},${order.length},${order.height},${order.width},${order.weight}\n`
            }
          }
          // // console.log("orders",orders);
          return response
        })
        .then(response => {
          if (response.status === 200) {
            let data = header + "\n" + orders
            const blob = new Blob([data], { type: "text/csv;charset=utf-8" })
            saveAs(
              blob,
              "exported-order-" +
              moment(new Date()).format("DD-MMM-YYYY") +
              ".csv"
            )
            uncheckAll()
            selectedOrders = []
            setSelectedOrdersCount(selectedOrders.length)
            clickedSideBarJson = []
            selectedOrderJson = []
            setBulkUpdateServiceId("")
            setBulkUpdateToPhone("")
            setEnableBulkUpdateToPhone(false)
            toast.success("Orders exported successfully")
            document.body.style.pointerEvents = "auto"
            toast.remove(loading)
          } else {
            document.body.style.pointerEvents = "auto"
            toast.remove(loading)
            toast.error(response.message)
          }
        })
        .catch(err => {
          selectedOrders = []
          toast.error("Something went wrong")
          document.body.style.pointerEvents = "auto"
          toast.remove(loading)
        })
    } else {
      toast.error("Please select at least one order")
    }
  }

  const handleRetry = async () => {
    if (failedOrders.length > 0) {
      let body = {
        user_id: JSON.parse(localStorage.getItem("authUser")).role_id == 4 ? JSON.parse(localStorage.getItem("authUser")).parent_id : JSON.parse(localStorage.getItem("authUser")).id,
        ids: failedOrders.toString(),
      }
      post("/user/calculate-order-amount", body, { headers }).then(
        async response => {
          if (response.status === 200) {
            // // console.log("data", response);
            setCalculatedAmounts(response.data)
            setCalculatedAmountsAboveFiveLbs(response.dataAboveFiveLbs)
            setOrderDetailsModal(!orderDetailsModal)
          }
        }
      )
    }
  }

  const upsPrintBulk = async () => {
    document.body.style.pointerEvents = "none"
    setOrderDetailsModal(!orderDetailsModal)
    let loading = toast.loading("Processing bulk Request...")
    let body = {
      user_id: JSON.parse(localStorage.getItem("authUser")).role_id == 4 ? JSON.parse(localStorage.getItem("authUser")).parent_id : JSON.parse(localStorage.getItem("authUser")).id,
      ids: selectedOrders.toString(),
      description: `${JSON.parse(localStorage.getItem("authUser")).first_name} Printend label`

    }
    post("/user/print-batch-orders-ups", body, { headers }).then(
      async response => {
        // toast.remove(loading);
        selectedOrders = []
        setSelectedOrdersCount(selectedOrders.length)
        clickedSideBarJson = []
        await getUserBalance()
        if (selectedGroupBy) {
          await handleGroupByOrders(selectedGroupBy)
        } 
        else {
          await retrieveOrdersByFilterType()
        }
        uncheckAll()
        selectedOrders = []
        setSelectedOrdersCount(selectedOrders.length)
        clickedSideBarJson = []
        selectedOrderJson = []
        setBulkUpdateServiceId("")
        setBulkUpdateToPhone("")
        setEnableBulkUpdateToPhone(false)
        document.body.style.pointerEvents = "auto"
        if (response.status === 200) {
          toast.remove(loading)
          setResponseSuccess(true)
          setResponseMessage(response.message)
        } 
        else {
          toast.remove(loading)
          setResponseSuccess(false)
          setResponseMessage(response.message)
        }
      }
    )
  }
  const upsPrint = async () => {
    document.body.style.pointerEvents = "none"
    setOrderDetailsModal(!orderDetailsModal)
    let loading = toast.loading("Processing Request...")
    let body = {
      user_id: JSON.parse(localStorage.getItem("authUser")).role_id == 4 ? JSON.parse(localStorage.getItem("authUser")).parent_id : JSON.parse(localStorage.getItem("authUser")).id,
      ids: selectedOrders.toString(),
      totalAmount: parseFloat(totalAmount),
      carrier: "UPS",
      type: null,
      description: `${JSON.parse(localStorage.getItem("authUser")).first_name} Printend label`,
      walletId: JSON.parse(localStorage.getItem("authUser")).id,


    }
    get("/user/active-service-provider", { headers }).then(apiResponse => {
      console.log("apiresponse", apiResponse)
      if (apiResponse.status == 200) {
        let url = ""
        console.log(apiResponse.active_api,'apiResponse.active_api')
        if (apiResponse.active_api == "easypost") {
          url = "/user/print-orders-ups-updated"
          body.type = apiResponse.active_api
        }
        else if (apiResponse.active_api == "shipengine") {
          url = "/shipengine/create-shipment"
          body.type = apiResponse.active_api
        }
        else if (apiResponse.active_api == "aftership-label") {
          url = "/aftership/create-shipment"
          body.type = apiResponse.active_api
        }
        else if (apiResponse.active_api == "express-label") {
          url = "/express/create-shipment"
          body.type = apiResponse.active_api
        }
        else {
          toast.error("Service unavailable");
        }

        if (url) {
          console.log("url", url)
          document.body.style.pointerEvents = "auto"
          post(url, body, { headers }).then(async response => {
            selectedOrders = []
            setSelectedOrdersCount(selectedOrders.length)
            await getUserBalance()
            if (selectedGroupBy) {
              await handleGroupByOrders(selectedGroupBy)
            } 
            else {
              await retrieveOrdersByFilterType()
            }
            uncheckAll()
            document.body.style.pointerEvents = "auto"
            if (response.status === 200) {
              toast.remove(loading)
              setResponseSuccess(true)
              setResponseMessage(response.message)
            } 
            else {
              toast.remove(loading)
              setResponseSuccess(false)
              setResponseMessage(response.message)
            }
          })
        }
      }
      else {
        toast.error(apiResponse.message)
      }
    })
  }

  const upsV2Print = async () => {
    document.body.style.pointerEvents = "none"
    setOrderDetailsModal(!orderDetailsModal)
    let body = {
      user_id: JSON.parse(localStorage.getItem("authUser")).role_id == 4 ? JSON.parse(localStorage.getItem("authUser")).parent_id : JSON.parse(localStorage.getItem("authUser")).id,
      ids: selectedOrders.toString(),
      totalAmount: parseFloat(totalAmount),
      description: `${JSON.parse(localStorage.getItem("authUser")).first_name} Printend label`,
      walletId: JSON.parse(localStorage.getItem("authUser")).id,
    }
    post("/user/print-orders-ups-v2", body, { headers }).then(
      async response => {
        // if (response.pdf && response.pdf.length > 0) {
        //   const pdfUrls = response.pdf;
        //   const zip = new JSZip();
        //   let loadingComp = toast.loading("Creating compressed file...");
        //   new Promise(async function(resolve, reject) {
        //     for (let i = 0; i < pdfUrls.length; i++) {
        //       await axios.get(`${baseUrl()}/user/get-pdf/${pdfUrls[i]}`, { headers, responseType: "blob" })
        //         .then(async (response) => {
        //           // console.log(response.headers["file-name"]);
        //           const blob = new Blob([response.data], { type: 'application/pdf' });
        //           await zip.file(`${response.headers["file-name"]}.pdf`, blob)
        //
        //           if(i === (pdfUrls.length - 1)) {
        //             resolve(zip);
        //           }
        //         });
        //     }
        //   }).then(async () => {
        //     await zip.generateAsync({ type: "blob" })
        //       .then(function (content) {
        //         toast.remove(loadingComp);
        //         saveAs(content, "orders.zip");
        //       });
        //   })
        // }
        selectedOrders = []
        setSelectedOrdersCount(selectedOrders.length)
        // clickedSideBarJson = []
        await getUserBalance()
        if (selectedGroupBy) {
          await handleGroupByOrders(selectedGroupBy)
        } else {
          await retrieveUpsv2Orders()
        }
        uncheckAll()
        document.body.style.pointerEvents = "auto"
        if (response.status === 200) {
          toast.remove(loading)
          setResponseSuccess(true)
          setResponseMessage(response.message)
        } else {
          toast.remove(loading)
          setResponseSuccess(false)
          setResponseMessage(response.message)
        }
        // if(response.success_orders.length > 0 ) {
        //   let body = {
        //     data: response.success_orders
        //   };
        //   // console.log("generate tracking body", body);
        //   await post('/user/generate-tracking', body, {headers});
        // }
      }
    )
  }

  const fedExPrintBulk = async () => {
    document.body.style.pointerEvents = "none"
    setOrderDetailsModal(!orderDetailsModal)
    let loading = toast.loading("Processing bulk Request...")
    let body = {
      user_id: JSON.parse(localStorage.getItem("authUser")).role_id == 4 ? JSON.parse(localStorage.getItem("authUser")).parent_id : JSON.parse(localStorage.getItem("authUser")).id,
      ids: isRetry ? failedOrders.toString() : selectedOrders.toString(),
      description: `${JSON.parse(localStorage.getItem("authUser")).first_name} Printend label`
    }
    post("/user/print-batch-orders-fedex", body, { headers }).then(
      async response => {
        // toast.remove(loading);
        selectedOrders = []
        setSelectedOrdersCount(selectedOrders.length)
        clickedSideBarJson = []
        await getUserBalance()
        if (selectedGroupBy) {
          await handleGroupByOrders(selectedGroupBy)
        }
        else {
          await retrieveUpsOrders()
        }
        uncheckAll()
        document.body.style.pointerEvents = "auto"
        if (response.status === 200) {
          toast.remove(loading)
          setResponseSuccess(true)
          setResponseMessage(response.message)
        }
        else {
          toast.remove(loading)
          setResponseSuccess(false)
          setResponseMessage(response.message)
        }
      }
    )
  }

  const fedExPrint = async () => {
    document.body.style.pointerEvents = "none"
    setOrderDetailsModal(!orderDetailsModal)
    let loading = toast.loading("Processing Request...")
    let body = {
      user_id: JSON.parse(localStorage.getItem("authUser")).role_id == 4 ? JSON.parse(localStorage.getItem("authUser")).parent_id : JSON.parse(localStorage.getItem("authUser")).id,
      ids: selectedOrders.toString(),
      totalAmount: parseFloat(totalAmount),
      carrier: "FedEx",
      type: null,
      api_url: null,
      description: `${JSON.parse(localStorage.getItem("authUser")).first_name} Printend label`,
      walletId: JSON.parse(localStorage.getItem("authUser")).id,

    }
    get("/user/active-service-provider", { headers }).then(apiResponse => {
      if (apiResponse.status == 200) {
        let url = ""
        if (apiResponse.active_api == "easypost") {
          url = "/user/print-orders-fedex-updated"
          body.type = apiResponse.active_api
        }
        else if (apiResponse.active_api == "shipengine") {
          url = "/shipengine/create-shipment"
          body.type = apiResponse.active_api
        }
        else if (apiResponse.active_api == "aftership-label") {
          url = "/aftership/create-shipment"
          body.type = apiResponse.active_api
        }
        else if (apiResponse.active_api == "shippo-ip") {
          url = "/user/print-orders-fedex-updated2"
          body.api_url = apiResponse.api_key
        }
        if (url) {
          post(url, body, { headers }).then(async response => {
            selectedOrders = []
            setSelectedOrdersCount(selectedOrders.length)
            await getUserBalance()
            if (selectedGroupBy) {
              await handleGroupByOrders(selectedGroupBy)
            }
            else {
              await retrieveOrdersByFilterType()
            }
            uncheckAll()
            document.body.style.pointerEvents = "auto"
            if (response.status === 200) {
              toast.remove(loading)
              setResponseSuccess(true)
              setResponseMessage(response.message)
            }
            else {
              toast.remove(loading)
              setResponseSuccess(false)
              setResponseMessage(response.message)
            }
          })
        }
      }
      else {
        toast.error(apiResponse.message)
      }
    })
  }

  const dhlPrint = async () => {
    document.body.style.pointerEvents = "none"
    setOrderDetailsModal(!orderDetailsModal)
    let loading = toast.loading("Processing Request...")
    let body = {
      user_id: JSON.parse(localStorage.getItem("authUser")).role_id == 4 ? JSON.parse(localStorage.getItem("authUser")).parent_id : JSON.parse(localStorage.getItem("authUser")).id,
      ids: selectedOrders.toString(),
      totalAmount: parseFloat(totalAmount),
      description: `${JSON.parse(localStorage.getItem("authUser")).first_name} Printend label`,
      walletId: JSON.parse(localStorage.getItem("authUser")).id,

    }
    post("/user/submit-orders-dhl-usps", body, { headers }).then(
      async response => {
        setTimeout(async () => {
          toast.remove(loading)
          await getUserBalance()
          if (selectedGroupBy) {
            await handleGroupByOrders(selectedGroupBy)
          }
          else { await retrieveDhlOrders(); }
          uncheckAll()
          selectedOrders = []
          setSelectedOrdersCount(selectedOrders.length)
          document.body.style.pointerEvents = "auto"
          if (response.status === 200) {
            setResponseSuccess(true)
            setResponseMessage(response.message)
          }
          else {
            toast.remove(loading)
            setResponseSuccess(false)
            setResponseMessage(response.message)
          }
        }, 3000)
      }
    )
  }


  const uspsPrint = async () => {

    document.body.style.pointerEvents = "none"
    setOrderDetailsModal(!orderDetailsModal)
    let loading = toast.loading("Processing Request...")
    let body = {
      user_id: JSON.parse(localStorage.getItem("authUser")).role_id == 4 ? JSON.parse(localStorage.getItem("authUser")).parent_id : JSON.parse(localStorage.getItem("authUser")).id,
      walletId: JSON.parse(localStorage.getItem("authUser")).id,
      ids: selectedOrders.toString(),
      totalAmount: parseFloat(totalAmount),
      type: null,
      description: `${JSON.parse(localStorage.getItem("authUser")).role_id == 4 ? `Sub User ${JSON.parse(localStorage.getItem("authUser")).first_name} ` : `${JSON.parse(localStorage.getItem("authUser")).first_name}`} Printend label`

    }
    post("/user/print-orders-usps-updated", body, { headers }).then(
      async response => {
        console.log("response", response)
        uncheckAll()
        selectedOrders = []
        setSelectedOrdersCount(selectedOrders.length)
        await getUserBalance()
        if (selectedGroupBy) { await handleGroupByOrders(selectedGroupBy) }
        else { await retrieveUspsOrders() }

        document.body.style.pointerEvents = "auto"
        if (response.status === 200) {
          toast.remove(loading)
          setResponseSuccess(true)
          setResponseMessage(response.message)
        }
        else {
          toast.remove(loading)
          setResponseSuccess(false)
          setResponseMessage(response.message)
        }
      });
  }

  const handleBulkProceed = async () => {
    if (selectedOrders.length > 0 || failedOrders.length > 0) {
      if (filterType === "FedEx") {
        // //FedEx
        // console.log("printing fedex orders");
        await fedExPrintBulk()
      } else if (filterType === "UPS") {
        // //UPS
        // console.log("printing ups orders");
        await upsPrintBulk()
      }
    } else {
      toast.error("Please select at least one order")
    }
  }

  const handleProceed = async () => {
    if (selectedOrders.length > 0 || failedOrders.length > 0) {
      if (filterType === "FedEx") {
        await fedExPrint()
      }
      else if (filterType === "UPS") {
        await upsPrint()
      }
      else if (filterType === "UPSv2") {
        await upsV2Print()
      }
      else if (filterType === "DHL") {
        await dhlPrint()
      }
      else if (filterType === "USPS") {
        await uspsPrint()
      }
    }
    else {
      toast.error("Please select at least one order")
    }
  }

  const handleService = async e => {
    const { value } = e.target
    console.log("object", value)
    // console.log("selected service", selectedService)
    setSelectedService(value)
    setSelectedServiceName(e.target.getAttribute("data-name"))
  }

  const handleNoServiceOrders = async () => {
    // document.getElementsByClassName("filter-text")[0].value = ""
    setFilterType("Unassigned")
    selectedGroupBy = ""
    setGroupByDataTables([])
    setGroupByDataTablesAll([])
    selectedOrders = []
    selectedOrderJson = []
    setSelectedOrdersCount(selectedOrders.length)
    clickedSideBarJson = []
    uncheckAll()
    await retrieveUnassignedOrders()
  }

  const handleFedExOrders = async () => {
    // document.getElementsByClassName("filter-text")[0].value = ""
    selectedGroupBy = ""
    setGroupByDataTables([])
    setGroupByDataTablesAll([])
    setFilteredData([])
    setAllData([])
    setFilterType("FedEx")
    selectedOrders = []
    selectedOrderJson = []
    setSelectedOrdersCount(selectedOrders.length)
    clickedSideBarJson = []
    uncheckAll()
    await retrieveFedexOrders()
  }

  const handleUPSOrders = async () => {
    // document.getElementsByClassName("filter-text")[0].value = ""
    selectedGroupBy = ""
    setGroupByDataTables([])
    setGroupByDataTablesAll([])
    setFilteredData([])
    setAllData([])
    setFilterType("UPS")
    selectedOrders = []
    selectedOrderJson = []
    setSelectedOrdersCount(selectedOrders.length)
    clickedSideBarJson = []
    uncheckAll()
    await retrieveUpsOrders()
  }

  const handleUPSv2Orders = async () => {
    // document.getElementsByClassName("filter-text")[0].value = ""
    selectedGroupBy = ""
    setGroupByDataTables([])
    setGroupByDataTablesAll([])
    setFilteredData([])
    setAllData([])
    setFilterType("UPSv2")
    selectedOrders = []
    selectedOrderJson = []
    setSelectedOrdersCount(selectedOrders.length)
    clickedSideBarJson = []
    uncheckAll()
    await retrieveUpsv2Orders()
  }

  const handleDHLOrders = async () => {
    selectedGroupBy = ""
    setGroupByDataTables([])
    setGroupByDataTablesAll([])
    setFilteredData([])
    setAllData([])
    setFilterType("DHL")
    selectedOrders = []
    selectedOrderJson = []
    setSelectedOrdersCount(selectedOrders.length)
    clickedSideBarJson = []
    uncheckAll()
    await retrieveDhlOrders()
  }

  const handleUSPSOrders = async () => {
    selectedGroupBy = ""
    setGroupByDataTables([])
    setGroupByDataTablesAll([])
    setFilteredData([])
    setAllData([])
    setFilterType("USPS")
    selectedOrders = []
    selectedOrderJson = []
    setSelectedOrdersCount(selectedOrders.length)
    clickedSideBarJson = []
    uncheckAll()
    retrieveUspsOrders()
  }

  const handleCarrier = async e => {
    setSelectedCarrier(e.target.value)
    setSelectedCarrierName(e.target.getAttribute("data-name"))
    setSelectedService(null)
    setSelectedServiceName(null)
    setSelectAvailableService(false)
    setSelectAvailableCarrier(false)
    const ele = document.getElementsByName("service_id")
    for (let i = 0; i < ele.length; i++) { ele[i].checked = false }
  }

  const handleImport = () => {
    setModal(!modal)
    setSelectedMeasurement("pounds")
    // setSelectedCarrier("")
    // setSelectedCarrierName(null)
    // setSelectedService("")
    // setSelectedServiceName(null)
    // setSelectAvailableService(false)
    // setSelectAvailableCarrier(false)
    const ele = document.getElementsByName("service_id")
    for (let i = 0; i < ele.length; i++) {
      ele[i].checked = false
    }
  }

  const handleImportWithItems = () => {
    setModalItems(!modalItems)
    setSelectedMeasurement("pounds")
    const ele = document.getElementsByName("service_id")
    for (let i = 0; i < ele.length; i++) { ele[i].checked = false }
  }

  const retrieveStores = async () => {
    try {
      let body = { user_id: JSON.parse(localStorage.getItem("authUser")).role_id == 4 ? JSON.parse(localStorage.getItem("authUser")).parent_id : JSON.parse(localStorage.getItem("authUser")).id }
      post("/user/getStores", body, { headers })
      .then(response => {
        let arr = []
        arr.push({ name: "Manual Store", value: "0", type: "manual" })
        for (let i = 0; i < response.data.length; i++) { arr.push({ name: response.data[i].name, value: response.data[i].id.toString(), type: response.data[i].store_type }) }
        setStores(arr)
        setCurrentStore(arr.filter(el => el.value == localStorage.getItem("store"))[0])
        if (arr?.filter(el => el.value == localStorage.getItem("store"))[0]?.type == "zenventory") { hideShowZenventory = false }
        else { hideShowZenventory = true }
        columns[12].omit = hideShowZenventory
        if (arr?.filter(el => el.value == localStorage.getItem("store"))[0]?.type == "sellercloud") { hideShowChannelNumber = false }
        else { hideShowChannelNumber = true }
        columns[14].omit = hideShowChannelNumber
      })
    }
    catch (e) {
      console.log(e, 'e in retrieveStores functions')
    }
  }

  document.title = "Awaiting Shipment | NullShip"

  const retrieveOrder = async oid => {
    try {
      let body = {
        id: oid,
      }
      post("/user/get-order", body, { headers }).then(async response => {
        // console.log("get order", response)
        let data = response.data[0]
        setSingleOrderWeight(data.weight)
        setSingleOrderServiceId(data.service_id != null ? data.service_id : "")
        setSingleOrderWidth(data.width != null ? data.width : "")
        setSingleOrderHeight(data.height != null ? data.height : "")
        setSingleOrderLength(data.length != null ? data.length : "")
        setSingleOrderRate("")
        if (data.weight && data.service_id != null) {
          const body = {
            user_id: JSON.parse(localStorage.getItem("authUser")).role_id == 4 ? JSON.parse(localStorage.getItem("authUser")).parent_id : JSON.parse(localStorage.getItem("authUser")).id,
            service_id: data.service_id,
            weight: data.weight,
          }
          await post("/shopify/get-service-rates-by-weight-and-service", body, {
            headers,
          }).then(response => {
            // console.log("get rate response", response)
            if (response.status === 200) {
              setSingleOrderRate(response.data.servicePrice)
            } else {
              setResponseSuccess(false);
              setResponseMessage(response.message);
            }
          })
        }
      })
    } catch (e) {
      // console.log(e)
    }
  }

  const retrieveOrderWeight = async oid => {
    return new Promise(resolve => {
      try {
        let body = {
          id: oid,
        }
        post("/user/get-order-weight", body, { headers }).then(
          async response => {
            // console.log("response",response)
            let data = response.data[0]
            console.log("data", data)
            return resolve(data)
          }
        )
      } catch (e) {
        // console.log(e)
      }
    })
  }

  const handleRowClick = row => {
    // Handle row click event here
    // let data = row
    // // console.log("data", JSON.parse(data.custom_items_data))
    // // // console.log("data.id", data.id)
    // // // console.log("tableData", tableData)
    // let index = tableData.data.findIndex(el => el.id == data.id)
    // setOrderUniqueId(tableData.data[index].order_unique_id)
    //
    // retrieveOrder(data.id)
    // uncheckAll();
    // document.getElementById(`order-${data.id}`).checked = true;
    // selectedOrders = [data.id];
    // // console.log("handleRowClick selectedOrders", selectedOrders);
    // selectedOrderJson.push(data);
    // // console.log("selectedOrderJson", selectedOrderJson);
    // setClickedJson(selectedOrderJson);
    // console.log(`order-${row.id}`)
    document.getElementById(`order-${row.id}`).click()
  }

  const handleOrderWeight = async e => {
    setSingleOrderWeight(e.target.value)
    if (
      e.target.value &&
      parseFloat(e.target.value) > 0 &&
      SingleOrderServiceId
    ) {
      const body = {
        user_id: JSON.parse(localStorage.getItem("authUser")).role_id == 4 ? JSON.parse(localStorage.getItem("authUser")).parent_id : JSON.parse(localStorage.getItem("authUser")).id,
        service_id: SingleOrderServiceId,
        weight: e.target.value,
      }
      await post("/shopify/get-service-rates-by-weight-and-service", body, {
        headers,
      }).then(response => {
        // console.log("get rate response", response)
        if (response.status === 200) {
          setSingleOrderRate(response.data.servicePrice)
        } else {
          setResponseSuccess(false);
          setResponseMessage(response.message);
        }
      })
    }
  }

  const handleServiceOrder = async e => {
    setSingleOrderServiceId(e.target.value)
    let index = services.findIndex(el => el.id == e.target.value)
    if (
      services[index].carrier_name == "DHL" ||
      services[index].carrier_name == "USPS"
    ) {
      setSingleIsDimenssion(true)
    } else {
      setSingleIsDimenssion(false)
    }
    if (
      e.target.value &&
      parseFloat(singleOrderWeight) > 0 &&
      singleOrderWeight
    ) {
      const body = {
        user_id: JSON.parse(localStorage.getItem("authUser")).role_id == 4 ? JSON.parse(localStorage.getItem("authUser")).parent_id : JSON.parse(localStorage.getItem("authUser")).id,
        service_id: e.target.value,
        weight: singleOrderWeight,
      }
      await post("/shopify/get-service-rates-by-weight-and-service", body, {
        headers,
      }).then(response => {
        // console.log("get rate response", response)
        if (response.status === 200) {
          setSingleOrderRate(response.data.servicePrice)
        } else {
          // setResponseSuccess(false);
          // setResponseMessage(response.message);
        }
      })
    } else {
      toast.error("Please select an service")
    }
  }

  const handleServiceOrderBelowFiveLbs = async e => {
    if (e.target.value) {
      setBelowFiveLbsService(e.target.value)
      const body = {
        user_id: JSON.parse(localStorage.getItem("authUser")).role_id == 4 ? JSON.parse(localStorage.getItem("authUser")).parent_id : JSON.parse(localStorage.getItem("authUser")).id,
        service_id: e.target.value,
        weight: 1,
      }
      await post("/shopify/get-service-rates-by-weight-and-service", body, {
        headers,
      }).then(response => {
        // console.log("get rate response", response)
        if (response.status === 200) {
          setServiceRateBelowFiveLbs(response.data.servicePrice)
        } else {
          toast.error(response.message)
        }
      })
    }
  }

  const handleServiceOrderAboveFiveLbs = async e => {
    if (e.target.value) {
      setAboveFiveLbsService(e.target.value)
      const body = {
        user_id: JSON.parse(localStorage.getItem("authUser")).role_id == 4 ? JSON.parse(localStorage.getItem("authUser")).parent_id : JSON.parse(localStorage.getItem("authUser")).id,
        service_id: e.target.value,
        weight: 6,
      }
      await post("/shopify/get-service-rates-by-weight-and-service", body, {
        headers,
      }).then(response => {
        // console.log("get rate response", response)
        if (response.status === 200) {
          setServiceRateAboveFiveLbs(response.data.servicePrice)
        } else {
          toast.error(response.message)
        }
      })
    }
  }

  const handleServiceOrderUnderOneLbs = async e => {
    if (e.target.value) {
      setUnderOneLbsService(e.target.value)
      const body = {
        user_id: JSON.parse(localStorage.getItem("authUser")).role_id == 4 ? JSON.parse(localStorage.getItem("authUser")).parent_id : JSON.parse(localStorage.getItem("authUser")).id,
        service_id: e.target.value,
        weight: 0.5,
      }
      await post("/shopify/get-service-rates-by-weight-and-service", body, {
        headers,
      }).then(response => {
        // console.log("get rate response", response)
        if (response.status === 200) {
          setServiceRateUnderOneLbs(response.data.servicePrice)
        } else {
          toast.error(response.message)
        }
      })
    }
  }

  const handleServiceOrderAboveTwentyLbs = async e => {
    if (e.target.value) {
      setAboveTwentyLbsService(e.target.value)
      const body = {
        user_id: JSON.parse(localStorage.getItem("authUser")).role_id == 4 ? JSON.parse(localStorage.getItem("authUser")).parent_id : JSON.parse(localStorage.getItem("authUser")).id,
        service_id: e.target.value,
        weight: 21,
      }
      await post("/shopify/get-service-rates-by-weight-and-service", body, {
        headers,
      }).then(response => {
        // console.log("get rate response", response)
        if (response.status === 200) {
          setServiceRateAboveTwentyLbs(response.data.servicePrice)
        } else {
          toast.error(response.message)
        }
      })
    }
  }

  const singleOrderUpdate = async () => {
    // console.log("working...")
    if (singleOrderWeight && SingleOrderServiceId) {
      if (singleOrderWeight == 0) {
        toast.error("Shipment weight must be greater than 0")
        return true
      }
      if (clickedJson.length == 1) {
        const body = {
          user_id: JSON.parse(localStorage.getItem("authUser")).role_id == 4 ? JSON.parse(localStorage.getItem("authUser")).parent_id : JSON.parse(localStorage.getItem("authUser")).id,
          service_id: SingleOrderServiceId,
          weight: singleOrderWeight,
          order_id: selectedOrders[0],
          length: singleOrderLength,
          width: singleOrderWidth,
          height: singleOrderHeight,
          ship_to_phone: singleUpdateToPhone,
        }
        await post("/shopify/update-single-order", body, {
          headers,
        }).then(async response => {
          // console.log("get rate response", response)
          if (selectedGroupBy) {
            await handleGroupByOrders(selectedGroupBy)
          } else {
            await retrieveOrdersByFilterType()
          }

          if (response.status === 200) {
            toast.success(response.message)
            document.body.style.overflow = "visible"
            setClickedJson([])
            let x = []
            clickedSideBarJson = x
            uncheckAll()
            selectedOrders = []
            setSelectedOrdersCount(selectedOrders.length)
            clickedSideBarJson = []
            selectedOrderJson = []
            setSingleOrderWeight("")
            setSingleOrderServiceId("")
            setSingleOrderWidth("")
            setSingleOrderHeight("")
            setSingleOrderLength("")
            setIsFullTextVisible(false)
            setSingleUpdateToPhone("")
            setEnableSingleUpdateToPhone(false)

            // setShowSideBar(false);
          } else {
            toast.error(response.message)
          }
        })
      } else {
        const body = {
          user_id: JSON.parse(localStorage.getItem("authUser")).role_id == 4 ? JSON.parse(localStorage.getItem("authUser")).parent_id : JSON.parse(localStorage.getItem("authUser")).id,
          service_id: SingleOrderServiceId,
          weight: singleOrderWeight,
          order_ids: selectedOrders.toString(),
          length: singleOrderLength,
          width: singleOrderWidth,
          height: singleOrderHeight,
          ship_to_phone: singleUpdateToPhone,
        }
        await post("/shopify/update-bulk-orders", body, {
          headers,
        }).then(async response => {
          // console.log("get rate response", response)
          if (selectedGroupBy) {
            await handleGroupByOrders(selectedGroupBy)
          } else {
            await retrieveOrdersByFilterType()
          }
          if (response.status === 200) {
            toast.success(response.message)
            document.body.style.overflow = "visible"
            setClickedJson([])
            uncheckAll()
            selectedOrders = []
            setSelectedOrdersCount(selectedOrders.length)
            clickedSideBarJson = []
            selectedOrderJson = []
            setSingleOrderWeight("")
            setSingleOrderServiceId("")
            setSingleOrderWidth("")
            setSingleOrderHeight("")
            setSingleOrderLength("")
            setSingleUpdateToPhone("")
            setEnableSingleUpdateToPhone(false)
          } else {
            toast.error(response.message)
          }
        })
      }
    } else {
      toast.error("Please fill required fields")
    }
  }

  const updateBulkOrders = async () => {
    if (bulkUpdateServiceId) {
      if (enableBulkUpdateToPhone) {
        if (bulkUpdateToPhone.length < 10) {
          toast.error("Ship to phone must contain 10 or more digits")
          return false
        }
      }

      const body = {
        user_id: JSON.parse(localStorage.getItem("authUser")).role_id == 4 ? JSON.parse(localStorage.getItem("authUser")).parent_id : JSON.parse(localStorage.getItem("authUser")).id,
        service_id: bulkUpdateServiceId,
        order_ids: selectedOrders.toString(),
        update_ship_to_phone: enableBulkUpdateToPhone,
        ship_to_phone: bulkUpdateToPhone,
      }

      await post("/user/update-bulk-orders-service", body, {
        headers,
      }).then(async response => {
        if (selectedGroupBy) {
          await handleGroupByOrders(selectedGroupBy)
        } else {
          await retrieveOrdersByFilterType()
        }
        if (response.status === 200) {
          toast.success(response.message)
          setClickedBulkUpdateJson([])
          uncheckAll()
          selectedOrders = []
          setSelectedOrdersCount(selectedOrders.length)
          clickedSideBarJson = []
          selectedOrderJson = []
          setBulkUpdateServiceId("")
          setBulkUpdateToPhone("")
          setEnableBulkUpdateToPhone(false)
          document.getElementById("bulk-update-enable-to-phone").checked = false
          setIsFullTextVisible1(false)
        } else {
          toast.error(response.message)
        }
      })
    } else {
      toast.error("Please fill required fields")
    }
  }

  const fedexValidation = async () => {
    let loading = toast.loading("Validating Orders...")
    return new Promise(function (resolve, reject) {
      let body = {
        ids: selectedOrders.toString(),
      }
      post("/user/validate-fedex-orders", body, { headers })
        .then(response => {
          toast.remove(loading)
          document.body.style.pointerEvents = "auto"
          if (response.status == 200) {
            resolve(response)
          } else {
            toast.error(response.message)
          }
        })
        .catch(() => {
          toast.remove(loading)
          document.body.style.pointerEvents = "auto"
          toast.error(
            "Your request was not processed completely, Please try again"
          )
        })
    })
  }

  const upsValidation = async () => {
    let loading = toast.loading("Validating Orders...")
    // console.log("selectedOrders",selectedOrders);
    return new Promise(function (resolve, reject) {
      let body = {
        ids: selectedOrders.toString(),
      }
      post("/user/validate-ups-orders", body, { headers })
        .then(response => {
          toast.remove(loading)
          document.body.style.pointerEvents = "auto"
          if (response.status == 200) {
            resolve(response)
          } else {
            toast.error(response.message)
          }
        })
        .catch(() => {
          toast.remove(loading)
          document.body.style.pointerEvents = "auto"
          toast.error(
            "Your request was not processed completely, Please try again"
          )
        })
    })
  }

  const upsV2Validation = async () => {
    let loading = toast.loading("Validating Orders...")
    return new Promise(function (resolve, reject) {
      let body = {
        ids: selectedOrders.toString(),
      }
      post("/user/validate-ups-v2-orders", body, { headers })
        .then(response => {
          toast.remove(loading)
          document.body.style.pointerEvents = "auto"
          if (response.status == 200) {
            resolve(response)
          } else {
            toast.error(response.message)
          }
        })
        .catch(err => {
          resolve(err)
          // console.log("validation error",err)
          toast.remove(loading)
          document.body.style.pointerEvents = "auto"
          toast.error(
            "Your request was not processed completely, Please try again"
          )
        })
    })
  }

  const dhlValidation = async () => {
    let loading = toast.loading("Validating Orders...")
    return new Promise(function (resolve, reject) {
      let body = {
        ids: selectedOrders.toString(),
      }
      post("/user/validate-dhl-orders", body, { headers })
        .then(response => {
          toast.remove(loading)
          document.body.style.pointerEvents = "auto"
          if (response.status == 200) {
            resolve(response)
          } else {
            toast.error(response.message)
          }
        })
        .catch(() => {
          toast.remove(loading)
          document.body.style.pointerEvents = "auto"
          toast.error(
            "Your request was not processed completely, Please try again"
          )
        })
    })
  }

  const uspsValidation = async () => {
    let loading = toast.loading("Validating Orders...")
    return new Promise(function (resolve, reject) {
      let body = {
        ids: selectedOrders.toString(),
      }
      post("/user/validate-usps-orders", body, { headers })
        .then(response => {
          toast.remove(loading)
          document.body.style.pointerEvents = "auto"
          console.log(response, "response")
          if (response.status == 200) {
            resolve(response)
          } else {
            toast.error(response.message)
          }
        })
        .catch(e => {
          console.log(e, "e")
          toast.remove(loading)
          document.body.style.pointerEvents = "auto"
          toast.error(
            "Your request was not processed completely, Please try again"
          )
        })
    })
  }

  const noServiceValidation = async () => {
    let loading = toast.loading("Validating Orders...")
    return new Promise(function (resolve, reject) {
      let body = {
        ids: selectedOrders.toString(),
      }
      post("/user/validate-shopify-orders", body, { headers })
        .then(response => {
          toast.remove(loading)
          document.body.style.pointerEvents = "auto"
          if (response.status == 200) {
            resolve(response)
          } else {
            toast.error(response.message)
          }
        })
        .catch(() => {
          toast.remove(loading)
          document.body.style.pointerEvents = "auto"
          toast.error(
            "Your request was not processed completely, Please try again"
          )
        })
    })
  }

  const handleValidate = async () => {
    document.body.style.pointerEvents = "none"
    if (filterType == "FedEx") {
      let response = await fedexValidation()
      selectedOrders = response.success_orders
      return { orders: response.success_orders, }
    }
    else if (filterType == "UPS") {
      let response = await upsValidation()
      selectedOrders = response.success_orders
      return {
        orders: response.success_orders,
      }
    } 
    else if (filterType == "UPSv2") {
      let response = await upsV2Validation()
      selectedOrders = response.success_orders
      return {
        orders: response.success_orders,
      }
    } 
    else if (filterType == "DHL") {
      let response = await dhlValidation()
      selectedOrders = response.success_orders
      return {
        orders: response.success_orders,
      }
    } 
    else if (filterType == "USPS") {
      let response = await uspsValidation()
      selectedOrders = response.success_orders
      return {
        orders: response.success_orders,
      }
    } 
    else if (filterType == "Unassigned") {
      let response = await noServiceValidation()
      selectedOrders = response.success_orders
      return {
        orders: response.success_orders,
      }
    }
  }

  // console.log(selectedOrders,'selectedOrders')
  // console.log(clickedSideBarJson,'clickedSideBarJson>')

  
  let skip = JSON.parse(localStorage.getItem("authUser")).skip_proceed_screen == 1 ? true : false
  const handleVerification = async () => {
    if (skip) {
      if (selectedOrders.length > 0) {
        setHasDownloaded(true);
        if (!selectedGroupBy) {
          for (let i = 0; i < selectedOrders.length; i++) {
            let index = filteredData.findIndex(el => el.id == selectedOrders[i])
            if (filteredData[index]?.service_id == null) {
              toast.error("Selected orders do not have any associated service")
              return false
            }
          }
        }



        handleValidate().then(data => {
          console.log(data.orders, 'data.orders')
          if (data.orders.length == 0) {
            uncheckAll()
            selectedOrders = []
            setSelectedOrdersCount(selectedOrders.length)
            clickedSideBarJson = []
            selectedOrderJson = []
            setBulkUpdateServiceId("")
            setBulkUpdateToPhone("")
            setEnableBulkUpdateToPhone(false)
            toast.error("Order Validation Failed")
          }
          else {
            handleCustomCalculatedPrice()
              .then((cal) => {
                console.log(cal, 'cal response')
                if ((is_credit_enable == "1" && parseFloat(cal) <= parseFloat(parseFloat(max_credit_amount) + parseFloat(balance))) || parseFloat(cal) <= parseFloat(balance)) {
                  handleProceed()
                }
                else {
                  toast.error("Insufficent Balance")
                  setSelectedOrdersCount(0)
                }
              })
          }
          // document.body.style.pointerEvents = "auto"
          // setVerificationModal(true)
          // setValTime(3)
          // let x = 0
          // const intrvl = setInterval(() => {
          //   setValTime(parseInt(validationTimeout) - parseInt(x))
          //   x++
          //   if (parseInt(validationTimeout) - parseInt(x) == -1) {
          //     // console.log("click validate-btn");
          //     document.getElementById("validate-btn").click()
          //     clearInterval(intrvl)
          //   }
          // }, 1000)
        })
      }
      else {
        toast.error("Please select at least one order")
      }
    }
    else {
      if (selectedOrders.length > 0) {
        if (!selectedGroupBy) {
          for (let i = 0; i < selectedOrders.length; i++) {
            let index = filteredData.findIndex(el => el.id == selectedOrders[i])
            if (filteredData[index]?.service_id == null) {
              toast.error("Selected orders do not have any associated service")
              return false
            }
          }
        }

        handleValidate().then(data => {
          if (data.orders.length == 0) {
            uncheckAll()
            selectedOrders = []
            setSelectedOrdersCount(selectedOrders.length)
            clickedSideBarJson = []
            selectedOrderJson = []
            setBulkUpdateServiceId("")
            setBulkUpdateToPhone("")
            setEnableBulkUpdateToPhone(false)
          }
          document.body.style.pointerEvents = "auto"
          setVerificationModal(true)
          setValTime(3)
          let x = 0
          const intrvl = setInterval(() => {
            setValTime(parseInt(validationTimeout) - parseInt(x))
            x++
            if (parseInt(validationTimeout) - parseInt(x) == -1) {
              document.getElementById("validate-btn").click()
              clearInterval(intrvl)
            }
          }, 1000)
        })
      }
      else {
        toast.error("Please select at least one order")
      }

    }
  }

  // customValidation
  const handleCustomCalculatedPrice = async () => {
    if (skip) {
      return new Promise(async (resolve) => {
        if (selectedGroupBy) {
          await handleGroupByOrders(selectedGroupBy).then(() => {
            setCustomVerificationModal(false)
            let body = {
              user_id: JSON.parse(localStorage.getItem("authUser")).role_id == 4 ? JSON.parse(localStorage.getItem("authUser")).parent_id : JSON.parse(localStorage.getItem("authUser")).id,
              ids: selectedOrders.toString(),
            }
            post("/user/calculate-order-amount", body, { headers }).then(
              async response => {
                if (response.status === 200) {
                  // // console.log("data", response);
                  setCalculatedAmounts(response.data)
                  setCalculatedAmountsAboveFiveLbs(response.dataAboveFiveLbs)

                  let price = 0
                  if (response.data.length > 0) {
                    price = await response.data.map(item => item.price).reduce((prev, next) => prev + next)
                  }

                  let above5lbsPrice = 0
                  if (response.dataAboveFiveLbs.length > 0) {
                    above5lbsPrice = await response.dataAboveFiveLbs.map(item => item.price).reduce((prev, next) => prev + next)
                  }
                  let above20lbsPrice = 0
                  if (response.dataAboveTwentyLbs.length > 0) {
                    above20lbsPrice = await response.dataAboveTwentyLbs.map(item => item.price).reduce((prev, next) => prev + next)
                  }
                  let under1lbsPrice = 0
                  if (response.dataUnderOneLbs.length > 0) {
                    under1lbsPrice = await response.dataUnderOneLbs.map(item => item.price).reduce((prev, next) => prev + next)
                  }


                  console.log("price", price)
                  console.log("above5lbsPrice", above5lbsPrice)
                  const totalAmt = parseFloat(price) + parseFloat(above5lbsPrice) + parseFloat(above20lbsPrice) + parseFloat(under1lbsPrice);

                  setTotalAmount(totalAmt)
                  setOrderDetailsModal(!orderDetailsModal)
                  resolve(totalAmt);
                }
              }
            )
          })
        }
        else {
          retrieveOrdersByFilterType().then(() => {
            setCustomVerificationModal(false)
            let body = { user_id: JSON.parse(localStorage.getItem("authUser")).role_id == 4 ? JSON.parse(localStorage.getItem("authUser")).parent_id : JSON.parse(localStorage.getItem("authUser")).id, ids: selectedOrders.toString(), }
            post("/user/calculate-order-amount", body, { headers }).then(
              async response => {
                if (response.status === 200) {
                  console.log(response, 'response of handle custom calculate price')
                  setCalculatedAmounts(response.data)
                  setCalculatedAmountsAboveFiveLbs(response.dataAboveFiveLbs)

                  let price = 0
                  if (response.data.length > 0) {
                    price = await response.data.map(item => item.price).reduce((prev, next) => prev + next)
                  }

                  let above5lbsPrice = 0
                  if (response.dataAboveFiveLbs.length > 0) {
                    above5lbsPrice = await response.dataAboveFiveLbs.map(item => item.price).reduce((prev, next) => prev + next)
                  }

                  let above20lbsPrice = 0
                  if (response.dataAboveTwentyLbs.length > 0) {
                    above20lbsPrice = await response.dataAboveTwentyLbs.map(item => item.price).reduce((prev, next) => prev + next)
                  }

                  let under1lbsPrice = 0
                  if (response.dataUnderOneLbs.length > 0) {
                    under1lbsPrice = await response.dataUnderOneLbs.map(item => item.price).reduce((prev, next) => prev + next)
                  }

                  console.log("price", price)
                  console.log("above5lbsPrice", above5lbsPrice)
                  console.log("above20lbsPrice", above20lbsPrice)

                  const totalAmt = parseFloat(price) + parseFloat(above5lbsPrice) + parseFloat(above20lbsPrice) + parseFloat(under1lbsPrice);
                  console.log(totalAmt, 'totalAmt')
                  setTotalAmount(totalAmt)
                  setOrderDetailsModal(!orderDetailsModal)
                  resolve(totalAmt);
                }
              }
            )
          })
        }
      })
    }
    else {
      if (selectedGroupBy) {
        await handleGroupByOrders(selectedGroupBy).then(() => {
          setCustomVerificationModal(false)
          let body = {
            user_id: JSON.parse(localStorage.getItem("authUser")).role_id == 4 ? JSON.parse(localStorage.getItem("authUser")).parent_id : JSON.parse(localStorage.getItem("authUser")).id,
            ids: selectedOrders.toString(),
          }
          post("/user/calculate-order-amount", body, { headers }).then(
            async response => {
              if (response.status === 200) {
                setCalculatedAmounts(response.data)
                setCalculatedAmountsAboveFiveLbs(response.dataAboveFiveLbs)
  
                let price = 0
                if (response.data.length > 0) {
                  price = await response.data.map(item => item.price).reduce((prev, next) => prev + next)
                }
  
                let above5lbsPrice = 0
                if (response.dataAboveFiveLbs.length > 0) {
                  above5lbsPrice = await response.dataAboveFiveLbs.map(item => item.price).reduce((prev, next) => prev + next)
                }
                let above20lbsPrice = 0
                if (response.dataAboveTwentyLbs.length > 0) {
                  above20lbsPrice = await response.dataAboveTwentyLbs.map(item => item.price).reduce((prev, next) => prev + next)
                }
                let under1lbsPrice = 0
                if (response.dataUnderOneLbs.length > 0) {
                  under1lbsPrice = await response.dataUnderOneLbs.map(item => item.price).reduce((prev, next) => prev + next)
                }
  
                setTotalAmount(parseFloat(price) + parseFloat(above5lbsPrice)+ parseFloat(under1lbsPrice)+ parseFloat(above20lbsPrice))
                setOrderDetailsModal(!orderDetailsModal)
              }
            }
          )
        })
      } else {
        retrieveOrdersByFilterType().then(() => {
          setCustomVerificationModal(false)
          let body = {
            user_id: JSON.parse(localStorage.getItem("authUser")).role_id == 4 ? JSON.parse(localStorage.getItem("authUser")).parent_id : JSON.parse(localStorage.getItem("authUser")).id,
            ids: selectedOrders.toString(),
          }
          post("/user/calculate-order-amount", body, { headers }).then(
            async response => {
              if (response.status === 200) {
                setCalculatedAmounts(response.data)
                setCalculatedAmountsAboveFiveLbs(response.dataAboveFiveLbs)
  
                let price = 0
                if (response.data.length > 0) { price = await response.data.map(item => item.price).reduce((prev, next) => prev + next)}
  
                let above5lbsPrice = 0
                if (response.dataAboveFiveLbs.length > 0) {
                  above5lbsPrice = await response.dataAboveFiveLbs.map(item => item.price).reduce((prev, next) => prev + next)
                }

                let above20lbsPrice = 0
                if (response.dataAboveTwentyLbs.length > 0) {
                  above20lbsPrice = await response.dataAboveTwentyLbs.map(item => item.price).reduce((prev, next) => prev + next)
                }
                let under1lbsPrice = 0
                if (response.dataUnderOneLbs.length > 0) {
                  under1lbsPrice = await response.dataUnderOneLbs.map(item => item.price).reduce((prev, next) => prev + next)
                }
  
                setTotalAmount(parseFloat(price) + parseFloat(above5lbsPrice)+ parseFloat(under1lbsPrice)+ parseFloat(above20lbsPrice))
                setOrderDetailsModal(!orderDetailsModal)
              }
            }
          )
        })
      }
    }

  }

  const fedexCustomValidation = async () => {
    let loading = toast.loading("Validating Orders...")
    return new Promise(function (resolve, reject) {
      let body = {ids: selectedOrders.toString(),}
      post("/user/validate-fedex-orders", body, { headers })
        .then(response => {
          toast.remove(loading)
          document.body.style.pointerEvents = "auto"
          resolve(response)
        })
        .catch(() => {
          toast.remove(loading)
          document.body.style.pointerEvents = "auto"
          toast.error(
            "Your request was not processed completely, Please try again"
          )
        })
    })
  }

  const upsCustomValidation = async () => {
    let loading = toast.loading("Validating Orders...")
    return new Promise(function (resolve, reject) {
      let body = {
        ids: selectedOrders.toString(),
      }
      post("/user/validate-ups-orders", body, { headers })
        .then(response => {
          toast.remove(loading)
          document.body.style.pointerEvents = "auto"
          resolve(response)
        })
        .catch(() => {
          toast.remove(loading)
          document.body.style.pointerEvents = "auto"
          toast.error(
            "Your request was not processed completely, Please try again"
          )
        })
    })
  }

  const dhlCustomValidation = async () => {
    let loading = toast.loading("Validating Orders...")
    return new Promise(function (resolve, reject) {
      let body = {
        ids: selectedOrders.toString(),
      }
      post("/user/validate-dhl-orders", body, { headers })
        .then(response => {
          toast.remove(loading)
          document.body.style.pointerEvents = "auto"
          resolve(response)
        })
        .catch(() => {
          toast.remove(loading)
          document.body.style.pointerEvents = "auto"
          toast.error(
            "Your request was not processed completely, Please try again"
          )
        })
    })
  }

  const uspsCustomValidation = async () => {
    let loading = toast.loading("Validating Orders...")
    return new Promise(function (resolve, reject) {
      let body = {
        ids: selectedOrders.toString(),
      }
      post("/user/validate-usps-orders", body, { headers })
        .then(response => {
          toast.remove(loading)
          document.body.style.pointerEvents = "auto"
          resolve(response)
        })
        .catch(() => {
          toast.remove(loading)
          document.body.style.pointerEvents = "auto"
          toast.error(
            "Your request was not processed completely, Please try again"
          )
        })
    })
  }

  const handleCustomOrderValidate = async () => {
    document.body.style.pointerEvents = "none"
    let index = services.findIndex(el => el.id == SingleOrderServiceId)
    if (services[index].carrier_name == "FedEx") {
      let response = await fedexCustomValidation()
      selectedOrders = response.success_orders
      return {
        orders: response.success_orders,
      }
    } else if (services[index].carrier_name == "UPS") {
      let response = await upsCustomValidation()
      selectedOrders = response.success_orders
      return {
        orders: response.success_orders,
      }
    } else if (services[index].carrier_name == "DHL") {
      let response = await dhlCustomValidation()
      selectedOrders = response.success_orders
      return {
        orders: response.success_orders,
      }
    } else if (services[index].carrier_name == "USPS") {
      let response = await uspsCustomValidation()
      selectedOrders = response.success_orders
      return {
        orders: response.success_orders,
      }
    }
  }

  const handleCustomOrderVerification = async () => {
    if (selectedOrders.length > 0) {
      handleCustomOrderValidate().then(data => {
        // console.log("handleCustomOrderValidate", data)
        document.body.style.pointerEvents = "auto"
        setCustomVerificationModal(!customVerificationModal)
        setClickedJson([])
        document.body.style.overflow = "visible"
      })
    }
    else {
      toast.error("Please select at least one order")
    }
  }

  const handleSort = (
    name_sort,
    weight_sort,
    quantity_sort,
    order_date,
    tag_filter,
    destination,
    validation,
    picklist,
    filtered_Data
  ) => {
    if (selectedGroupBy != "") {
      // let first_data = [...filtered_Data];
      let finalData = [...filtered_Data]
      let data = []

      // console.log("filtered_Data",finalData)
      if (destination) {
        // console.log("destinationdestinationdestination",destination)
        for (let i = 0; i < finalData.length; i++) {
          if (destination == "International") {
            data.push({
              name: finalData[i].name,
              data: finalData[i].data.filter(
                el => el.from_country != el.to_country
              ),
            })
          } else if (destination == "Domestic") {
            data.push({
              name: finalData[i].name,
              data: finalData[i].data.filter(
                el => el.from_country == el.to_country
              ),
            })
          } else {
            data.push({ name: finalData[i].name, data: finalData[i].data })
          }
        }
      } else {
        for (let i = 0; i < finalData.length; i++) {
          data.push({ name: finalData[i].name, data: finalData[i].data })
        }
      }

      for (let i = 0; i < data.length; i++) {
        if (name_sort) {
          if (name_sort == "ASC") {
            data[i].data.sort((a, b) => a.to_name.localeCompare(b.to_name))
          } else if (name_sort == "DESC") {
            data[i].data.sort((a, b) => b.to_name.localeCompare(a.to_name))
          }
        }

        if (weight_sort) {
          if (weight_sort == "ASC") {
            data[i].data.sort(
              (a, b) => parseFloat(a.weight) - parseFloat(b.weight)
            )
          } else if (weight_sort == "DESC") {
            data[i].data.sort(
              (a, b) => parseFloat(b.weight) - parseFloat(a.weight)
            )
          }
        }

        if (order_date) {
          // if(order_date == "ASC") {
          //   data.sort((a, b) => a.updated_at.localeCompare(b.updated_at));
          // }
          // else if (order_date == "DESC") {
          //   data.sort((a, b) => b.updated_at.localeCompare(a.updated_at));
          // }
          if (order_date == "Today") {
            data[i].data = data[i].data.filter(
              el =>
                moment(el.created_at)
                  .tz(moment.tz.guess())
                  .format("YYYY-MM-DD") ==
                moment(new Date()).tz(moment.tz.guess()).format("YYYY-MM-DD")
            )
          } else if (order_date == "Yesterday") {
            // console.log("moment(new Date()).tz(moment.tz.guess()).subtract(1, 'day').format(\"YYYY-MM-DD\")",moment(new Date()).tz(moment.tz.guess()).subtract(1, 'day').format("YYYY-MM-DD"));
            data[i].data = data[i].data.filter(
              el =>
                moment(el.created_at)
                  .tz(moment.tz.guess())
                  .format("YYYY-MM-DD") >=
                moment(new Date())
                  .tz(moment.tz.guess())
                  .subtract(1, "day")
                  .format("YYYY-MM-DD")
            )
          } else if (order_date == "Last Week") {
            // console.log("fff",moment(new Date()).tz(moment.tz.guess()).subtract(1, 'week').format("YYYY-MM-DD"));
            data[i].data = data[i].data.filter(
              el =>
                moment(el.created_at)
                  .tz(moment.tz.guess())
                  .format("YYYY-MM-DD") >=
                moment(new Date())
                  .tz(moment.tz.guess())
                  .subtract(1, "week")
                  .format("YYYY-MM-DD")
            )
          } else if (order_date == "Last Month") {
            data[i].data = data[i].data.filter(
              el =>
                moment(el.created_at)
                  .tz(moment.tz.guess())
                  .format("YYYY-MM-DD") >=
                moment(new Date())
                  .tz(moment.tz.guess())
                  .subtract(1, "month")
                  .format("YYYY-MM-DD")
            )
          } else if (order_date == "Last Year") {
            data[i].data = data[i].data.filter(
              el =>
                moment(el.created_at)
                  .tz(moment.tz.guess())
                  .format("YYYY-MM-DD") >=
                moment(new Date())
                  .tz(moment.tz.guess())
                  .subtract(1, "year")
                  .format("YYYY-MM-DD")
            )
          }
        }

        if (quantity_sort) {
          if (quantity_sort == "ASC") {
            data[i].data.sort((a, b) => {
              if (a.custom_items_data && b.custom_items_data) {
                return (
                  JSON.parse(a.custom_items_data).length -
                  JSON.parse(b.custom_items_data).length
                )
              }
            })
          } else if (quantity_sort == "DESC") {
            data[i].data.sort((a, b) => {
              if (a.custom_items_data && b.custom_items_data) {
                return (
                  JSON.parse(b.custom_items_data).length -
                  JSON.parse(a.custom_items_data).length
                ) // Compare normal ages
              }
            })
          }
        }

        if (tag_filter) {
          if (tag_filter != "none") {
            // console.log("tag_filter",tag_filter);
            // console.log("data.filter((el) => el.tag != null)",data.filter((el) => el.tag != null));
            data[i].data = data[i].data.filter(
              el => el.tag && el.tag?.toString().includes(tag_filter)
            )
          }
        }

        if (picklist.length > 0) {
          // console.log("tag_filter",tag_filter);
          // console.log("data.filter((el) => el.tag != null)",data.filter((el) => el.tag != null));
          let picklistFilteredData = []
          data[i].data.map(d => {
            for (let p = 0; p < picklist.length; p++) {
              if (
                d.picklists &&
                d.picklists?.toString().includes(picklist[p])
              ) {
                picklistFilteredData.push(d)
              }
            }
          })
          data[i].data = picklistFilteredData
        }

        // if(picklist) {
        //   if(picklist != "none") {
        //     // console.log("tag_filter",tag_filter);
        //     // console.log("data.filter((el) => el.tag != null)",data.filter((el) => el.tag != null));
        //     data[i].data = data[i].data.filter((el) => el.picklists && el.picklists?.toString().includes(picklist));
        //   }
        // }
      }

      console.log("data", data)
      let final = []
      for (let i = 0; i < data.length; i++) {
        if (data[i].data.length > 0) {
          final.push(data[i])
        }
      }

      if (final.length == 0) {
        final.push({ name: "-", data: [] })
      }

      // console.log("first_data",final);
      setGroupByDataTables(final)
      document.getElementsByClassName("right-bar-enabled")[0].click()
    } else {
      let data1 = [...filtered_Data]
      let data = []
      if (destination) {
        if (destination == "All") {
          data = data1
        }
        if (destination == "International") {
          data1.map(el => {
            if (el.from_country != el.to_country) {
              data.push(el)
            }
          })
        } else if (destination == "Domestic") {
          data1.map(el => {
            if (el.from_country == el.to_country) {
              data.push(el)
            }
          })
        }
      } else {
        data = data1
      }

      // console.log("data after",data);

      // let data = [...filtered_Data];

      if (name_sort) {
        if (name_sort == "ASC") {
          data.sort((a, b) => a.to_name.localeCompare(b.to_name))
        } else if (name_sort == "DESC") {
          data.sort((a, b) => b.to_name.localeCompare(a.to_name))
        }
      }

      if (weight_sort) {
        if (weight_sort == "ASC") {
          data.sort((a, b) => parseFloat(a.weight) - parseFloat(b.weight))
        } else if (weight_sort == "DESC") {
          data.sort((a, b) => parseFloat(b.weight) - parseFloat(a.weight))
        }
      }

      if (order_date) {
        // if(order_date == "ASC") {
        //   data.sort((a, b) => a.updated_at.localeCompare(b.updated_at));
        // }
        // else if (order_date == "DESC") {
        //   data.sort((a, b) => b.updated_at.localeCompare(a.updated_at));
        // }
        if (order_date == "Today") {
          data = data.filter(
            el =>
              moment(el.created_at)
                .tz(moment.tz.guess())
                .format("YYYY-MM-DD") ==
              moment(new Date()).tz(moment.tz.guess()).format("YYYY-MM-DD")
          )
        } else if (order_date == "Yesterday") {
          // console.log("moment(new Date()).tz(moment.tz.guess()).subtract(1, 'day').format(\"YYYY-MM-DD\")",moment(new Date()).tz(moment.tz.guess()).subtract(1, 'day').format("YYYY-MM-DD"));
          data = data.filter(
            el =>
              moment(el.created_at)
                .tz(moment.tz.guess())
                .format("YYYY-MM-DD") >=
              moment(new Date())
                .tz(moment.tz.guess())
                .subtract(1, "day")
                .format("YYYY-MM-DD")
          )
        } else if (order_date == "Last Week") {
          // console.log("fff",moment(new Date()).tz(moment.tz.guess()).subtract(1, 'week').format("YYYY-MM-DD"));
          data = data.filter(
            el =>
              moment(el.created_at)
                .tz(moment.tz.guess())
                .format("YYYY-MM-DD") >=
              moment(new Date())
                .tz(moment.tz.guess())
                .subtract(1, "week")
                .format("YYYY-MM-DD")
          )
        } else if (order_date == "Last Month") {
          data = data.filter(
            el =>
              moment(el.created_at)
                .tz(moment.tz.guess())
                .format("YYYY-MM-DD") >=
              moment(new Date())
                .tz(moment.tz.guess())
                .subtract(1, "month")
                .format("YYYY-MM-DD")
          )
        } else if (order_date == "Last Year") {
          data = data.filter(
            el =>
              moment(el.created_at)
                .tz(moment.tz.guess())
                .format("YYYY-MM-DD") >=
              moment(new Date())
                .tz(moment.tz.guess())
                .subtract(1, "year")
                .format("YYYY-MM-DD")
          )
        }
      }

      if (quantity_sort) {
        if (quantity_sort == "ASC") {
          data.sort((a, b) => {
            if (a.custom_items_data && b.custom_items_data) {
              return (
                JSON.parse(a.custom_items_data).length -
                JSON.parse(b.custom_items_data).length
              )
            }
          })
        } else if (quantity_sort == "DESC") {
          data.sort((a, b) => {
            if (a.custom_items_data && b.custom_items_data) {
              return (
                JSON.parse(b.custom_items_data).length -
                JSON.parse(a.custom_items_data).length
              ) // Compare normal ages
            }
          })
        }
      }

      if (tag_filter) {
        if (tag_filter != "none") {
          // console.log("tag_filter",tag_filter);
          // console.log("data.filter((el) => el.tag != null)",data.filter((el) => el.tag != null));
          data = data.filter(
            el => el.tag && el.tag?.toString().includes(tag_filter)
          )
        }
      }

      // if(picklist != "none") {
      if (picklist.length > 0) {
        // console.log("tag_filter",tag_filter);
        // console.log("data.filter((el) => el.tag != null)",data.filter((el) => el.tag != null));
        let picklistFilteredData = []
        data.map(d => {
          for (let p = 0; p < picklist.length; p++) {
            if (d.picklists && d.picklists?.toString().includes(picklist[p])) {
              picklistFilteredData.push(d)
            }
          }
        })
        data = picklistFilteredData
      }
      // }

      if (validation) {
        // console.log("validation",validation);
        if (validation == "Passed") {
          data = data.filter(
            el =>
              el.is_from_address_verified == 1 && el.is_to_address_verified == 1
          )
        } else if (validation == "Failed") {
          data = data.filter(
            el =>
              (el.is_verified == 0 && el.error_message) ||
              el.is_from_address_verified == 0 ||
              el.is_to_address_verified == 0
          )
        }
      }

      // console.log("data.filter((el) => el.tag != null) 111",data);

      setTableData({ columns, data })
      setFilteredData(data)
      setPending(false)
    }
  }

  //Filter and sortings
  const handleSortName = e => {
    setNameSort(e.target.value)
    handleSort(
      e.target.value,
      weightSort,
      quantitySort,
      orderDate,
      tagFilter,
      filterShipment,
      validationFilter,
      picklistFilter,
      filteredData
    )
  }

  const handleSortWeight = e => {
    setWeightSort(e.target.value)
    handleSort(
      nameSort,
      e.target.value,
      quantitySort,
      orderDate,
      tagFilter,
      filterShipment,
      validationFilter,
      picklistFilter,
      filteredData
    )
  }

  const handleOrderDate = e => {
    setOrderDate(e.target.value)

    if (selectedGroupBy) {
      let fdata = groupByDataTablesAll
      handleSort(
        nameSort,
        weightSort,
        quantitySort,
        e.target.value,
        tagFilter,
        filterShipment,
        validationFilter,
        picklistFilter,
        fdata
      )
    } else {
      let fdata = allData
      handleSort(
        nameSort,
        weightSort,
        quantitySort,
        e.target.value,
        tagFilter,
        filterShipment,
        validationFilter,
        picklistFilter,
        fdata
      )
    }
  }

  const handleValidationFilter = e => {
    setValidationFilter(e.target.value)
    if (selectedGroupBy) {
      let fdata = groupByDataTablesAll
      handleSort(
        nameSort,
        weightSort,
        quantitySort,
        orderDate,
        tagFilter,
        filterShipment,
        e.target.value,
        picklistFilter,
        fdata
      )
    } else {
      let fdata = allData
      handleSort(
        nameSort,
        weightSort,
        quantitySort,
        orderDate,
        tagFilter,
        filterShipment,
        e.target.value,
        picklistFilter,
        fdata
      )
    }
  }

  const handleSortQuantity = e => {
    setQuantitySort(e.target.value)
    handleSort(
      nameSort,
      weightSort,
      e.target.value,
      orderDate,
      tagFilter,
      filterShipment,
      validationFilter,
      picklistFilter,
      filteredData
    )
  }

  const handleSortTag = e => {
    setTagFilter(e.target.value)
    if (selectedGroupBy) {
      let fdata = groupByDataTablesAll
      handleSort(
        nameSort,
        weightSort,
        quantitySort,
        orderDate,
        e.target.value,
        filterShipment,
        validationFilter,
        picklistFilter,
        fdata
      )
    } else {
      let fdata = allData
      handleSort(
        nameSort,
        weightSort,
        quantitySort,
        orderDate,
        e.target.value,
        filterShipment,
        validationFilter,
        picklistFilter,
        fdata
      )
    }
  }

  const handleSortPicklist = e => {
    picklistFilter = e.target
    if (selectedGroupBy) {
      let fdata = groupByDataTablesAll
      handleSort(
        nameSort,
        weightSort,
        quantitySort,
        orderDate,
        tagFilter,
        filterShipment,
        validationFilter,
        e.target,
        fdata
      )
    } else {
      let fdata = allData
      handleSort(
        nameSort,
        weightSort,
        quantitySort,
        orderDate,
        tagFilter,
        filterShipment,
        validationFilter,
        e.target,
        fdata
      )
    }
  }

  const handleAllocation = e => {
    // setAllocation(e.target.value);
    handleSort(
      nameSort,
      weightSort,
      quantitySort,
      orderDate,
      tagFilter,
      filterShipment,
      validationFilter,
      picklistFilter,
      filteredData
    )
  }

  const handleStoreSelect = async e => {
    // console.log("value",e);
    setCurrentStore(e.target.value)
    post(
      "/user/store-changed",
      { user_id: JSON.parse(localStorage.getItem("authUser")).role_id == 4 ? JSON.parse(localStorage.getItem("authUser")).parent_id : JSON.parse(localStorage.getItem("authUser")).id },
      { headers }
    )
    localStorage.setItem("store", e.target.value)
    if (e.target.value == 0) {
      columns[16].omit = true
      columns[15].omit = true
    }

    if (
      stores.filter(el => el.value == e.target.value)[0].type == "zenventory"
    ) {
      hideShowZenventory = false
    } else {
      hideShowZenventory = true
    }

    columns[12].omit = hideShowZenventory

    if (
      stores.filter(el => el.value == e.target.value)[0].type == "sellercloud"
    ) {
      // hideShowPicklists = false;
      hideShowChannelNumber = false
    } else {
      // hideShowPicklists = true;
      hideShowChannelNumber = true
    }

    // columns[13].omit = hideShowPicklists;
    columns[14].omit = hideShowChannelNumber

    let xx = []
    setAllData(xx)
    setFilteredData(xx)
    setGroupByDataTables(xx)
    setGroupByDataTablesAll(xx)
    let x = ""
    selectedGroupBy = ""
    sideBarData = []
    clickedSideBarJson = []
    await retrievePicklist()
    // const picklistResetFilter = "";
    picklistFilter = []
    if (localStorage.getItem("order-filter") == "Unassigned") {
      if (e.target.value == "0") {
        const activeCarrier = carriers.filter(el => el.is_active == 1)[0].name
        localStorage.setItem("order-filter", activeCarrier)
        setFilterType(localStorage.getItem("order-filter"))
        if (selectedGroupBy) {
          await handleGroupByOrders(selectedGroupBy)
        } else {
          await retrieveOrdersByFilterType(localStorage.getItem("order-filter"))
        }
      } else {
        if (selectedGroupBy) {
          await handleGroupByOrders(selectedGroupBy)
        } else {
          await retrieveOrdersByFilterType()
        }
      }
    } else {
      if (selectedGroupBy) {
        await handleGroupByOrders(selectedGroupBy)
      } else {
        await retrieveOrdersByFilterType()
      }
    }
  }

  const handleFilterShipment = async e => {
    setFilterShipment(e.target.value)
    if (selectedGroupBy) {
      let finalGroupData = groupByDataTablesAll
      await handleSort(
        nameSort,
        weightSort,
        quantitySort,
        orderDate,
        tagFilter,
        e.target.value,
        validationFilter,
        picklistFilter,
        finalGroupData
      )
    } else {
      let data1 = allData
      await handleSort(
        nameSort,
        weightSort,
        quantitySort,
        orderDate,
        tagFilter,
        e.target.value,
        validationFilter,
        picklistFilter,
        data1
      )
    }
  }

  const handleAssignee = async e => {
    // setAssignee(e.target.value)
    handleSort(
      nameSort,
      weightSort,
      quantitySort,
      orderDate,
      tagFilter,
      filterShipment,
      validationFilter,
      picklistFilter,
      filteredData
    )
  }

  const expandableRows = row => {
    // if(row.store_type=="shipstation"){
    // console.log("shipstation",row)
    // }
    return (
      <>
        <div
          className={"col-md-12 mt-2 mb-2 row p-2"}
          style={{
            backgroundColor:
              localStorage.getItem("theme") == "dark" ? "#222224" : "#f7f5f5",
          }}
        >
          <h5>Item Details</h5>
          <div
            style={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            {row.data.is_custom_form >= 0 ? (
              row.data.custom_items_data?.length > 0 ? (
                JSON.parse(
                  row.data.store_type == "ebay"
                    ? JSON.parse(row.data.custom_items_data)
                    : row.data.custom_items_data
                ).map((el, id) => {
                  if (row.data.store_type == null) {
                    return (
                      <div
                        key={id}
                        className={"border rounded shadow"}
                        style={{
                          width: "300px",
                          height: "fit-content",
                          padding: "10px 10px 0px 10px",
                          margin: "10px",
                          backgroundColor:
                            localStorage.getItem("theme") == "dark"
                              ? "#0f0f0f"
                              : "white",
                        }}
                      >
                        <h6
                          className={"bold"}
                          style={{ marginBottom: "-10px" }}
                        >
                          ID: {el.product_identifier}
                        </h6>
                        <hr />
                        <h5>
                          <span style={{ float: "right" }}>
                            {el.quantity ? el.quantity : "-"}x
                          </span>
                          <div
                            style={{
                              width: "200px",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                            }}
                          >
                            {el.description ? el.description : "-"}
                          </div>
                        </h5>
                        {/*total value is unit price*/}
                        <p>{el.total_value ? el.total_value : "-"} USD</p>
                      </div>
                    )
                  } else if (row.data.store_type == "shopify") {
                    return (
                      <div
                        key={id}
                        className={"border rounded shadow"}
                        style={{
                          width: "300px",
                          height: "fit-content",
                          padding: "10px 10px 0px 10px",
                          margin: "10px",
                          backgroundColor:
                            localStorage.getItem("theme") == "dark"
                              ? "#0f0f0f"
                              : "white",
                        }}
                      >
                        <h6
                          className={"bold"}
                          style={{ marginBottom: "-10px" }}
                        >
                          ID: {el.id}
                        </h6>
                        <hr />
                        <h5>
                          <span style={{ float: "right" }}>
                            {el.quantity ? el.quantity : "-"}x
                          </span>
                          <div
                            style={{
                              width: "200px",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                            }}
                          >
                            {el.name ? el.name : "-"}
                          </div>
                        </h5>
                        <p>
                          {el.price ? el.price : "-"}{" "}
                          {el.price_set.shop_money.currency_code}
                        </p>
                      </div>
                    )
                  } else if (row.data.store_type == "ebay") {
                    return (
                      <div
                        key={id}
                        className={"border rounded shadow"}
                        style={{
                          width: "300px",
                          height: "fit-content",
                          padding: "10px 10px 0px 10px",
                          margin: "10px",
                          backgroundColor:
                            localStorage.getItem("theme") == "dark"
                              ? "#0f0f0f"
                              : "white",
                        }}
                      >
                        <h6
                          className={"bold"}
                          style={{ marginBottom: "-10px" }}
                        >
                          ID: {el.lineItemId}
                        </h6>
                        <hr />
                        <h5>
                          <span style={{ float: "right" }}>
                            {el.quantity ? el.quantity : "-"}x
                          </span>
                          <div
                            style={{
                              width: "200px",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                            }}
                          >
                            {el.title ? el.title : "-"}
                          </div>
                        </h5>
                        <p>
                          {el.lineItemCost.value} {el.lineItemCost.currency}
                        </p>
                      </div>
                    )
                  } else if (row.data.store_type == "walmart") {
                    return (
                      <div
                        key={id}
                        className={"border rounded shadow"}
                        style={{
                          width: "300px",
                          height: "fit-content",
                          padding: "10px 10px 0px 10px",
                          margin: "10px",
                          backgroundColor:
                            localStorage.getItem("theme") == "dark"
                              ? "#0f0f0f"
                              : "white",
                        }}
                      >
                        <h6
                          className={"bold"}
                          style={{ marginBottom: "-10px" }}
                        >
                          SKU: {el.item.sku}
                        </h6>
                        <hr />
                        <h5>
                          <span style={{ float: "right" }}>
                            {el.orderLineQuantity.amount
                              ? el.orderLineQuantity.amount
                              : "-"}
                            x
                          </span>
                          <div
                            style={{
                              width: "200px",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                            }}
                          >
                            {el.item.productName ? el.item.productName : "-"}
                          </div>
                        </h5>
                        <p>
                          {el.charges.charge[0].chargeAmount.amount}{" "}
                          {el.charges.charge[0].chargeAmount.currency}
                        </p>
                      </div>
                    )
                  } else if (row.data.store_type == "zenventory") {
                    return (
                      <div
                        key={id}
                        className={"border rounded shadow"}
                        style={{
                          width: "300px",
                          height: "fit-content",
                          padding: "10px 10px 0px 10px",
                          margin: "10px",
                          backgroundColor:
                            localStorage.getItem("theme") == "dark"
                              ? "#0f0f0f"
                              : "white",
                        }}
                      >
                        <h6
                          className={"bold"}
                          style={{ marginBottom: "-10px" }}
                        >
                          ID: {el.customerorderitemid}
                        </h6>
                        <hr />
                        <h5>
                          <span style={{ float: "right" }}>
                            {el.quantity ? el.quantity : "-"}x
                          </span>
                          <div
                            style={{
                              width: "200px",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                            }}
                          >
                            {el.itemmaster.description
                              ? el.itemmaster.description
                              : "-"}
                          </div>
                        </h5>
                        <p>${el.customerprice}</p>
                      </div>
                    )
                  } else if (row.data.store_type == "sellercloud") {
                    return (
                      <div
                        key={id}
                        className={"border rounded shadow"}
                        style={{
                          width: "300px",
                          height: "fit-content",
                          padding: "10px 10px 0px 10px",
                          margin: "10px",
                          backgroundColor:
                            localStorage.getItem("theme") == "dark"
                              ? "#0f0f0f"
                              : "white",
                        }}
                      >
                        <h6
                          className={"bold"}
                          style={{ marginBottom: "-10px" }}
                        >
                          ID: {el.ProductID}
                        </h6>
                        <hr />
                        <h5>
                          <span style={{ float: "right" }}>
                            {el.Qty ? el.Qty : "-"}x
                          </span>
                          <div
                            style={{
                              width: "200px",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                            }}
                          >
                            {el.DisplayName ? el.DisplayName : "-"}
                          </div>
                        </h5>
                        <p>
                          {el.DisplayDescription ? el.DisplayDescription : "-"}
                        </p>
                        <div style={{ display: "flex" }}>
                          <p style={{ width: "50%" }}>
                            ${el.AdjustedSitePrice}
                          </p>
                          <p style={{ width: "50%", textAlign: "right" }}>
                            <span style={{ fontWeight: "500" }}>Weight:</span>{" "}
                            {el.Weight ? el.Weight + "lb." : "-"}
                          </p>
                        </div>
                        <p>
                          <span style={{ fontWeight: "500" }}>L x W x H:</span>{" "}
                          {el.Length ? el.Length : "-"} x{" "}
                          {el.Width ? el.Width : "-"} x{" "}
                          {el.Height ? el.Height : "-"}
                        </p>
                      </div>
                    )
                  } else if (row.data.store_type == "veeqo") {
                    return (
                      <div
                        key={id}
                        className={"border rounded shadow"}
                        style={{
                          width: "300px",
                          height: "fit-content",
                          padding: "10px 10px 0px 10px",
                          margin: "10px",
                          backgroundColor:
                            localStorage.getItem("theme") == "dark"
                              ? "#0f0f0f"
                              : "white",
                        }}
                      >
                        <h6
                          className={"bold"}
                          style={{ marginBottom: "-10px" }}
                        >
                          ID: {el.sellable.id}
                        </h6>
                        <hr />
                        <h5>
                          <span style={{ float: "right" }}>
                            {el.quantity ? el.quantity : "-"}x
                          </span>
                          <div
                            style={{
                              width: "200px",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                            }}
                          >
                            {el.sellable.product_title
                              ? el.sellable.product_title
                              : "-"}
                          </div>
                        </h5>
                        {/*<p>{el.name?el.name:"-"}</p>*/}
                        <div style={{ display: "flex" }}>
                          <p style={{ width: "50%" }}>${el.sellable.price}</p>
                          {/*<p style={{width:"50%",textAlign:"right"}}><span style={{fontWeight:"500"}}>Weight:</span> {el.Weight?el.Weight + "lb.":"-"}</p>*/}
                        </div>
                        {/*<p><span style={{fontWeight:"500"}}>L x W x H:</span> {el.Length?el.Length:"-"} x {el.Width?el.Width:"-"} x {el.Height?el.Height:"-"}</p>*/}
                      </div>
                    )
                  } else if (row.data.store_type == "shiphero") {
                    return (
                      <div
                        key={id}
                        className={"border rounded shadow"}
                        style={{
                          width: "300px",
                          height: "fit-content",
                          padding: "10px 10px 0px 10px",
                          margin: "10px",
                          backgroundColor:
                            localStorage.getItem("theme") == "dark"
                              ? "#0f0f0f"
                              : "white",
                        }}
                      >
                        <h6
                          className={"bold"}
                          style={{ marginBottom: "-10px" }}
                        >
                          ID: {el.line_item_id}
                        </h6>
                        <hr />
                        <h5>
                          <span style={{ float: "right" }}>
                            {el.quantity_allocated
                              ? el.quantity_allocated
                              : "-"}
                            x
                          </span>
                          <div
                            style={{
                              width: "200px",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                            }}
                          >
                            {el?.sellable?.product_title
                              ? el?.sellable?.product_title
                              : "-"}
                          </div>
                        </h5>
                        {/*<p>{el.name?el.name:"-"}</p>*/}
                        <div style={{ display: "flex" }}>
                          {/* <p style={{ width: "50%" }}>${el.total_price}</p> */}
                          <p style={{ width: "50%" }}>${"--"}</p>
                          {/*<p style={{width:"50%",textAlign:"right"}}><span style={{fontWeight:"500"}}>Weight:</span> {el.Weight?el.Weight + "lb.":"-"}</p>*/}
                        </div>
                        {/*<p><span style={{fontWeight:"500"}}>L x W x H:</span> {el.Length?el.Length:"-"} x {el.Width?el.Width:"-"} x {el.Height?el.Height:"-"}</p>*/}
                      </div>
                    )
                  } else if (row.data.store_type == "orderdesk") {
                    return (
                      <div
                        key={id}
                        className={"border rounded shadow store-dropdown"}
                        style={{
                          width: "400px",
                          maxHeight: "260px",
                          padding: "10px 10px 0px 10px",
                          margin: "10px",
                          overflowY: "auto",
                          backgroundColor:
                            localStorage.getItem("theme") == "dark"
                              ? "#0f0f0f"
                              : "white",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <span className={"bold"} style={{}}>
                            ID: {el?.id ? el?.id : "-"}
                          </span>
                          <span style={{ width: "fit-content" }}>
                            ${el?.price}
                          </span>
                        </div>
                        <hr />
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            paddingBottom: "5px",
                          }}
                        >
                          <div
                            style={{
                              width: "",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {el?.metadata?.image && (
                              <a
                                href={el?.metadata?.image}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ cursor: "pointer" }}
                              >
                                <img
                                  src={el?.metadata?.image}
                                  alt={el?.name}
                                  style={{
                                    height: "auto",
                                    width: "40px",
                                    marginRight: "10px",
                                  }}
                                />
                              </a>
                            )}
                            <span
                              style={{
                                width: "180px",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                              }}
                            >
                              {el?.name ? el?.name : "-"}
                            </span>
                          </div>
                          <span>{el.quantity ? el.quantity : "-"}x</span>
                        </div>
                        {Object.keys(el?.metadata).map(key =>
                          el?.metadata[key].includes("https") ? (
                            <div
                              key={key}
                              style={{ marginTop: "3px", paddingBottom: "5px" }}
                            >
                              <strong>{key.replace("_", " ")}:</strong>{" "}
                              {key === "image" ? (
                                <a
                                  rel="noopener noreferrer"
                                  target="_blank"
                                  href={el?.metadata[key]}
                                  alt="product"
                                >
                                  {el?.metadata[key]}
                                </a>
                              ) : null}
                            </div>
                          ) : (
                            <div
                              key={key}
                              style={{ marginTop: "3px", paddingBottom: "5px" }}
                            >
                              <strong>{key.replace("_", " ")}:</strong>
                              <span style={{ marginLeft: "2px" }}>
                                {el?.metadata[key]}
                              </span>
                            </div>
                          )
                        )}
                      </div>
                    )
                  } else if (row.data.store_type == "shipstation") {
                    return (
                      <div
                        key={id}
                        className={"border rounded shadow"}
                        style={{
                          width: "400px",
                          height: "fit-content",
                          padding: "10px 10px 0px 10px",
                          margin: "10px",
                          backgroundColor:
                            localStorage.getItem("theme") == "dark"
                              ? "#0f0f0f"
                              : "white",
                        }}
                      >
                        <h6
                          className={"bold"}
                          style={{ marginBottom: "-10px" }}
                        >
                          ID: {el.orderItemId}
                        </h6>
                        <hr />
                        <h5>
                          <span style={{ float: "right" }}>
                            {el.quantity ? el.quantity : "-"}x
                          </span>
                          <div
                            style={{
                              width: "200px",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                            }}
                          >
                            {el.name ? el.name : "-"}
                          </div>
                        </h5>
                        {/*<p>{el.name?el.name:"-"}</p>*/}
                        <div style={{ display: "flex" }}>
                          <p style={{ width: "50%" }}>${el.unitPrice}</p>
                          {/*<p style={{width:"50%",textAlign:"right"}}><span style={{fontWeight:"500"}}>Weight:</span> {el.Weight?el.Weight + "lb.":"-"}</p>*/}
                        </div>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            paddingBottom: "5px",
                            flexDirection: "column",
                          }}
                        >
                          <div
                            style={{
                              width: "",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {el?.imageUrl && (
                              <a
                                href={el?.imageUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ cursor: "pointer" }}
                              >
                                <img
                                  src={el?.imageUrl}
                                  alt={el?.name}
                                  style={{
                                    height: "auto",
                                    width: "40px",
                                    marginRight: "10px",
                                  }}
                                />
                              </a>
                            )}
                            {/* <span style={{width: "180px",whiteSpace: "nowrap",textOverflow: "ellipsis",overflow: "hidden"}}>{el?.name ? el?.name: "-"}</span> */}
                          </div>
                          <div className="mt-2">
                            {/* <p><span style={{fontWeight:"500"}}><strong>Item Weight:</strong></span> {el.weight?el.weight:"-"}</p> */}
                            {el.imageUrl != null && (
                              <p>
                                <span
                                  style={{
                                    fontWeight: "500",
                                    marginRight: "10px",
                                  }}
                                >
                                  <strong>image:</strong>
                                </span>
                                <a
                                  rel="noopener noreferrer"
                                  target="_blank"
                                  href={el?.imageUrl}
                                >
                                  {el.imageUrl ? el.imageUrl : "-"}
                                </a>
                              </p>
                            )}
                          </div>
                        </div>
                        {/*<p><span style={{fontWeight:"500"}}>L x W x H:</span> {el.weight?el.weight:"-"} x {el.Width?el.Width:"-"} x {el.Height?el.Height:"-"}</p>*/}
                      </div>
                    )
                  }
                })
              ) : (
                ""
              )
            ) : (
              <h6 style={{ textAlign: "center" }}>No Item Found</h6>
            )}
          </div>
        </div>
      </>
    )
  }

  // Function to update the window width state
  const updateWindowWidth = () => {
    setWindowWidth(window.innerWidth)
  }

  // Add an event listener to the window when the component mounts
  useEffect(() => {
    window.addEventListener("resize", updateWindowWidth)

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", updateWindowWidth)
    }
  }, [])

  const handleReloadPage = async () => {
    // console.log("hit")
    // setNameSort("");
    // setWeightSort("");
    // setQuantitySort("");
    // setOrderDate("");
    // setFilterShipment("All");

    if (selectedGroupBy) {
      await handleGroupByOrders(selectedGroupBy)
    } else {
      await retrieveOrdersByFilterType()
    }

    getUserBalance()
    retrieveTags()
  }

  const handleUpdateNotes = () => {
    try {
      let body = {
        order_id: notesOrderId,
        type: fromBuyerNotesModal ? "from_buyer_notes" : toBuyerNotesModal ? "to_buyer_notes" : "internal_notes",
        notes: fromBuyerNotesModal ? fromBuyerNotes : toBuyerNotesModal ? toBuyerNotes : internalNotes,
        customerRef1: customerRef1,
        customerRef2: customerRef2
      }
      post("/user/update-order-notes", body, { headers }).then(
        async response => {
          if (response.status == 200) {
            toast.success(response.message)
            setFromBuyerNotesModal(false)
            setToBuyerNotesModal(false)
            setInternalBuyerNotesModal(false)

            uncheckAll()
            selectedOrders = []
            setSelectedOrdersCount(selectedOrders.length)
            clickedSideBarJson = []
            selectedOrderJson = []
            setBulkUpdateServiceId("")
            setBulkUpdateToPhone("")
            setEnableBulkUpdateToPhone(false)
            if (selectedGroupBy) {
              await handleGroupByOrders(selectedGroupBy)
            } else {
              await retrieveOrdersByFilterType()
            }
          } else {
            toast.error(response.message)
          }
        }
      )
    } catch (e) { }
  }
  const componentColumnsRef = useRef(null)
  const componentGroupByRef = useRef(null)

  const handleColumnsClickOutside = event => {
    if (
      componentColumnsRef.current &&
      !componentColumnsRef.current.contains(event.target)
    ) {
      setHideShowColumns(false)
    }
  }

  const handleGroupByClickOutside = event => {
    if (
      componentGroupByRef.current &&
      !componentGroupByRef.current.contains(event.target)
    ) {
      setHideShowGroupBy(false)
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleColumnsClickOutside)
    document.addEventListener("mousedown", handleGroupByClickOutside)

    return () => {
      document.removeEventListener("mousedown", handleColumnsClickOutside)
      document.removeEventListener("mousedown", handleGroupByClickOutside)
    }
  }, [])

  const handleGroupByOrders = async (type, clear = true) => {
    if (clear) {
      clickedSideBarJson = []
      uncheckAll()
      selectedOrders = []
      setSelectedOrdersCount(selectedOrders.length)
      clickedSideBarJson = []
      selectedOrderJson = []
      setBulkUpdateServiceId("")
      setBulkUpdateToPhone("")
      setEnableBulkUpdateToPhone(false)
      setFilteredData([])
      setAllData([])
    }

    setPendingGroupBy(true)
    const body = {
      id: JSON.parse(localStorage.getItem("authUser")).role_id == 4 ? JSON.parse(localStorage.getItem("authUser")).parent_id : JSON.parse(localStorage.getItem("authUser")).id,
      store_id: parseInt(localStorage.getItem("store")),
      carrier: filterType,
      type: type,
    }
    // console.log("body", body);
    post("/user/get-group-by-orders", body, { headers }).then(
      async response => {
        console.log("get-group-by-orders", response.data)

        await handleTableData(response.data)
        const checkCheckboxExist = setInterval(() => {
          if (document.getElementById("select-all-checkbox")) {
            for (
              let i = 0;
              i < document.getElementsByClassName("select-all-checkbox").length;
              i++
            ) {
              document.getElementsByClassName("select-all-checkbox")[
                i
              ].style.display = "none"
            }
            clearInterval(checkCheckboxExist)
          }
        }, 100)

        setPendingGroupBy(false)
      }
    )

    return true
  }

  const styles = StyleSheet.create({
    page: {
      flexDirection: "row",
      backgroundColor: "white",
    },
    section: {
      margin: 20,
      padding: 20,
      flexGrow: 1,
    },
  })

  const [slip_name, setSlipName] = useState("")
  const [pc_to_street1, setPCToStreet1] = useState("")
  const [pc_to_street2, setPCToStreet2] = useState("")
  const [pc_to_city, setPCToCity] = useState("")
  const [pc_to_state, setPCToState] = useState("")
  const [pc_to_zip, setPCToZip] = useState("")
  const [pc_to_country, setPCToCountry] = useState("")
  const [pc_to_name, setPCToName] = useState("")
  const [pc_bill_to_street1, setPCBillToStreet1] = useState("")
  const [pc_bill_to_street2, setPCBillToStreet2] = useState("")
  const [pc_bill_to_city, setPCBillToCity] = useState("")
  const [pc_bill_to_state, setPCBillToState] = useState("")
  const [pc_bill_to_zip, setPCBillToZip] = useState("")
  const [pc_bill_to_country, setPCBillToCountry] = useState("")
  const [pc_bill_to_name, setPCBillToName] = useState("")

  const [pc_from_name, setPCFromName] = useState("")
  const [pc_from_street1, setPCFromStreet1] = useState("")
  const [pc_from_street2, setPCFromStreet2] = useState("")
  const [pc_from_city, setPCFromCity] = useState("")
  const [pc_from_state, setPCFromState] = useState("")
  const [pc_from_zip, setPCFromZip] = useState("")
  const [pc_from_country, setPCFromCountry] = useState("")

  const [pc_reference_number, setPCReferenceNumber] = useState("")
  const [pc_order_number, setPCOrderNumber] = useState("")
  const [pc_order_date, setPCOrderDate] = useState("")

  const [pc_items, setPcItems] = useState([])
  const [pc_extra_row, setPcExtraRow] = useState([])
  const [pc_sub_total, setPcSubTotal] = useState("")
  const [pc_total, setPcTotal] = useState("")

  const tableStyles = StyleSheet.create({
    tableEmptyRow: {
      margin: "auto",
      width: "100%",
      flexDirection: "row",
      height: "20px",
      borderStyle: "solid",
      borderWidth: 1,
      borderTopWidth: 0,
      borderBottomWidth: 0,
    },
    tableEmptyLastRow: {
      margin: "auto",
      width: "100%",
      flexDirection: "row",
      height: "20px",
      borderStyle: "solid",
      borderWidth: 1,
      borderTopWidth: 0,
    },
  })

  const handlePackingSlip = async () => {
    console.log("handle packing slip")
    if (clickedSideBarJson.length > 0) {
      document.body.style.pointerEvents = "none"
      let loading = toast.loading(`Creating Packing ${clickedSideBarJson.length > 1 ? "Slips" : "Slip"}...`)
      await Promise.all(
        clickedSideBarJson.map(async (el, i) => {
          setTimeout(async () => {
            setSlipName(el.order_unique_id)
            setPCToStreet1(el.to_street1)
            setPCToStreet2(el.to_street2)
            setPCToCity(el.to_city)
            setPCToState(el.to_state)
            setPCToZip(el.to_zip)
            setPCToCountry(el.to_country)
            setPCToName(el.to_name)

            setPCFromName(el.from_name)
            setPCFromStreet1(el.from_street1)
            setPCFromStreet2(el.from_street2)
            setPCFromCity(el.from_city)
            setPCFromState(el.from_state)
            setPCFromZip(el.from_zip)
            setPCFromCountry(el.from_country)
            setPCOrderNumber(el.order_number ? el.order_number + (el.package_number ? "-" + el.package_number + (el.package_index ? " (" + el.package_index + ")" : "") : "") : "-")
            setPCReferenceNumber(el.reference_number)
            let storeOrderData = ""
            if (el.store_type) {
              let StoreOrder = JSON.parse(el.store_order_json)
              if (el.store_type == "shopify") {
                storeOrderData = moment(StoreOrder.created_at).format(
                  "YYYY-MM-DD"
                )
              } else if (el.store_type == "ebay") {
                storeOrderData = moment(StoreOrder.updated_at).format(
                  "YYYY-MM-DD"
                )
              } else if (el.store_type == "walmart") {
                storeOrderData = moment(StoreOrder.orderDate).format(
                  "YYYY-MM-DD"
                )
              } else if (el.store_type == "zenventory") {
                storeOrderData = moment(StoreOrder.ordereddate).format(
                  "YYYY-MM-DD"
                )
              } else if (el.store_type == "sellercloud") {
                // console.log("StoreOrder.TimeOfOrder",StoreOrder.TimeOfOrder)
                storeOrderData = moment(StoreOrder.TimeOfOrder).format(
                  "YYYY-MM-DD"
                )
                // console.log("storeOrderData",storeOrderData)
              } else if (el.store_type == "shipstation") {
                // console.log("StoreOrder.TimeOfOrder",StoreOrder.TimeOfOrder)
                storeOrderData = moment(StoreOrder.createDate).format(
                  "YYYY-MM-DD"
                )
                // console.log("storeOrderData",storeOrderData)
              } else if (el.store_type == "veeqo") {
                // console.log("StoreOrder.TimeOfOrder",StoreOrder.TimeOfOrder)
                storeOrderData = moment(StoreOrder.created_at).format(
                  "YYYY-MM-DD"
                )
                // console.log("storeOrderData",storeOrderData)
              } else if (el.store_type == "shiphero") {
                // console.log("StoreOrder.TimeOfOrder",StoreOrder.TimeOfOrder)
                storeOrderData = moment(
                  StoreOrder.order_history[0].created_at
                ).format("YYYY-MM-DD")
                // console.log("storeOrderData",storeOrderData)
              } else if (el.store_type == "orderdesk") {
                // console.log("StoreOrder.TimeOfOrder",StoreOrder.TimeOfOrder)
                storeOrderData = moment(StoreOrder.date_added).format(
                  "YYYY-MM-DD"
                )
                // console.log("storeOrderData",storeOrderData)
              }
            } else {
              storeOrderData = moment(el.order_date)
                .tz(moment.tz.guess())
                .format("YYYY-MM-DD")
            }

            setPCOrderDate(storeOrderData)
            if (el.custom_items_data && JSON.parse(el.store_type == "ebay" ? JSON.parse(el.custom_items_data) : el.custom_items_data).length > 0) {
              let items = JSON.parse(el.store_type == "ebay" ? JSON.parse(el.custom_items_data) : el.custom_items_data)
              let itemArray = []
              // console.log("items",items);
              await Promise.all(
                items.map(item => {
                  if (el.store_type == null) {
                    itemArray.push({
                      product_identifier: item.product_identifier,
                      product_sku: "-",
                      description: item.description,
                      quantity: item.quantity,
                      price: parseFloat(item.total_value).toFixed(2),
                      total_price: parseFloat(
                        parseInt(item.quantity) * parseFloat(item.total_value)
                      ).toFixed(2),
                    })
                  } else if (el.store_type == "shopify") {
                    itemArray.push({
                      product_identifier: item.id,
                      product_sku: "-",
                      description: item.name,
                      quantity: item.quantity,
                      price: parseFloat(item.price).toFixed(2),
                      total_price: parseFloat(
                        parseInt(item.quantity) * parseFloat(item.price)
                      ).toFixed(2),
                    })
                  } else if (el.store_type == "ebay") {
                    itemArray.push({
                      product_identifier: item.lineItemId,
                      product_sku: "-",
                      description: item.title,
                      quantity: item.quantity,
                      price: parseFloat(item.lineItemCost.value).toFixed(2),
                      total_price: parseFloat(
                        parseInt(item.quantity) *
                        parseFloat(item.lineItemCost.value)
                      ).toFixed(2),
                    })
                  } else if (el.store_type == "walmart") {
                    itemArray.push({
                      product_identifier: item.id,
                      product_sku: item.item.sku,
                      description: item.item.productName,
                      quantity: item.orderLineQuantity.amount,
                      price: parseFloat(
                        item.charges.charge[0].chargeAmount.amount
                      ).toFixed(2),
                      total_price: parseFloat(
                        parseInt(item.orderLineQuantity.amount) *
                        parseFloat(item.charges.charge[0].chargeAmount.amount)
                      ).toFixed(2),
                    })
                  } else if (el.store_type == "zenventory") {
                    itemArray.push({
                      product_identifier: item.customerorderitemid,
                      product_sku: "-",
                      description: item.itemmaster.description,
                      quantity: item.quantity,
                      price: parseFloat(item.customerprice).toFixed(2),
                      total_price: parseFloat(
                        parseInt(item.quantity) * parseFloat(item.customerprice)
                      ).toFixed(2),
                    })
                  } else if (el.store_type == "sellercloud") {
                    // itemArray.push({
                    //   'product_identifier': item.ProductID,
                    //   'description': el.is_package==0?item.DisplayName:item.ItemName,
                    //   'quantity': item.Qty,
                    //   'price': parseFloat(el.is_package==0?item.LineTotal:0).toFixed(2),
                    //   'total_price': parseFloat(parseInt(item.Qty) * parseFloat(el.is_package==0?item.LineTotal:0)).toFixed(2)
                    // });

                    itemArray.push({
                      product_identifier: item.ProductID,
                      product_sku: "-",
                      description: item.DisplayName,
                      quantity: item.Qty,
                      price: parseFloat(item.AdjustedSitePrice).toFixed(2),
                      total_price: parseFloat(
                        parseInt(item.Qty) * parseFloat(item.AdjustedSitePrice)
                      ).toFixed(2),
                    })
                  } else if (el.store_type == "shipstation") {
                    itemArray.push({
                      product_identifier: item.orderItemId,
                      product_sku: item.sku ? item.sku : "-",
                      description: item.name,
                      quantity: item.quantity,
                      price: parseFloat(item.unitPrice).toFixed(2),
                      total_price: parseFloat(
                        parseInt(item.quantity) * parseFloat(item.unitPrice)
                      ).toFixed(2),
                    })
                  } else if (el.store_type == "veeqo") {
                    itemArray.push({
                      product_identifier: item.sellable.id,
                      product_sku: item.sellable.sku_code
                        ? item.sellable.sku_code
                        : "-",
                      description: item.sellable.product_title,
                      quantity: item.quantity,
                      price: parseFloat(item.sellable.price).toFixed(2),
                      total_price: parseFloat(
                        parseInt(item.quantity) *
                        parseFloat(item.sellable.price)
                      ).toFixed(2),
                    })
                  } else if (el.store_type == "shiphero") {
                    itemArray.push({
                      product_identifier: "-",
                      product_sku: item.sku ? item.sku : "-",
                      description: item?.product_title
                        ? item?.product_title
                        : "",
                      quantity: item.quantity_allocated,
                      price: "-",
                      total_price: "-",
                    })
                  } else if (el.store_type == "orderdesk") {
                    itemArray.push({
                      product_identifier: item?.id,
                      product_sku: item.sku ? item.sku : "-",
                      description: item?.name ? item?.name : "",
                      quantity: item.quantity,
                      price: item?.price,
                      total_price: item?.price * item.quantity,
                    })
                  }
                })
              )
              setPcItems(itemArray)
              setPcSubTotal(
                parseFloat(
                  itemArray.reduce((accumulator, currentObject) => {
                    return accumulator + parseFloat(currentObject.total_price)
                  }, 0)
                ).toFixed(2)
              )
              setPcTotal(
                parseFloat(
                  itemArray.reduce((accumulator, currentObject) => {
                    return accumulator + parseFloat(currentObject.total_price)
                  }, 0)
                ).toFixed(2)
              )
            }
            else {
              setPcItems([])
              setPcSubTotal("0.00")
              setPcTotal("0.00")
            }

            setTimeout(() => {
              document.getElementsByClassName("pdf-downloader")[0].click()
            }, 1000)

            if (clickedSideBarJson.length == i + 1) {
              toast.remove(loading)
              toast.success(
                clickedSideBarJson.length +
                ` packing ${clickedSideBarJson.length > 1 ? "slips" : "slip"
                } generated`
              )
              clickedSideBarJson = []
              uncheckAll()
              selectedOrders = []
              setSelectedOrdersCount(selectedOrders.length)
              clickedSideBarJson = []
              selectedOrderJson = []
              setBulkUpdateServiceId("")
              setBulkUpdateToPhone("")
              setEnableBulkUpdateToPhone(false)
              document.body.style.pointerEvents = "auto"
            }
          }, (i + 1) * 2000)
        })
      )

    } else {
      toast.error("Please select at least one order")
    }
  }

  const handlePackingSlipTemplate2 = async () => {
    // console.log("orders", clickedSideBarJson)\
    console.log("template2")
    if (clickedSideBarJson.length > 0) {
      document.body.style.pointerEvents = "none"
      let loading = toast.loading(
        `Creating Packing ${clickedSideBarJson.length > 1 ? "Slips" : "Slip"
        }...`
      )

      await Promise.all(
        clickedSideBarJson.map(async (el, i) => {
          setTimeout(async () => {
            // setSlipName("")
            //
            // setPCToStreet1("")
            // setPCToStreet2("")
            // setPCToCity("")
            // setPCToState("")
            // setPCToZip("")
            // setPCToCountry("")
            // setPCToName("")
            //
            // setPCFromStreet1("")
            // setPCFromStreet2("")
            // setPCFromCity("")
            // setPCFromState("")
            // setPCFromZip("")
            // setPCFromCountry("")
            //
            // setPCOrderNumber("")
            // setPCOrderDate("")
            // setPcItems([]);
            // setPcSubTotal("0.00")
            // setPcTotal("0.00")

            setSlipName(el.order_unique_id)
            // console.log("el",el);
            setPCToStreet1(el.to_street1)
            setPCToStreet2(el.to_street2)
            setPCToCity(el.to_city)
            setPCToState(el.to_state)
            setPCToZip(el.to_zip)
            setPCToCountry(el.to_country)
            setPCToName(el.to_name)

            setPCFromName(el.from_name)
            setPCFromStreet1(el.from_street1)
            setPCFromStreet2(el.from_street2)
            setPCFromCity(el.from_city)
            setPCFromState(el.from_state)
            setPCFromZip(el.from_zip)
            setPCFromCountry(el.from_country)
            // console.log("eeeeee",el.package_number);

            setPCOrderNumber(
              el.order_number
                ? el.order_number +
                (el.package_number
                  ? "-" +
                  el.package_number +
                  (el.package_index ? " (" + el.package_index + ")" : "")
                  : "")
                : "-"
            )
            setPCReferenceNumber(el.reference_number)
            let storeOrderData = ""
            if (el.store_type) {
              let StoreOrder = JSON.parse(el.store_order_json)
              if (el.store_type == "shopify") {
                storeOrderData = moment(StoreOrder.created_at).format(
                  "YYYY-MM-DD"
                )
              } else if (el.store_type == "ebay") {
                storeOrderData = moment(StoreOrder.updated_at).format(
                  "YYYY-MM-DD"
                )
              } else if (el.store_type == "walmart") {
                storeOrderData = moment(StoreOrder.orderDate).format(
                  "YYYY-MM-DD"
                )
              } else if (el.store_type == "zenventory") {
                storeOrderData = moment(StoreOrder.ordereddate).format(
                  "YYYY-MM-DD"
                )
              } else if (el.store_type == "sellercloud") {
                // console.log("StoreOrder.TimeOfOrder",StoreOrder.TimeOfOrder)
                storeOrderData = moment(StoreOrder.TimeOfOrder).format(
                  "YYYY-MM-DD"
                )
                // console.log("storeOrderData",storeOrderData)
              } else if (el.store_type == "shipstation") {
                // console.log("StoreOrder.TimeOfOrder",StoreOrder.TimeOfOrder)
                storeOrderData = moment(StoreOrder.createDate).format(
                  "YYYY-MM-DD"
                )

                setPCBillToStreet1(StoreOrder.billTo.street1)
                setPCBillToStreet2(StoreOrder.billTo.street2)
                setPCBillToCity(StoreOrder.billTo.city)
                setPCBillToState(StoreOrder.billTo.state)
                setPCBillToZip(StoreOrder.billTo.postalCode)
                setPCBillToCountry(StoreOrder.billTo.country)
                setPCBillToName(StoreOrder.billTo.name)
                // console.log("storeOrderData",storeOrderData)
              } else if (el.store_type == "veeqo") {
                // console.log("StoreOrder.TimeOfOrder",StoreOrder.TimeOfOrder)
                storeOrderData = moment(StoreOrder.created_at).format(
                  "YYYY-MM-DD"
                )
                // console.log("storeOrderData",storeOrderData)
              } else if (el.store_type == "shiphero") {
                // console.log("StoreOrder.TimeOfOrder",StoreOrder.TimeOfOrder)
                storeOrderData = moment(
                  StoreOrder.order_history[0].created_at
                ).format("YYYY-MM-DD")
                // console.log("storeOrderData",storeOrderData)
              } else if (el.store_type == "orderdesk") {
                // console.log("StoreOrder.TimeOfOrder",StoreOrder.TimeOfOrder)
                storeOrderData = moment(StoreOrder?.date_added)?.format(
                  "YYYY-MM-DD"
                )
                // console.log("storeOrderData",storeOrderData)
              }
            } else {
              storeOrderData = moment(el.order_date)
                .tz(moment.tz.guess())
                .format("YYYY-MM-DD")
            }

            // setPCOrderDate(el.store_type?(el.store_type=="walmart"?moment(el.store_order_updated_at).format("YYYY-MM-DD"):el.store_order_updated_at.toString().substring(0,10)):el.created_at.toString().substring(0,10))
            setPCOrderDate(storeOrderData)

            if (
              el.custom_items_data &&
              JSON.parse(
                el.store_type == "ebay"
                  ? JSON.parse(el.custom_items_data)
                  : el.custom_items_data
              ).length > 0
            ) {
              let items = JSON.parse(
                el.store_type == "ebay"
                  ? JSON.parse(el.custom_items_data)
                  : el.custom_items_data
              )
              let itemArray = []
              // console.log("items",items);
              await Promise.all(
                items.map(item => {
                  if (el.store_type == null) {
                    itemArray.push({
                      product_identifier: item.product_identifier,
                      product_sku: "-",
                      description: item.description,
                      quantity: item.quantity,
                      price: parseFloat(item.total_value).toFixed(2),
                      total_price: parseFloat(
                        parseInt(item.quantity) * parseFloat(item.total_value)
                      ).toFixed(2),
                    })
                  } else if (el.store_type == "shopify") {
                    itemArray.push({
                      product_identifier: item.id,
                      product_sku: "-",
                      description: item.name,
                      quantity: item.quantity,
                      price: parseFloat(item.price).toFixed(2),
                      total_price: parseFloat(
                        parseInt(item.quantity) * parseFloat(item.price)
                      ).toFixed(2),
                    })
                  } else if (el.store_type == "ebay") {
                    itemArray.push({
                      product_identifier: item.lineItemId,
                      product_sku: "-",
                      description: item.title,
                      quantity: item.quantity,
                      price: parseFloat(item.lineItemCost.value).toFixed(2),
                      total_price: parseFloat(
                        parseInt(item.quantity) *
                        parseFloat(item.lineItemCost.value)
                      ).toFixed(2),
                    })
                  } else if (el.store_type == "walmart") {
                    itemArray.push({
                      product_identifier: item.id,
                      product_sku: item.item.sku,
                      description: item.item.productName,
                      quantity: item.orderLineQuantity.amount,
                      price: parseFloat(
                        item.charges.charge[0].chargeAmount.amount
                      ).toFixed(2),
                      total_price: parseFloat(
                        parseInt(item.orderLineQuantity.amount) *
                        parseFloat(item.charges.charge[0].chargeAmount.amount)
                      ).toFixed(2),
                    })
                  } else if (el.store_type == "zenventory") {
                    itemArray.push({
                      product_identifier: item.customerorderitemid,
                      product_sku: "-",
                      description: item.itemmaster.description,
                      quantity: item.quantity,
                      price: parseFloat(item.customerprice).toFixed(2),
                      total_price: parseFloat(
                        parseInt(item.quantity) * parseFloat(item.customerprice)
                      ).toFixed(2),
                    })
                  } else if (el.store_type == "sellercloud") {
                    // itemArray.push({
                    //   'product_identifier': item.ProductID,
                    //   'description': el.is_package==0?item.DisplayName:item.ItemName,
                    //   'quantity': item.Qty,
                    //   'price': parseFloat(el.is_package==0?item.LineTotal:0).toFixed(2),
                    //   'total_price': parseFloat(parseInt(item.Qty) * parseFloat(el.is_package==0?item.LineTotal:0)).toFixed(2)
                    // });

                    itemArray.push({
                      product_identifier: item.ProductID,
                      product_sku: "-",
                      description: item.DisplayName,
                      quantity: item.Qty,
                      price: parseFloat(item.AdjustedSitePrice).toFixed(2),
                      total_price: parseFloat(
                        parseInt(item.Qty) * parseFloat(item.AdjustedSitePrice)
                      ).toFixed(2),
                    })
                  } else if (el.store_type == "shipstation") {
                    itemArray.push({
                      product_identifier: item.orderItemId,
                      product_sku: item.sku ? item.sku : "-",
                      description: item.name,
                      quantity: item.quantity,
                      price: parseFloat(item.unitPrice).toFixed(2),
                      total_price: parseFloat(
                        parseInt(item.quantity) * parseFloat(item.unitPrice)
                      ).toFixed(2),
                    })
                  } else if (el.store_type == "veeqo") {
                    itemArray.push({
                      product_identifier: item.sellable.id,
                      product_sku: item.sellable.sku_code
                        ? item.sellable.sku_code
                        : "-",
                      description: item.sellable.product_title,
                      quantity: item.quantity,
                      price: parseFloat(item.sellable.price).toFixed(2),
                      total_price: parseFloat(
                        parseInt(item.quantity) *
                        parseFloat(item.sellable.price)
                      ).toFixed(2),
                    })
                  } else if (el.store_type == "shiphero") {
                    itemArray.push({
                      product_identifier: "-",
                      product_sku: item.sku ? item.sku : "-",
                      description: item?.product_title
                        ? item?.product_title
                        : "",
                      quantity: item.quantity_allocated,
                      // 'total_price': parseFloat(item.total_price).toFixed(2),
                      // 'total_price': parseFloat(parseInt(item.quantity) * parseFloat(item.sellable.price)).toFixed(2)
                      price: "-",
                      total_price: "-",
                    })
                  } else if (el.store_type == "orderdesk") {
                    itemArray.push({
                      product_identifier: item?.id,
                      product_sku: item.sku ? item.sku : "-",
                      description: item?.name ? item?.name : "",
                      quantity: item.quantity,
                      // 'total_price': parseFloat(item.total_price).toFixed(2),
                      // 'total_price': parseFloat(parseInt(item.quantity) * parseFloat(item.sellable.price)).toFixed(2)
                      price: item?.price ? item?.price : "-",
                      total_price: item?.price * item?.quantity,
                    })
                  }
                })
              )

              setPcItems(itemArray)
              setPcExtraRow([])

              for (let i = itemArray.length; i <= 8; i++) {
                if (i < 8) {
                  setPcExtraRow(prevElements => [
                    ...prevElements,
                    <>
                      <View key={i} style={tableStyles.tableEmptyRow}></View>
                    </>,
                  ])
                } else {
                  setPcExtraRow(prevElements => [
                    ...prevElements,
                    <>
                      <View
                        key={i}
                        style={tableStyles.tableEmptyLastRow}
                      ></View>
                    </>,
                  ])
                }
              }

              setPcSubTotal(
                parseFloat(
                  itemArray.reduce((accumulator, currentObject) => {
                    return accumulator + parseFloat(currentObject.total_price)
                  }, 0)
                ).toFixed(2)
              )
              setPcTotal(
                parseFloat(
                  itemArray.reduce((accumulator, currentObject) => {
                    return accumulator + parseFloat(currentObject.total_price)
                  }, 0)
                ).toFixed(2)
              )
            } else {
              setPcItems([])
              setPcSubTotal("0.00")
              setPcTotal("0.00")
            }

            setTimeout(() => {
              document
                .getElementsByClassName("pdf-downloader-template-2")[0]
                .click()
            }, 1000)

            if (clickedSideBarJson.length == i + 1) {
              toast.remove(loading)
              toast.success(
                clickedSideBarJson.length +
                ` packing ${clickedSideBarJson.length > 1 ? "slips" : "slip"
                } generated`
              )
              clickedSideBarJson = []
              uncheckAll()
              selectedOrders = []
              setSelectedOrdersCount(selectedOrders.length)
              clickedSideBarJson = []
              selectedOrderJson = []
              setBulkUpdateServiceId("")
              setBulkUpdateToPhone("")
              setEnableBulkUpdateToPhone(false)
              document.body.style.pointerEvents = "auto"
            }
          }, (i + 1) * 2000)
        })
      )
    } else {
      toast.error("Please select at least one order")
    }
  }

  const downloadMergedPackingSlip = async () => {
    if (clickedSideBarJson.length > 0) {
      document.body.style.pointerEvents = "none"
      let loading = toast.loading(
        `Creating Packing ${clickedSideBarJson.length > 1 ? "Slips" : "Slip"
        }...`
      )

      let dataArray = []

      await clickedSideBarJson.map(async (el, i) => {
        let itemArray = []
        if (
          el.custom_items_data &&
          JSON.parse(
            el.store_type == "ebay"
              ? JSON.parse(el.custom_items_data)
              : el.custom_items_data
          ).length > 0
        ) {
          let items = JSON.parse(
            el.store_type == "ebay"
              ? JSON.parse(el.custom_items_data)
              : el.custom_items_data
          )
          items.map(item => {
            if (el.store_type == null) {
              itemArray.push({
                product_identifier: item.product_identifier,
                product_sku: "-",
                description: item.description,
                quantity: item.quantity,
                price: parseFloat(item.total_value).toFixed(2),
                total_price: parseFloat(
                  parseInt(item.quantity) * parseFloat(item.total_value)
                ).toFixed(2),
              })
            } else if (el.store_type == "shopify") {
              itemArray.push({
                product_identifier: item.id,
                product_sku: "-",
                description: item.name,
                quantity: item.quantity,
                price: parseFloat(item.price).toFixed(2),
                total_price: parseFloat(
                  parseInt(item.quantity) * parseFloat(item.price)
                ).toFixed(2),
              })
            } else if (el.store_type == "ebay") {
              itemArray.push({
                product_identifier: item.lineItemId,
                product_sku: "-",
                description: item.title,
                quantity: item.quantity,
                price: parseFloat(item.lineItemCost.value).toFixed(2),
                total_price: parseFloat(
                  parseInt(item.quantity) * parseFloat(item.lineItemCost.value)
                ).toFixed(2),
              })
            } else if (el.store_type == "walmart") {
              itemArray.push({
                product_identifier: item.id,
                product_sku: item.item.sku,
                description: item.item.productName,
                quantity: item.orderLineQuantity.amount,
                price: parseFloat(
                  item.charges.charge[0].chargeAmount.amount
                ).toFixed(2),
                total_price: parseFloat(
                  parseInt(item.orderLineQuantity.amount) *
                  parseFloat(item.charges.charge[0].chargeAmount.amount)
                ).toFixed(2),
              })
            } else if (el.store_type == "zenventory") {
              itemArray.push({
                product_identifier: item.customerorderitemid,
                product_sku: "-",
                description: item.itemmaster.description,
                quantity: item.quantity,
                price: parseFloat(item.customerprice).toFixed(2),
                total_price: parseFloat(
                  parseInt(item.quantity) * parseFloat(item.customerprice)
                ).toFixed(2),
              })
            } else if (el.store_type == "sellercloud") {
              // itemArray.push({
              //   'product_identifier': item.ProductID,
              //   'description': el.is_package==0?item.DisplayName:item.ItemName,
              //   'quantity': item.Qty,
              //   'price': parseFloat(el.is_package==0?item.LineTotal:0).toFixed(2),
              //   'total_price': parseFloat(parseInt(item.Qty) * parseFloat(el.is_package==0?item.LineTotal:0)).toFixed(2)
              // });

              itemArray.push({
                product_identifier: item.ProductID,
                product_sku: "-",
                description: item.DisplayName,
                quantity: item.Qty,
                price: parseFloat(item.AdjustedSitePrice).toFixed(2),
                total_price: parseFloat(
                  parseInt(item.Qty) * parseFloat(item.AdjustedSitePrice)
                ).toFixed(2),
              })
            } else if (el.store_type == "shipstation") {
              itemArray.push({
                product_identifier: item.orderItemId,
                product_sku: item.sku ? item.sku : "-",
                description: item.name,
                quantity: item.quantity,
                price: parseFloat(item.unitPrice).toFixed(2),
                total_price: parseFloat(
                  parseInt(item.quantity) * parseFloat(item.unitPrice)
                ).toFixed(2),
              })
            } else if (el.store_type == "veeqo") {
              itemArray.push({
                product_identifier: item.sellable.id,
                product_sku: item.sellable.sku_code
                  ? item.sellable.sku_code
                  : "-",
                description: item.sellable.product_title,
                quantity: item.quantity,
                price: parseFloat(item.sellable.price).toFixed(2),
                total_price: parseFloat(
                  parseInt(item.quantity) * parseFloat(item.sellable.price)
                ).toFixed(2),
              })
            } else if (el.store_type == "shiphero") {
              itemArray.push({
                product_identifier: "-",
                product_sku: item.sku ? item.sku : "-",
                description: item?.product_title ? item?.product_title : "",
                quantity: item.quantity_allocated,
                // 'price': parseFloat(item.total_price).toFixed(2),
                // 'total_price': parseFloat(parseInt(item.quantity) * parseFloat(item.sellable.price)).toFixed(2)
                price: "-",
                total_price: "-",
              })
            } else if (el.store_type == "orderdesk") {
              itemArray.push({
                product_identifier: item?.id,
                product_sku: item.sku ? item.sku : "-",
                description: item?.name ? item?.name : "",
                quantity: item.quantity,
                // 'total_price': parseFloat(item.total_price).toFixed(2),
                // 'total_price': parseFloat(parseInt(item.quantity) * parseFloat(item.sellable.price)).toFixed(2)
                price: item?.price ? item?.price : "-",
                total_price: item.quantity * item?.price,
              })
            }
          })
        }

        let storeOrderData = ""
        if (el.store_type) {
          let StoreOrder = JSON.parse(el.store_order_json)
          if (el.store_type == "shopify") {
            storeOrderData = moment(StoreOrder.created_at).format("YYYY-MM-DD")
          } else if (el.store_type == "ebay") {
            storeOrderData = moment(StoreOrder.updated_at).format("YYYY-MM-DD")
          } else if (el.store_type == "walmart") {
            storeOrderData = moment(StoreOrder.orderDate).format("YYYY-MM-DD")
          } else if (el.store_type == "zenventory") {
            storeOrderData = moment(StoreOrder.ordereddate).format("YYYY-MM-DD")
          } else if (el.store_type == "sellercloud") {
            // console.log("StoreOrder.TimeOfOrder",StoreOrder.TimeOfOrder)
            storeOrderData = moment(StoreOrder.TimeOfOrder).format("YYYY-MM-DD")
            // console.log("storeOrderData",storeOrderData)
          } else if (el.store_type == "shipstation") {
            // console.log("StoreOrder.TimeOfOrder",StoreOrder.TimeOfOrder)
            storeOrderData = moment(StoreOrder.createDate).format("YYYY-MM-DD")
            // console.log("storeOrderData",storeOrderData)
          } else if (el.store_type == "veeqo") {
            // console.log("StoreOrder.TimeOfOrder",StoreOrder.TimeOfOrder)
            storeOrderData = moment(StoreOrder.created_at).format("YYYY-MM-DD")
            // console.log("storeOrderData",storeOrderData)
          } else if (el.store_type == "shiphero") {
            storeOrderData = moment(StoreOrder.order_history.created_at).format(
              "YYYY-MM-DD"
            )
          } else if (el.store_type == "orderdesk") {
            storeOrderData = moment(StoreOrder.date_added).format("YYYY-MM-DD")
          }
        } else {
          storeOrderData = moment(el.order_date)
            .tz(moment.tz.guess())
            .format("YYYY-MM-DD")
        }

        // console.log("pushing index",i);
        dataArray.push({
          to_street1: el.to_street1,
          to_street2: el.to_street2,
          to_city: el.to_city,
          to_state: el.to_state,
          to_zip: el.to_zip,
          to_country: el.to_country,
          to_name: el.to_name,
          from_street1: el.from_street1,
          from_street2: el.from_street2,
          from_city: el.from_city,
          from_state: el.from_state,
          from_zip: el.from_zip,
          from_country: el.from_country,
          from_name: el.from_name,
          order_number: el.order_number
            ? el.order_number +
            (el.package_number
              ? "-" +
              el.package_number +
              (el.package_index ? " (" + el.package_index + ")" : "")
              : "")
            : "-",
          // order_date: el.store_type?(el.store_type=="walmart"?moment(el.store_order_updated_at).format("YYYY-MM-DD"):el.store_order_updated_at.toString().substring(0,10)):el.order_date.toString().substring(0,10),
          order_date: storeOrderData,
          shipment_date: "-",
          items: itemArray,
          sub_total: parseFloat(
            itemArray.reduce((accumulator, currentObject) => {
              return accumulator + parseFloat(currentObject.total_price)
            }, 0)
          ).toFixed(2),
          total: parseFloat(
            itemArray.reduce((accumulator, currentObject) => {
              return accumulator + parseFloat(currentObject.total_price)
            }, 0)
          ).toFixed(2),
        })
      })

      let x = dataArray
      setMergedPackingSlip([...x])
      document.body.click()
      console.log("x", x.length)
      setTimeout(() => {
        document.body.style.pointerEvents = "auto"
        toast.remove(loading)
        toast.success(
          x.length + ` packing ${x.length > 1 ? "slips" : "slip"} generated`
        )

        document
          .getElementsByClassName("merged-packing-slip-downloader")[0]
          .click()
        clickedSideBarJson = []
        uncheckAll()
        selectedOrders = []
        setSelectedOrdersCount(selectedOrders.length)
        setMergedPackingSlip([])
      }, x.length * 200)
    } else {
      toast.error("Please select at least one order")
    }
  }

  const downloadMergedPackingSlipTemplete2 = async () => {
    if (clickedSideBarJson.length > 0) {
      document.body.style.pointerEvents = "none"
      let loading = toast.loading(
        `Creating Packing ${clickedSideBarJson.length > 1 ? "Slips" : "Slip"
        }...`
      )

      let dataArray = []

      await clickedSideBarJson.map(async (el, i) => {
        let itemArray = []
        if (
          el.custom_items_data &&
          JSON.parse(
            el.store_type == "ebay"
              ? JSON.parse(el.custom_items_data)
              : el.custom_items_data
          ).length > 0
        ) {
          let items = JSON.parse(
            el.store_type == "ebay"
              ? JSON.parse(el.custom_items_data)
              : el.custom_items_data
          )
          items.map(item => {
            if (el.store_type == null) {
              itemArray.push({
                product_identifier: item.product_identifier,
                product_sku: "-",
                description: item.description,
                quantity: item.quantity,
                price: parseFloat(item.total_value).toFixed(2),
                total_price: parseFloat(
                  parseInt(item.quantity) * parseFloat(item.total_value)
                ).toFixed(2),
              })
            } else if (el.store_type == "shopify") {
              itemArray.push({
                product_identifier: item.id,
                product_sku: "-",
                description: item.name,
                quantity: item.quantity,
                price: parseFloat(item.price).toFixed(2),
                total_price: parseFloat(
                  parseInt(item.quantity) * parseFloat(item.price)
                ).toFixed(2),
              })
            } else if (el.store_type == "ebay") {
              itemArray.push({
                product_identifier: item.lineItemId,
                product_sku: "-",
                description: item.title,
                quantity: item.quantity,
                price: parseFloat(item.lineItemCost.value).toFixed(2),
                total_price: parseFloat(
                  parseInt(item.quantity) * parseFloat(item.lineItemCost.value)
                ).toFixed(2),
              })
            } else if (el.store_type == "walmart") {
              itemArray.push({
                product_identifier: item.id,
                product_sku: item.item.sku,
                description: item.item.productName,
                quantity: item.orderLineQuantity.amount,
                price: parseFloat(
                  item.charges.charge[0].chargeAmount.amount
                ).toFixed(2),
                total_price: parseFloat(
                  parseInt(item.orderLineQuantity.amount) *
                  parseFloat(item.charges.charge[0].chargeAmount.amount)
                ).toFixed(2),
              })
            } else if (el.store_type == "zenventory") {
              itemArray.push({
                product_identifier: item.customerorderitemid,
                product_sku: "-",
                description: item.itemmaster.description,
                quantity: item.quantity,
                price: parseFloat(item.customerprice).toFixed(2),
                total_price: parseFloat(
                  parseInt(item.quantity) * parseFloat(item.customerprice)
                ).toFixed(2),
              })
            } else if (el.store_type == "sellercloud") {
              // itemArray.push({
              //   'product_identifier': item.ProductID,
              //   'description': el.is_package==0?item.DisplayName:item.ItemName,
              //   'quantity': item.Qty,
              //   'price': parseFloat(el.is_package==0?item.AdjustedSitePrice:0).toFixed(2),
              //   'total_price': parseFloat(parseInt(item.Qty) * parseFloat(el.is_package==0?item.AdjustedSitePrice:0)).toFixed(2)
              // });

              itemArray.push({
                product_identifier: item.ProductID,
                product_sku: "-",
                description: item.DisplayName,
                quantity: item.Qty,
                price: parseFloat(item.AdjustedSitePrice).toFixed(2),
                total_price: parseFloat(
                  parseInt(item.Qty) *
                  parseFloat(item.AdjustedSitePrice).toFixed(2)
                ),
              })
            } else if (el.store_type == "shipstation") {
              itemArray.push({
                product_identifier: item.orderItemId,
                product_sku: item.sku ? item.sku : "-",
                description: item.name,
                quantity: item.quantity,
                price: parseFloat(item.unitPrice).toFixed(2),
                total_price: parseFloat(
                  parseInt(item.quantity) * parseFloat(item.unitPrice)
                ).toFixed(2),
              })
            } else if (el.store_type == "veeqo") {
              itemArray.push({
                product_identifier: item.sellable.id,
                product_sku: item.sellable.sku_code
                  ? item.sellable.sku_code
                  : "-",
                description: item.sellable.product_title,
                quantity: item.quantity,
                price: parseFloat(item.sellable.price).toFixed(2),
                total_price: parseFloat(
                  parseInt(item.quantity) * parseFloat(item.sellable.price)
                ).toFixed(2),
              })
            } else if (el.store_type == "shiphero") {
              itemArray.push({
                product_identifier: "-",
                product_sku: item.sku ? item.sku : "-",
                description: item?.product_title ? item?.product_title : "",
                quantity: item.quantity_allocated,
                // 'price': parseFloat(item.total_price).toFixed(2),
                // 'total_price': parseFloat(parseInt(item.quantity) * parseFloat(item.sellable.price)).toFixed(2)
                price: "-",
                total_price: "-",
              })
            } else if (el.store_type == "orderdesk") {
              itemArray.push({
                product_identifier: el.id,
                product_sku: item.sku ? item.sku : "-",
                description: item?.name ? item?.name : "",
                quantity: item.quantity,
                // 'price': parseFloat(item.total_price).toFixed(2),
                // 'total_price': parseFloat(parseInt(item.quantity) * parseFloat(item.sellable.price)).toFixed(2)
                price: item?.price,
                total_price: item?.price * item.quantity,
              })
            }
          })
        }

        console.log("itemArray", itemArray)
        let storeOrderData = ""
        let bill_to_street1 = ""
        let bill_to_street2 = ""
        let bill_to_city = ""
        let bill_to_state = ""
        let bill_to_zip = ""
        let bill_to_country = ""
        if (el.store_type) {
          let StoreOrder = JSON.parse(el.store_order_json)
          if (el.store_type == "shopify") {
            storeOrderData = moment(StoreOrder.created_at).format("YYYY-MM-DD")
          } else if (el.store_type == "ebay") {
            storeOrderData = moment(StoreOrder.updated_at).format("YYYY-MM-DD")
          } else if (el.store_type == "walmart") {
            storeOrderData = moment(StoreOrder.orderDate).format("YYYY-MM-DD")
          } else if (el.store_type == "zenventory") {
            storeOrderData = moment(StoreOrder.ordereddate).format("YYYY-MM-DD")
          } else if (el.store_type == "sellercloud") {
            // console.log("StoreOrder.TimeOfOrder",StoreOrder.TimeOfOrder)
            storeOrderData = moment(StoreOrder.TimeOfOrder).format("YYYY-MM-DD")
            // console.log("storeOrderData",storeOrderData)
          } else if (el.store_type == "shipstation") {
            storeOrderData = moment(StoreOrder.TimeOfOrder).format("YYYY-MM-DD")

            bill_to_street1 = StoreOrder.billTo.street1
            bill_to_street2 = StoreOrder.billTo.street2
            bill_to_city = StoreOrder.billTo.city
            bill_to_state = StoreOrder.billTo.state
            bill_to_zip = StoreOrder.billTo.postalCode
            bill_to_country = StoreOrder.billTo.country
          } else if (el.store_type == "veeqo") {
            // console.log("StoreOrder.TimeOfOrder",StoreOrder.TimeOfOrder)
            storeOrderData = moment(StoreOrder.created_at).format("YYYY-MM-DD")
            // console.log("storeOrderData",storeOrderData)
          } else if (el.store_type == "shiphero") {
            storeOrderData = moment(StoreOrder.order_history.created_at).format(
              "YYYY-MM-DD"
            )
          } else if (el.store_type == "orderdesk") {
            storeOrderData = moment(StoreOrder.date_added).format("YYYY-MM-DD")
          }
        } else {
          storeOrderData = moment(el.order_date)
            .tz(moment.tz.guess())
            .format("YYYY-MM-DD")
        }

        let extra_row = []

        for (let i = itemArray.length; i <= 8; i++) {
          if (i < 8) {
            extra_row.push(
              <>
                <View key={i} style={tableStyles.tableEmptyRow}></View>
              </>
            )
          } else {
            extra_row.push(
              <>
                <View key={i} style={tableStyles.tableEmptyLastRow}></View>
              </>
            )
          }
        }

        dataArray.push({
          to_street1: el.to_street1,
          to_street2: el.to_street2,
          to_city: el.to_city,
          to_state: el.to_state,
          to_zip: el.to_zip,
          to_country: el.to_country,
          to_name: el.to_name,
          bill_to_street1: bill_to_street1,
          bill_to_street2: bill_to_street2,
          bill_to_city: bill_to_city,
          bill_to_state: bill_to_state,
          bill_to_zip: bill_to_zip,
          bill_to_country: bill_to_country,
          from_street1: el.from_street1,
          from_street2: el.from_street2,
          from_city: el.from_city,
          from_state: el.from_state,
          from_zip: el.from_zip,
          from_country: el.from_country,
          from_name: el.from_name,
          reference_number: el.reference_number,
          order_number: el.order_number
            ? el.order_number +
            (el.package_number
              ? "-" +
              el.package_number +
              (el.package_index ? " (" + el.package_index + ")" : "")
              : "")
            : "-",
          // order_date: el.store_type?(el.store_type=="walmart"?moment(el.store_order_updated_at).format("YYYY-MM-DD"):el.store_order_updated_at.toString().substring(0,10)):el.order_date.toString().substring(0,10),
          order_date: storeOrderData,
          shipment_date: moment(el.created_at)
            .tz(moment.tz.guess())
            .format("YYYY-MM-DD"),
          items: itemArray,
          extra_row: extra_row,
          sub_total: parseFloat(
            itemArray.reduce((accumulator, currentObject) => {
              return accumulator + parseFloat(currentObject.total_price)
            }, 0)
          ).toFixed(2),
          total: parseFloat(
            itemArray.reduce((accumulator, currentObject) => {
              return accumulator + parseFloat(currentObject.total_price)
            }, 0)
          ).toFixed(2),
        })
      })

      let x = dataArray
      setMergedPackingSlipTemplete2([...x])
      document.body.click()
      console.log("x", x.length)
      setTimeout(() => {
        document.body.style.pointerEvents = "auto"
        toast.remove(loading)
        toast.success(
          x.length + ` packing ${x.length > 1 ? "slips" : "slip"} generated`
        )

        document
          .getElementsByClassName("merged-packing-slip-template2-downloader")[0]
          .click()
        clickedSideBarJson = []
        uncheckAll()
        selectedOrders = []
        setSelectedOrdersCount(selectedOrders.length)
        setMergedPackingSlipTemplete2([])
      }, x.length * 200)
    } else {
      toast.error("Please select at least one order")
    }
  }

  createTheme("solarized", { text: { primary: "#fffff", secondary: "#fffff", }, background: { default: "#0f0f0f", }, context: { background: "#cb4b16", text: "#0f0f0f", }, divider: { default: "#282828", }, action: { button: "rgba(0,0,0,.54)", hover: "rgba(0,0,0,.08)", disabled: "rgba(0,0,0,.12)", }, }, "dark")

  const handleUpdateBelowAndAboveFiveLbs = () => {
    if (
      aboveFiveLbsOrders.length > 0 ||
      belowFiveLbsOrders.length > 0 ||
      underOneLbsOrders.length > 0 ||
      aboveTwentyLbsOrders.length > 0
    ) {
      let body = {
        user_id: JSON.parse(localStorage.getItem("authUser")).role_id == 4 ? JSON.parse(localStorage.getItem("authUser")).parent_id : JSON.parse(localStorage.getItem("authUser")).id,
        aboveFiveLbsOrders:
          aboveFiveLbsOrders.length > 0
            ? aboveFiveLbsOrders.map(el => el.id).toString()
            : "",
        aboveFiveLbsService: aboveFiveLbsService,
        belowFiveLbsOrders:
          belowFiveLbsOrders.length > 0
            ? belowFiveLbsOrders.map(el => el.id).toString()
            : "",
        belowFiveLbsService: belowFiveLbsService,
        underOneLbsOrders:
          underOneLbsOrders.length > 0
            ? underOneLbsOrders.map(el => el.id).toString()
            : "",
        underOneLbsService: underOneLbsService,
        aboveTwentyLbsOrders:
          aboveTwentyLbsOrders.length > 0
            ? aboveTwentyLbsOrders.map(el => el.id).toString()
            : "",
        aboveTwentyLbsService: aboveTwentyLbsService,
      }

      console.log("body", body)
      post("/user/update-orders-above-below", body, { headers }).then(
        async response => {
          if (response.status == 200) {
            retrieveOrdersByFilterType()
            toast.success(response.message)
            setClickedJson([])
            uncheckAll()
            selectedOrders = []
            setSelectedOrdersCount(selectedOrders.length)
            clickedSideBarJson = []
            selectedOrderJson = []
            selectedOrderJson = []
            setSingleOrderWeight("")
            setSingleOrderServiceId("")
            setSingleOrderWidth("")
            setSingleOrderHeight("")
            setSingleOrderLength("")
            setIsFullTextVisible(false)
            setIsFullTextVisibleBelow(false)
            setIsFullTextVisibleUnderOne(false)
            setIsFullTextVisibleAboveTwenty(false)
            setServiceRateAboveFiveLbs("")
            setServiceRateAboveTwentyLbs("")
            setServiceRateBelowFiveLbs("")
            setServiceRateUnderOneLbs("")
            setBelowFiveLbsOrders([])
            setAboveFiveLbsOrders([])
            setUnderOneLbsOrders([])
            setAboveTwentyLbsOrders([])
          } else {
            toast.error(response.message)
          }
        }
      )
    }
  }

  const handleUpdateUserPreferences = data => {
    try {
      const key = Object.keys(data)[0]
      let columnPreferences = {
        hideShowItemName: showHide.itemName,
        hideShowServiceName: showHide.serviceName,
        hideShowFromName: showHide.fromName,
        hideShowToName: showHide.toName,
        hideShowNotes: showHide.notes,
        hideShowTags: showHide.tags,
        hideShowOrderNumber: showHide.orderNumber,
        hideShowPackageNumber: showHide.packageNumber,
        hideShowOrderDate: showHide.orderDate,
        hideShowUpdatedAt: showHide.updatedAt,
        hideShowDimension: showHide.dimension,
        hideShowWeight: showHide.weight,
        hideShowStatus: showHide.status,
        hideShowItemNames: showHide.itemNames,
        hideShowProductIds: showHide.productIds,
        hideShowProductSKU: showHide.productSkus,
        hideShowPicklists: showHide.picklists,
        hideShowAssignedUser: showHide.assignedUser
      }
      columnPreferences[key] = data[key]

      let body = {
        user_id: JSON.parse(localStorage.getItem("authUser")).role_id == 4 ? JSON.parse(localStorage.getItem("authUser")).parent_id : JSON.parse(localStorage.getItem("authUser")).id,
        store_id: selectedStore,
        type: "awaiting-shipment-columns",
        data: JSON.stringify(columnPreferences),
      }

      // console.log("update-user-preferences body", body)

      post("/user/update-user-preferences", body, { headers }).then(
        response => {
          // console.log("response", response)
        }
      )
    } catch (e) { }
  }


  // LABEL AND PACKIN SLIP DOWNLOADIND CODE NEW 

  let allLabelsAndPackingSlip = useRef([])
  let pdfBlobs3 = useRef([]);
  let pdfBlobs = useRef([]);

  const [hasDownloaded, setHasDownloaded] = useState(false);
  const [downloadedFiles, setDownloadedFiles] = useState([]); // Track downloaded files


  function debounce(func, delay) {
    let timeout;
    return function (...args) {
      if (timeout) { clearTimeout(timeout); }
      timeout = setTimeout(() => { func(...args); }, delay);
    };
  }


  const retrieveNotifications = useCallback(async () => {
    if(JSON.parse(localStorage.getItem("authUser")).auto_download == 1){
      const body = { id: JSON.parse(localStorage.getItem("authUser")).role_id == 4 ? JSON.parse(localStorage.getItem("authUser")).id : JSON.parse(localStorage.getItem("authUser")).id };
      await post('/user/get-notifications-limited', body, { headers })
        .then(async (response) => {
          if (response.status === 200) {
            let notifications = [];
            for (let i = 0; i < response.data.length; i++) {
              let found = notifications.some((el) => el.title === response.data[i].title && el.batch_id === response.data[i].batch_id);
              if (!found) { notifications.push(response.data[i]); }
            }
  
            const createdAtDate = new Date(notifications[0]?.created_at);
            const currentDate = new Date();
            const timeDifference = currentDate - createdAtDate;
            const thirtySeconds = 10 * 1000;
  
            if (notifications[0]?.files && timeDifference <= thirtySeconds && clickedSideBarJson.length > 0) {
              handleDownloadMerged(notifications[0]?.files);
              handlePackingSlip3();
  
            }
          }
        })
        .catch((err) => {
          console.log(err, 'Error fetching notifications');
        });
    }
    else{
      console.log('NOT DOWNLOADING')
    }
  }, [downloadedFiles]);

  let toastLoading;

  let startTime


  const handleDownloadMerged = async (files) => {
    startTime = performance.now();
    let downloadFiles = files.split(",");
    pdfBlobs.current = [];
    let order_ids = [];
    document.body.style.pointerEvents = "none"
    // let loading = toast.loading("Compressing orders...")
    try {
      let notExistingFilesCount = 0;

      await Promise.all(downloadFiles.map(async (el) => {
        await axios.get(`${baseUrl()}/user/get-pdf/${el}`, { headers, responseType: "blob" })
          .then(async (response) => {
            if (response.headers["file-name"]) {
              const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
              order_ids.push(response.headers["order-id"]);
              pdfBlobs.current.push(pdfBlob);
            }
            else {
              notExistingFilesCount++;
            }
          }).catch((err) => {
            // console.log("err",err);
          });
      })).then(async () => {
        if (notExistingFilesCount > 0) {
          toast.error(notExistingFilesCount + " pdf file do not exits");
        }
        document.body.style.pointerEvents = "auto"
        // toast.remove(loading)
        // let loading1 = toast.loading("Downloading orders...")


        // Create a URL for the Blob
        // const url = window.URL.createObjectURL(blob);
        // const a = document.createElement('a');
        // a.href = url;
        // a.download = 'merged-labels.pdf';
        // a.style.display = 'none';
        // document.body.appendChild(a);
        // a.click();

        let payload = { order_ids: order_ids.toString() }
        console.log("payload", payload)
        post('/user/mark-pdf-printed', payload, { headers }).then(async (response) => {
          const mergedBlob = await mergePDFs(pdfBlobs.current);
          const blob = new Blob([mergedBlob], { type: 'application/pdf' });
          // window.URL.revokeObjectURL(url);
          // toast.remove(loading1)
        }).catch(async (errr) => {
          window.URL.revokeObjectURL(url);
          // toast.remove(loading1)
        })

      })
    }
    catch (e) {
      // console.log("e",e);
      // toast.remove(loading)
      toast.error("Something went wrong");
    }
  };
  const handlePackingSlip3 = async () => {
    toastLoading = toast.loading("Merging Label And Packing Slip")
    let l = clickedSideBarJson.length
    if (clickedSideBarJson.length > 0) {
      document.body.style.pointerEvents = "none"
      await Promise.all(
        clickedSideBarJson.map(async (el, i) => {
          setTimeout(async () => {
            setSlipName(el.order_unique_id)
            setPCToStreet1(el.to_street1)
            setPCToStreet2(el.to_street2)
            setPCToCity(el.to_city)
            setPCToState(el.to_state)
            setPCToZip(el.to_zip)
            setPCToCountry(el.to_country)
            setPCToName(el.to_name)
            setPCFromName(el.from_name)
            setPCFromStreet1(el.from_street1)
            setPCFromStreet2(el.from_street2)
            setPCFromCity(el.from_city)
            setPCFromState(el.from_state)
            setPCFromZip(el.from_zip)
            setPCFromCountry(el.from_country)
            setPCOrderNumber(el.order_number ? el.order_number + (el.package_number ? "-" + el.package_number + (el.package_index ? " (" + el.package_index + ")" : "") : "") : "-")
            setPCReferenceNumber(el.reference_number)
            let storeOrderData = ""
            if (el.store_type) {
              let StoreOrder = JSON.parse(el.store_order_json)
              if (el.store_type == "shopify") {
                storeOrderData = moment(StoreOrder.created_at).format("YYYY-MM-DD")
              }
              else if (el.store_type == "ebay") {
                storeOrderData = moment(StoreOrder.updated_at).format("YYYY-MM-DD")
              }
              else if (el.store_type == "walmart") {
                storeOrderData = moment(StoreOrder.orderDate).format("YYYY-MM-DD")
              }
              else if (el.store_type == "zenventory") {
                storeOrderData = moment(StoreOrder.ordereddate).format("YYYY-MM-DD")
              }
              else if (el.store_type == "sellercloud") {
                storeOrderData = moment(StoreOrder.TimeOfOrder).format("YYYY-MM-DD")
              }
              else if (el.store_type == "shipstation") {
                storeOrderData = moment(StoreOrder.createDate).format("YYYY-MM-DD")
              }
              else if (el.store_type == "veeqo") {
                storeOrderData = moment(StoreOrder.created_at).format("YYYY-MM-DD")
              }
              else if (el.store_type == "shiphero") {
                storeOrderData = moment(StoreOrder.order_history[0].created_at).format("YYYY-MM-DD")
              }
              else if (el.store_type == "orderdesk") {
                storeOrderData = moment(StoreOrder.date_added).format("YYYY-MM-DD")
              }
            }
            else {
              storeOrderData = moment(el.order_date).tz(moment.tz.guess()).format("YYYY-MM-DD")
            }

            let itemArray = []
            let subTotals = 0
            let totals = 0
            setPCOrderDate(storeOrderData)
            if (el.custom_items_data && JSON.parse(el.store_type == "ebay" ? JSON.parse(el.custom_items_data) : el.custom_items_data).length > 0) {
              let items = JSON.parse(el.store_type == "ebay" ? JSON.parse(el.custom_items_data) : el.custom_items_data)
              await Promise.all(
                items.map(item => {
                  if (el.store_type == null) {
                    itemArray.push({ product_identifier: item.product_identifier, product_sku: "-", description: item.description, quantity: item.quantity, price: parseFloat(item.total_value).toFixed(2), total_price: parseFloat(parseInt(item.quantity) * parseFloat(item.total_value)).toFixed(2), })
                  }
                  else if (el.store_type == "shopify") {
                    itemArray.push({ product_identifier: item.id, product_sku: "-", description: item.name, quantity: item.quantity, price: parseFloat(item.price).toFixed(2), total_price: parseFloat(parseInt(item.quantity) * parseFloat(item.price)).toFixed(2), })
                  }
                  else if (el.store_type == "ebay") {
                    itemArray.push({ product_identifier: item.lineItemId, product_sku: "-", description: item.title, quantity: item.quantity, price: parseFloat(item.lineItemCost.value).toFixed(2), total_price: parseFloat(parseInt(item.quantity) * parseFloat(item.lineItemCost.value)).toFixed(2), })
                  }
                  else if (el.store_type == "walmart") {
                    itemArray.push({ product_identifier: item.id, product_sku: item.item.sku, description: item.item.productName, quantity: item.orderLineQuantity.amount, price: parseFloat(item.charges.charge[0].chargeAmount.amount).toFixed(2), total_price: parseFloat(parseInt(item.orderLineQuantity.amount) * parseFloat(item.charges.charge[0].chargeAmount.amount)).toFixed(2), })
                  }
                  else if (el.store_type == "zenventory") {
                    itemArray.push({ product_identifier: item.customerorderitemid, product_sku: "-", description: item.itemmaster.description, quantity: item.quantity, price: parseFloat(item.customerprice).toFixed(2), total_price: parseFloat(parseInt(item.quantity) * parseFloat(item.customerprice)).toFixed(2), })
                  }
                  else if (el.store_type == "sellercloud") {
                    itemArray.push({ product_identifier: item.ProductID, product_sku: "-", description: item.DisplayName, quantity: item.Qty, price: parseFloat(item.AdjustedSitePrice).toFixed(2), total_price: parseFloat(parseInt(item.Qty) * parseFloat(item.AdjustedSitePrice)).toFixed(2), })
                  }
                  else if (el.store_type == "shipstation") {
                    itemArray.push({ product_identifier: item.orderItemId, product_sku: item.sku ? item.sku : "-", description: item.name, quantity: item.quantity, price: parseFloat(item.unitPrice).toFixed(2), total_price: parseFloat(parseInt(item.quantity) * parseFloat(item.unitPrice)).toFixed(2), })
                  }
                  else if (el.store_type == "veeqo") {
                    itemArray.push({ product_identifier: item.sellable.id, product_sku: item.sellable.sku_code ? item.sellable.sku_code : "-", description: item.sellable.product_title, quantity: item.quantity, price: parseFloat(item.sellable.price).toFixed(2), total_price: parseFloat(parseInt(item.quantity) * parseFloat(item.sellable.price)).toFixed(2), })
                  }
                  else if (el.store_type == "shiphero") {
                    itemArray.push({ product_identifier: "-", product_sku: item.sku ? item.sku : "-", description: item?.product_title ? item?.product_title : "", quantity: item.quantity_allocated, price: "-", total_price: "-", })
                  }
                  else if (el.store_type == "orderdesk") {
                    itemArray.push({ product_identifier: item?.id, product_sku: item.sku ? item.sku : "-", description: item?.name ? item?.name : "", quantity: item.quantity, price: item?.price, total_price: item?.price * item.quantity, })
                  }
                })
              )
              setPcItems(itemArray)
              subTotals = parseFloat(itemArray.reduce((accumulator, currentObject) => { return accumulator + parseFloat(currentObject.total_price) }, 0)).toFixed(2)
              totals = parseFloat(itemArray.reduce((accumulator, currentObject) => { return accumulator + parseFloat(currentObject.total_price) }, 0)).toFixed(2)
              setPcSubTotal(parseFloat(itemArray.reduce((accumulator, currentObject) => { return accumulator + parseFloat(currentObject.total_price) }, 0)).toFixed(2))
              setPcTotal(parseFloat(itemArray.reduce((accumulator, currentObject) => { return accumulator + parseFloat(currentObject.total_price) }, 0)).toFixed(2))
            }
            else {
              setPcItems([])
              setPcSubTotal("0.00")
              setPcTotal("0.00")
            }

            setTimeout(() => {

              const container = document.createElement("div");
              document.body.appendChild(container); // Append it to the DOM

              const pdfComponent = (
                <PDFDownloadLink document={<PackingSlip pc_from_name={el.from_name} pc_from_street1={el.from_street1} pc_from_street2={el.from_street2} pc_from_city={el.from_city} pc_from_state={el.from_state} pc_from_zip={el.from_zip} pc_from_country={el.from_country} pc_to_street1={el.to_street1} pc_to_street2={el.to_street2} pc_to_city={el.to_city} pc_to_state={el.to_state} pc_to_zip={el.to_zip} pc_to_country={el.to_country} pc_order_number={el.order_number}
                  pc_order_date={storeOrderData} pc_to_name={el.to_name} pc_items={itemArray} pc_sub_total={subTotals} pc_total={totals} />} fileName={`${slip_name}-packing-slip.pdf`}>
                  {({ blob, url }) => {
                    if (blob && url) {
                      if (i + 1 >= pdfBlobs3.current.length) {
                        handleBlobsAndUrls(blob, l);
                      }
                    }
                    return null;
                  }}
                </PDFDownloadLink>
              );

              const root = createRoot(container);
              root.render(pdfComponent);

            }, 1000)

            if (clickedSideBarJson.length == i + 1) {
              // toast.remove(loading)
              // toast.success(clickedSideBarJson.length + ` packing ${clickedSideBarJson.length > 1 ? "slips" : "slip"} generated`)
              clickedSideBarJson = []
              uncheckAll()
              selectedOrders = []
              setSelectedOrdersCount(selectedOrders.length)
              clickedSideBarJson = []
              selectedOrderJson = []
              setBulkUpdateServiceId("")
              setBulkUpdateToPhone("")
              setEnableBulkUpdateToPhone(false)
              document.body.style.pointerEvents = "auto"
            }
          }, (i + 1) * 2000)
        })
      )

    }
    else {
      toast.error("Please select at least one order")
    }
  }

  async function mergePDFs(pdfBlobs) {

    const mergedPdf = await PDFDocument.create();

    for (const pdfBlob of pdfBlobs) {
      const pdfBytes = await pdfBlob.arrayBuffer();
      const pdf = await PDFDocument.load(pdfBytes);
      const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
      copiedPages.forEach((page) => mergedPdf.addPage(page));
    }

    const mergedPdfBlob = await mergedPdf.save();
    let blobFormat = new Blob([mergedPdfBlob], { type: 'application/pdf' })
    allLabelsAndPackingSlip.current.push(blobFormat)
    return mergedPdfBlob;
  }
  async function mergePDFsForPackingSlip(pdfBlobs) {
    const mergedPdf = await PDFDocument.create();
    for (const pdfBlob of pdfBlobs) {
      const pdfBytes = await pdfBlob.arrayBuffer();
      const pdf = await PDFDocument.load(pdfBytes);
      const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
      copiedPages.forEach((page) => mergedPdf.addPage(page));
    }

    // Wrap the merged PDF bytes in a Blob
    const mergedPdfBytes = await mergedPdf.save();
    let blobFormat = new Blob([mergedPdfBytes], { type: 'application/pdf' })
    allLabelsAndPackingSlip.current.push(blobFormat)

    return new Blob([mergedPdfBytes], { type: 'application/pdf' });
  }

  async function mergeAll(pdfBlobss) {
    const mergedPdf = await PDFDocument.create();
    const allPages = [];
    for (const pdfBlob of pdfBlobss) {
      const pdfBytes = await pdfBlob.arrayBuffer();
      const pdf = await PDFDocument.load(pdfBytes);
      const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
      allPages.push(...copiedPages)
    }
    if (allPages.length % 2 !== 0) {
      allPages.pop();
    }

    const midPoint = Math.floor(allPages.length / 2);
    const sortedPages = Array.from({ length: allPages.length }, (_, i) => i % 2 === 0 ? allPages[Math.floor(i / 2)] : allPages[Math.floor((i - 1) / 2) + midPoint]);

    sortedPages.forEach((page) => mergedPdf.addPage(page));
    const mergedPdfBytes = await mergedPdf.save();
    return new Blob([mergedPdfBytes], { type: 'application/pdf' });
  }


  const handleBlobsAndUrls = async (blob, packingLength) => {
    pdfBlobs3.current.push(blob);
    if (pdfBlobs3.current.length === packingLength) {
      const mergedBlob = await mergePDFsForPackingSlip(pdfBlobs3.current);
      finalDownloadAll()
    }
  };


  const finalDownloadAll = async () => {
    toast.remove(toastLoading);
    const mergedBlob = await mergeAll(allLabelsAndPackingSlip.current);
    toast.success("Label & Packing Slip Generated");

    const blob = new Blob([mergedBlob], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);
    // window.open(url);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'merged-labels-and-packing-slip.pdf';
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
    console.log(`Total Time Taken For Label Generation ${(performance.now() - startTime) / 1000}`);
    allLabelsAndPackingSlip.current = []
    pdfBlobs3.current = []
    pdfBlobs.current = []
    window.location.reload()
  };

  
  const debouncedRetrieveNotifications = useCallback(debounce(retrieveNotifications, 1000), [retrieveNotifications]);



  useEffect(() => {
    if ('Notification' in window) {
      if (Notification.permission !== 'granted') {
        Notification.requestPermission();
      }
    }

    if (JSON.parse(localStorage.getItem('authUser')).role_id === 1 || JSON.parse(localStorage.getItem('authUser')).role_id === 2 || JSON.parse(localStorage.getItem('authUser')).role_id === 4) {
      debouncedRetrieveNotifications();

      socket.emit('join', localStorage.getItem('admvu') === '1' ? 0 : JSON.parse(localStorage.getItem('authUser')).id);
      socket.on('connect', debouncedRetrieveNotifications);
      socket.on('disconnect', debouncedRetrieveNotifications);
      socket.on('newNotification', debouncedRetrieveNotifications);

      return () => {
        socket.off('connect', debouncedRetrieveNotifications);
        socket.off('disconnect', debouncedRetrieveNotifications);
        socket.off('newNotification', debouncedRetrieveNotifications);
      };
    }
  }, [debouncedRetrieveNotifications]);



  // DOWNLOADING LABEL AND PACKING  SLIP OLD CODE 
  // const [hasDownloaded, setHasDownloaded] = useState(false);
  // const [downloadedFiles, setDownloadedFiles] = useState([]); // Track downloaded files
  // let pdfBlobs = [];

  // function debounce(func, delay) {
  //   let timeout;
  //   return function (...args) {
  //     if (timeout) { clearTimeout(timeout); }
  //     timeout = setTimeout(() => { func(...args); }, delay);
  //   };
  // }


  // const retrieveNotifications = useCallback(async () => {
  //   const body = { id: JSON.parse(localStorage.getItem("authUser")).role_id == 4 ? JSON.parse(localStorage.getItem("authUser")).id : JSON.parse(localStorage.getItem("authUser")).id };
  //   await post('/user/get-notifications-limited', body, { headers })
  //     .then(async (response) => {
  //       if (response.status === 200) {
  //         let notifications = [];
  //         for (let i = 0; i < response.data.length; i++) {
  //           let found = notifications.some((el) => el.title === response.data[i].title && el.batch_id === response.data[i].batch_id);
  //           if (!found) { notifications.push(response.data[i]); }
  //         }

  //         const createdAtDate = new Date(notifications[0]?.created_at);
  //         const currentDate = new Date();
  //         const timeDifference = currentDate - createdAtDate;
  //         const thirtySeconds = 10 * 1000;

  //         if (notifications[0]?.files && timeDifference <= thirtySeconds) {
  //           handleDownloadMerged(notifications[0]?.files);
  //           handlePackingSlip()
  //         }
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err, 'Error fetching notifications');
  //     });
  // }, [downloadedFiles]);

  // const handleDownloadMerged = async (files) => {
  //   let downloadFiles = files.split(",");
  //   pdfBlobs = [];
  //   let order_ids = [];
  //   document.body.style.pointerEvents = "none"
  //   let loading = toast.loading("Compressing orders...")
  //   try {
  //     let notExistingFilesCount = 0;

  //     await Promise.all(downloadFiles.map(async (el) => {
  //       await axios.get(`${baseUrl()}/user/get-pdf/${el}`, { headers, responseType: "blob" })
  //         .then(async (response) => {
  //           if (response.headers["file-name"]) {
  //             // console.log("response",response);
  //             const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
  //             order_ids.push(response.headers["order-id"]);
  //             pdfBlobs.push(pdfBlob);
  //           }
  //           else {
  //             notExistingFilesCount++;
  //           }
  //         }).catch((err) => {
  //           // console.log("err",err);
  //         });
  //     })).then(async () => {
  //       if (notExistingFilesCount > 0) {
  //         toast.error(notExistingFilesCount + " pdf file do not exits");
  //       }
  //       document.body.style.pointerEvents = "auto"
  //       toast.remove(loading)
  //       let loading1 = toast.loading("Downloading orders...")
  //       const mergedBlob = await mergePDFs(pdfBlobs);
  //       const blob = new Blob([mergedBlob], { type: 'application/pdf' });

  //       // Create a URL for the Blob
  //       const url = window.URL.createObjectURL(blob);

  //       // Create a temporary link to trigger the download
  //       const a = document.createElement('a');
  //       a.href = url;
  //       a.download = 'merged-labels.pdf';
  //       a.style.display = 'none';
  //       document.body.appendChild(a);
  //       a.click();

  //       let payload = {
  //         order_ids: order_ids.toString()
  //       }
  //       console.log("payload", payload)
  //       post('/user/mark-pdf-printed', payload, { headers }).then(async (response) => {
  //         window.URL.revokeObjectURL(url);
  //         toast.remove(loading1)
  //       }).catch(async (errr) => {
  //         window.URL.revokeObjectURL(url);
  //         toast.remove(loading1)
  //       })

  //     })
  //   }
  //   catch (e) {
  //     // console.log("e",e);
  //     toast.remove(loading)
  //     toast.error("Something went wrong");
  //   }
  // };
  // const debouncedRetrieveNotifications = useCallback(
  //   debounce(retrieveNotifications, 1000),  // 1 second debounce
  //   [retrieveNotifications]
  // );

  // async function mergePDFs(pdfBlobs) {
  //   const mergedPdf = await PDFDocument.create();

  //   for (const pdfBlob of pdfBlobs) {
  //     const pdfBytes = await pdfBlob.arrayBuffer();
  //     const pdf = await PDFDocument.load(pdfBytes);
  //     const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
  //     copiedPages.forEach((page) => mergedPdf.addPage(page));
  //   }

  //   const mergedPdfBlob = await mergedPdf.save();

  //   return mergedPdfBlob;
  // }

  // useEffect(() => {
  //   if ('Notification' in window) {
  //     if (Notification.permission !== 'granted') {
  //       Notification.requestPermission();
  //     }
  //   }

  //   if (JSON.parse(localStorage.getItem('authUser')).role_id === 1 || JSON.parse(localStorage.getItem('authUser')).role_id === 2 || JSON.parse(localStorage.getItem('authUser')).role_id === 4) {
  //     debouncedRetrieveNotifications();

  //     socket.emit('join', localStorage.getItem('admvu') === '1' ? 0 : JSON.parse(localStorage.getItem('authUser')).id);
  //     socket.on('connect', debouncedRetrieveNotifications);
  //     socket.on('disconnect', debouncedRetrieveNotifications);
  //     socket.on('newNotification', debouncedRetrieveNotifications);

  //     return () => {
  //       socket.off('connect', debouncedRetrieveNotifications);
  //       socket.off('disconnect', debouncedRetrieveNotifications);
  //       socket.off('newNotification', debouncedRetrieveNotifications);
  //     };
  //   }
  // }, [debouncedRetrieveNotifications]);



  return (
    <div className="page-content" style={{ position: "relative", marginTop: "-0.5rem" }}>
      <span style={{ fontSize: "12px", fontWeight: "normal", position: "fixed", bottom: "75px", boxShadow: "0px 0px 4px 5px rgba(0,0,0,0.05)", right: "0px", padding: "5px", backgroundColor: localStorage.getItem("theme") == "dark" ? "#333" : "white", zIndex: "999", display: selectedOrdersCount > 0 ? "block" : "none", }}>
        {selectedOrdersCount} {selectedOrdersCount > 1 ? "Orders" : "Order"}{" "}
        Selected{" "}
      </span>
      {/*Right Get Rate Modal*/}
      <div
        className={clickedJson.length > 0 ? "modal-background" : ""}
        onClick={() => {
          setClickedJson([])
          uncheckAll()
          selectedOrders = []
          setSelectedOrdersCount(selectedOrders.length)
          clickedSideBarJson = []
          selectedOrderJson = []
          setSingleOrderWeight("")
          setSingleOrderServiceId("")
          setSingleOrderWidth("")
          setSingleOrderHeight("")
          setSingleOrderLength("")
          setIsFullTextVisible(false)
          setIsFullTextVisibleBelow(false)
          setIsFullTextVisibleUnderOne(false)
          setIsFullTextVisibleAboveTwenty(false)
          setServiceRateAboveFiveLbs("")
          setServiceRateBelowFiveLbs("")
          setServiceRateAboveTwentyLbs("")
          setServiceRateUnderOneLbs("")
          setBelowFiveLbsOrders([])
          setAboveFiveLbsOrders([])
          setUnderOneLbsOrders([])
          setAboveTwentyLbsOrders([])
        }}
      ></div>
      <div
        className={
          "order-info " +
          (clickedJson.length > 0 ? "order-active" : "order-inactive")
        }
        style={{
          backgroundColor:
            localStorage.getItem("theme") == "dark" ? "#0f0f0f" : "white",
        }}
      >
        <Form
          onSubmit={e => {
            e.preventDefault()
            handleUpdateBelowAndAboveFiveLbs()
          }}
        >
          <div className={"head my-2"}>
            <span
              className={"close-btn"}
              onClick={() => {
                document.body.style.overflow = "visible"
                setClickedJson([])
                uncheckAll()
                selectedOrders = []
                setSelectedOrdersCount(selectedOrders.length)
                clickedSideBarJson = []
                selectedOrderJson = []
                setSingleOrderWeight("")
                setSingleOrderServiceId("")
                setSingleOrderWidth("")
                setSingleOrderHeight("")
                setSingleOrderLength("")
                setIsFullTextVisible(false)
                setIsFullTextVisibleBelow(false)
                setIsFullTextVisibleUnderOne(false)
                setIsFullTextVisibleAboveTwenty(false)
                setServiceRateAboveFiveLbs("")
                setServiceRateBelowFiveLbs("")
                setServiceRateAboveTwentyLbs("")
                setServiceRateUnderOneLbs("")
                setBelowFiveLbsOrders([])
                setAboveFiveLbsOrders([])
                setUnderOneLbsOrders([])
                setAboveTwentyLbsOrders([])
              }}
            >
              ⨉
            </span>
            <h4 onClick={() => { }} style={{ width: "fit-content" }}>
              Get Rates
              {/*Update {clickedJson.length>1?"Shipments":"Shipment"}*/}
              {/*{orderUniqueId != null ? orderUniqueId : ""}*!/*/}
            </h4>
          </div>
          <div className={"body"}>
            {belowFiveLbsOrders.length > 0 ? (
              <Row>
                <fieldset>
                  <legend>Below 5 lb. ({belowFiveLbsOrders.length})</legend>
                  <p
                    className={`read-more-text ${isFullTextVisibleBelow ? "expanded" : ""
                      }`}
                    style={{ fontSize: "12px", marginBottom: "0px" }}
                  >
                    {belowFiveLbsOrders.length > 0
                      ? belowFiveLbsOrders.map((part, id) => {
                        if (id == belowFiveLbsOrders.length - 1) {
                          return (
                            <span key={id} title={part.order_number}>
                              Shipment #{part.order_unique_id}
                            </span>
                          )
                        } else {
                          return (
                            <span key={id} title={part.order_number}>
                              Shipment #{part.order_unique_id},{" "}
                            </span>
                          )
                          // return ("Shipment #"+part.order_unique_id+", ")
                        }
                      })
                      : null}
                  </p>
                  {belowFiveLbsOrders.length > 2 ? (
                    <p
                      className={"text-primary"}
                      style={{
                        fontSize: "12px",
                        fontWeight: "500",
                        cursor: "pointer",
                      }}
                      type={"button"}
                      onClick={() => {
                        console.log(
                          "setIsFullTextVisibleBelow 1",
                          !isFullTextVisibleBelow
                        )
                        setIsFullTextVisibleBelow(!isFullTextVisibleBelow)
                      }}
                    >
                      {isFullTextVisibleBelow ? "View Less" : "View More"}
                    </p>
                  ) : null}
                  <Row className={"mt-3"}>
                    <label className="col-md-3 col-form-label">
                      Service <span style={{ color: "red" }}> *</span>
                    </label>
                    <div className={"col-md-9"}>
                      <select
                        name="serviceName"
                        className="form-control"
                        onChange={handleServiceOrderBelowFiveLbs}
                      >
                        <option selected="true" value={""} disabled="true">
                          Select a service
                        </option>
                        {services.map((part, ind) => {
                          return part.is_active == 1 ? (
                            <option key={ind} value={part.id}>
                              {part.carrier_name} - {part.name}
                            </option>
                          ) : null
                        })}
                      </select>
                    </div>
                  </Row>
                  <div>
                    <Row className="mb-12 py-3">
                      <div className="col">
                        <h4
                          className="text-center"
                          onClick={() => { }}
                        // style={{ textAlign: "center" }}
                        >
                          Rate $
                          {serviceRateBelowFiveLbs
                            ? parseFloat(serviceRateBelowFiveLbs).toFixed(2)
                            : "0.00"}{" "}
                          <span
                            className={"text-secondary"}
                            style={{ fontSize: "12px", fontWeight: "400" }}
                          >
                            per shipment
                          </span>
                        </h4>
                      </div>
                    </Row>
                  </div>
                </fieldset>
              </Row>
            ) : null}
            {aboveFiveLbsOrders.length > 0 ? (
              <Row>
                <fieldset>
                  <legend>Above 5 lb. ({aboveFiveLbsOrders.length})</legend>
                  <p
                    className={`read-more-text ${isFullTextVisible ? "expanded" : ""
                      }`}
                    style={{ fontSize: "12px", marginBottom: "0px" }}
                  >
                    {aboveFiveLbsOrders.length > 0
                      ? aboveFiveLbsOrders.map((part, id) => {
                        if (id == aboveFiveLbsOrders.length - 1) {
                          return "Shipment #" + part.order_unique_id
                        } else {
                          return "Shipment #" + part.order_unique_id + ", "
                        }
                      })
                      : null}
                  </p>
                  {aboveFiveLbsOrders.length > 2 ? (
                    <p
                      className={"text-primary"}
                      style={{
                        fontSize: "12px",
                        fontWeight: "500",
                        cursor: "pointer",
                      }}
                      type={"button"}
                      onClick={() => {
                        console.log("isFullTextVisible 1", !isFullTextVisible)
                        setIsFullTextVisible(!isFullTextVisible)
                      }}
                    >
                      {isFullTextVisible ? "View Less" : "View More"}
                    </p>
                  ) : null}
                  <Row className={"mt-3"}>
                    <label className="col-md-3 col-form-label">
                      Service <span style={{ color: "red" }}> *</span>
                    </label>
                    <div className={"col-md-9"}>
                      <select
                        name="serviceName"
                        className="form-control"
                        onChange={handleServiceOrderAboveFiveLbs}
                      >
                        <option selected="true" value={""} disabled="true">
                          Select a service
                        </option>
                        {services.map((part, ind) => {
                          return part.is_active == 1 ? (
                            <option key={ind} value={part.id}>
                              {part.carrier_name} - {part.name}
                            </option>
                          ) : null
                        })}
                      </select>
                    </div>
                  </Row>
                  <div>
                    <Row className="mb-12 py-3">
                      <div className="col">
                        <h4
                          className="text-center"
                          onClick={() => { }}
                        // style={{ textAlign: "center" }}
                        >
                          Rate $
                          {serviceRateAboveFiveLbs
                            ? parseFloat(serviceRateAboveFiveLbs).toFixed(2)
                            : "0.00"}{" "}
                          <span
                            className={"text-secondary"}
                            style={{ fontSize: "12px", fontWeight: "400" }}
                          >
                            per shipment
                          </span>
                        </h4>
                      </div>
                    </Row>
                  </div>
                </fieldset>
              </Row>
            ) : null}
            {underOneLbsOrders.length > 0 ? (
              <Row>
                <fieldset>
                  <legend>Under 1 lb. ({underOneLbsOrders.length})</legend>
                  <p
                    className={`read-more-text ${isFullTextVisibleUnderOne ? "expanded" : ""
                      }`}
                    style={{ fontSize: "12px", marginBottom: "0px" }}
                  >
                    {underOneLbsOrders.length > 0
                      ? underOneLbsOrders.map((part, id) => {
                        if (id == underOneLbsOrders.length - 1) {
                          return "Shipment #" + part.order_unique_id
                        } else {
                          return "Shipment #" + part.order_unique_id + ", "
                        }
                      })
                      : null}
                  </p>
                  {underOneLbsOrders.length > 2 ? (
                    <p
                      className={"text-primary"}
                      style={{
                        fontSize: "12px",
                        fontWeight: "500",
                        cursor: "pointer",
                      }}
                      type={"button"}
                      onClick={() => {
                        console.log(
                          "isFullTextVisible 1",
                          !isFullTextVisibleUnderOne
                        )
                        setIsFullTextVisibleUnderOne(!isFullTextVisibleUnderOne)
                      }}
                    >
                      {isFullTextVisibleUnderOne ? "View Less" : "View More"}
                    </p>
                  ) : null}
                  <Row className={"mt-3"}>
                    <label className="col-md-3 col-form-label">
                      Service <span style={{ color: "red" }}> *</span>
                    </label>
                    <div className={"col-md-9"}>
                      <select
                        name="serviceName"
                        className="form-control"
                        onChange={handleServiceOrderUnderOneLbs}
                      >
                        <option selected="true" value={""} disabled="true">
                          Select a service
                        </option>
                        {services.map((part, ind) => {
                          return part.is_active == 1 ? (
                            <option key={ind} value={part.id}>
                              {part.carrier_name} - {part.name}
                            </option>
                          ) : null
                        })}
                      </select>
                    </div>
                  </Row>
                  <div>
                    <Row className="mb-12 py-3">
                      <div className="col">
                        <h4
                          className="text-center"
                          onClick={() => { }}
                        // style={{ textAlign: "center" }}
                        >
                          Rate $
                          {serviceRateUnderOneLbs
                            ? parseFloat(serviceRateUnderOneLbs).toFixed(2)
                            : "0.00"}{" "}
                          <span
                            className={"text-secondary"}
                            style={{ fontSize: "12px", fontWeight: "400" }}
                          >
                            per shipment
                          </span>
                        </h4>
                      </div>
                    </Row>
                  </div>
                </fieldset>
              </Row>
            ) : null}
            {aboveTwentyLbsOrders.length > 0 ? (
              <Row>
                <fieldset>
                  <legend>Above 20 lb. ({aboveTwentyLbsOrders.length})</legend>
                  <p
                    className={`read-more-text ${isFullTextVisibleAboveTwenty ? "expanded" : ""
                      }`}
                    style={{ fontSize: "12px", marginBottom: "0px" }}
                  >
                    {aboveTwentyLbsOrders.length > 0
                      ? aboveTwentyLbsOrders.map((part, id) => {
                        if (id == aboveTwentyLbsOrders.length - 1) {
                          return "Shipment #" + part.order_unique_id
                        } else {
                          return "Shipment #" + part.order_unique_id + ", "
                        }
                      })
                      : null}
                  </p>
                  {aboveTwentyLbsOrders.length > 2 ? (
                    <p
                      className={"text-primary"}
                      style={{
                        fontSize: "12px",
                        fontWeight: "500",
                        cursor: "pointer",
                      }}
                      type={"button"}
                      onClick={() => {
                        console.log(
                          "isFullTextVisible 1",
                          !isFullTextVisibleAboveTwenty
                        )
                        setIsFullTextVisibleAboveTwenty(
                          !isFullTextVisibleAboveTwenty
                        )
                      }}
                    >
                      {isFullTextVisibleAboveTwenty ? "View Less" : "View More"}
                    </p>
                  ) : null}
                  <Row className={"mt-3"}>
                    <label className="col-md-3 col-form-label">
                      Service <span style={{ color: "red" }}> *</span>
                    </label>
                    <div className={"col-md-9"}>
                      <select name="serviceName" className="form-control" onChange={handleServiceOrderAboveTwentyLbs}>
                        <option selected="true" value={""} disabled="true">
                          Select a service
                        </option>
                        {services.map((part, ind) => {
                          return part.is_active == 1 ? (
                            <option key={ind} value={part.id}>
                              {part.carrier_name} - {part.name}
                            </option>
                          ) : null
                        })}
                      </select>
                    </div>
                  </Row>
                  <div>
                    <Row className="mb-12 py-3">
                      <div className="col">
                        <h4
                          className="text-center"
                          onClick={() => { }}
                        // style={{ textAlign: "center" }}
                        >
                          Rate $
                          {serviceRateAboveTwentyLbs
                            ? parseFloat(serviceRateAboveTwentyLbs).toFixed(2)
                            : "0.00"}{" "}
                          <span
                            className={"text-secondary"}
                            style={{ fontSize: "12px", fontWeight: "400" }}
                          >
                            per shipment
                          </span>
                        </h4>
                      </div>
                    </Row>
                  </div>
                </fieldset>
              </Row>
            ) : null}

            <Row className="d-flex" style={{ justifyContent: "space-evenly" }}>
              <button
                type="submit"
                className="w-25 btn btn-sm btn-success waves-effect waves-light"
              >
                Update Orders
              </button>
            </Row>
            <hr />
            <div className={"my-2"}>
              <h4 onClick={() => { }} style={{ width: "fit-content" }}>
                Items
              </h4>
            </div>
            <hr />
            {clickedJson.length > 0
              ? clickedJson.map((part, id) => {
                if (part.store_type == null) {
                  return (
                    <>
                      <h6>Shipment #{part.order_unique_id}</h6>
                      {part.custom_items_data &&
                        JSON.parse(part.custom_items_data).length > 0 ? (
                        JSON.parse(part.custom_items_data).map((val, ind) => {
                          return (
                            <Row
                              key={ind}
                              className={"border rounded mt-2"}
                              style={{ padding: "10px 10px 0px 10px" }}
                            >
                              <Col sm="12">
                                <h6
                                  className={"bold"}
                                  style={{ marginBottom: "-10px" }}
                                >
                                  ID: {val.product_identifier}
                                </h6>
                                <hr />
                                <h5>
                                  <span style={{ float: "right" }}>
                                    {val.quantity} x
                                  </span>
                                  <div
                                    style={{
                                      width: "200px",
                                      whiteSpace: "nowrap",
                                      textOverflow: "ellipsis",
                                      overflow: "hidden",
                                    }}
                                  >
                                    {val.description}{" "}
                                  </div>
                                </h5>
                                <p>{val.total_value} USD</p>
                              </Col>
                            </Row>
                          )
                        })
                      ) : (
                        <h6 style={{ textAlign: "center" }}>No Item Found</h6>
                      )}
                      <hr />
                    </>
                  )
                } else if (part.store_type == "shopify") {
                  return (
                    <>
                      <h6>Shipment #{part.order_unique_id}</h6>
                      {JSON.parse(part.custom_items_data).map((val, ind) => {
                        return (
                          <Row
                            key={ind}
                            className={"border rounded mt-2"}
                            style={{ padding: "10px 10px 0px 10px" }}
                          >
                            <Col md="12">
                              <h6
                                className={"bold"}
                                style={{ marginBottom: "-10px" }}
                              >
                                ID: {val.id}
                              </h6>
                              <hr />
                            </Col>
                            <Col sm="2">
                              <img width="100%" src={val.image_url} />
                            </Col>
                            <Col sm="10">
                              <h5>
                                <span style={{ float: "right" }}>
                                  {val.quantity} x
                                </span>
                                <div
                                  style={{
                                    width: "200px",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                  }}
                                >
                                  {val.name}{" "}
                                </div>
                              </h5>
                              <p>
                                {val.price}{" "}
                                {val.price_set.shop_money.currency_code}
                              </p>
                            </Col>
                          </Row>
                        )
                      })}
                      <hr />
                    </>
                  )
                } else if (part.store_type == "ebay") {
                  return (
                    <>
                      <h6>Shipment #{part.order_unique_id}</h6>
                      {JSON.parse(JSON.parse(part.custom_items_data)).map(
                        (val, ind) => {
                          return (
                            <Row
                              key={ind}
                              className={"border rounded mt-2"}
                              style={{ padding: "10px 10px 0px 10px" }}
                            >
                              <Col sm="12">
                                <h6
                                  className={"bold"}
                                  style={{ marginBottom: "-10px" }}
                                >
                                  ID: {val.lineItemId}
                                </h6>
                                <hr />
                                <h5>
                                  <span style={{ float: "right" }}>
                                    {val.quantity} x
                                  </span>
                                  <div
                                    style={{
                                      width: "200px",
                                      whiteSpace: "nowrap",
                                      textOverflow: "ellipsis",
                                      overflow: "hidden",
                                    }}
                                  >
                                    {val.title}{" "}
                                  </div>
                                </h5>
                                <p>
                                  {val.total.value} {val.total.currency}
                                </p>
                              </Col>
                            </Row>
                          )
                        }
                      )}
                      <hr />
                    </>
                  )
                } else if (part.store_type == "walmart") {
                  // <h5><span style={{float:"right"}}>{el.orderLineQuantity.amount ? el.orderLineQuantity.amount : "-"}x</span><div style={{width:"200px",whiteSpace:"nowrap",textOverflow:"ellipsis",overflow:"hidden"}}>{el.item.productName ? el.item.productName : "-"}</div></h5>
                  // <p>{el.charges.charge[0].chargeAmount.amount} {el.charges.charge[0].chargeAmount.currency}</p>
                  return (
                    <>
                      <h6>Shipment #{part.order_unique_id}</h6>
                      {JSON.parse(part.custom_items_data).map((val, ind) => {
                        return (
                          <Row
                            key={ind}
                            className={"border rounded mt-2"}
                            style={{ padding: "10px 10px 0px 10px" }}
                          >
                            <Col sm="12">
                              <h6
                                className={"bold"}
                                style={{ marginBottom: "-10px" }}
                              >
                                SKU: {val.item.sku}
                              </h6>
                              <hr />
                              <h5>
                                <span style={{ float: "right" }}>
                                  {val.orderLineQuantity.amount} x
                                </span>
                                <div
                                  style={{
                                    width: "200px",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                  }}
                                >
                                  {val.item.productName
                                    ? val.item.productName
                                    : "-"}{" "}
                                </div>
                              </h5>
                              <p>
                                {val.charges.charge[0].chargeAmount.amount}{" "}
                                {val.charges.charge[0].chargeAmount.currency}
                              </p>
                            </Col>
                          </Row>
                        )
                      })}
                      <hr />
                    </>
                  )
                } else if (part.store_type == "zenventory") {
                  // <h5><span style={{float:"right"}}>{el.orderLineQuantity.amount ? el.orderLineQuantity.amount : "-"}x</span><div style={{width:"200px",whiteSpace:"nowrap",textOverflow:"ellipsis",overflow:"hidden"}}>{el.item.productName ? el.item.productName : "-"}</div></h5>
                  // <p>{el.charges.charge[0].chargeAmount.amount} {el.charges.charge[0].chargeAmount.currency}</p>
                  return (
                    <>
                      <h6>Shipment #{part.order_unique_id}</h6>
                      {JSON.parse(part.custom_items_data).map((val, ind) => {
                        return (
                          <Row
                            key={ind}
                            className={"border rounded mt-2"}
                            style={{ padding: "10px 10px 0px 10px" }}
                          >
                            <Col sm="12">
                              <h6
                                className={"bold"}
                                style={{ marginBottom: "-10px" }}
                              >
                                ID: {val.customerorderitemid}
                              </h6>
                              <hr />
                              <h5>
                                <span style={{ float: "right" }}>
                                  {val.quantity} x
                                </span>
                                <div
                                  style={{
                                    width: "200px",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                  }}
                                >
                                  {val.itemmaster.description
                                    ? val.itemmaster.description
                                    : "-"}{" "}
                                </div>
                              </h5>
                              <p>${val.customerprice}</p>
                            </Col>
                          </Row>
                        )
                      })}
                      <hr />
                    </>
                  )
                } else if (part.store_type == "sellercloud") {
                  // <h5><span style={{float:"right"}}>{el.orderLineQuantity.amount ? el.orderLineQuantity.amount : "-"}x</span><div style={{width:"200px",whiteSpace:"nowrap",textOverflow:"ellipsis",overflow:"hidden"}}>{el.item.productName ? el.item.productName : "-"}</div></h5>
                  // <p>{el.charges.charge[0].chargeAmount.amount} {el.charges.charge[0].chargeAmount.currency}</p>
                  return (
                    <>
                      <h6>Shipment #{part.order_unique_id}</h6>
                      {JSON.parse(part.custom_items_data).map((val, ind) => {
                        return (
                          <Row
                            key={ind}
                            className={"border rounded mt-2"}
                            style={{ padding: "10px 10px 0px 10px" }}
                          >
                            <Col sm="12">
                              <h6
                                className={"bold"}
                                style={{ marginBottom: "-10px" }}
                              >
                                ID: {val.ProductID}
                              </h6>
                              <hr />
                              <h5>
                                <span style={{ float: "right" }}>
                                  {val.Qty} x
                                </span>
                                <div
                                  style={{
                                    width: "200px",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                  }}
                                >
                                  {val.DisplayName ? val.DisplayName : "-"}{" "}
                                </div>
                              </h5>
                              <p>
                                {val.DisplayDescription
                                  ? val.DisplayDescription
                                  : "-"}
                              </p>
                              <div style={{ display: "flex" }}>
                                <p style={{ width: "50%" }}>
                                  ${val.AdjustedSitePrice}
                                </p>
                                <p
                                  style={{ width: "50%", textAlign: "right" }}
                                >
                                  <span style={{ fontWeight: "500" }}>
                                    Weight:
                                  </span>{" "}
                                  {val.Weight ? val.Weight + "lb." : "-"}
                                </p>
                              </div>
                              <p>
                                <span style={{ fontWeight: "500" }}>
                                  L x W x H:
                                </span>{" "}
                                {val.Length ? val.Length : "-"} x{" "}
                                {val.Width ? val.Width : "-"} x{" "}
                                {val.Height ? val.Height : "-"}
                              </p>
                            </Col>
                          </Row>
                        )
                      })}
                      <hr />
                    </>
                  )
                } else if (part.store_type == "shipstation") {
                  // <h5><span style={{float:"right"}}>{el.orderLineQuantity.amount ? el.orderLineQuantity.amount : "-"}x</span><div style={{width:"200px",whiteSpace:"nowrap",textOverflow:"ellipsis",overflow:"hidden"}}>{el.item.productName ? el.item.productName : "-"}</div></h5>
                  // <p>{el.charges.charge[0].chargeAmount.amount} {el.charges.charge[0].chargeAmount.currency}</p>
                  return (
                    <>
                      <h6>Shipment #{part.order_unique_id}</h6>
                      {JSON.parse(part.custom_items_data).map((val, ind) => {
                        return (
                          <Row
                            key={ind}
                            className={"border rounded mt-2"}
                            style={{ padding: "10px 10px 0px 10px" }}
                          >
                            <Col sm="12">
                              <h6
                                className={"bold"}
                                style={{ marginBottom: "-10px" }}
                              >
                                ID: {val.orderItemId}
                              </h6>
                              <hr />
                              <h5>
                                <span style={{ float: "right" }}>
                                  {val.quantity} x
                                </span>
                                <div
                                  style={{
                                    width: "200px",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                  }}
                                >
                                  {val.name ? val.name : "-"}{" "}
                                </div>
                              </h5>
                              <p>${val.unitPrice}</p>
                            </Col>
                          </Row>
                        )
                      })}
                      <hr />
                    </>
                  )
                } else if (part.store_type == "veeqo") {
                  // <h5><span style={{float:"right"}}>{el.orderLineQuantity.amount ? el.orderLineQuantity.amount : "-"}x</span><div style={{width:"200px",whiteSpace:"nowrap",textOverflow:"ellipsis",overflow:"hidden"}}>{el.item.productName ? el.item.productName : "-"}</div></h5>
                  // <p>{el.charges.charge[0].chargeAmount.amount} {el.charges.charge[0].chargeAmount.currency}</p>
                  return (
                    <>
                      <h6>Shipment #{part.order_unique_id}</h6>
                      {JSON.parse(part.custom_items_data).map((val, ind) => {
                        return (
                          <Row
                            key={ind}
                            className={"border rounded mt-2"}
                            style={{ padding: "10px 10px 0px 10px" }}
                          >
                            <Col sm="12">
                              <h6
                                className={"bold"}
                                style={{ marginBottom: "-10px" }}
                              >
                                ID: {val.sellable.id}
                              </h6>
                              <hr />
                              <h5>
                                <span style={{ float: "right" }}>
                                  {val.quantity} x
                                </span>
                                <div
                                  style={{
                                    width: "200px",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                  }}
                                >
                                  {val.sellable.product_title
                                    ? val.sellable.product_title
                                    : "-"}{" "}
                                </div>
                              </h5>
                              <p>${val.sellable.price}</p>
                            </Col>
                          </Row>
                        )
                      })}
                      <hr />
                    </>
                  )
                } else if (part.store_type == "shiphero") {
                  // <h5><span style={{float:"right"}}>{el.orderLineQuantity.amount ? el.orderLineQuantity.amount : "-"}x</span><div style={{width:"200px",whiteSpace:"nowrap",textOverflow:"ellipsis",overflow:"hidden"}}>{el.item.productName ? el.item.productName : "-"}</div></h5>
                  // <p>{el.charges.charge[0].chargeAmount.amount} {el.charges.charge[0].chargeAmount.currency}</p>
                  return (
                    <>
                      <h6>Shipment #{part.order_unique_id}</h6>
                      {JSON.parse(part.custom_items_data).map((val, ind) => {
                        return (
                          <Row
                            key={ind}
                            className={"border rounded mt-2"}
                            style={{ padding: "10px 10px 0px 10px" }}
                          >
                            <Col sm="12">
                              <h6
                                className={"bold"}
                                style={{ marginBottom: "-10px" }}
                              >
                                ID: {val.line_item_id}
                              </h6>
                              <hr />
                              <h5>
                                <span style={{ float: "right" }}>
                                  {val.quantity_allocated} x
                                </span>
                                <div
                                  style={{
                                    width: "200px",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                  }}
                                >
                                  {val?.sellable?.product_title
                                    ? val?.sellable?.product_title
                                    : "-"}{" "}
                                </div>
                              </h5>
                              {/* <p>${val.total_price}</p> */}
                              <p>${"--"}</p>
                            </Col>
                          </Row>
                        )
                      })}
                      <hr />
                    </>
                  )
                } else if (part.store_type == "orderdesk") {
                  // <h5><span style={{float:"right"}}>{el.orderLineQuantity.amount ? el.orderLineQuantity.amount : "-"}x</span><div style={{width:"200px",whiteSpace:"nowrap",textOverflow:"ellipsis",overflow:"hidden"}}>{el.item.productName ? el.item.productName : "-"}</div></h5>
                  // <p>{el.charges.charge[0].chargeAmount.amount} {el.charges.charge[0].chargeAmount.currency}</p>
                  return (
                    <>
                      <h6>Shipment #{part?.order_unique_id}</h6>
                      {JSON.parse(part.custom_items_data).map((val, ind) => {
                        return (
                          <Row
                            key={ind}
                            className={"border rounded mt-2"}
                            style={{ padding: "10px 10px 0px 10px" }}
                          >
                            <Col sm="12">
                              <h6
                                className={"bold"}
                                style={{ marginBottom: "-10px" }}
                              >
                                ID: {val.id}
                              </h6>
                              <hr />
                              <h5>
                                <span style={{ float: "right" }}>
                                  {val.quantity_allocated} x
                                </span>
                                <div
                                  style={{
                                    width: "200px",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                  }}
                                >
                                  {val?.name ? val?.name : "-"}{" "}
                                </div>
                              </h5>
                              {/* <p>${val.total_price}</p> */}
                              <p>${"--"}</p>
                            </Col>
                          </Row>
                        )
                      })}
                      <hr />
                    </>
                  )
                }
              })
              : ""}
          </div>
        </Form>
      </div>
      {/*Right Get Rate Modal*/}

      {/*Right Bulk Update Modal*/}
      <div
        className={clickedBulkUpdateJson.length > 0 ? "modal-background" : ""}
        onClick={() => {
          setClickedBulkUpdateJson([])
          uncheckAll()
          selectedOrders = []
          setSelectedOrdersCount(selectedOrders.length)
          clickedSideBarJson = []
          selectedOrderJson = []
          setBulkUpdateServiceId("")
          setBulkUpdateToPhone("")
          setEnableBulkUpdateToPhone(false)
          document.getElementById("bulk-update-enable-to-phone").checked = false
          setIsFullTextVisible1(false)
        }}
      ></div>
      <div
        className={
          "order-info " +
          (clickedBulkUpdateJson.length > 0 ? "order-active" : "order-inactive")
        }
        style={{
          backgroundColor:
            localStorage.getItem("theme") == "dark" ? "#0f0f0f" : "white",
        }}
      >
        <Form
          onSubmit={e => {
            e.preventDefault()
            updateBulkOrders()
          }}
        >
          <div className={"head my-2"}>
            <span
              className={"close-btn"}
              onClick={() => {
                document.body.style.overflow = "visible"
                clickedSideBarJson = []
                setClickedBulkUpdateJson([])
                uncheckAll()
                selectedOrders = []
                setSelectedOrdersCount(selectedOrders.length)
                clickedSideBarJson = []
                selectedOrderJson = []
                setBulkUpdateServiceId("")
                setBulkUpdateToPhone("")
                setEnableBulkUpdateToPhone(false)
                document.getElementById(
                  "bulk-update-enable-to-phone"
                ).checked = false
                setIsFullTextVisible1(false)
              }}
            >
              ⨉
            </span>
            <h4 onClick={() => { }} style={{ width: "fit-content" }}>
              Update{" "}
              {clickedBulkUpdateJson.length > 1 ? "Shipments" : "Shipment"}
              {/*{orderUniqueId != null ? orderUniqueId : ""}*!/*/}
            </h4>
            <p
              className={`read-more-text ${isFullTextVisible1 ? "expanded" : ""
                }`}
              style={{ fontSize: "12px", marginBottom: "0px" }}
            >
              {clickedBulkUpdateJson.length > 0
                ? clickedBulkUpdateJson.map((part, id) => {
                  if (id == clickedBulkUpdateJson.length - 1) {
                    return "Shipment #" + part.order_unique_id
                  } else {
                    return "Shipment #" + part.order_unique_id + ", "
                  }
                })
                : null}
            </p>
            {clickedBulkUpdateJson.length > 2 ? (
              <p
                className={"text-primary"}
                style={{
                  fontSize: "12px",
                  fontWeight: "500",
                  cursor: "pointer",
                }}
                type={"button"}
                onClick={() => setIsFullTextVisible1(!isFullTextVisible1)}
              >
                {isFullTextVisible1 ? "View Less" : "View More"}
              </p>
            ) : null}
          </div>
          <div className={"body"}>
            <Row className="mb-3 mt-3">
              <label className="col-md-4 col-form-label">
                Service <span style={{ color: "red" }}> *</span>
              </label>
              <div className="col-md-8">
                <select
                  name="serviceName"
                  className="form-control"
                  value={bulkUpdateServiceId}
                  onChange={e => setBulkUpdateServiceId(e.target.value)}
                  required={true}
                >
                  <option selected="true" value={""} disabled="true">
                    Select a service
                  </option>
                  {services.map((part, ind) => {
                    return part.is_active == 1 ? (
                      <option key={ind} value={part.id}>
                        {part.carrier_name} - {part.name}
                      </option>
                    ) : null
                  })}
                </select>
              </div>
            </Row>
            <Row className="mb-3 mt-3">
              <label
                className="col-md-4 col-form-label"
                style={{
                  color: enableBulkUpdateToPhone
                    ? localStorage.getItem("theme") == "dark"
                      ? "#a6b0c4"
                      : "black"
                    : "gray",
                }}
              >
                <input
                  type={"checkbox"}
                  id={"bulk-update-enable-to-phone"}
                  onChange={e => {
                    setBulkUpdateToPhone("")
                    setEnableBulkUpdateToPhone(e.target.checked)
                  }}
                />{" "}
                Ship To Phone{" "}
                {enableBulkUpdateToPhone ? (
                  <span style={{ color: "red" }}> *</span>
                ) : null}
              </label>
              <div className="col-md-8">
                <input
                  name="to_phone"
                  // value={name}
                  className="form-control non-arrow-number"
                  placeholder="Enter Ship To Phone"
                  type="text"
                  onWheel={e => e.target.blur()}
                  value={bulkUpdateToPhone}
                  disabled={!enableBulkUpdateToPhone}
                  onChange={e => setBulkUpdateToPhone(e.target.value)}
                  required={enableBulkUpdateToPhone}
                />
              </div>
            </Row>
            <Row className="d-flex" style={{ justifyContent: "space-evenly" }}>
              <button
                type="submit"
                className="w-25 btn btn-sm btn-success waves-effect waves-light"
              >
                Update Orders
              </button>
            </Row>
            <hr />
            <div className={"my-2"}>
              <h4 onClick={() => { }} style={{ width: "fit-content" }}>
                Items
              </h4>
            </div>
            <hr />
            {clickedBulkUpdateJson.length > 0
              ? clickedBulkUpdateJson.map((part, id) => {
                if (part.store_type == null) {
                  return (
                    <>
                      <h6>Shipment #{part.order_unique_id}</h6>
                      {part.custom_items_data &&
                        JSON.parse(part.custom_items_data).length > 0 ? (
                        JSON.parse(part.custom_items_data).map((val, ind) => {
                          return (
                            <Row
                              key={ind}
                              className={"border rounded mt-2"}
                              style={{ padding: "10px 10px 0px 10px" }}
                            >
                              <Col sm="12">
                                <h5>
                                  <span style={{ float: "right" }}>
                                    {val.quantity} x
                                  </span>
                                  <div
                                    style={{
                                      width: "200px",
                                      whiteSpace: "nowrap",
                                      textOverflow: "ellipsis",
                                      overflow: "hidden",
                                    }}
                                  >
                                    {val.description}{" "}
                                  </div>
                                </h5>
                                <p>{val.total_value} USD</p>
                              </Col>
                            </Row>
                          )
                        })
                      ) : (
                        <h6 style={{ textAlign: "center" }}>No Item Found</h6>
                      )}
                      <hr />
                    </>
                  )
                } else if (part.store_type == "shopify") {
                  return (
                    <>
                      <h6>Shipment #{part.order_unique_id}</h6>
                      {JSON.parse(part.custom_items_data).map((val, ind) => {
                        return (
                          <Row
                            key={ind}
                            className={"border rounded mt-2"}
                            style={{ padding: "10px 10px 0px 10px" }}
                          >
                            <Col sm="2">
                              <img width="100%" src={val.image_url} />
                            </Col>
                            <Col sm="10">
                              <h5>
                                <span style={{ float: "right" }}>
                                  {val.quantity} x
                                </span>
                                <div
                                  style={{
                                    width: "200px",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                  }}
                                >
                                  {val.name}{" "}
                                </div>
                              </h5>
                              <p>
                                {val.price}{" "}
                                {val.price_set.shop_money.currency_code}
                              </p>
                            </Col>
                          </Row>
                        )
                      })}
                      <hr />
                    </>
                  )
                } else if (part.store_type == "ebay") {
                  return (
                    <>
                      <h6>Shipment #{part.order_unique_id}</h6>
                      {JSON.parse(JSON.parse(part.custom_items_data)).map(
                        (val, ind) => {
                          return (
                            <Row
                              key={ind}
                              className={"border rounded mt-2"}
                              style={{ padding: "10px 10px 0px 10px" }}
                            >
                              <Col sm="12">
                                <h5>
                                  <span style={{ float: "right" }}>
                                    {val.quantity} x
                                  </span>
                                  <div
                                    style={{
                                      width: "200px",
                                      whiteSpace: "nowrap",
                                      textOverflow: "ellipsis",
                                      overflow: "hidden",
                                    }}
                                  >
                                    {val.title}{" "}
                                  </div>
                                </h5>
                                <p>
                                  {val.total.value} {val.total.currency}
                                </p>
                              </Col>
                            </Row>
                          )
                        }
                      )}
                      <hr />
                    </>
                  )
                } else if (part.store_type == "walmart") {
                  // <h5><span style={{float:"right"}}>{el.orderLineQuantity.amount ? el.orderLineQuantity.amount : "-"}x</span><div style={{width:"200px",whiteSpace:"nowrap",textOverflow:"ellipsis",overflow:"hidden"}}>{el.item.productName ? el.item.productName : "-"}</div></h5>
                  // <p>{el.charges.charge[0].chargeAmount.amount} {el.charges.charge[0].chargeAmount.currency}</p>
                  return (
                    <>
                      <h6>Shipment #{part.order_unique_id}</h6>
                      {JSON.parse(part.custom_items_data).map((val, ind) => {
                        return (
                          <Row
                            key={ind}
                            className={"border rounded mt-2"}
                            style={{ padding: "10px 10px 0px 10px" }}
                          >
                            <Col sm="12">
                              <h5>
                                <span style={{ float: "right" }}>
                                  {val.orderLineQuantity.amount} x
                                </span>
                                <div
                                  style={{
                                    width: "200px",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                  }}
                                >
                                  {val.item.productName
                                    ? val.item.productName
                                    : "-"}{" "}
                                </div>
                              </h5>
                              <p>
                                {val.charges.charge[0].chargeAmount.amount}{" "}
                                {val.charges.charge[0].chargeAmount.currency}
                              </p>
                            </Col>
                          </Row>
                        )
                      })}
                      <hr />
                    </>
                  )
                } else if (part.store_type == "zenventory") {
                  // <h5><span style={{float:"right"}}>{el.orderLineQuantity.amount ? el.orderLineQuantity.amount : "-"}x</span><div style={{width:"200px",whiteSpace:"nowrap",textOverflow:"ellipsis",overflow:"hidden"}}>{el.item.productName ? el.item.productName : "-"}</div></h5>
                  // <p>{el.charges.charge[0].chargeAmount.amount} {el.charges.charge[0].chargeAmount.currency}</p>
                  return (
                    <>
                      <h6>Shipment #{part.order_unique_id}</h6>
                      {JSON.parse(part.custom_items_data).map((val, ind) => {
                        return (
                          <Row
                            key={ind}
                            className={"border rounded mt-2"}
                            style={{ padding: "10px 10px 0px 10px" }}
                          >
                            <Col sm="12">
                              <h5>
                                <span style={{ float: "right" }}>
                                  {val.quantity} x
                                </span>
                                <div
                                  style={{
                                    width: "200px",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                  }}
                                >
                                  {val.itemmaster.description
                                    ? val.itemmaster.description
                                    : "-"}{" "}
                                </div>
                              </h5>
                              <p>${val.customerprice}</p>
                            </Col>
                          </Row>
                        )
                      })}
                      <hr />
                    </>
                  )
                } else if (part.store_type == "sellercloud") {
                  // <h5><span style={{float:"right"}}>{el.orderLineQuantity.amount ? el.orderLineQuantity.amount : "-"}x</span><div style={{width:"200px",whiteSpace:"nowrap",textOverflow:"ellipsis",overflow:"hidden"}}>{el.item.productName ? el.item.productName : "-"}</div></h5>
                  // <p>{el.charges.charge[0].chargeAmount.amount} {el.charges.charge[0].chargeAmount.currency}</p>
                  return (
                    <>
                      <h6>Shipment #{part.order_unique_id}</h6>
                      {JSON.parse(part.custom_items_data).map((val, ind) => {
                        return (
                          <Row
                            key={ind}
                            className={"border rounded mt-2"}
                            style={{ padding: "10px 10px 0px 10px" }}
                          >
                            <Col sm="12">
                              <h6
                                className={"bold"}
                                style={{ marginBottom: "-10px" }}
                              >
                                ID: {val.ProductID}
                              </h6>
                              <hr />
                              <h5>
                                <span style={{ float: "right" }}>
                                  {val.Qty} x
                                </span>
                                <div
                                  style={{
                                    width: "200px",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                  }}
                                >
                                  {val.DisplayName ? val.DisplayName : "-"}{" "}
                                </div>
                              </h5>
                              <p>
                                {val.DisplayDescription
                                  ? val.DisplayDescription
                                  : "-"}
                              </p>
                              <div style={{ display: "flex" }}>
                                <p style={{ width: "50%" }}>
                                  ${val.AdjustedSitePrice}
                                </p>
                                <p
                                  style={{ width: "50%", textAlign: "right" }}
                                >
                                  <span style={{ fontWeight: "500" }}>
                                    Weight:
                                  </span>{" "}
                                  {val.Weight ? val.Weight + "lb." : "-"}
                                </p>
                              </div>
                              <p>
                                <span style={{ fontWeight: "500" }}>
                                  L x W x H:
                                </span>{" "}
                                {val.Length ? val.Length : "-"} x{" "}
                                {val.Width ? val.Width : "-"} x{" "}
                                {val.Height ? val.Height : "-"}
                              </p>
                            </Col>
                          </Row>
                        )
                      })}
                      <hr />
                    </>
                  )
                } else if (part.store_type == "shipstation") {
                  // <h5><span style={{float:"right"}}>{el.orderLineQuantity.amount ? el.orderLineQuantity.amount : "-"}x</span><div style={{width:"200px",whiteSpace:"nowrap",textOverflow:"ellipsis",overflow:"hidden"}}>{el.item.productName ? el.item.productName : "-"}</div></h5>
                  // <p>{el.charges.charge[0].chargeAmount.amount} {el.charges.charge[0].chargeAmount.currency}</p>
                  return (
                    <>
                      <h6>Shipment #{part.order_unique_id}</h6>
                      {JSON.parse(part.custom_items_data).map((val, ind) => {
                        return (
                          <Row
                            key={ind}
                            className={"border rounded mt-2"}
                            style={{ padding: "10px 10px 0px 10px" }}
                          >
                            <Col sm="12">
                              <h6
                                className={"bold"}
                                style={{ marginBottom: "-10px" }}
                              >
                                ID: {val.orderItemId}
                              </h6>
                              <hr />
                              <h5>
                                <span style={{ float: "right" }}>
                                  {val.quantity} x
                                </span>
                                <div
                                  style={{
                                    width: "200px",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                  }}
                                >
                                  {val.name ? val.name : "-"}{" "}
                                </div>
                              </h5>
                              <p>${val.unitPrice}</p>
                            </Col>
                          </Row>
                        )
                      })}
                      <hr />
                    </>
                  )
                } else if (part.store_type == "veeqo") {
                  // <h5><span style={{float:"right"}}>{el.orderLineQuantity.amount ? el.orderLineQuantity.amount : "-"}x</span><div style={{width:"200px",whiteSpace:"nowrap",textOverflow:"ellipsis",overflow:"hidden"}}>{el.item.productName ? el.item.productName : "-"}</div></h5>
                  // <p>{el.charges.charge[0].chargeAmount.amount} {el.charges.charge[0].chargeAmount.currency}</p>
                  return (
                    <>
                      <h6>Shipment #{part.order_unique_id}</h6>
                      {JSON.parse(part.custom_items_data).map((val, ind) => {
                        return (
                          <Row
                            key={ind}
                            className={"border rounded mt-2"}
                            style={{ padding: "10px 10px 0px 10px" }}
                          >
                            <Col sm="12">
                              <h6
                                className={"bold"}
                                style={{ marginBottom: "-10px" }}
                              >
                                ID: {val.sellable.id}
                              </h6>
                              <hr />
                              <h5>
                                <span style={{ float: "right" }}>
                                  {val.quantity} x
                                </span>
                                <div
                                  style={{
                                    width: "200px",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                  }}
                                >
                                  {val.sellable.product_title
                                    ? val.sellable.product_title
                                    : "-"}{" "}
                                </div>
                              </h5>
                              <p>${val.sellable.price}</p>
                            </Col>
                          </Row>
                        )
                      })}
                      <hr />
                    </>
                  )
                } else if (part.store_type == "shiphero") {
                  // <h5><span style={{float:"right"}}>{el.orderLineQuantity.amount ? el.orderLineQuantity.amount : "-"}x</span><div style={{width:"200px",whiteSpace:"nowrap",textOverflow:"ellipsis",overflow:"hidden"}}>{el.item.productName ? el.item.productName : "-"}</div></h5>
                  // <p>{el.charges.charge[0].chargeAmount.amount} {el.charges.charge[0].chargeAmount.currency}</p>
                  return (
                    <>
                      <h6>Shipment #{part.order_unique_id}</h6>
                      {JSON.parse(part.custom_items_data).map((val, ind) => {
                        return (
                          <Row
                            key={ind}
                            className={"border rounded mt-2"}
                            style={{ padding: "10px 10px 0px 10px" }}
                          >
                            <Col sm="12">
                              <h6
                                className={"bold"}
                                style={{ marginBottom: "-10px" }}
                              >
                                ID: {val.line_item_id}
                              </h6>
                              <hr />
                              <h5>
                                <span style={{ float: "right" }}>
                                  {val.quantity_allocated} x
                                </span>
                                <div
                                  style={{
                                    width: "200px",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                  }}
                                >
                                  {val?.sellable?.product_title
                                    ? val?.sellable?.product_title
                                    : "-"}{" "}
                                </div>
                              </h5>
                              {/* <p>${val.total_price}</p> */}
                              <p>${"--"}</p>
                            </Col>
                          </Row>
                        )
                      })}
                      <hr />
                    </>
                  )
                } else if (part.store_type == "orderdesk") {
                  // <h5><span style={{float:"right"}}>{el.orderLineQuantity.amount ? el.orderLineQuantity.amount : "-"}x</span><div style={{width:"200px",whiteSpace:"nowrap",textOverflow:"ellipsis",overflow:"hidden"}}>{el.item.productName ? el.item.productName : "-"}</div></h5>
                  // <p>{el.charges.charge[0].chargeAmount.amount} {el.charges.charge[0].chargeAmount.currency}</p>
                  return (
                    <>
                      <h6>Shipment #{part?.order_unique_id}</h6>
                      {JSON.parse(part.custom_items_data).map((val, ind) => {
                        return (
                          <Row
                            key={ind}
                            className={"border rounded mt-2"}
                            style={{ padding: "10px 10px 0px 10px" }}
                          >
                            <Col sm="12">
                              <h6
                                className={"bold"}
                                style={{ marginBottom: "-10px" }}
                              >
                                ID: {val.id}
                              </h6>
                              <hr />
                              <h5>
                                <span style={{ float: "right" }}>
                                  {val.quantity_allocated} x
                                </span>
                                <div
                                  style={{
                                    width: "200px",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                  }}
                                >
                                  {val?.name ? val?.name : "-"}{" "}
                                </div>
                              </h5>
                              {/* <p>${val.total_price}</p> */}
                              <p>${"--"}</p>
                            </Col>
                          </Row>
                        )
                      })}
                      <hr />
                    </>
                  )
                }
              })
              : ""}
          </div>
        </Form>
      </div>
      {/*Right Bulk Update Modal*/}

      <div className="container-fluid">
        <span className={"group-by"} ref={componentGroupByRef} onClick={() => { setHideShowGroupBy(!hideShowGroupBy) }}>
          <span style={{ color: selectedGroupBy ? "#ff4800" : localStorage.getItem("theme") == "dark" ? "#a6b0c4" : "black", }}>Group By</span>{" "}
          <i
            className={`bx bx-${hideShowGroupBy ? "chevron-up" : "chevron-down"
              } font-size-16`}
          ></i>
          <ul
            style={{
              display: hideShowGroupBy ? "block" : "none",
              backgroundColor:
                localStorage.getItem("theme") == "dark" ? "#0f0f0f" : "white",
            }}
          >
            <li
              onClick={async () => {
                clickedSideBarJson = []
                uncheckAll()
                selectedOrders = []
                setSelectedOrdersCount(selectedOrders.length)
                selectedGroupBy = ""
                await retrieveOrdersByFilterType()
              }}
              style={{ color: selectedGroupBy ? "#FF6969" : "gray" }}
            >
              Remove Group By
            </li>
            <li
              onClick={async () => {
                selectedGroupBy = "Line Items Count"
                await handleGroupByOrders("Line Items Count")
                // setSelectedGroupBy( "Line Items Count")
                // handleGroupByOrders( "Line Items Count");
              }}
            >
              <i
                className={"bx bx-check"}
                style={{
                  fontSize: "16px",
                  marginTop: "0px",
                  marginRight: "5px",
                  float: "right",
                  display:
                    selectedGroupBy == "Line Items Count" ? "block" : "none",
                }}
              ></i>{" "}
              Line Items Count
            </li>
            <li
              onClick={async () => {
                selectedGroupBy = "Weight"
                await handleGroupByOrders("Weight")
                // setSelectedGroupBy( "Weight")
                // handleGroupByOrders( "Weight");
              }}
            >
              <i
                className={"bx bx-check"}
                style={{
                  fontSize: "16px",
                  marginTop: "0px",
                  marginRight: "5px",
                  float: "right",
                  display: selectedGroupBy == "Weight" ? "block" : "none",
                }}
              ></i>{" "}
              Weight
            </li>
            <li
              onClick={async () => {
                selectedGroupBy = "Total Quantity"
                await handleGroupByOrders("Total Quantity")
              }}
            >
              <i
                className={"bx bx-check"}
                style={{
                  fontSize: "16px",
                  marginTop: "0px",
                  marginRight: "5px",
                  float: "right",
                  display:
                    selectedGroupBy == "Total Quantity" ? "block" : "none",
                }}
              ></i>{" "}
              Total Quantity
            </li>
            <li
              onClick={async () => {
                selectedGroupBy = "Country"
                await handleGroupByOrders("Country")
              }}
            >
              <i
                className={"bx bx-check"}
                style={{
                  fontSize: "16px",
                  marginTop: "0px",
                  marginRight: "5px",
                  float: "right",
                  display: selectedGroupBy == "Country" ? "block" : "none",
                }}
              ></i>{" "}
              Country
            </li>
            {filterType != "Unassigned" ? (
              <>
                <li
                  onClick={async () => {
                    selectedGroupBy = "Service"
                    await handleGroupByOrders("Service")
                  }}
                >
                  <i
                    className={"bx bx-check"}
                    style={{
                      fontSize: "16px",
                      marginTop: "0px",
                      marginRight: "5px",
                      float: "right",
                      display: selectedGroupBy == "Service" ? "block" : "none",
                    }}
                  ></i>{" "}
                  Service
                </li>
              </>
            ) : null}
          </ul>
        </span>
        <span className={"columns"} ref={componentColumnsRef} onClick={() => setHideShowColumns(!hideShowColumns)} style={{ color: localStorage.getItem("theme") == "dark" ? "#a6b0c4" : "black" }}>
          Columns{" "}
          <i
            className={`bx bx-${hideShowColumns ? "chevron-up" : "chevron-down"
              } font-size-16`}
          ></i>
          <ul
            style={{
              display: hideShowColumns ? "block" : "none",
              backgroundColor:
                localStorage.getItem("theme") == "dark" ? "#0f0f0f" : "white",
              height: "350px",
              overflow: "auto",
            }}
          >
            <li
              style={{
                backgroundColor: !showHide.itemName
                  ? localStorage.getItem("theme") == "dark"
                    ? "#0f0f0f"
                    : "white"
                  : localStorage.getItem("theme") == "dark"
                    ? "#1c1c1c"
                    : "#efefef",
              }}
              onClick={() => {
                // hideShowItemName = !hideShowItemName
                // columns[2].omit = hideShowItemName
                // setTableData({ columns, data: allData })
                // handleUpdateUserPreferences()

                setShowHide({ ...showHide, itemName: !showHide.itemName })
                handleUpdateUserPreferences({
                  hideShowItemName: !showHide.itemName,
                })
                setReload(!reload)
              }}
            >
              <i
                className={"bx " + (showHide.itemName ? "bx-x" : "bx-check")}
                style={{
                  fontSize: "16px",
                  marginTop: "0px",
                  marginRight: "10px",
                  float: "left",
                }}
              ></i>{" "}
              Items
            </li>
            <li
              style={{
                backgroundColor: !showHide.itemNames
                  ? localStorage.getItem("theme") == "dark"
                    ? "#0f0f0f"
                    : "white"
                  : localStorage.getItem("theme") == "dark"
                    ? "#1c1c1c"
                    : "#efefef",
              }}
              onClick={() => {
                // hideShowItemNames = !hideShowItemNames
                // columns[3].omit = hideShowItemNames
                // setTableData({ columns, data: allData })

                setShowHide({ ...showHide, itemNames: !showHide.itemNames })
                handleUpdateUserPreferences({
                  hideShowItemNames: !showHide.itemNames,
                })
                setReload(!reload)
              }}
            >
              <i
                className={"bx " + (showHide.itemNames ? "bx-x" : "bx-check")}
                style={{
                  fontSize: "16px",
                  marginTop: "0px",
                  marginRight: "10px",
                  float: "left",
                }}
              ></i>{" "}
              Item Names
            </li>
            <li
              style={{
                backgroundColor: !showHide.productIds
                  ? localStorage.getItem("theme") == "dark"
                    ? "#0f0f0f"
                    : "white"
                  : localStorage.getItem("theme") == "dark"
                    ? "#1c1c1c"
                    : "#efefef",
              }}
              onClick={() => {
                // hideShowProductIds = !hideShowProductIds
                // columns[4].omit = hideShowProductIds
                // setTableData({ columns, data: allData })
                setShowHide({ ...showHide, productIds: !showHide.productIds })
                handleUpdateUserPreferences({
                  hideShowProductIds: !showHide.productIds,
                })
                setReload(!reload)
              }}
            >
              <i
                className={"bx " + (showHide.productIds ? "bx-x" : "bx-check")}
                style={{
                  fontSize: "16px",
                  marginTop: "0px",
                  marginRight: "10px",
                  float: "left",
                }}
              ></i>{" "}
              Product IDs
            </li>
            <li
              style={{
                backgroundColor: !showHide.productSkus
                  ? localStorage.getItem("theme") == "dark"
                    ? "#0f0f0f"
                    : "white"
                  : localStorage.getItem("theme") == "dark"
                    ? "#1c1c1c"
                    : "#efefef",
              }}
              onClick={() => {
                // hideShowProductSKU = !hideShowProductSKU
                // columns[5].omit = hideShowProductSKU
                // setTableData({ columns, data: allData })

                setShowHide({ ...showHide, productSkus: !showHide.productSkus })
                handleUpdateUserPreferences({
                  hideShowProductSKU: !showHide.productSkus,
                })
                setReload(!reload)
              }}
            >
              <i
                className={"bx " + (showHide.productSkus ? "bx-x" : "bx-check")}
                style={{
                  fontSize: "16px",
                  marginTop: "0px",
                  marginRight: "10px",
                  float: "left",
                }}
              ></i>{" "}
              Product SKU
            </li>
            <li
              style={{
                backgroundColor: !showHide.serviceName
                  ? localStorage.getItem("theme") == "dark"
                    ? "#0f0f0f"
                    : "white"
                  : localStorage.getItem("theme") == "dark"
                    ? "#1c1c1c"
                    : "#efefef",
              }}
              onClick={() => {
                // hideShowServiceName = !hideShowServiceName
                // columns[6].omit = hideShowServiceName
                // setTableData({ columns, data: allData })

                setShowHide({ ...showHide, serviceName: !showHide.serviceName })
                handleUpdateUserPreferences({
                  hideShowServiceName: !showHide.serviceName,
                })
                setReload(!reload)
              }}
            >
              <i
                className={"bx " + (showHide.serviceName ? "bx-x" : "bx-check")}
                style={{
                  fontSize: "16px",
                  marginTop: "0px",
                  marginRight: "10px",
                  float: "left",
                }}
              ></i>{" "}
              Service Name
            </li>
            <li
              style={{
                backgroundColor: !showHide.fromName
                  ? localStorage.getItem("theme") == "dark"
                    ? "#0f0f0f"
                    : "white"
                  : localStorage.getItem("theme") == "dark"
                    ? "#1c1c1c"
                    : "#efefef",
              }}
              onClick={() => {
                // hideShowFromName = !hideShowFromName
                // columns[7].omit = hideShowFromName
                // setTableData({ columns, data: allData })

                setShowHide({ ...showHide, fromName: !showHide.fromName })
                handleUpdateUserPreferences({
                  hideShowFromName: !showHide.fromName,
                })
                setReload(!reload)
              }}
            >
              <i
                className={"bx " + (showHide.fromName ? "bx-x" : "bx-check")}
                style={{
                  fontSize: "16px",
                  marginTop: "0px",
                  marginRight: "10px",
                  float: "left",
                }}
              ></i>{" "}
              From Name
            </li>
            <li
              style={{
                backgroundColor: !showHide.toName
                  ? localStorage.getItem("theme") == "dark"
                    ? "#0f0f0f"
                    : "white"
                  : localStorage.getItem("theme") == "dark"
                    ? "#1c1c1c"
                    : "#efefef",
              }}
              onClick={() => {
                // hideShowToName = !hideShowToName
                // columns[8].omit = hideShowToName
                // setTableData({ columns, data: allData })

                setShowHide({ ...showHide, toName: !showHide.toName })
                handleUpdateUserPreferences({
                  hideShowToName: !showHide.toName,
                })
                setReload(!reload)
              }}
            >
              <i
                className={"bx " + (showHide.toName ? "bx-x" : "bx-check")}
                style={{
                  fontSize: "16px",
                  marginTop: "0px",
                  marginRight: "10px",
                  float: "left",
                }}
              ></i>{" "}
              To Name
            </li>
            <li
              style={{
                backgroundColor: !showHide.notes
                  ? localStorage.getItem("theme") == "dark"
                    ? "#0f0f0f"
                    : "white"
                  : localStorage.getItem("theme") == "dark"
                    ? "#1c1c1c"
                    : "#efefef",
              }}
              onClick={() => {
                // hideShowNotes = !hideShowNotes
                // columns[9].omit = hideShowNotes
                // setTableData({ columns, data: allData })

                setShowHide({ ...showHide, notes: !showHide.notes })
                handleUpdateUserPreferences({ hideShowNotes: !showHide.notes })
                setReload(!reload)
              }}
            >
              <i
                className={"bx " + (showHide.notes ? "bx-x" : "bx-check")}
                style={{
                  fontSize: "16px",
                  marginTop: "0px",
                  marginRight: "10px",
                  float: "left",
                }}
              ></i>{" "}
              Notes
            </li>
            <li
              style={{
                backgroundColor: !showHide.tags
                  ? localStorage.getItem("theme") == "dark"
                    ? "#0f0f0f"
                    : "white"
                  : localStorage.getItem("theme") == "dark"
                    ? "#1c1c1c"
                    : "#efefef",
              }}
              onClick={() => {
                // hideShowTags = !hideShowTags
                // columns[10].omit = hideShowTags
                // setTableData({ columns, data: allData })

                setShowHide({ ...showHide, tags: !showHide.tags })
                handleUpdateUserPreferences({ hideShowTags: !showHide.tags })
                setReload(!reload)
              }}
            >
              <i
                className={"bx " + (showHide.tags ? "bx-x" : "bx-check")}
                style={{
                  fontSize: "16px",
                  marginTop: "0px",
                  marginRight: "10px",
                  float: "left",
                }}
              ></i>{" "}
              Tags
            </li>
            <li
              style={{
                backgroundColor: !showHide.orderNumber
                  ? localStorage.getItem("theme") == "dark"
                    ? "#0f0f0f"
                    : "white"
                  : localStorage.getItem("theme") == "dark"
                    ? "#1c1c1c"
                    : "#efefef",
              }}
              onClick={() => {
                // hideShowOrderNumber = !hideShowOrderNumber
                // columns[11].omit = hideShowOrderNumber
                // setTableData({ columns, data: allData })
                setShowHide({ ...showHide, orderNumber: !showHide.orderNumber })
                handleUpdateUserPreferences({
                  hideShowOrderNumber: !showHide.orderNumber,
                })
                setReload(!reload)
              }}
            >
              <i
                className={"bx " + (showHide.orderNumber ? "bx-x" : "bx-check")}
                style={{
                  fontSize: "16px",
                  marginTop: "0px",
                  marginRight: "10px",
                  float: "left",
                }}
              ></i>{" "}
              Order #
            </li>
            <li
              style={{
                backgroundColor: !showHide.packageNumber
                  ? localStorage.getItem("theme") == "dark"
                    ? "#0f0f0f"
                    : "white"
                  : localStorage.getItem("theme") == "dark"
                    ? "#1c1c1c"
                    : "#efefef",
              }}
              onClick={() => {
                // hideShowPackageNumber = !hideShowPackageNumber
                // columns[12].omit = hideShowPackageNumber
                // setTableData({ columns, data: allData })

                setShowHide({
                  ...showHide,
                  packageNumber: !showHide.packageNumber,
                })
                handleUpdateUserPreferences({
                  hideShowPackageNumber: !showHide.packageNumber,
                })
                setReload(!reload)
              }}
            >
              <i
                className={
                  "bx " + (showHide.packageNumber ? "bx-x" : "bx-check")
                }
                style={{
                  fontSize: "16px",
                  marginTop: "0px",
                  marginRight: "10px",
                  float: "left",
                }}
              ></i>{" "}
              Package #
            </li>
            <li
              style={{
                backgroundColor: !showHide.picklists
                  ? localStorage.getItem("theme") == "dark"
                    ? "#0f0f0f"
                    : "white"
                  : localStorage.getItem("theme") == "dark"
                    ? "#1c1c1c"
                    : "#efefef",
              }}
              onClick={() => {
                // hideShowPicklists = !hideShowPicklists
                // columns[14].omit = hideShowPicklists
                // setTableData({ columns, data: allData })

                setShowHide({ ...showHide, picklists: !showHide.picklists })
                handleUpdateUserPreferences({
                  hideShowPicklists: !showHide.picklists,
                })
                setReload(!reload)
              }}
            >
              <i
                className={"bx " + (showHide.picklists ? "bx-x" : "bx-check")}
                style={{
                  fontSize: "16px",
                  marginTop: "0px",
                  marginRight: "10px",
                  float: "left",
                }}
              ></i>{" "}
              Picklist
            </li>
            <li
              style={{
                backgroundColor: !showHide.orderDate
                  ? localStorage.getItem("theme") == "dark"
                    ? "#0f0f0f"
                    : "white"
                  : localStorage.getItem("theme") == "dark"
                    ? "#1c1c1c"
                    : "#efefef",
              }}
              onClick={() => {
                // hideShowOrderDate = !hideShowOrderDate
                setShowHide({ ...showHide, orderDate: !showHide.orderDate })
                // columns[17].omit = hideShowOrderDate
                // setTableData({ columns, data: allData })
                handleUpdateUserPreferences({
                  hideShowOrderDate: !showHide.orderDate,
                })

                // columns[21].omit = hideShowStatus
                // setTableData({ columns, data: allData })
                setReload(!reload)
              }}
            >
              <i
                className={"bx " + (showHide.orderDate ? "bx-x" : "bx-check")}
                style={{
                  fontSize: "16px",
                  marginTop: "0px",
                  marginRight: "10px",
                  float: "left",
                }}
              ></i>{" "}
              Order Date
            </li>
            <li
              style={{
                backgroundColor: !showHide.updatedAt
                  ? localStorage.getItem("theme") == "dark"
                    ? "#0f0f0f"
                    : "white"
                  : localStorage.getItem("theme") == "dark"
                    ? "#1c1c1c"
                    : "#efefef",
              }}
              onClick={() => {
                // hideShowUpdatedAt = !hideShowUpdatedAt
                // columns[18].omit = hideShowUpdatedAt
                // setTableData({ columns, data: allData })
                // handleUpdateUserPreferences()

                setShowHide({ ...showHide, updatedAt: !showHide.updatedAt })

                // columns[21].omit = hideShowStatus
                // setTableData({ columns, data: allData })
                handleUpdateUserPreferences({
                  hideShowUpdatedAt: !showHide.updatedAt,
                })
                setReload(!reload)
              }}
            >
              <i
                className={"bx " + (showHide.updatedAt ? "bx-x" : "bx-check")}
                style={{
                  fontSize: "16px",
                  marginTop: "0px",
                  marginRight: "10px",
                  float: "left",
                }}
              ></i>{" "}
              Updated At
            </li>
            <li
              style={{
                backgroundColor: !showHide.dimension
                  ? localStorage.getItem("theme") == "dark"
                    ? "#0f0f0f"
                    : "white"
                  : localStorage.getItem("theme") == "dark"
                    ? "#1c1c1c"
                    : "#efefef",
              }}
              onClick={() => {
                // hideShowDimension = !hideShowDimension
                // setHideShowDimension(!hideShowDimension)
                setShowHide({ ...showHide, dimension: !showHide.dimension })
                // columns[19].omit = hideShowDimension
                // setTableData({ columns, data: allData })
                setReload(!reload)
                handleUpdateUserPreferences({
                  hideShowDimension: !showHide.dimension,
                })
              }}
            >
              <i
                className={"bx " + (showHide.dimension ? "bx-x" : "bx-check")}
                style={{
                  fontSize: "16px",
                  marginTop: "0px",
                  marginRight: "10px",
                  float: "left",
                }}
              ></i>{" "}
              Dimension
            </li>
            <li
              style={{
                backgroundColor: !showHide.weight
                  ? localStorage.getItem("theme") == "dark"
                    ? "#0f0f0f"
                    : "white"
                  : localStorage.getItem("theme") == "dark"
                    ? "#1c1c1c"
                    : "#efefef",
              }}
              onClick={() => {
                // hideShowWeight = !showHide.weight
                setShowHide({ ...showHide, weight: !showHide.weight })

                // columns[20].omit = hideShowWeight
                // setTableData({ columns, data: allData })
                handleUpdateUserPreferences({
                  hideShowWeight: !showHide.weight,
                })
                setReload(!reload)
              }}
            >
              <i
                className={"bx " + (showHide.weight ? "bx-x" : "bx-check")}
                style={{
                  fontSize: "16px",
                  marginTop: "0px",
                  marginRight: "10px",
                  float: "left",
                }}
              ></i>{" "}
              Weight
            </li>
            <li
              style={{
                backgroundColor: !showHide.status
                  ? localStorage.getItem("theme") == "dark"
                    ? "#0f0f0f"
                    : "white"
                  : localStorage.getItem("theme") == "dark"
                    ? "#1c1c1c"
                    : "#efefef",
              }}
              onClick={() => {
                // hideShowStatus = !hideShowStatus
                setShowHide({ ...showHide, status: !showHide.status })

                // columns[21].omit = hideShowStatus
                // setTableData({ columns, data: allData })
                handleUpdateUserPreferences({
                  hideShowStatus: !showHide.status,
                })
                setReload(!reload)
              }}
            >
              <i
                className={"bx " + (showHide.status ? "bx-x" : "bx-check")}
                style={{
                  fontSize: "16px",
                  marginTop: "0px",
                  marginRight: "10px",
                  float: "left",
                }}
              ></i>{" "}
              Status
            </li>
            <li style={{ backgroundColor: !showHide.assignedUser ? localStorage.getItem("theme") == "dark" ? "#0f0f0f" : "white" : localStorage.getItem("theme") == "dark" ? "#1c1c1c" : "#efefef", }}
              onClick={() => { setShowHide({ ...showHide, assignedUser: !showHide.assignedUser }); handleUpdateUserPreferences({ hideShowAssignedUser: !showHide.assignedUser, }); setReload(!reload) }}>
              <i className={"bx " + (showHide.assignedUser ? "bx-x" : "bx-check")} style={{ fontSize: "16px", marginTop: "0px", marginRight: "10px", float: "left", }}></i>{" "}Assigned User
            </li>
          </ul>
        </span>

        <Breadcrumbs title="NullShip" sourceItem="Orders" breadcrumbItem="Awaiting Shipment" reloadPage={handleReloadPage} isOrder={true} />

        <Card style={{ marginTop: "-0.7rem" }}>
          <div style={{ padding: '10px' }}>
            <PDFDownloadLink className={"pdf-downloader"}
              document={<PackingSlip pc_from_name={pc_from_name} pc_from_street1={pc_from_street1} pc_from_street2={pc_from_street2} pc_from_city={pc_from_city} pc_from_state={pc_from_state} pc_from_zip={pc_from_zip} pc_from_country={pc_from_country} pc_to_street1={pc_to_street1} pc_to_street2={pc_to_street2} pc_to_city={pc_to_city} pc_to_state={pc_to_state} pc_to_zip={pc_to_zip} pc_to_country={pc_to_country} pc_order_number={pc_order_number} pc_order_date={pc_order_date} pc_to_name={pc_to_name} pc_items={pc_items} pc_sub_total={pc_sub_total} pc_total={pc_total} />} style={{ display: "none" }} fileName={`${slip_name}-packing-slip.pdf`}>
              {({ blob, url, loading, error }) =>
                loading ? "Loading document..." : "Download now!"
              }
            </PDFDownloadLink>
            <PDFDownloadLink
              className={"pdf-downloader-template-2"}
              document={
                <PackingSlipTemplate2
                  pc_to_street1={pc_to_street1}
                  pc_to_street2={pc_to_street2}
                  pc_to_city={pc_to_city}
                  pc_to_state={pc_to_state}
                  pc_to_zip={pc_to_zip}
                  pc_to_country={pc_to_country}
                  pc_order_number={pc_order_number}
                  pc_reference_number={pc_reference_number}
                  pc_order_date={pc_order_date}
                  pc_to_name={pc_to_name}
                  pc_bill_to_street1={pc_bill_to_street1}
                  pc_bill_to_street2={pc_bill_to_street2}
                  pc_bill_to_city={pc_bill_to_city}
                  pc_bill_to_state={pc_bill_to_state}
                  pc_bill_to_zip={pc_bill_to_zip}
                  pc_bill_to_country={pc_bill_to_country}
                  pc_items={pc_items}
                  pc_extra_row={pc_extra_row}
                  pc_from_name={pc_from_name}
                  pc_from_street1={pc_from_street1}
                  pc_from_street2={pc_from_street2}
                  pc_from_city={pc_from_city}
                  pc_from_state={pc_from_state}
                  pc_from_zip={pc_from_zip}
                />
              }
              style={{ display: "none" }}
              fileName={`${slip_name}-packing-slip.pdf`}
            >
              {({ blob, url, loading, error }) =>
                loading ? "Loading document..." : "Download now!"
              }
            </PDFDownloadLink>
            <PDFDownloadLink
              className={"merged-packing-slip-downloader"}
              document={
                <MergedPackingSlip mergedPackingSlip={mergedPackingSlip} />
              }
              style={{ display: "none" }}
              fileName={`merged-packing-slips.pdf`}
            >
              {({ blob, url, loading, error }) =>
                loading ? "Loading document..." : "Download now!"
              }
            </PDFDownloadLink>
            <PDFDownloadLink
              className={"merged-packing-slip-template2-downloader"}
              document={
                <MergedPackingSlipTemplate2
                  mergedPackingSlipTemplete2={mergedPackingSlipTemplete2}
                />
              }
              style={{ display: "none" }}
              fileName={`merged-packing-slips.pdf`}
            >
              {({ blob, url, loading, error }) =>
                loading ? "Loading document..." : "Download now!"
              }
            </PDFDownloadLink>
            {filterType != "Unassigned" ? (
              <button className={"btn btn-primary waves-effect waves-light m-1"} onClick={handleVerification} disabled={selectedOrders.length == 0}>Create + Print Labels</button>
            ) : null}
            <button
              className={"btn btn-light waves-effect waves-light m-1"}
              onClick={handleBulKRate}
              disabled={showSideBar || selectedOrders.length == 0}
            >
              Get Rate
            </button>
            <ButtonDropdown
              isOpen={print_dropdown}
              toggle={() => {
                setPrintDropdown(!print_dropdown)
              }}
              disabled={clickedSideBarJson.length == 0}
              style={{ opacity: clickedSideBarJson.length == 0 ? "0.7" : "1" }}
            >
              <DropdownToggle
                className={"btn btn-light waves-effect waves-light m-1"}
                caret
                color="light"
              >
                Print <i className="bx bx-chevron-down"></i>
              </DropdownToggle>
              <DropdownMenu>
                {JSON.parse(localStorage.getItem("authUser")).role_id == 4 ? JSON.parse(localStorage.getItem("authUser")).parent_id : JSON.parse(localStorage.getItem("authUser")).id == 572 ? (
                  <DropdownItem onClick={handlePackingSlipTemplate2}>
                    Packing Slip
                  </DropdownItem>
                ) : (
                  <DropdownItem onClick={handlePackingSlip}>
                    Packing Slip
                  </DropdownItem>
                )}

                {JSON.parse(localStorage.getItem("authUser")).role_id == 4 ? JSON.parse(localStorage.getItem("authUser")).parent_id : JSON.parse(localStorage.getItem("authUser")).id == 572 ? (
                  <DropdownItem onClick={downloadMergedPackingSlipTemplete2}>
                    Merged Packing Slips
                  </DropdownItem>
                ) : (
                  <DropdownItem onClick={downloadMergedPackingSlip}>
                    Merged Packing Slips
                  </DropdownItem>
                )}
              </DropdownMenu>
            </ButtonDropdown>
            <button className={"btn btn-light waves-effect waves-light m-1"} disabled={true}>Assign To</button>
            <button className={"btn btn-light waves-effect waves-light m-1"} onClick={handleTags} disabled={selectedOrders.length == 0}>Tag</button>
            <button onClick={() => props.history.push("/order/create")} className={"btn btn-light waves-effect waves-light m-1"} disabled={localStorage.getItem("store") != 0}>New Order</button>
            <button className={"btn btn-light waves-effect waves-light m-1"} onClick={handleBulkUpdate} disabled={showSideBar || selectedOrders.length == 0}>Bulk Update</button>
            <button className={"btn btn-light waves-effect waves-light m-1"} disabled={true}>Allocate</button>
            <ButtonDropdown isOpen={drp_link} toggle={() => { setdrp_link(!drp_link) }}>
              <DropdownToggle className={"btn btn-light waves-effect waves-light m-1"} caret color="light"> Other Actions <i className="bx bx-chevron-down"></i></DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  disabled={localStorage.getItem("store") == 0 ? false : true}
                  onClick={handleImport}
                >
                  Import Orders
                </DropdownItem>
                <DropdownItem
                  disabled={localStorage.getItem("store") == 0 ? false : true}
                  onClick={handleImportWithItems}
                >
                  Import Orders With Items
                </DropdownItem>
                <DropdownItem
                  onClick={handleBulkDelete}
                  disabled={selectedOrders.length == 0}
                >
                  Bulk Delete
                </DropdownItem>
                <DropdownItem onClick={handleExport}>
                  Export Orders
                </DropdownItem>
              </DropdownMenu>
            </ButtonDropdown>
            <div className={"col-md-1 col-lg-1 col-xl-2 col-xxl-4 m-1 mt-2"} style={{ position: "relative", float: "right", width: "fit-content", }}>
              {(JSON.parse(localStorage.getItem("authUser")).role_id === 2 || JSON.parse(localStorage.getItem("authUser")).role_id === 4) ? (
                <div className={"order-desktop-balance"} style={{ right: "10px", top: "-10px", position: "absolute" }}>
                  <h6>Balance <br /> <span style={{ fontSize: "1.5rem" }}>${balance != "-" ? amountFormat(parseFloat(balance).toFixed(2)) : "..."}</span></h6>
                </div>
              ) : null}
            </div>
          </div>
        </Card>
        <Card className={"order-mobile-balance"}>
          <CardBody>
            {(JSON.parse(localStorage.getItem("authUser")).role_id === 2 || JSON.parse(localStorage.getItem("authUser")).role_id === 4) ? (
              <div>
                <h6 style={{ textAlign: "right" }}>
                  Balance:{" "}
                  <span style={{ fontSize: "1.5em" }}>
                    $
                    {balance != "-"
                      ? amountFormat(parseFloat(balance).toFixed(2))
                      : "..."}
                  </span>
                </h6>
              </div>
            ) : null}
          </CardBody>
        </Card>

        <div style={{ marginTop: "-1rem" }}>
          {carriers.length == 0 ? ( <button style={{marginLeft: "0px",marginRight: "0px",borderRadius: "4px 4px 0px 0px",borderColor: "rgba(0,0,0,0)",}} disabled={pending}>Loading...</button>) : null}
          {localStorage.getItem("store") != 0 ? (
            <button className={"btn btn-md rounded-top " +(filterType === "Unassigned" ? " text-dark" : " text-secondary")} style={{marginLeft: "0px",marginRight: "0px",borderRadius: "4px 4px 0px 0px",borderColor: "rgba(0,0,0,0)",backgroundColor:filterType == "Unassigned"? localStorage.getItem("theme") == "dark"? "#0f0f0f": "white": localStorage.getItem("theme") == "dark"? "#222224": "#E1E5E8",}} onClick={handleNoServiceOrders} disabled={pending}>
              Unassigned ({unassignedOrderCount})
            </button>
          ) : null}
          {carriers.length > 0 &&
            carriers.filter(el => el.name == "FedEx")[0].is_active == 1 ? (
            <button className={"btn btn-md rounded-top " + (filterType === "FedEx" ? " text-dark" : " text-secondary")} style={{ marginLeft: "0px", marginRight: "0px", borderRadius: "4px 4px 0px 0px", borderColor: "rgba(0,0,0,0)", backgroundColor: filterType == "FedEx" ? localStorage.getItem("theme") == "dark" ? "#0f0f0f" : "white" : localStorage.getItem("theme") == "dark" ? "#222224" : "#E1E5E8", }} onClick={handleFedExOrders} disabled={pending}>
              FedEx ({fedexOrderCount})
            </button>
          ) : null}
          {carriers.length > 0 &&
            carriers.filter(el => el.name == "UPS")[0].is_active == 1 ? (
            <button
              className={
                "btn btn-md rounded-top " +
                (filterType === "UPS" ? " text-dark" : " text-secondary")
              }
              // disabled={loadingOrders}
              style={{
                marginLeft: "0px",
                marginRight: "0px",
                borderRadius: "4px 4px 0px 0px",
                borderColor: "rgba(0,0,0,0)",
                backgroundColor:
                  filterType == "UPS"
                    ? localStorage.getItem("theme") == "dark"
                      ? "#0f0f0f"
                      : "white"
                    : localStorage.getItem("theme") == "dark"
                      ? "#222224"
                      : "#E1E5E8",
              }}
              onClick={handleUPSOrders}
              disabled={pending}
            >
              UPS ({upsOrderCount})
            </button>
          ) : null}
          {/* {carriers.length > 0 &&
            carriers.filter(el => el.name == "UPSv2")[0].is_active == 1 ? (
              <button className={"btn btn-md rounded-top  " +(filterType === "UPSv2" ? " text-dark" : " text-secondary") } style={{ marginLeft: "0px", marginRight: "0px", borderRadius: "4px 4px 0px 0px", borderColor: "rgba(0,0,0,0)", backgroundColor: filterType == "UPSv2" ? localStorage.getItem("theme") == "dark" ? "#0f0f0f" : "white" : localStorage.getItem("theme") == "dark" ? "#222224" : "#E1E5E8", }} onClick={handleUPSv2Orders} disabled={pending}> UPSv2 ({upsv2OrderCount})</button>
          ) : null} */}
          {carriers.length > 0 &&
            carriers.filter(el => el.name == "DHL")[0].is_active == 1 ? (
            <button
              className={
                "btn btn-md rounded-top  " +
                (filterType === "DHL" ? " text-dark" : " text-secondary")
              }
              // disabled={loadingOrders}
              style={{
                marginLeft: "0px",
                marginRight: "0px",
                borderRadius: "4px 4px 0px 0px",
                borderColor: "rgba(0,0,0,0)",
                backgroundColor:
                  filterType == "DHL"
                    ? localStorage.getItem("theme") == "dark"
                      ? "#0f0f0f"
                      : "white"
                    : localStorage.getItem("theme") == "dark"
                      ? "#222224"
                      : "#E1E5E8",
              }}
              onClick={handleDHLOrders}
              disabled={pending}
            >
              DHL ({dhlOrderCount})
            </button>
          ) : null}
          {carriers.length > 0 &&
            carriers.filter(el => el.name == "USPS")[0].is_active == 1 ? (
            <button
              className={
                "btn btn-md  " +
                (filterType === "USPS" ? " text-dark" : " text-secondary")
              }
              // disabled={loadingOrders}
              style={{
                marginLeft: "0px",
                marginRight: "0px",
                borderRadius: "4px 4px 0px 0px",
                borderColor: "rgba(0,0,0,0)",
                backgroundColor:
                  filterType == "USPS"
                    ? localStorage.getItem("theme") == "dark"
                      ? "#0f0f0f"
                      : "white"
                    : localStorage.getItem("theme") == "dark"
                      ? "#222224"
                      : "#E1E5E8",
              }}
              onClick={handleUSPSOrders}
              disabled={pending}
            >
              USPS ({uspsOrderCount})
            </button>
          ) : null}
        </div>
        <div className={"row"}>
          <div className={showSideBar ? "col-md-8" : "col-md-12"} style={{ position: "relative" }}>
            <label htmlFor={"showsidebar"} className={"showsidebar"} style={{ position: "absolute", right: "12px", top: "-20px", fontWeight: "500", fontSize: "12px", }}>
              <input type={"checkbox"} id={"showsidebar"}
                onClick={e => {
                  let { checked } = e.target
                  if (checked) { setShowSideBar(true) }
                  else { setShowSideBar(false); document.body.style.overflow = "visible"; let x = []; clickedSideBarJson = x; uncheckAll(); selectedOrders = []; setSelectedOrdersCount(selectedOrders.length); clickedSideBarJson = []; selectedOrderJson = []; setSingleOrderWeight(""); setSingleOrderServiceId(""); setSingleOrderWidth(""); setSingleOrderHeight(""); setSingleOrderLength(""); setIsFullTextVisible(false); setSingleUpdateToPhone(""); setEnableSingleUpdateToPhone(false) }
                }}
              />
              {" "}
              <span>Show Sidebar</span>
            </label>
            <Card>
              <CardBody>
                {responseSuccess === false ? (
                  <>
                    <br />
                    <Alert color="danger">
                      {responseMessage}
                      <span className={"alert-loading"}></span>
                    </Alert>
                    <br />
                  </>
                ) : null}
                {responseSuccess === true ? (
                  <>
                    <br />
                    <Alert color="success">
                      {responseMessage}{" "}
                      {isRetry ? (
                        <span>
                          {failedOrders.length} orders failed due to excessive
                          resource consumption, Please{" "}
                          <span
                            style={{
                              fontWeight: "bold",
                              textDecoration: "underline",
                              color: "blue",
                              cursor: "pointer",
                            }}
                            onClick={handleRetry}
                          >
                            Try Again
                          </span>
                        </span>
                      ) : (
                        ""
                      )}
                      <span className={"alert-loading"}></span>
                    </Alert>
                    <br />
                  </>
                ) : null}
                <div className={"row"} style={{ marginBottom: windowWidth > 1500 ? "-46px" : "10px", display: "flex", }}>
                  <div className={"custom-filter"} style={{ fontSize: "14px", color: localStorage.getItem("theme") == "dark" ? "#a6b0c4" : "black", }}>Filter By:</div>
                  {stores.length > 0 ? (
                    <CustomFilters
                      title={"Store"}
                      options={stores}
                      change={handleStoreSelect}
                    />
                  ) : null}
                  <CustomFilters title={"Destination"} options={[{ name: "All", value: "All" }, { name: "International", value: "International" }, { name: "Domestic", value: "Domestic" },]} change={handleFilterShipment} />
                  <CustomFilters title={"Assignee"} options={[{ name: "Remove Filter", value: "none" }, { name: "Unassigned", value: "Unassigned" },]} change={handleAssignee} />
                  {tagsForFilter.length > 1 ? (<CustomFilters title={"Tag"} options={tagsForFilter} change={handleSortTag} />) : null}
                  {picklists.length > 1 ? (<CustomMultiselectFilters title={"Picklist"} options={picklists} change={handleSortPicklist} />) : null}
                  <CustomFilters title={"Allocation"} options={[{ name: "Remove Filter", value: "none" }, { name: "Unallocated", value: "Unallocated" },]} change={handleAllocation} />
                  <CustomFilters title={"Validation"} options={[{ name: "Remove Filter", value: "none" }, { name: "Passed", value: "Passed" }, { name: "Failed", value: "Failed" },]} change={handleValidationFilter} />
                  <CustomFilters title={"Order Date"} options={[{ name: "Remove Filter", value: "none" }, { name: "Today", value: "Today" }, { name: "Yesterday", value: "Yesterday" }, { name: "Last Week", value: "Last Week" }, { name: "Last Month", value: "Last Month" }, { name: "Last Year", value: "Last Year" },]} change={handleOrderDate} />
                  <CustomFilters title={"Other"} options={[{ name: "Remove Filter", value: "none" }, { name: "Other", value: "Other" },]} change={handleAllocation} />
                  <CustomFilters title={"Save Filter"} options={[{ name: "Remove Filter", value: "none" }, { name: "Save Filter", value: "Save Filter" },]} change={handleAllocation} />
                </div>
                {showSideBar ? (
                  <>
                    <br />
                    <br />
                    <br />
                  </>
                ) : null}
                {!selectedGroupBy || (groupByDataTables.length == 0 && !pendingGroupBy) ? (
                  <div className="inner-wrapper">
                    <DataTableExtensions  {...tableData} filterPlaceholder={`Filter Order With Detail or Item Name...`}>
                      <DataTable
                        keyField={"id"}
                        columns={columns}
                        data={filteredData}
                        pagination={50}
                        progressPending={pending}
                        progressComponent={<h4 className={"mt-5 mb-5 text-center"}>Loading data, this may take a few moments...</h4>}
                        paginationPerPage={50}
                        paginationRowsPerPageOptions={[50, 100, 200, 500]}
                        highlightOnHover={true}
                        onRowClicked={handleRowClick}
                        theme={localStorage.getItem("theme") == "dark" ? "solarized" : null}
                        customStyles={localStorage.getItem("theme") == "dark" ? customDarkStyles : customStyles}
                        expandableRows={true}
                        expandableRowsComponent={expandableRows}
                        expandableRowDisabled={row => row.custom_items_data && JSON.parse(row.custom_items_data).length > 0 ? false : true}
                        expandableRowExpanded={row => displayOption === 1}
                        className={"order-table"}
                      // fixedHeader
                      />
                    </DataTableExtensions>
                  </div>

                ) : groupByDataTables.length > 0 ? (
                  !pendingGroupBy ? (
                    groupByDataTables.map((item, index) => (
                      <>
                        <h5
                          style={{ marginTop: "60px", marginBottom: "-40px" }}
                        >
                          {item.name != "-" ? selectedGroupBy + ":" : null}{" "}
                          {item.name != "-"
                            ? item.name +
                            (selectedGroupBy == "Weight" ? " lb." : "")
                            : null}
                        </h5>
                        <div className="inner-wrapper">
                          <DataTableExtensions {...{ columns, data: item.data }} filterPlaceholder={`Filter Order With Detail or Item Name...`}>
                            <DataTable key={index} keyField={"id"} columns={columns} data={item.data} pagination={50} progressPending={pending} progressComponent={<h4 className={"mt-5 mb-5 text-center"}>Loading data, this may take a few moments...</h4>}
                              paginationPerPage={50}
                              paginationRowsPerPageOptions={[50, 100, 200, 500]}
                              highlightOnHover={true}
                              onRowClicked={handleRowClick}
                              theme={localStorage.getItem("theme") == "dark" ? "solarized" : null}
                              customStyles={localStorage.getItem("theme") == "dark" ? customDarkStyles : customStyles}
                              expandableRows={true}
                              expandableRowsComponent={expandableRows}
                              expandableRowDisabled={row => row.custom_items_data && JSON.parse(row.custom_items_data).length > 0 ? false : true}
                              expandableRowExpanded={row => displayOption === 1}
                              className={"order-table"}
                            />
                          </DataTableExtensions>
                        </div>


                      </>
                    ))
                  ) : (
                    <h4 style={{ marginTop: "60px", marginBottom: "0px" }}>
                      Loading...
                    </h4>
                  )
                ) : pendingGroupBy ? (<h4 style={{ marginTop: "60px", marginBottom: "0px" }}>Loading...</h4>
                ) : null}
              </CardBody>
            </Card>
          </div>
          <div style={{ display: showSideBar ? "block" : "none" }} className={showSideBar ? "col-md-4 sticky-sidebar" : null} >
            <Card>
              <CardBody style={{ height: "590px", overflow: "auto", padding: "0px" }}>
                {clickedSideBarJson.length == 0 ? (<h5 className={"text-center p-2"}>No Rows Selected</h5>) :
                  (
                    <>
                      <h5 className={"bg-light p-2"}>Update{" "}{clickedSideBarJson.length > 1 ? "Shipments" : "Shipment"}{" "}({selectedOrdersCount} Rows Selected)</h5>
                      <br />
                      <Form onSubmit={e => { e.preventDefault(); singleOrderUpdate() }} className={"px-4"} style={{ marginTop: "-20px" }}>
                        <div className={"head my-2"}>
                          <p className={`read-more-text ${isFullTextVisible ? "expanded" : ""}`} style={{ fontSize: "12px", marginBottom: "0px" }}>
                            {clickedSideBarJson.length > 0 ?
                              clickedSideBarJson.map((part, id) => { if (id == clickedSideBarJson.length - 1) { return "Shipment #" + part.order_unique_id } else { return ("Shipment #" + part.order_unique_id + ", ") } }) : null}
                          </p>
                          {clickedSideBarJson.length > 1 ? (
                            <p
                              className={"text-primary"}
                              style={{
                                fontSize: "12px",
                                fontWeight: "500",
                                cursor: "pointer",
                              }}
                              type={"button"}
                              onClick={() =>
                                setIsFullTextVisible(!isFullTextVisible)
                              }
                            >
                              {isFullTextVisible ? "View Less" : "View More"}
                            </p>
                          ) : null}
                        </div>
                        <div className={"body"}>
                          <Row className="mb-3 mt-3">
                            <label htmlFor="weight_Input" className="col-md-3 col-form-label">Weight <span style={{ color: "red" }}> *</span></label>
                            <div className="col-md-9">
                              <input onChange={handleOrderWeight} value={singleOrderWeight} name="weight" placeholder="Weight in (lb)" className="form-control" type="number" onWheel={e => e.target.blur()} step="any" min={0} required={true} />
                            </div>
                          </Row>
                          <Row className="mb-3 mt-3">
                            <label className="col-md-3 col-form-label">
                              Service <span style={{ color: "red" }}> *</span>
                            </label>
                            <div className="col-md-9">
                              <select
                                name="serviceName"
                                className="form-control"
                                value={SingleOrderServiceId}
                                onChange={handleServiceOrder}
                              >
                                <option
                                  selected="true"
                                  value={""}
                                  disabled="true"
                                >
                                  Select a service
                                </option>
                                {services.map((part, ind) => {
                                  return part.is_active == 1 ? (
                                    <option key={ind} value={part.id}>
                                      {part.carrier_name} - {part.name}
                                    </option>
                                  ) : null
                                })}
                              </select>
                            </div>
                          </Row>
                          <Row className="mb-3 mt-3">
                            <label
                              className="col-md-5 col-form-label"
                              style={{
                                color: enableSingleUpdateToPhone
                                  ? localStorage.getItem("theme") == "dark"
                                    ? "#a6b0c4"
                                    : "black"
                                  : "gray",
                              }}
                            >
                              <input
                                type={"checkbox"}
                                id={"bulk-update-enable-to-phone"}
                                onChange={e => {
                                  setSingleUpdateToPhone("")
                                  setEnableSingleUpdateToPhone(e.target.checked)
                                }}
                              />{" "}
                              Ship To Phone{" "}
                              {enableSingleUpdateToPhone ? (
                                <span style={{ color: "red" }}> *</span>
                              ) : null}
                            </label>
                            <div className="col-md-7">
                              <input
                                name="to_phone"
                                // value={name}
                                className="form-control non-arrow-number"
                                placeholder="Enter Ship To Phone"
                                type="text"
                                onWheel={e => e.target.blur()}
                                value={singleUpdateToPhone}
                                disabled={!enableSingleUpdateToPhone}
                                onChange={e =>
                                  setSingleUpdateToPhone(e.target.value)
                                }
                                required={enableSingleUpdateToPhone}
                              />
                            </div>
                          </Row>
                          <Row className="mb-3 mt-3">
                            <fieldset>
                              <legend>Dimensions</legend>
                              <Row style={{ justifyContent: "center" }}>
                                <div className="col-md-4 mb-2">
                                  <label>
                                    Width{" "}
                                    {singleIsDimenssion && (
                                      <span style={{ color: "red" }}> *</span>
                                    )}
                                  </label>
                                  <input
                                    onChange={e =>
                                      setSingleOrderWidth(e.target.value)
                                    }
                                    placeholder="Enter width"
                                    className="form-control"
                                    type="number"
                                    onWheel={e => e.target.blur()}
                                    step="any"
                                    min={0}
                                    value={singleOrderWidth}
                                    required={singleIsDimenssion}
                                  />
                                </div>
                                <div className="col-md-4 mb-2">
                                  <label>
                                    Height{" "}
                                    {singleIsDimenssion && (
                                      <span style={{ color: "red" }}> *</span>
                                    )}
                                  </label>
                                  <input
                                    value={singleOrderHeight}
                                    onChange={e =>
                                      setSingleOrderHeight(e.target.value)
                                    }
                                    placeholder="Enter height"
                                    className="form-control"
                                    type="number"
                                    onWheel={e => e.target.blur()}
                                    step="any"
                                    min={0}
                                    required={singleIsDimenssion}
                                  />
                                </div>
                                <div className="col-md-4 mb-2">
                                  <label>
                                    Length{" "}
                                    {singleIsDimenssion && (
                                      <span style={{ color: "red" }}> *</span>
                                    )}
                                  </label>
                                  <input
                                    value={singleOrderLength}
                                    onChange={e =>
                                      setSingleOrderLength(e.target.value)
                                    }
                                    placeholder="Enter length"
                                    className="form-control"
                                    type="number"
                                    onWheel={e => e.target.blur()}
                                    step="any"
                                    min={0}
                                    required={singleIsDimenssion}
                                  />
                                </div>
                              </Row>
                            </fieldset>
                          </Row>
                          <Row className="mb-12 py-3">
                            <div className="col">
                              <h4
                                className="text-center"
                                onClick={() => { }}
                              // style={{ textAlign: "center" }}
                              >
                                Rate $
                                {singleOrderRate
                                  ? parseFloat(singleOrderRate).toFixed(2)
                                  : "0.00"}{" "}
                                <span
                                  className={"text-secondary"}
                                  style={{ fontSize: "12px", fontWeight: "400" }}
                                >
                                  per shipment
                                </span>
                              </h4>
                            </div>
                          </Row>
                          <Row
                            className="d-flex"
                            style={{ justifyContent: "space-evenly" }}
                          >
                            <button
                              type="submit"
                              className="w-50 btn btn-sm btn-success waves-effect waves-light"
                            >
                              Update Orders
                            </button>
                          </Row>
                          <hr />
                          <div className={"my-2"}>
                            <h4
                              onClick={() => { }}
                              style={{ width: "fit-content" }}
                            >
                              Items
                            </h4>
                          </div>
                          <hr />
                          {clickedSideBarJson.length > 0
                            ? clickedSideBarJson.map((part, id) => {
                              if (part.store_type == null) {
                                return (
                                  <>
                                    <h6>Shipment #{part.order_unique_id}</h6>
                                    {part.custom_items_data &&
                                      JSON.parse(part.custom_items_data).length >
                                      0 ? (
                                      JSON.parse(part.custom_items_data).map(
                                        (val, ind) => {
                                          return (
                                            <Row
                                              key={ind}
                                              className={"border rounded mt-2"}
                                              style={{
                                                padding: "10px 10px 0px 10px",
                                              }}
                                            >
                                              <Col sm="12">
                                                <h5>
                                                  <span
                                                    style={{ float: "right" }}
                                                  >
                                                    {val.quantity} x
                                                  </span>
                                                  <div
                                                    style={{
                                                      width: "200px",
                                                      whiteSpace: "nowrap",
                                                      textOverflow: "ellipsis",
                                                      overflow: "hidden",
                                                    }}
                                                  >
                                                    {val.description}{" "}
                                                  </div>
                                                </h5>
                                                <p>{val.total_value} USD</p>
                                              </Col>
                                            </Row>
                                          )
                                        }
                                      )
                                    ) : (
                                      <h6 style={{ textAlign: "center" }}>
                                        No Item Found
                                      </h6>
                                    )}
                                    <hr />
                                  </>
                                )
                              } else if (part.store_type == "shopify") {
                                return (
                                  <>
                                    <h6>Shipment #{part.order_unique_id}</h6>
                                    {JSON.parse(part.custom_items_data).map(
                                      (val, ind) => {
                                        return (
                                          <Row
                                            key={ind}
                                            className={"border rounded mt-2"}
                                            style={{
                                              padding: "10px 10px 0px 10px",
                                            }}
                                          >
                                            <Col sm="2">
                                              <img
                                                width="100%"
                                                src={val.image_url}
                                              />
                                            </Col>
                                            <Col sm="10">
                                              <h5>
                                                <span
                                                  style={{ float: "right" }}
                                                >
                                                  {val.quantity} x
                                                </span>
                                                <div
                                                  style={{
                                                    width: "200px",
                                                    whiteSpace: "nowrap",
                                                    textOverflow: "ellipsis",
                                                    overflow: "hidden",
                                                  }}
                                                >
                                                  {val.name}{" "}
                                                </div>
                                              </h5>
                                              <p>
                                                {val.price}{" "}
                                                {
                                                  val.price_set.shop_money
                                                    .currency_code
                                                }
                                              </p>
                                            </Col>
                                          </Row>
                                        )
                                      }
                                    )}
                                    <hr />
                                  </>
                                )
                              } else if (part.store_type == "ebay") {
                                return (
                                  <>
                                    <h6>Shipment #{part.order_unique_id}</h6>
                                    {JSON.parse(
                                      JSON.parse(part.custom_items_data)
                                    ).map((val, ind) => {
                                      return (
                                        <Row
                                          key={ind}
                                          className={"border rounded mt-2"}
                                          style={{
                                            padding: "10px 10px 0px 10px",
                                          }}
                                        >
                                          <Col sm="12">
                                            <h5>
                                              <span style={{ float: "right" }}>
                                                {val.quantity} x
                                              </span>
                                              <div
                                                style={{
                                                  width: "200px",
                                                  whiteSpace: "nowrap",
                                                  textOverflow: "ellipsis",
                                                  overflow: "hidden",
                                                }}
                                              >
                                                {val.title}{" "}
                                              </div>
                                            </h5>
                                            <p>
                                              {val.total.value}{" "}
                                              {val.total.currency}
                                            </p>
                                          </Col>
                                        </Row>
                                      )
                                    })}
                                    <hr />
                                  </>
                                )
                              } else if (part.store_type == "walmart") {
                                // <h5><span style={{float:"right"}}>{el.orderLineQuantity.amount ? el.orderLineQuantity.amount : "-"}x</span><div style={{width:"200px",whiteSpace:"nowrap",textOverflow:"ellipsis",overflow:"hidden"}}>{el.item.productName ? el.item.productName : "-"}</div></h5>
                                // <p>{el.charges.charge[0].chargeAmount.amount} {el.charges.charge[0].chargeAmount.currency}</p>
                                return (
                                  <>
                                    <h6>Shipment #{part.order_unique_id}</h6>
                                    {JSON.parse(part.custom_items_data).map(
                                      (val, ind) => {
                                        return (
                                          <Row
                                            key={ind}
                                            className={"border rounded mt-2"}
                                            style={{
                                              padding: "10px 10px 0px 10px",
                                            }}
                                          >
                                            <Col sm="12">
                                              <h5>
                                                <span
                                                  style={{ float: "right" }}
                                                >
                                                  {val.orderLineQuantity.amount}{" "}
                                                  x
                                                </span>
                                                <div
                                                  style={{
                                                    width: "200px",
                                                    whiteSpace: "nowrap",
                                                    textOverflow: "ellipsis",
                                                    overflow: "hidden",
                                                  }}
                                                >
                                                  {val.item.productName
                                                    ? val.item.productName
                                                    : "-"}{" "}
                                                </div>
                                              </h5>
                                              <p>
                                                {
                                                  val.charges.charge[0]
                                                    .chargeAmount.amount
                                                }{" "}
                                                {
                                                  val.charges.charge[0]
                                                    .chargeAmount.currency
                                                }
                                              </p>
                                            </Col>
                                          </Row>
                                        )
                                      }
                                    )}
                                    <hr />
                                  </>
                                )
                              } else if (part.store_type == "zenventory") {
                                // <h5><span style={{float:"right"}}>{el.orderLineQuantity.amount ? el.orderLineQuantity.amount : "-"}x</span><div style={{width:"200px",whiteSpace:"nowrap",textOverflow:"ellipsis",overflow:"hidden"}}>{el.item.productName ? el.item.productName : "-"}</div></h5>
                                // <p>{el.charges.charge[0].chargeAmount.amount} {el.charges.charge[0].chargeAmount.currency}</p>
                                return (
                                  <>
                                    <h6>Shipment #{part.order_unique_id}</h6>
                                    {JSON.parse(part.custom_items_data).map(
                                      (val, ind) => {
                                        return (
                                          <Row
                                            key={ind}
                                            className={"border rounded mt-2"}
                                            style={{
                                              padding: "10px 10px 0px 10px",
                                            }}
                                          >
                                            <Col sm="12">
                                              <h5>
                                                <span
                                                  style={{ float: "right" }}
                                                >
                                                  {val.quantity} x
                                                </span>
                                                <div
                                                  style={{
                                                    width: "200px",
                                                    whiteSpace: "nowrap",
                                                    textOverflow: "ellipsis",
                                                    overflow: "hidden",
                                                  }}
                                                >
                                                  {val.itemmaster.description
                                                    ? val.itemmaster.description
                                                    : "-"}{" "}
                                                </div>
                                              </h5>
                                              <p>${val.customerprice}</p>
                                            </Col>
                                          </Row>
                                        )
                                      }
                                    )}
                                    <hr />
                                  </>
                                )
                              } else if (part.store_type == "sellercloud") {
                                // <h5><span style={{float:"right"}}>{el.orderLineQuantity.amount ? el.orderLineQuantity.amount : "-"}x</span><div style={{width:"200px",whiteSpace:"nowrap",textOverflow:"ellipsis",overflow:"hidden"}}>{el.item.productName ? el.item.productName : "-"}</div></h5>
                                // <p>{el.charges.charge[0].chargeAmount.amount} {el.charges.charge[0].chargeAmount.currency}</p>
                                return (
                                  <>
                                    <h6>Shipment #{part.order_unique_id}</h6>
                                    {JSON.parse(part.custom_items_data).map(
                                      (val, ind) => {
                                        return (
                                          <Row
                                            key={ind}
                                            className={"border rounded mt-2"}
                                            style={{
                                              padding: "10px 10px 0px 10px",
                                            }}
                                          >
                                            <Col sm="12">
                                              <h6
                                                className={"bold"}
                                                style={{
                                                  marginBottom: "-10px",
                                                }}
                                              >
                                                ID: {val.ProductID}
                                              </h6>
                                              <hr />
                                              <h5>
                                                <span
                                                  style={{ float: "right" }}
                                                >
                                                  {val.Qty} x
                                                </span>
                                                <div
                                                  style={{
                                                    width: "200px",
                                                    whiteSpace: "nowrap",
                                                    textOverflow: "ellipsis",
                                                    overflow: "hidden",
                                                  }}
                                                >
                                                  {val.DisplayName
                                                    ? val.DisplayName
                                                    : "-"}{" "}
                                                </div>
                                              </h5>
                                              <p>
                                                {val.DisplayDescription
                                                  ? val.DisplayDescription
                                                  : "-"}
                                              </p>
                                              <div style={{ display: "flex" }}>
                                                <p style={{ width: "50%" }}>
                                                  ${val.AdjustedSitePrice}
                                                </p>
                                                <p
                                                  style={{
                                                    width: "50%",
                                                    textAlign: "right",
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      fontWeight: "500",
                                                    }}
                                                  >
                                                    Weight:
                                                  </span>{" "}
                                                  {val.Weight
                                                    ? val.Weight + "lb."
                                                    : "-"}
                                                </p>
                                              </div>
                                              <p>
                                                <span
                                                  style={{ fontWeight: "500" }}
                                                >
                                                  L x W x H:
                                                </span>{" "}
                                                {val.Length ? val.Length : "-"}{" "}
                                                x {val.Width ? val.Width : "-"}{" "}
                                                x{" "}
                                                {val.Height ? val.Height : "-"}
                                              </p>
                                            </Col>
                                          </Row>
                                        )
                                      }
                                    )}
                                    <hr />
                                  </>
                                )
                              } else if (part.store_type == "shipstation") {
                                // <h5><span style={{float:"right"}}>{el.orderLineQuantity.amount ? el.orderLineQuantity.amount : "-"}x</span><div style={{width:"200px",whiteSpace:"nowrap",textOverflow:"ellipsis",overflow:"hidden"}}>{el.item.productName ? el.item.productName : "-"}</div></h5>
                                // <p>{el.charges.charge[0].chargeAmount.amount} {el.charges.charge[0].chargeAmount.currency}</p>
                                return (
                                  <>
                                    <h6>Shipment #{part.order_unique_id}</h6>
                                    {JSON.parse(part.custom_items_data).map(
                                      (val, ind) => {
                                        return (
                                          <Row
                                            key={ind}
                                            className={"border rounded mt-2"}
                                            style={{
                                              padding: "10px 10px 0px 10px",
                                            }}
                                          >
                                            <Col sm="12">
                                              <h6
                                                className={"bold"}
                                                style={{
                                                  marginBottom: "-10px",
                                                }}
                                              >
                                                ID: {val.orderItemId}
                                              </h6>
                                              <hr />
                                              <h5>
                                                <span
                                                  style={{ float: "right" }}
                                                >
                                                  {val.quantity} x
                                                </span>
                                                <div
                                                  style={{
                                                    width: "200px",
                                                    whiteSpace: "nowrap",
                                                    textOverflow: "ellipsis",
                                                    overflow: "hidden",
                                                  }}
                                                >
                                                  {val.name ? val.name : "-"}{" "}
                                                </div>
                                              </h5>
                                              <p>${val.unitPrice}</p>
                                            </Col>
                                          </Row>
                                        )
                                      }
                                    )}
                                    <hr />
                                  </>
                                )
                              } else if (part.store_type == "veeqo") {
                                // <h5><span style={{float:"right"}}>{el.orderLineQuantity.amount ? el.orderLineQuantity.amount : "-"}x</span><div style={{width:"200px",whiteSpace:"nowrap",textOverflow:"ellipsis",overflow:"hidden"}}>{el.item.productName ? el.item.productName : "-"}</div></h5>
                                // <p>{el.charges.charge[0].chargeAmount.amount} {el.charges.charge[0].chargeAmount.currency}</p>
                                return (
                                  <>
                                    <h6>Shipment #{part.order_unique_id}</h6>
                                    {JSON.parse(part.custom_items_data).map(
                                      (val, ind) => {
                                        return (
                                          <Row
                                            key={ind}
                                            className={"border rounded mt-2"}
                                            style={{
                                              padding: "10px 10px 0px 10px",
                                            }}
                                          >
                                            <Col sm="12">
                                              <h6
                                                className={"bold"}
                                                style={{
                                                  marginBottom: "-10px",
                                                }}
                                              >
                                                ID: {val.sellable.id}
                                              </h6>
                                              <hr />
                                              <h5>
                                                <span
                                                  style={{ float: "right" }}
                                                >
                                                  {val.quantity} x
                                                </span>
                                                <div
                                                  style={{
                                                    width: "200px",
                                                    whiteSpace: "nowrap",
                                                    textOverflow: "ellipsis",
                                                    overflow: "hidden",
                                                  }}
                                                >
                                                  {val.sellable.product_title
                                                    ? val.sellable.product_title
                                                    : "-"}{" "}
                                                </div>
                                              </h5>
                                              <p>${val.sellable.price}</p>
                                            </Col>
                                          </Row>
                                        )
                                      }
                                    )}
                                    <hr />
                                  </>
                                )
                              } else if (part.store_type == "shiphero") {
                                // <h5><span style={{float:"right"}}>{el.orderLineQuantity.amount ? el.orderLineQuantity.amount : "-"}x</span><div style={{width:"200px",whiteSpace:"nowrap",textOverflow:"ellipsis",overflow:"hidden"}}>{el.item.productName ? el.item.productName : "-"}</div></h5>
                                // <p>{el.charges.charge[0].chargeAmount.amount} {el.charges.charge[0].chargeAmount.currency}</p>
                                return (
                                  <>
                                    <h6>Shipment #{part.order_unique_id}</h6>
                                    {JSON.parse(part.custom_items_data).map(
                                      (val, ind) => {
                                        return (
                                          <Row
                                            key={ind}
                                            className={"border rounded mt-2"}
                                            style={{
                                              padding: "10px 10px 0px 10px",
                                            }}
                                          >
                                            <Col sm="12">
                                              <h6
                                                className={"bold"}
                                                style={{
                                                  marginBottom: "-10px",
                                                }}
                                              >
                                                ID: {val.line_item_id}
                                              </h6>
                                              <hr />
                                              <h5>
                                                <span
                                                  style={{ float: "right" }}
                                                >
                                                  {val.quantity_allocated} x
                                                </span>
                                                <div
                                                  style={{
                                                    width: "200px",
                                                    whiteSpace: "nowrap",
                                                    textOverflow: "ellipsis",
                                                    overflow: "hidden",
                                                  }}
                                                >
                                                  {val?.sellable?.product_title
                                                    ? val?.sellable
                                                      ?.product_title
                                                    : "-"}{" "}
                                                </div>
                                              </h5>
                                              {/* <p>${val?.total_price}</p> */}
                                              <p>${"--"}</p>
                                            </Col>
                                          </Row>
                                        )
                                      }
                                    )}
                                    <hr />
                                  </>
                                )
                              } else if (part.store_type == "orderdesk") {
                                // <h5><span style={{float:"right"}}>{el.orderLineQuantity.amount ? el.orderLineQuantity.amount : "-"}x</span><div style={{width:"200px",whiteSpace:"nowrap",textOverflow:"ellipsis",overflow:"hidden"}}>{el.item.productName ? el.item.productName : "-"}</div></h5>
                                // <p>{el.charges.charge[0].chargeAmount.amount} {el.charges.charge[0].chargeAmount.currency}</p>
                                return (
                                  <>
                                    <h6>Shipment #{part?.order_unique_id}</h6>
                                    {JSON.parse(part.custom_items_data).map(
                                      (val, ind) => {
                                        return (
                                          <Row
                                            key={ind}
                                            className={"border rounded mt-2"}
                                            style={{
                                              padding: "10px 10px 0px 10px",
                                            }}
                                          >
                                            <Col sm="12">
                                              <h6
                                                className={"bold"}
                                                style={{
                                                  marginBottom: "-10px",
                                                }}
                                              >
                                                ID: {val.id}
                                              </h6>
                                              <hr />
                                              <h5>
                                                <span
                                                  style={{ float: "right" }}
                                                >
                                                  {val.quantity_allocated} x
                                                </span>
                                                <div
                                                  style={{
                                                    width: "200px",
                                                    whiteSpace: "nowrap",
                                                    textOverflow: "ellipsis",
                                                    overflow: "hidden",
                                                  }}
                                                >
                                                  {val?.name ? val?.name : "-"}{" "}
                                                </div>
                                              </h5>
                                              {/* <p>${val.total_price}</p> */}
                                              <p>${"--"}</p>
                                            </Col>
                                          </Row>
                                        )
                                      }
                                    )}
                                    <hr />
                                  </>
                                )
                              }
                            })
                            : ""}
                        </div>
                      </Form>
                    </>
                  )}
              </CardBody>
            </Card>
          </div>
        </div>

        {/*Modal*/}
        <Modal isOpen={JSON.parse(localStorage.getItem("authUser")).skip_proceed_screen == 1?false:orderDetailsModal} role="dialog" autoFocus={true} centered={true} className="exampleModal modal-xl" tabIndex="-1">
          <div>
            <ModalHeader
              toggle={() => {
                setOrderDetailsModal(!orderDetailsModal)
                uncheckAll()
                selectedOrders = []
                setSelectedOrdersCount(selectedOrders.length)
                clickedSideBarJson = []
                selectedOrderJson = []
                setBulkUpdateServiceId("")
                setBulkUpdateToPhone("")
                setEnableBulkUpdateToPhone(false)
                setTotalAmount(0)
                setCalculatedAmounts([])
                setCalculatedAmountsAboveFiveLbs([])
              }}
            >
              Order Cost Calculation
            </ModalHeader>
            <ModalBody>
              <table className={"table table-bordered"}>
                <tbody>
                  <tr style={{ border: "0px" }}>
                    <th style={{ border: "0px" }}>
                      {/* <img
                        src={
                          services.length > 0
                            ? services[
                                services.findIndex(
                                  el => el.id == SingleOrderServiceId
                                )
                              ].carrier_name == "FedEx"
                              ? fedex
                              : filterType == "UPS"
                              ? ups
                              : filterType == "USPS"
                              ? usps
                              : dhl
                            : ""
                        }
                        height={"50px"}
                      /> */}
                    </th>
                    <th style={{ textAlign: "right", border: "0px" }}>
                      Current Balance
                      <br />
                      <h4>${amountFormat(parseFloat(balance).toFixed(2))}</h4>
                    </th>
                  </tr>
                </tbody>
              </table>
              <br />
              <table className={"table table-bordered"}>
                <thead>
                  <tr>
                    <th>Service</th>
                    <th>Cost</th>
                    <th>Quantity</th>
                    <th>Discount Type</th>
                    <th>Discount</th>
                    <th>Final Cost</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th className={"bg-light"} colSpan={6}>
                      Under 1 lb. Orders
                    </th>
                  </tr>
                  {calculatedAmountsUnderOneLbs.length > 0 ? (
                    calculatedAmountsUnderOneLbs.map((el, id) => {
                      return (
                        <tr key={id}>
                          <td>{el.service_name}</td>
                          <td style={{ textAlign: "right" }}>
                            ${amountFormat(el.actual_service_price)}
                          </td>
                          <td style={{ textAlign: "right" }}>{el.quantity}</td>
                          <td>
                            {el.applied_discount !== "None"
                              ? el.applied_discount
                              : "-"}
                          </td>
                          <td>
                            {el.discount_type !== "None"
                              ? el.discounted_value + " " + el.discount_type
                              : "-"}
                          </td>
                          <td style={{ textAlign: "right" }}>
                            ${amountFormat(el.price)}
                          </td>
                        </tr>
                      )
                    })
                  ) : (
                    <tr>
                      <td colSpan={6}>-</td>
                    </tr>
                  )}
                  <tr>
                    <th className={"bg-light"} colSpan={6}>
                      1 lb. to 5 lb. Orders
                    </th>
                  </tr>
                  {calculatedAmounts.length > 0 ? (
                    calculatedAmounts.map((el, id) => {
                      return (
                        <tr key={id}>
                          <td>{el.service_name}</td>
                          <td style={{ textAlign: "right" }}>
                            ${amountFormat(el.actual_service_price)}
                          </td>
                          <td style={{ textAlign: "right" }}>{el.quantity}</td>
                          <td>
                            {el.applied_discount !== "None"
                              ? el.applied_discount
                              : "-"}
                          </td>
                          <td>
                            {el.discount_type !== "None"
                              ? el.discounted_value + " " + el.discount_type
                              : "-"}
                          </td>
                          <td style={{ textAlign: "right" }}>
                            ${amountFormat(el.price)}
                          </td>
                        </tr>
                      )
                    })
                  ) : (
                    <tr>
                      <td colSpan={6}>-</td>
                    </tr>
                  )}
                  <tr>
                    <th className={"bg-light"} colSpan={6}>
                      Above 5 lb. Orders
                    </th>
                  </tr>
                  {calculatedAmountsAboveFiveLbs.length > 0 ? (
                    calculatedAmountsAboveFiveLbs.map((el, id) => {
                      return (
                        <tr key={id}>
                          <td>{el.service_name}</td>
                          <td style={{ textAlign: "right" }}>
                            ${amountFormat(el.actual_service_price)}
                          </td>
                          <td style={{ textAlign: "right" }}>{el.quantity}</td>
                          <td>
                            {el.applied_discount !== "None"
                              ? el.applied_discount
                              : "-"}
                          </td>
                          <td>
                            {el.discount_type !== "None"
                              ? el.discounted_value + " " + el.discount_type
                              : "-"}
                          </td>
                          <td style={{ textAlign: "right" }}>
                            ${amountFormat(el.price)}
                          </td>
                        </tr>
                      )
                    })
                  ) : (
                    <tr>
                      <td colSpan={6}>-</td>
                    </tr>
                  )}
                  <tr>
                    <th className={"bg-light"} colSpan={6}>
                      Above 20 lb. Orders
                    </th>
                  </tr>
                  {calculatedAmountsAboveTwentyLbs.length > 0 ? (
                    calculatedAmountsAboveTwentyLbs.map((el, id) => {
                      return (
                        <tr key={id}>
                          <td>{el.service_name}</td>
                          <td style={{ textAlign: "right" }}>
                            ${amountFormat(el.actual_service_price)}
                          </td>
                          <td style={{ textAlign: "right" }}>{el.quantity}</td>
                          <td>
                            {el.applied_discount !== "None"
                              ? el.applied_discount
                              : "-"}
                          </td>
                          <td>
                            {el.discount_type !== "None"
                              ? el.discounted_value + " " + el.discount_type
                              : "-"}
                          </td>
                          <td style={{ textAlign: "right" }}>
                            ${amountFormat(el.price)}
                          </td>
                        </tr>
                      )
                    })
                  ) : (
                    <tr>
                      <td colSpan={6}>-</td>
                    </tr>
                  )}
                </tbody>
                <tfoot>
                  <tr>
                    <th style={{ textAlign: "right" }} colSpan={5}>
                      Total
                    </th>
                    {/*<th style={{textAlign:"right"}}>{calculatedAmounts.length>0?calculatedAmounts.map(item => item.price).reduce((prev, next) => prev + next):0}</th>*/}
                    <th style={{ textAlign: "right" }}>
                      ${amountFormat(totalAmount)}
                    </th>
                  </tr>
                </tfoot>
              </table>
              {/*<br/>*/}
              {(is_credit_enable == "1" && parseFloat(totalAmount) <= parseFloat(parseFloat(max_credit_amount) + parseFloat(balance))) || parseFloat(totalAmount) <= parseFloat(balance)
                ? (
                  filterType == "UPS" || filterType == "FedEx" ? (
                    <>
                      {/*<button style={{ float: "right",marginLeft:"10px" }} className={"btn btn-warning float-right"} title={"Click to proceed the order"} onClick={handleBulkProceed}>Bulk Proceed</button>*/}
                      <button
                        style={{ float: "right" }}
                        className={"btn btn-success float-right"}
                        title={"Click to proceed the order"}
                        onClick={handleProceed}
                      >
                        Proceed
                      </button>
                    </>
                  ) : (
                    <button
                      style={{ float: "right" }}
                      className={"btn btn-success float-right"}
                      title={"Click to proceed the order"}
                      onClick={handleProceed}
                    >
                      Proceed
                    </button>
                  )
                ) : (
                  <button
                    style={{ float: "right" }}
                    className={"btn btn-danger float-right"}
                    title={"You have insufficient balance to proceed this order."}
                  >
                    Insufficient Balance
                  </button>
                )}
              {is_credit_enable == "1" ? (
                <>
                  <h6>
                    Total Credit Amount: $
                    {amountFormat(parseFloat(max_credit_amount))}
                  </h6>
                  <h6>
                    Remaining Credit Amount: $
                    {balance > 0
                      ? amountFormat(parseFloat(max_credit_amount))
                      : amountFormat(
                        parseFloat(max_credit_amount) + parseFloat(balance)
                      )}
                  </h6>
                </>
              ) : null}
              <br />
              <br />
            </ModalBody>
          </div>
        </Modal>
        {/*Modal*/}

        {/*Import bulk orders modal*/}
        <ImportOrderModal type="bulk orders" isOpen={modal} heading="Import Bulk Orders"
          onClosed={() => {
            setSelectedMeasurement("pounds")
          }}
          toggle={() => {
            setModal(!modal)
          }}
          fileInputRef={fileInput}
          handleChange={handleChange}
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          setSelectedMeasurement={setSelectedMeasurement}
          selectedMeasurement={selectedMeasurement}
          setSelectAvailableCarrier={setSelectAvailableCarrier}
          selectAvailableCarrier={selectAvailableCarrier}
          selectedCarrierName={selectedCarrierName}
          carriers={carriers}
          handleCarrier={handleCarrier}
          selectedCarrier={selectedCarrier}
          setSelectAvailableService={setSelectAvailableService}
          selectAvailableService={selectAvailableService}
          selectedServiceName={selectedServiceName}
          services={services}
          handleService={handleService}
          selectedService={selectedService}
          handleSubmit={handleImportSubmit}
        />

        {/*Import bulk orders with items modal*/}
        <ImportOrderModal
          type="bulk orders with items"
          isOpen={modalItems}
          heading="Import Bulk Orders With Items"
          onClosed={() => {
            setSelectedMeasurement("pounds")
          }}
          toggle={() => {
            setModalItems(!modalItems)
          }}
          fileInputRef={fileInputItems}
          handleChange={handleChange}
          accept={".csv"}
          setSelectedMeasurement={setSelectedMeasurement}
          selectedMeasurement={selectedMeasurement}
          setSelectAvailableCarrier={setSelectAvailableCarrier}
          selectAvailableCarrier={selectAvailableCarrier}
          selectedCarrierName={selectedCarrierName}
          carriers={carriers}
          handleCarrier={handleCarrier}
          selectedCarrier={selectedCarrier}
          setSelectAvailableService={setSelectAvailableService}
          selectAvailableService={selectAvailableService}
          selectedServiceName={selectedServiceName}
          services={services}
          handleService={handleService}
          selectedService={selectedService}
          handleSubmit={handleImportWithItemsSubmit}
        />
        {/*Import bulk orders with items modal*/}

        {/*Verify orders modal*/}
        <Modal
          isOpen={verificationModal}
          role="dialog"
          autoFocus={true}
          centered={true}
          className="exampleModal modal"
          tabIndex="-1"
        // toggle={() => {
        //   if(selectedGroupBy) {
        //     handleGroupByOrders(selectedGroupBy).then(() => {
        //       setVerificationModal(!verificationModal)
        //       selectedOrders = []
        //     });
        //   }
        //   else {
        //     retrieveOrdersByFilterType().then(() => {
        //       setVerificationModal(!verificationModal)
        //       selectedOrders = []
        //     })
        //     }
        // }}
        >
          <div>
            <ModalHeader
            // toggle={() => {
            //   if(selectedGroupBy) {
            //     handleGroupByOrders(selectedGroupBy).then(() => {
            //       setVerificationModal(!verificationModal)
            //       selectedOrders = []
            //     });
            //   }
            //   else {
            //     retrieveOrdersByFilterType().then(() => {
            //       setVerificationModal(!verificationModal)
            //       selectedOrders = []
            //     })
            //     }
            // }}
            >
              Order Validation Report
            </ModalHeader>
            <ModalBody>
              <Alert color={"success"}>
                {selectedOrders.length} orders validation passed.
              </Alert>
              <hr />
              <h5 style={{ textAlign: "right" }}>
                Auto skip in{" "}
                <span className="text-success">
                  {valTime > 0 ? "0" + valTime.toString() : "..."}
                </span>
              </h5>
              <button
                className={"btn btn-warning"}
                id={"validate-btn"}
                style={{ float: "right", display: "none" }}
                // disabled={selectedOrders.length > 0 ? (valTime>0?true:false) : true}
                onClick={handleCalculatedPrice}
              >
                {/*{valTime>0?"Skip in " + valTime.toString():"Proceed To Checkout"}*/}
              </button>
              {/*<br />*/}
              {/*<br />*/}
            </ModalBody>
          </div>
        </Modal>
        {/*Verify orders modal*/}

        {/*Verify orders modal*/}
        <Modal
          isOpen={customVerificationModal}
          role="dialog"
          autoFocus={true}
          centered={true}
          className="exampleModal modal"
          tabIndex="-1"
          toggle={() => {
            if (selectedGroupBy) {
              handleGroupByOrders(selectedGroupBy).then(() => {
                setCustomVerificationModal(!customVerificationModal)
                selectedOrders = []
              })
            } else {
              retrieveOrdersByFilterType().then(() => {
                setCustomVerificationModal(!customVerificationModal)
                selectedOrders = []
              })
            }
          }}
        >
          <div>
            <ModalHeader
              toggle={() => {
                if (selectedGroupBy) {
                  handleGroupByOrders(selectedGroupBy).then(() => {
                    setCustomVerificationModal(!customVerificationModal)
                    selectedOrders = []
                  })
                } else {
                  retrieveOrdersByFilterType().then(() => {
                    setCustomVerificationModal(!customVerificationModal)
                    selectedOrders = []
                  })
                }
              }}
            >
              Order Validation Report
            </ModalHeader>
            <ModalBody>
              <Alert color={"success"}>
                {selectedOrders.length} orders validation passed.
              </Alert>
              <hr />
              <button
                className={"btn btn-warning"}
                style={{ float: "right" }}
                disabled={selectedOrders.length > 0 ? false : true}
                onClick={handleCustomCalculatedPrice}
              >
                Proceed To Checkout
              </button>
              <br />
              <br />
            </ModalBody>
          </div>
        </Modal>
        {/*Verify orders modal*/}

        {/*orders error modal*/}
        <Modal
          isOpen={orderErrorModal}
          role="dialog"
          autoFocus={true}
          centered={true}
          className="exampleModal modal"
          tabIndex="-1"
          toggle={() => {
            setOrderErrorModal(!orderErrorModal)
          }}
        >
          <div>
            <ModalHeader
              toggle={() => {
                setOrderErrorModal(!orderErrorModal)
              }}
            >
              Order Errors
            </ModalHeader>
            <ModalBody>
              <ul>
                {orderErrors != ""
                  ? orderErrors.split(",").map((part, id) => {
                    if (part && part != "null") {
                      return (
                        <li className={"text-danger"} key={id}>
                          {part}
                        </li>
                      )
                    }
                  })
                  : ""}
              </ul>
            </ModalBody>
          </div>
        </Modal>
        {/*orders error modal*/}

        {/*tag modal*/}
        <TagsModal
          isOpen={tagsModal}
          toggle={() => setTagsModal(!tagsModal)}
          onClosed={() => {
            setTag("")
            setTagColor("#e3e3e3")
            uncheckAllTags()
          }}
          handleCreateTag={handleCreateTag}
          handleUpdateTag={handleUpdateTag}
          handleDeleteTag={handleDeleteTag}
          tags={tags}
          tag={tag}
          setTag={setTag}
          tagColor={tagColor}
          setTagColor={setTagColor}
          selectedTags={selectedTags}
          handleTagCheck={handleTagCheck}
          getBrightness={getBrightness}
        />
        {/*tag modal*/}

        {/*from buyer note modal*/}

        {/* From Buyer Notes Modal */}
        <NotesModal isOpen={fromBuyerNotesModal} toggle={() => setFromBuyerNotesModal(!fromBuyerNotesModal)} onClosed={() => setNotesOrderId("")} handleUpdateNotes={handleUpdateNotes} notesValue={fromBuyerNotes} setNotesValue={setFromBuyerNotes} modalTitle="From Buyer Notes" placeholder="Enter From Buyer Notes" />

        {/*to buyer note modal*/}
        <NotesModal isOpen={toBuyerNotesModal} toggle={() => setToBuyerNotesModal(!toBuyerNotesModal)} onClosed={() => setNotesOrderId("")} handleUpdateNotes={handleUpdateNotes} notesValue={toBuyerNotes} setNotesValue={setToBuyerNotes} modalTitle="To Buyer Notes" placeholder="Enter To Buyer Notes" />

        {/*internal note modal*/}
        <NotesModal
          isOpen={internalNotesModal}
          toggle={() => {
            setInternalBuyerNotesModal(!internalNotesModal)
          }}
          onClosed={() => {
            setNotesOrderId("")
          }}
          handleUpdateNotes={handleUpdateNotes}
          notesValue={internalNotes}
          customerRef1={customerRef1}
          customerRef2={customerRef2}
          setNotesValue={setInternalBuyerNotes}
          setCustomerRef1={setCustomerRef1}
          setCustomerRef2={setCustomerRef2}
          modalTitle="Internal Notes"
          placeholder="Enter Internal Notes"
        />

        {/*Item name - product ids modal*/}
        <Modal
          isOpen={orderItemInfoModal}
          role="dialog"
          autoFocus={true}
          centered={true}
          className="exampleModal modal"
          tabIndex="-1"
          toggle={() => {
            setOrderItemInfoModal(!orderItemInfoModal)
            setOrderItemType("")
            setOrderItemData("")
          }}
        >
          <div>
            <ModalHeader
              toggle={() => {
                setOrderItemInfoModal(!orderItemInfoModal)
                setOrderItemType("")
                setOrderItemData("")
              }}
            >
              {orderItemType}
            </ModalHeader>
            <ModalBody>{orderItemData}</ModalBody>
          </div>
        </Modal>
        {/*Item name - product ids modal*/}

        {/*Duplicate order modal*/}
        <Modal
          isOpen={duplicateOrderModal}
          role="dialog"
          autoFocus={true}
          centered={true}
          className="exampleModal modal"
          tabIndex="-1"
          toggle={() => {
            setDuplicateOrderModal(!duplicateOrderModal)
            setDuplicateOrderId("")
            setDuplicateOrderUniqueId("")
            setDuplicateOrderCount("")
          }}
        >
          <div>
            <ModalHeader
              toggle={() => {
                setDuplicateOrderModal(!duplicateOrderModal)
                setDuplicateOrderId("")
                setDuplicateOrderUniqueId("")
                setDuplicateOrderCount("")
              }}
            >
              Duplicate Order {duplicateOrderUniqueId}
            </ModalHeader>
            <ModalBody>
              <form onSubmit={handleSubmitDuplicateOrder}>
                <Label className="form-label">Duplication Count</Label>
                <Input
                  name="duplication_count"
                  // value={name}
                  className="form-control"
                  onWheel={e => e.target.blur()}
                  placeholder="Enter Duplication Count"
                  type="number"
                  min="1"
                  max="15"
                  required={true}
                  onChange={e => setDuplicateOrderCount(e.target.value)}
                  value={duplicateOrderCount}
                />
                <br />
                <button
                  type={"submit"}
                  className={"btn btn-success btn-sm"}
                  style={{ float: "right" }}
                >
                  Save
                </button>
                <br />
              </form>
            </ModalBody>
          </div>
        </Modal>
        {/*Duplicate order modal*/}

        <OrderViewModal orderId={rowId} viewModal={viewModal} setViewModal={data => { setViewModal(data) }} />
      </div>

    </div>
  )
}
AwaitingShipment.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default AwaitingShipment
