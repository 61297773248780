import React from "react"
import { useRef } from "react"
import { useEffect } from "react"
import { useState } from "react"
import { get, post } from "../../helpers/api_helper"
import moment from "moment-timezone"
import { Link } from "react-router-dom"
import ebay from "../../assets/images/integrations/ebay.svg"
import walmart from "../../assets/images/integrations/walmart.svg"
import shopify from "../../assets/images/integrations/shopify.svg"
import walmart_icon from "../../assets/images/integrations/walmart-icon-yellow.png"
import default_store from "../../assets/images/integrations/default-store.png"
import zenventory from "../../assets/images/integrations/1651688363447.jfif"
import sellercloudSmall from "../../assets/images/integrations/sellercloud-small.png"
import shipstationSmall from "../../assets/images/integrations/shipstation-small.png"
import veeqoSmall from "../../assets/images/integrations/veeqo-small.png"
import { socket } from "../../assets/js/initializeSocket"
import shipheroSmall from "../../assets/images/integrations/shiphero-small.png"
import smallOrderDesk from "../../assets/images/integrations/smallOrderDesk.png"

const SiderBarStoreDropDown = props => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  }
  const [showDropDown, setShowDropDown] = useState(false)
  const [stores, setStores] = useState([])
  const [currentStore, setCurrentStore] = useState([])

  const [parseStr] = useState(JSON.parse(localStorage.getItem("authUser")))

  const dropdownRef = useRef(null)

  const retrieveStores = async () => {
    try {
      let body = { user_id: JSON.parse(localStorage.getItem("authUser")).role_id == 4 ? JSON.parse(localStorage.getItem("authUser")).parent_id : JSON.parse(localStorage.getItem("authUser")).id };

      const response = await post("/user/getStores", body, { headers });
      let arr = [];

      const bodyForManual = { id:JSON.parse(localStorage.getItem("authUser")).role_id == 4 ? JSON.parse(localStorage.getItem("authUser")).parent_id : JSON.parse(localStorage.getItem("authUser")).id, };

      const responseAdd = await post("/user/get-ship-from-address", bodyForManual, { headers });

      if (responseAdd.status === 200) {
        if (responseAdd.data.length > 0) {
          JSON.parse(localStorage.getItem("authUser")).role_id != 4 && arr.push({ id: 0, store_id: null, store_name: responseAdd.data[0].name, last_synced: null, store_type: "manual_store", });
        }
        else {
          JSON.parse(localStorage.getItem("authUser")).role_id != 4 && arr.push({ id: 0, store_id: null, store_name: "Manual Store", last_synced: null, store_type: "manual_store", });
        }
      }

      // Add stores from the user's response
      for (let i = 0; i < response.data.length; i++) {
        if (parseStr.role_id == 4) {
          await get('/admin/get-sub-user-by-id/' + JSON.parse(localStorage.getItem("authUser")).id, { headers })
            .then((re) => {
              if (re.data.access_store.includes(response.data[i].id)) {
                if(re.data.access_store.includes(0)){
                  arr.push({ id: 0, store_id: null, store_name: responseAdd.data[0].name, last_synced: null, store_type: "manual_store", })
                }
                if(true){
                  arr.push({ id: response.data[i].id, store_id: response.data[i].id, store_name: response.data[i].name, last_synced: response.data[i].last_synced, store_type: response.data[i].store_type, });
                }
              }
              else{
                if(re.data.access_store.includes(0)){
                  arr.push({ id: 0, store_id: null, store_name: responseAdd.data[0].name, last_synced: null, store_type: "manual_store", })
                }
              }
            });
        }
        else {
          arr.push({ id: response.data[i].id, store_id: response.data[i].id, store_name: response.data[i].name, last_synced: response.data[i].last_synced, store_type: response.data[i].store_type, });
        }
      }

      const uniqueStores = arr.filter((store, index, self) => index === self.findIndex((s) => (JSON.stringify(s.id) === JSON.stringify(store.id))));
      console.log(uniqueStores,'uniqueStores')
      let allStore;
      if(uniqueStores.length>1){
        allStore = {id: uniqueStores.map(store => store.id), store_id: uniqueStores.map(store => store.id), store_name: "All Store", last_synced: null, store_type: "all_store",};
      }


      if(allStore){
        uniqueStores.push(allStore);
      }



      setStores(uniqueStores);
      // setStores(arr);

      const intr = setInterval(() => {
        if (arr.length > 0) {
          JSON.parse(localStorage.getItem("authUser")).role_id == 4 ? setCurrentStore(uniqueStores.filter(el => el.id == localStorage.getItem("store"))[0]) : setCurrentStore(uniqueStores.filter(el => el.id == localStorage.getItem("store"))[0]);
          clearInterval(intr);
        }
      }, 100);
    } catch (e) {
      // Handle error if needed
      console.error(e);
    }
  };

  useEffect(() => {
    const handleBodyClick = event => {
      // Check if the clicked element is outside the dropdown
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropDown(false)
      }
    }

    retrieveStores()

    document.body.addEventListener("click", handleBodyClick)

    return () => {
      document.body.removeEventListener("click", handleBodyClick)
    }
  }, [])

  useEffect(() => {
    async function storeConnected() {
      // console.log("emit storeConnected")
      await retrieveStores()
      // console.log("emit storeConnected 1")
    }
    async function storeUpdated() {
      console.log("emit storeUpdated")
      await retrieveStores()
      // console.log("emit storeConnected 1")
    }
    async function storeDisconnected() {
      // console.log("emit storeDisconnected")
      await retrieveStores()
    }

    socket.on("storeConnected", storeConnected)
    socket.on("storeUpdated", storeUpdated)
    socket.on("storeDisconnected", storeDisconnected)

    return () => {
      socket.off("storeConnected", storeConnected)
      socket.off("storeUpdated", storeUpdated)
      socket.off("storeDisconnected", storeDisconnected)
    }
  }, [])

  const handleStoreSelect = val => {
    try {
      console.log(val,'val')
      setCurrentStore(val)
      localStorage.setItem("store", val.id)
      document.getElementById("main-drop").click()
      if (document.title === "Awaiting Shipment | NullShip") {
        document.body.style.display = "none"
        // document.getElementById("dsh").click();
        // console.log("currentStore.store_name ",val.store_name)
        if (val.store_type === "manual_store") {
          localStorage.removeItem("order-filter")
          get("/admin/get-carriers", { headers }).then(response => {
            // console.log("carriers", response.data);
            if (response.data.length > 0) {
              // console.log("response.data.filter(el => el.is_active == 1)[0]",response.data.filter(el => el.is_active == 1)[0]);
              localStorage.setItem(
                "order-filter",
                response.data.filter(el => el.is_active == 1)[0].name
              )
            }
            window.location = "/orders/awaiting-shipment"
          })
        } else {
          window.location = "/orders/awaiting-shipment"
        }
      } else if (document.title === "Completed Orders | NullShip") {
        document.body.style.display = "none"
        if (val.store_type === "manual_store") {
          localStorage.removeItem("order-filter")
          get("/admin/get-carriers", { headers }).then(response => {
            // console.log("carriers", response.data);
            if (response.data.length > 0) {
              // console.log("response.data.filter(el => el.is_active == 1)[0]",response.data.filter(el => el.is_active == 1)[0]);
              localStorage.setItem(
                "order-filter",
                response.data.filter(el => el.is_active == 1)[0].name
              )
            }
            window.location = "/orders/completed"
          })
        } else {
          window.location = "/orders/completed"
        }
      }
    } catch (e) {
      console.log("error", e)
    }
  }

  useEffect(() => {

    async function storeUpdated() {
      const fetchUserData = async () => {
        let res = await get('/admin/get-sub-user-by-id/' + JSON.parse(localStorage.getItem("authUser")).id, { headers })
        console.log(res.data, 'res of fetch user data in socket')
        if (res.data.role_id == 4) {
          console.log(JSON.parse(res?.data?.access_store).length,'JSON.parse(res?.data?.access_store).length')
          if (JSON.parse(res?.data?.access_store).length > 0) {
            localStorage.setItem("store", JSON.parse(res?.data?.access_store)[0])
            window.location.reload()
          }
          else {
            localStorage.removeItem("store")
            window.location.href = "/dashboard"
          }
        }
      }
      fetchUserData();
      retrieveStores();
    }

    socket.on("subUserStoreDisconnected", storeUpdated)

    return () => {
      socket.off("subUserStoreDisconnected", storeUpdated)
    }
  }, [])

  return currentStore ? (
    <>
      <Link to={"/orders/awaiting-shipment"} id={"dsh"} style={{ display: "none" }} ></Link>
      <li id={"store-nav"} style={{backgroundColor: "#1f1f1f",width: "85%",margin: "auto",marginBottom: "5px",borderRadius: "5px",}} >
        <Link id={"main-drop"} to="/#" className="has-arrow" style={{ display: "flex", lineHeight: "30px", paddingLeft: "16px", paddingRight: "10px", fontSize: "15px", borderBottom: "2px solid #0f0f0f", color: "white",}}>
          <img
            src={
              currentStore.store_type == "shopify"
                ? shopify
                : currentStore.store_type == "ebay"
                  ? ebay
                  : currentStore.store_type == "walmart"
                    ? walmart_icon
                    : currentStore.store_type == "zenventory"
                      ? zenventory
                      : currentStore.store_type == "sellercloud"
                        ? sellercloudSmall
                        : currentStore.store_type == "shipstation"
                          ? shipstationSmall
                          : currentStore.store_type == "veeqo"
                            ? veeqoSmall
                            : currentStore.store_type == "shiphero"
                              ? shipheroSmall
                              : currentStore.store_type == "orderdesk"
                                ? smallOrderDesk
                                : default_store
            }
            width={"25px"}
            height={"25px"}
            style={{ marginRight: "5px", marginTop: "2px" }}
            alt={"img"}
          />
          {/*<i style={{ fontSize: "20px",marginTop:"5px",color:"white" }} className={currentStore.id != 0? (currentStore.store_type =="walmart"?"bx bx-store":"bx bxl-"+currentStore.store_type):"bx bx-"+currentStore.store_type}></i>*/}
          <span
            style={{
              width: "160px",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {currentStore.store_name}
          </span>
        </Link>
        <ul className="sub-menu pb-2 store-dropdown" style={{ maxHeight: "200px", overflowY: "auto" }}>
          {stores.map((val, index) => {
            return (
              <li key={index}>
                <Link
                  onClick={() => {
                    val.id != currentStore.id ? handleStoreSelect(val) : ""
                  }}
                  title={val.store_name}
                  to={"#"}
                  style={{ display: "flex", paddingLeft: "20px" }}
                >
                  <img
                    alt={"img"}
                    src={
                      val.store_type === "shopify"
                        ? shopify
                        : val.store_type === "ebay"
                          ? ebay
                          : val.store_type === "walmart"
                            ? walmart_icon
                            : val.store_type === "zenventory"
                              ? zenventory
                              : val.store_type === "sellercloud"
                                ? sellercloudSmall
                                : val.store_type === "shipstation"
                                  ? shipstationSmall
                                  : val.store_type === "veeqo"
                                    ? veeqoSmall
                                    : val.store_type === "shiphero"
                                      ? shipheroSmall
                                      : val.store_type == "orderdesk"
                                        ? smallOrderDesk
                                        : default_store
                    }
                    width={"20px"}
                    height={"20px"}
                    style={{ marginRight: "5px" }}
                  />
                  {/*<i style={{ fontSize: "18px",color:(val.store_name == currentStore.store_name?"white":"#79829c") }} className={val.id != 0? (val.store_type =="walmart"?"bx bx-store":"bx bxl-"+val.store_type):"bx bx-"+val.store_type}></i>*/}
                  <span
                    style={{
                      width: "150px",
                      marginTop: "2px",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      color: val.id == currentStore.id ? "white" : "#79829c",
                    }}
                  >
                    {val.store_name}
                  </span>
                </Link>
              </li>
            )
          })}
        </ul>
      </li>
    </>
  ) : null
}

export default SiderBarStoreDropDown
