import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
} from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

import avatar from "../../assets/images/users/avatar-1.jpg";
// actions
import { editProfile, resetProfileFlag } from "../../store/actions";
import { post, get } from "../../helpers/api_helper"
import { toast } from "react-hot-toast"

import ShipFromAddress from "./ship-from-address";
import { amountFormat } from "../../assets/js/numberFormatter"

const UserProfile = () => {

  //meta title
  document.title = "Profile | NullShip";

  const dispatch = useDispatch();

  const [email, setemail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [id, setId] = useState(1);
  const [balance, setBalance] = useState("-");

  const [skipValidation, setSkipValidation] = useState(JSON.parse(localStorage.getItem("authUser")).skip_proceed_screen)
  const [autoDownload, setAutoDownload] = useState(JSON.parse(localStorage.getItem("authUser")).auto_download)


  const [responseSuccess, setResponseSuccess] = useState(null);
  const [responseError, setResponseError] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");


  const [PWFocus, setPWFocus] = useState(false);
  const [passValid, setPassValid] = useState(null);
  const [confirmValid, setConfirmValid] = useState(true);
  const [firstCondition, setFirstCondition] = useState(false);
  const [secondCondition, setSecondCondition] = useState(false);
  const [thirdCondition, setThirdCondition] = useState(false);
  const [forthCondition, setForthCondition] = useState(false);

  const [currentPassword, setCurrentPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const formRef = useRef();
  const { error, success } = useSelector(state => ({
    error: state.Profile.error,
    success: state.Profile.success,
  }));

  const headers = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${localStorage.getItem("token")}`,
  };

  const getUserBalance = async () => {
    const body = {
      id: id
    };
    await post('/user/get-balance', body, { headers }).then((response) => {
      // console.log("response", response);
      if (response.status === 200) {
        localStorage.setItem("balance", response.balance);
        setBalance(response.balance);
      }
      else {
        // setResponseSuccess(false);
        // setResponseMessage(response.message);
      }
    })
  }

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      setFirstName(obj.first_name);
      setLastName(obj.last_name);
      setemail(obj.email);
      setId(obj.id);

      getUserBalance();
      setTimeout(() => {
        dispatch(resetProfileFlag());
      }, 3000);
    }
  }, [dispatch, success, balance]);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      firstName: firstName || '',
      lastName: lastName || '',
      id: id || '',
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("Please Enter Your First Name"),
      lastName: Yup.string().required("Please Enter Your Last Name")
    }),
    onSubmit: (values) => {
      dispatch(handleNameUpdate(values));
    }
  });


  const handleNameUpdate = async (values) => {
    // console.log("values",values);
    const { id, firstName, lastName } = values;
    await post('/user/update-profile', values, { headers }).then((response) => {
      // console.log("response", response);
      if (response.status === 200) {
        setFirstName(response.user.first_name);
        setLastName(response.user.last_name);
        localStorage.removeItem("authUser");
        localStorage.setItem("authUser", JSON.stringify(response.user));
        toast.success(response.message);
      }
      else {
        // setResponseSuccess(false);
        // setResponseMessage(response.message);
        toast.error(response.message);
      }
    })
  }

  const handleAutoDownloadUpdate = async (val) => {
    console.log(val)
    let body = { user_id: JSON.parse(localStorage.getItem("authUser")).id, val: val };
    try {
      let update = await post(`/user/update/auto-download`, body, { headers });
      if (update) {
        if(val==1){
          toast.success("Auto Download Added ")
          localStorage.removeItem("authUser");
          localStorage.setItem("authUser", JSON.stringify(update.data[0]));
          setAutoDownload(update.data[0]?.auto_download)

        }
        else{
          toast.success("Auto Download Removed ")
          localStorage.removeItem("authUser");
          localStorage.setItem("authUser", JSON.stringify(update.data[0]));
          setAutoDownload(update.data[0]?.auto_download)
        }
      }
    }
    catch (error) {
      console.error("Error updating store access:", error);
    }
  }

  const handleSkipValidationUpdate = async (val) => {
    let body = { user_id: JSON.parse(localStorage.getItem("authUser")).id, val: val };
    try {
      let update = await post(`/user/update/skip_validation`, body, { headers });
      if (update) {
        if(val==1){
          toast.success("Skip Validation Added ")
          localStorage.removeItem("authUser");
          setSkipValidation(update.data[0]?.skip_proceed_screen)
          localStorage.setItem("authUser", JSON.stringify(update.data[0]));
        }
        else{
          toast.success("Skip Validation Removed ")
          localStorage.removeItem("authUser");
          setSkipValidation(update.data[0]?.skip_proceed_screen)
          localStorage.setItem("authUser", JSON.stringify(update.data[0]));
        }
      }
    }
    catch (error) {
      console.error("Error updating store access:", error);
    }
  }


  function hasLowerCase(str) {
    return (/[a-z]/.test(str));
  }

  function hasUpperCase(str) {
    return (/[A-Z]/.test(str));
  }

  function hasNumber(str) {
    return (/[0-9]/.test(str));
  }

  function hasSpecial(str) {
    let format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    return (format.test(str));
  }

  const handleFocus = () => {
    // console.log("setPWFocus true")
    setPWFocus(true);
  }

  const handleFocusOut = () => {
    // console.log("setPWFocus false")
    setPWFocus(false);
  }

  const handlePassword = (e) => {
    setPassword(e.target.value);
    //// console.log("e.target.value.length",e.target.value.length);
    if (e.target.value.length >= 8) {
      setFirstCondition(true);
      // //// console.log(firstCondition)
    }
    else {
      setFirstCondition(false);
    }

    if (hasUpperCase(e.target.value) && hasLowerCase(e.target.value)) {
      setSecondCondition(true);
    }
    else {
      setSecondCondition(false);
    }

    if (hasNumber(e.target.value)) {
      setThirdCondition(true);
    }
    else {
      setThirdCondition(false);
    }


    if (hasSpecial(e.target.value)) {
      setForthCondition(true);
    }
    else {
      setForthCondition(false);
    }

    if (e.target.value.length >= 7 && hasUpperCase(e.target.value) && hasLowerCase(e.target.value) && hasNumber(e.target.value) && hasSpecial(e.target.value)) {
      setPassValid(true);
    }
    else {
      setPassValid(false);
    }

    if (confirmPassword === e.target.value) {
      setConfirmValid(true);
    }
    else {
      setConfirmValid(false);
    }
  }

  const handleConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
    if (password === e.target.value) {
      setConfirmValid(true);
    }
    else {
      setConfirmValid(false);
    }
  }

  const handlePasswordUpdate = async () => {
    if (password === confirmPassword) {
      let body = {
        id: id,
        currentPassword: currentPassword
      };
      await post('/user/check-password', body, { headers }).then(async (response) => {
        if (response.status === 200) {
          let payload = {
            id: id,
            newPassword: password
          };
          await post('/user/update-password', payload, { headers }).then((responseUpdate) => {
            setCurrentPassword("");
            setPassword("");
            setConfirmPassword("");
            if (responseUpdate.status === 200) {
              toast.success(responseUpdate.message);
            }
            else {
              toast.error(responseUpdate.message);
            }
          });
        }
        else {
          toast.error("Current password do not match");
        }
      });
    }
    else {
      toast.error("New password and confirm password do not match");
    }
  }


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="NullShip" breadcrumbItem="Profile" />

          <Row>
            <Col lg="12">
              {responseMessage && !responseSuccess ? <Alert color="danger">{responseMessage}</Alert> : null}
              {responseSuccess ? <Alert color="success">{responseMessage}</Alert> : null}

              <Card>
                <CardBody>
                  <div className="d-flex">

                    <div className="ms-3">
                      <img src={avatar} alt="" className="avatar-md rounded-circle img-thumbnail" />
                    </div>

                    <div className="flex-grow-1 align-self-center" style={{ marginLeft: "10px" }}>

                      <div className="text-muted">
                        <h5>{firstName + " " + lastName}</h5>
                        <p className="mb-1">{email}</p>
                        <p className="mb-0">Id no: #{id}</p>
                      </div>

                    </div>

                    {
                      JSON.parse(localStorage.getItem('authUser')).role_id === 2 ?
                        <div className={"desktop-balance"}>
                          <h4>Balance<br /><span style={{ fontSize: "3rem" }}>${amountFormat(balance)}</span></h4>
                        </div> : ""
                    }

                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Card className={"mobile-balance"}>

            <CardBody>
              { JSON.parse(localStorage.getItem('authUser')).role_id === 2 ? <div> <h4>Balance<hr /><span style={{ fontSize: "3rem" }}>${amountFormat(balance)}</span></h4> </div> : "" }
            </CardBody>

          </Card>


          <div className="row">

            <div className="col-md-3">
              <h4 className="card-title mb-4">Update User Name</h4>
              <Card>

                <CardBody>

                  <Form className="form-horizontal" onSubmit={(e) => {e.preventDefault();validation.handleSubmit();return false;}}>
                    <div className={"row"}>
                      <div className={"col-md-6"}>
                        <div className="form-group">
                          <Label className="form-label">First Name <span style={{ color: "red" }}>*</span></Label>
                          <Input name="firstName" className="form-control" placeholder="Enter First Name" type="text" onChange={validation.handleChange} onBlur={validation.handleBlur} value={validation.values.firstName || ""} invalid={validation.touched.firstName && validation.errors.firstName ? true : false} required={true}/>
                          {validation.touched.firstName && validation.errors.firstName ? ( <FormFeedback type="invalid">{validation.errors.firstName}</FormFeedback> ) : null}
                        </div>
                      </div>
                      <div className={"col-md-6"}>
                        <div className="form-group">
                          <Label className="form-label">Last Name <span style={{ color: "red" }}>*</span></Label>
                          <Input name="lastName" className="form-control" placeholder="Enter First Name" type="text" onChange={validation.handleChange} onBlur={validation.handleBlur} value={validation.values.lastName || ""} invalid={ validation.touched.lastName && validation.errors.lastName ? true : false } required={true}/>
                          {validation.touched.lastName && validation.errors.lastName ? ( <FormFeedback type="invalid">{validation.errors.lastName}</FormFeedback> ) : null}
                          <Input name="idx" value={id} type="hidden" />
                        </div>
                      </div>
                    </div>

                    <div className="mt-3" style={{ display: "flex", justifyContent: "right" }}>
                      <Button type="submit" color="success">Save</Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </div>

            <div className="col-md-6">
              <h4 className="card-title mb-4">Change Password</h4>
              <Card>
                <CardBody>
                  <Form className="form-horizontal" ref={formRef} onSubmit={async (e) => {e.preventDefault();await handlePasswordUpdate();return false;}}>
                    <div className={"row"}>
                      <div className={"col-md-4"}>
                        <div className="form-group">
                          <Label className="form-label">Current Password <span style={{ color: "red" }}>*</span></Label>
                          <Input name="currentPassword" className="form-control" placeholder="Enter Current Password" type="password" onChange={(e) => setCurrentPassword(e.target.value)} value={currentPassword} required={true}/>
                        </div>
                      </div>
                      <div className={"col-md-4"}>
                        <div className="form-group" style={{ position: "relative" }}>
                          <Label className="form-label">New Password <span style={{ color: "red" }}>*</span></Label>
                          <Input name="newPassword" className="form-control" placeholder="Enter New Password" type="password" value={password} pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{7,20}$" onChange={handlePassword} onFocus={handleFocus} onBlur={handleFocusOut} required={true}/>
                          <span style={{ color: "red", fontSize: "11px" }}>{passValid == null || passValid == true ? "" : "Please fulfill the password requirements"}</span>
                          <div className={"bg-white"} style={{ display: (PWFocus ? "block" : "none"), position: "absolute", bottom: "-150px", backgroundColor: "red", right: "0", paddingRight: "30px", paddingLeft: "30px", borderRadius: "10px", border: "1px solid #333", zIndex: "99999" }}>
                            <p style={{ fontWeight: "600" }}>Password Requirements</p>
                            <ul id="ul" style={{ fontSize: "12px", listStyleType: "none", marginLeft: "-50px" }}>
                              <li className={firstCondition ? "conditionPass" : "conditionFail"}>At least 8 characters.</li>
                              <li className={secondCondition ? "conditionPass" : "conditionFail"}>Contains uppercase and lowercase letters.</li>
                              <li className={thirdCondition ? "conditionPass" : "conditionFail"}>Contains numbers.</li>
                              <li className={forthCondition ? "conditionPass" : "conditionFail"}>Contains at least one special character, <br />e.g. _ ! @ # ? ]</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className={"col-md-4"}>
                        <div className="form-group">
                          <Label className="form-label">Confirm Password <span style={{ color: "red" }}>*</span></Label>
                          <Input name="confirmPassword" className="form-control" placeholder="Confirm Password" type="password" onChange={handleConfirmPassword} value={confirmPassword} required={true} disabled={password ? false : true}/>
                          <span style={{ color: "red", fontSize: "11px" }}>{confirmValid ? "" : "Password do not match"}</span>
                        </div>
                      </div>
                    </div>



                    <div className="mt-3" style={{ display: "flex", justifyContent: "right" }}>
                      <Button type="submit" color="success" disabled={(confirmValid ? (passValid ? false : true): true)}>Update Password</Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </div>

            <div className="col-md-3">

              <h4 className="card-title mb-4">Update Views</h4>

              <Card>

                <div style={{ padding: 10 }}>

                  <Label className="form-label">Auto Donwload Label</Label>
                  <select onChange={(e) => handleAutoDownloadUpdate(e.target.value)} className={"form-control"} value={autoDownload== 1 ? 1 : 0}>
                    <option value={1}>Granted</option>
                    <option value={0}>Revoked</option>
                  </select>

                  <Label className="form-label" style={{ marginTop: 5 }}>Skip Validation Screen</Label>
                  <select onChange={(e) => handleSkipValidationUpdate(e.target.value)} className={"form-control"} value={skipValidation == 1 ? 1 : 0}>
                    <option value={1}>Granted</option>
                    <option value={0}>Revoked</option>
                  </select>

                </div>

              </Card>

            </div>

          </div>








          {/*we comment this code be we implement manual store and update these details on store page*/}
          {/*{JSON.parse(localStorage.getItem("authUser")).role_id === 2 ? <ShipFromAddress/> : ""}*/}

        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(UserProfile);
