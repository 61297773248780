
const customStyles = {
  headCells: {
    style: {
      border:"1px solid #F6F6FA",
      background:"#F6F6FA",
    },
  },
  rows: {
    style: {
      minHeight: '35px',
      maxHeight: '35px',
      // overflowY:"hidden"
    },
  },
  // pagination: {
  //   style: {
  //     position: 'fixed',
  //     bottom:'0.6rem',
  //     zIndex:1,
  //     border:"none",
  //     width:"max-content",
  //     marginLeft:"3rem",
  //     marginRight:"3rem",
  //   },
  // },
};
export default customStyles;