module.exports = {
    google: {
        API_KEY: "",
        CLIENT_ID: "23144678283-oek7ncjmmrgkgmi2i56sc411gp71a8sp.apps.googleusercontent.com",
        SECRET: "",
    },
    facebook: {
        APP_ID: "",
    },
    recaptcha: {
        SITE_KEY: "6LfxtyElAAAAAF2u_4uGCn1ZxC-fCLeqdnPElpVL",
        V2_SITE_KEY: "6Ld7syElAAAAAC6_7A_1pxJZdbvqEM7kEq7DaHDq"
    },
    tracking_url: {
        URL: "https://wwwapps.ups.com/WebTracking/track?Loc=en_US&track.x=Track&trackNums="
    },
    paypal: {
        // CLIENT_ID: "AQTEP5iWBs6lqBuYHRitEmCeNMWGF_wxj_7VzIxfvCVgKZPDBNkY7ujMQmk-WkqqlBsI3LKoxrysl79b"
        // CLIENT_ID: "AcCIHe9smVUUnD_sttE807A2MEPOoLo7s3-Rr1g1Sse-YTTdjOhvzFFjK7eSgBqXUU-M_vBeQUODnboo"
        CLIENT_ID: "AUi4z_EMySPuEx5XxDlHWQW2NRoLwV_-Ad-Hv-MZago_5FARjOQ9YLx88GDP7cMxnG5EuED2cg3QoYdh"
    },
    coinbase: {
        CHECKOUT_ID: ""
    },
    aftership: {
        API_KEY: 'asat_b6605998b5854da7bbcefdc668a70341'
    },
    main_web: {
        URL: 'https://nullship.gg'
    },
    site: {
        // URL: 'http://localhost:3000',
        // BASE_URL: 'http://localhost:3000/api',
        // BASE_URL_FOR_SOCKET: 'http://localhost:3000/',
        // BASE_URL_FOR_IMAGES: 'http://localhost:3000/',
        // MODE: 'development',

        // URL: 'https://beta.nullship.gg',
        // BASE_URL: 'https://beta.nullship.gg/apis/api',
        // BASE_URL_FOR_SOCKET: 'https://beta.nullship.gg/',
        // BASE_URL_FOR_IMAGES: 'https://beta.nullship.gg/apis/',
        // MODE: 'development',

        URL: 'https://login.nullship.gg',
        BASE_URL: 'https://login.nullship.gg/apis/api',
        BASE_URL_FOR_SOCKET: 'https://login.nullship.gg/',
        BASE_URL_FOR_IMAGES: 'https://login.nullship.gg/apis/',
        MODE: 'production'
    },
    eBay: {
        SANDBOX: {
            clientId: "Brittany-NullShip-SBX-742a55cd8-72c715ae",
            clientSecret: "SBX-42a55cd8a04e-c24e-41ca-8b06-97f7",
            devid: "2b13f164-ea11-4b1b-a7be-2d05704b899f",
            redirectUri: "Brittany_Board-Brittany-NullSh-rxedzdra",
            baseUrl: "https://api.sandbox.ebay.com",
            authUrl: "https://auth.sandbox.ebay.com/oauth2/authorize",
        },
        PRODUCTION: {
            clientId: "Brittany-NullShip-PRD-c428ece0e-845982c0",
            clientSecret: "PRD-428ece0e887a-dfa2-4fe2-9e30-a7d5",
            devid: "2b13f164-ea11-4b1b-a7be-2d05704b899f",
            redirectUri: "Brittany_Board-Brittany-NullSh-ihjrulmh",
            baseUrl: "https://api.ebay.com",
            authUrl: "https://auth.ebay.com/oauth2/authorize",
        },
    },
    stripe: {
        STRIPE_PUB_KEY: "pk_live_51O2GNEBrHaLB6wYStdGw745umcL31bsHMNo7ykTURxWNYHDNzlsJDMZyAO9O0JjiE6GR8TFElKLi6Jdq54cSP7OQ00SF7eUzkM"
    }
}
