import React from "react"
import {
  Page,
  Text,
  View,
  Document,
  Line,
  StyleSheet,
} from "@react-pdf/renderer"
const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "white",
  },
  section: {
    margin: 20,
    padding: 20,
    flexGrow: 1,
  },
  detailsText: {
    left: "20px",
    fontSize: "10px",
    fontWeight: 400,
    paddingTop: "3px",
    color: "black",
  },
  tags: {
    fontSize: "10px",
    fontWeight: "bold",
    color: "black",
    marginTop: "10px",
    direction: "rtl",
  },
  mergedSlipDetailsText: {
    left: "20px",
    fontSize: "9px",
    fontWeight: 400,
    paddingTop: "3px",
    color: "black",
  },
})
const tableStyles = StyleSheet.create({
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableEmptyRow: {
    margin: "auto",
    width: "100%",
    flexDirection: "row",
    height: "20px",
    borderStyle: "solid",
    borderWidth: 1,
    borderTopWidth: 0,
    borderBottomWidth: 0,
  },
  tableEmptyLastRow: {
    margin: "auto",
    width: "100%",
    flexDirection: "row",
    height: "20px",
    borderStyle: "solid",
    borderWidth: 1,
    borderTopWidth: 0,
  },
  tableHeaderSKUCol: {
    width: "20%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: "#d3d9de",
  },
  tableHeaderItemCol: {
    width: "47%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: "#d3d9de",
  },
  tableHeaderQuantityCol: {
    width: "18%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: "#d3d9de",
  },
  tableHeaderReturnCodeCol: {
    width: "15%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: "#d3d9de",
  },
  tableHeader: {
    margin: "auto",
    marginTop: 5,
    fontSize: 7,
    backgroundColor: "#d3d9de",
  },
  tableSKUCol: {
    width: "20%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableItemCol: {
    width: "47%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableQuantityCol: {
    width: "18%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableReturnCol: {
    width: "15%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCell: {
    margin: "auto",
    marginTop: 5,
    fontSize: 7,
  },
  tableLeftCell: {
    marginTop: 5,
    fontSize: 7,
    marginLeft: "5px",
  },
  centerTextHeading: {
    fontSize: 7,
    textAlign: "center",
    width: "100%",
    marginTop: "10px",
  },
  centerText: {
    fontSize: 7,
    textAlign: "center",
    width: "100%",
  },
})
export const PackingSlip = ({pc_from_name,pc_from_street1,pc_from_street2,pc_from_city,pc_from_state,pc_from_zip,pc_from_country,pc_to_street1,pc_to_street2,pc_to_city,pc_to_state,pc_to_zip,pc_to_country,pc_order_number,pc_order_date,pc_to_name,pc_items,pc_sub_total,pc_total,}) => {

  return (
    <Document>
      <Page size={[384, 520]} style={styles.page}>
        <View>
          <View>
            <Text style={{left: "150px",fontSize: "16px",marginTop: "10px",fontWeight: 900,color: "black",}}>Packing Slip</Text>
          </View>
          <View style={{marginTop: "15px",}}>
            <Text style={styles.detailsText}>{pc_from_name}</Text>
            <Text style={styles.detailsText}>{pc_from_street1}</Text>
            {pc_from_street2 ? ( <Text style={styles.detailsText}>{pc_from_street2}</Text>) : null}
            <Text style={styles.detailsText}>{pc_from_city},</Text>
            <Text style={styles.detailsText}>{pc_from_state} {pc_from_zip} {pc_from_country}</Text>
          </View>

          <View style={{marginTop: "20px",display: "flex",flexDirection: "row",justifyContent: "space-between",}}>
            <View style={{display: "flex",flexDirection: "row",width: "50%",left: "20px",}}>
              <Text style={{fontSize: "10px",fontWeight: "bold",color: "black",marginRight: "10px",}}>Ship To:</Text>
              <Text style={{fontSize: "10px",fontWeight: 400,marginLeft: "10px",width: "117px",color: "black",}}>
                {pc_to_street1}
                {pc_to_street2 ? " " + pc_to_street2 : null} {pc_to_city},{" "}
                {pc_to_state} {pc_to_zip} {pc_to_country}
              </Text>
            </View>
            <View style={{width: "100%",marginLeft: "80px",gap: "6px",display: "flex",flexDirection: "row",}}>
              <View>
                <Text style={styles.tags}>Order #</Text>
                <Text style={styles.tags}>Date</Text>
                <Text style={styles.tags}>User</Text>
                <Text style={styles.tags}>Ship Date</Text>
              </View>
              <Line style={{width: "2px",height: "80px",borderLeftWidth: 1,borderLeftColor: "black",}}></Line>
              <View style={{width: "140px",}}>
                <Text style={{fontSize: "10px",color: "black",}}>{pc_order_number}</Text>
                <Text style={{fontSize: "10px",color: "black",marginTop: "10px",}}>{pc_order_date}</Text>
                <Text style={{fontSize: "10px",color: "black",marginTop: "13px",}}> {pc_to_name}</Text>
                <Text style={{fontSize: "10px",color: "black",marginTop: "13px",}}>-</Text>
              </View>
            </View>
          </View>
          <View style={{ width: "98%", marginHorizontal: "auto" }}>
            <View style={{display: "flex",flexDirection: "row",justifyContent: "space-evenly",padding: "10px",width: "100%",marginTop: "10px",}}>
              <View style={{padding: "6px 0px 0px 5px",border: "1px 0px 1px 1px solid black",width: "25%",}}>
                <Text style={{fontSize: 10,}}>Item</Text>
              </View>
              <View style={{padding: "6px 0px 0px 5px",border: "1px 0px 1px 1px solid black",width: "25%",}}>
                <Text style={{fontSize: 10,}}>SKU</Text>
              </View>
              <View style={{padding: "6px 0px 0px 5px",border: "1px 0px 1px 1px solid black",width: "30%",}}>
                <Text style={{fontSize: 10,}}>Description</Text>
              </View>
              <View style={{padding: "6px 0px 0px 5px",border: "1px 0px 1px 1px solid black",width: "15%",}}>
                <Text style={{fontSize: 10,}}>Price</Text>
              </View>
              <View style={{padding: "6px 0px 0px 5px",border: "1px 0px 1px 1px solid black",width: "10%",}}>
                <Text style={{fontSize: 10,}}>Qty</Text>
              </View>
              <View style={{padding: "6px 0px 0px 5px",border: "1px 1px 1px 1px solid black",width: "20%",}}>
                <Text style={{fontSize: 10,}}>Ext. Price</Text>
              </View>
            </View>
            {pc_items.length > 0
              ? pc_items.map((el, index) => (
                <View key={index} style={{display: "flex",flexDirection: "row",justifyContent: "space-evenly",padding: "5px",width: "100%",marginTop: "3px",}}>
                  <View style={{width: "25%",paddingLeft: "10px",}}>
                    <Text style={{fontSize: 10,color: "black",}}>{el.product_identifier}</Text>
                  </View>
                  <View style={{width: "25%",paddingLeft: "10px",}}>
                    <Text style={{fontSize: 10,color: "black",}}>{el.product_sku}</Text>
                  </View>
                  <View style={{width: "30%",paddingLeft: "10px",}}>
                    <Text style={{fontSize: 10,padding: 1,}}>{el.description}</Text>
                  </View>
                  <View style={{width: "15%",}}>
                    <Text style={{fontSize: 10,textAlign: "center",color: "black",padding: 1,}}>${el.price}</Text>
                  </View>
                  <View style={{width: "10%",}}>
                    <Text style={{fontSize: 10,textAlign: "center",padding: 1,}}>{el.quantity}</Text>
                  </View>
                  <View style={{width: "20%",}}>
                    <Text style={{fontSize: 10,textAlign: "center",color: "black",padding: 1,}}>${el.total_price}</Text>
                  </View>
                </View>
              ))
              : null}
            <Line style={{width: "100%",borderBottom: "1px solid black",}}></Line>
          </View>
          <View style={{display: "flex",marginLeft: "60%",marginTop: "15px",width: "40%",}}>
            <View style={{display: "flex",flexDirection: "row",justifyContent: "space-evenly",fontSize: "10px",}}>
              <View>
                <View style={{ marginTop: "5px" }}>
                  <Text style={{ fontWeight: "bold" }}>Sub Total:</Text>
                </View>
                <View style={{ marginTop: "5px" }}>
                  <Text style={{ fontWeight: "bold" }}>Tax:</Text>
                </View>
                <View style={{ marginTop: "5px" }}>
                  <Text style={{ fontWeight: "bold" }}>Shipping:</Text>
                </View>
                <View style={{ marginTop: "5px" }}>
                  <Text style={{ fontWeight: "bold" }}>Total:</Text>
                </View>
              </View>
              <View>
                <View style={{ marginTop: "5px" }}>
                  <Text style={{ color: "black" }}>${pc_sub_total}</Text>
                </View>
                <View style={{ marginTop: "5px" }}>
                  <Text style={{ color: "black" }}>$0.00</Text>
                </View>
                <View style={{ marginTop: "5px" }}>
                  <Text style={{ color: "black" }}>$0.00</Text>
                </View>
                <View style={{ marginTop: "5px" }}>
                  <Text style={{ color: "black" }}>${pc_total}</Text>
                </View>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  )
}
export const PackingSlipTemplate2 = ({
  pc_to_street1,
  pc_to_street2,
  pc_to_city,
  pc_to_state,
  pc_to_zip,
  pc_to_country,
  pc_order_number,
  pc_reference_number,
  pc_order_date,
  pc_to_name,
  pc_bill_to_street1,
  pc_bill_to_street2,
  pc_bill_to_city,
  pc_bill_to_state,
  pc_bill_to_zip,
  pc_bill_to_country,
  pc_items,
  pc_extra_row,
  pc_from_name,
  pc_from_street1,
  pc_from_street2,
  pc_from_city,
  pc_from_state,
  pc_from_zip,
}) => {
  return (
    <Document>
      <Page size={[384, 520]} style={styles.page}>
        <View>
          <View
            style={{
              marginTop: "10px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              padding: "0px 10px",
            }}
          >
            <View
              style={{
                width: "25%",
              }}
            >
              <Text
                style={{
                  fontSize: 7,
                  fontWeight: "bold",
                }}
              >
                SHIP TO
              </Text>
              <Text
                style={{
                  fontSize: 7,
                  fontWeight: "bold",
                }}
              >
                {pc_to_street1}
                {pc_to_street2 ? " " + pc_to_street2 : null}
              </Text>
              <Text
                style={{
                  fontSize: 7,
                  fontWeight: "bold",
                }}
              >
                {pc_to_city}
              </Text>
              <Text
                style={{
                  fontSize: 7,
                  fontWeight: "bold",
                }}
              >
                {pc_to_state}
              </Text>
              <Text
                style={{
                  fontSize: 7,
                  fontWeight: "bold",
                }}
              >
                {pc_to_zip}
              </Text>
              <Text
                style={{
                  fontSize: 7,
                  fontWeight: "bold",
                }}
              >
                {pc_to_country}
              </Text>
            </View>
            <View
              style={{
                width: "50%",
                textAlign: "center",
              }}
            >
              <Text
                style={{
                  fontSize: 7,
                  fontWeight: "bold",
                }}
              >
                ORDER# {pc_order_number}
              </Text>
              <Text
                style={{
                  fontSize: 7,
                  fontWeight: "bold",
                }}
              >
                REFERENCE# {pc_reference_number ? pc_reference_number : "-"}
              </Text>
              <Text
                style={{
                  fontSize: 7,
                  fontWeight: "bold",
                }}
              >
                ORDER DATE - {pc_order_date}
              </Text>
              <Text
                style={{
                  fontSize: 7,
                  fontWeight: "bold",
                }}
              >
                {pc_to_name}
              </Text>
              <Line
                style={{
                  width: "100%",
                  borderBottom: "1px solid gray",
                }}
              ></Line>
            </View>
            <View
              style={{
                width: "25%",
                textAlign: "right",
              }}
            >
              <Text
                style={{
                  fontSize: 7,
                  fontWeight: "bold",
                }}
              >
                BILL TO
              </Text>
              {pc_bill_to_street1 &&
                pc_bill_to_city &&
                pc_bill_to_state &&
                pc_bill_to_country ? (
                <>
                  <Text
                    style={{
                      fontSize: 7,
                      fontWeight: "bold",
                    }}
                  >
                    {pc_bill_to_street1}
                    {pc_bill_to_street2 ? " " + pc_bill_to_street2 : null}
                  </Text>
                  <Text
                    style={{
                      fontSize: 7,
                      fontWeight: "bold",
                    }}
                  >
                    {pc_bill_to_city}
                  </Text>
                  <Text
                    style={{
                      fontSize: 7,
                      fontWeight: "bold",
                    }}
                  >
                    {pc_bill_to_state}
                  </Text>
                  <Text
                    style={{
                      fontSize: 7,
                      fontWeight: "bold",
                    }}
                  >
                    {pc_bill_to_zip}
                  </Text>
                  <Text
                    style={{
                      fontSize: 7,
                      fontWeight: "bold",
                    }}
                  >
                    {pc_bill_to_country}
                  </Text>
                </>
              ) : (
                <>
                  <Text
                    style={{
                      fontSize: 7,
                      fontWeight: "bold",
                    }}
                  >
                    {pc_to_street1}
                    {pc_to_street2 ? " " + pc_to_street2 : null}
                  </Text>
                  <Text
                    style={{
                      fontSize: 7,
                      fontWeight: "bold",
                    }}
                  >
                    {pc_to_city}
                  </Text>
                  <Text
                    style={{
                      fontSize: 7,
                      fontWeight: "bold",
                    }}
                  >
                    {pc_to_state}
                  </Text>
                  <Text
                    style={{
                      fontSize: 7,
                      fontWeight: "bold",
                    }}
                  >
                    {pc_to_zip}
                  </Text>
                  <Text
                    style={{
                      fontSize: 7,
                      fontWeight: "bold",
                    }}
                  >
                    {pc_to_country}
                  </Text>
                </>
              )}
            </View>
          </View>
          <View
            style={{
              marginTop: "10px",
              padding: "0px 10px",
            }}
          >
            <View style={tableStyles.table}>
              <View style={tableStyles.tableRow}>
                <View style={tableStyles.tableHeaderSKUCol}>
                  <Text style={tableStyles.tableHeader}>SKU</Text>
                </View>
                <View style={tableStyles.tableHeaderItemCol}>
                  <Text style={tableStyles.tableHeader}>ITEM</Text>
                </View>
                <View style={tableStyles.tableHeaderQuantityCol}>
                  <Text style={tableStyles.tableHeader}>QUANTITY</Text>
                </View>
                <View style={tableStyles.tableHeaderReturnCodeCol}>
                  <Text style={tableStyles.tableHeader}>RETURN CODE</Text>
                </View>
              </View>
              {pc_items.length > 0
                ? pc_items.map((el, index) => (
                  <View key={index} style={tableStyles.tableRow}>
                    <View style={tableStyles.tableSKUCol}>
                      <Text style={tableStyles.tableLeftCell}>
                        {el.product_sku}
                      </Text>
                    </View>
                    <View style={tableStyles.tableItemCol}>
                      <Text style={tableStyles.tableLeftCell}>
                        {el.description}
                      </Text>
                    </View>
                    <View style={tableStyles.tableQuantityCol}>
                      <Text style={tableStyles.tableCell}>{el.quantity}</Text>
                    </View>
                    <View style={tableStyles.tableReturnCol}>
                      <Text style={tableStyles.tableCell}></Text>
                    </View>
                  </View>
                ))
                : ""}
            </View>
            {pc_extra_row}
          </View>
          <View
            style={{
              marginTop: "10px",
              padding: "0px 10px",
            }}
          >
            <Text style={tableStyles.centerTextHeading}>RETURN CODES</Text>
            <Text style={tableStyles.centerText}>
              01- TOO BIG 02-TO SMALL 03-DAMAGED/DEFECTIVE 04-NOT AS PICTURED
              05-CHANGED MIND 06-WRONG COLOR/SIZE
            </Text>

            <Text style={tableStyles.centerTextHeading}>
              RETURN INFORMATION
            </Text>
            <Text style={tableStyles.centerText}>
              1. Complete this return form and include it inside your return
              shipment.
            </Text>
            <Text style={tableStyles.centerText}>
              2. Ship your package using a trackable, insured shipping method.
            </Text>
            <Text style={tableStyles.centerText}>
              3. We cannot accept responsibility for packages we do not receive.
            </Text>
            <Text style={tableStyles.centerText}>
              4. Please keep the tracking details for your records.
            </Text>
            <Text style={tableStyles.centerText}>
              5. Please send your return to:
            </Text>
            <Text style={tableStyles.centerText}>{pc_from_name}</Text>
            <Text style={tableStyles.centerText}>
              {pc_from_street1}
              {pc_from_street2 ? " " + pc_from_street2 : ""}
            </Text>
            <Text style={tableStyles.centerText}>
              {pc_from_city}, {pc_from_state} {pc_from_zip}
            </Text>
            <Text style={tableStyles.centerText}>
              6. You will receive an email once your return has been processed.
            </Text>
            <Text style={tableStyles.centerText}>
              7. Please allow 5 business days from the time we receive your
              return for it to be processed.
            </Text>

            <Text style={tableStyles.centerTextHeading}>RETURN POLICY</Text>
            <Text style={tableStyles.centerText}>
              1. Merchandise must be returned within 14 days of shipment
              delivery.
            </Text>
            <Text style={tableStyles.centerText}>
              2. Merchandise must not be worn, altered, or washed.
            </Text>
            <Text style={tableStyles.centerText}>
              3. Merchandise must have all tags attached.
            </Text>

            <Text style={tableStyles.centerTextHeading}>CONTACT INFO</Text>
            <Text style={tableStyles.centerText}>
              Call us at or email us at
            </Text>
            <Text style={tableStyles.centerText}>1-866-531-1773</Text>
            <Text style={tableStyles.centerText}>
              SUPPORT@FANTASTICSPORTSSTORE.COM
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  )
}


const staticData = [
  {
    from_name: "John Doe",
    from_street1: "123 Elm St",
    from_street2: "Apt 4B",
    from_city: "Metropolis",
    from_state: "NY",
    from_zip: "10001",
    from_country: "USA",
    order_number: "ORD123456",
    order_date: "2024-10-10",
    to_name: "Jane Smith",
    to_street1: "456 Oak St",
    to_street2: "",
    to_city: "Gotham",
    to_state: "CA",
    to_zip: "90210",
    to_country: "USA",
    shipment_date: "2024-10-11",
    items: [
      {
        product_identifier: "Item 1",
        product_sku: "SKU001",
        description: "Sample Item 1 Description",
        price: 10.00,
        quantity: 2,
        total_price: 20.00
      },
      {
        product_identifier: "Item 2",
        product_sku: "SKU002",
        description: "Sample Item 2 Description",
        price: 15.00,
        quantity: 1,
        total_price: 15.00
      }
    ],
    subtotal_price: 35.00,
    tax_price: 3.50,
    total_price: 38.50,
  },
];


export const MergedPackingSlip = ({ mergedPackingSlip }) => {
  return (
    <Document>
      {mergedPackingSlip?.map((el, i) => (
        <Page size={[384, 520]} style={styles.page} key={i}>
          <View>
            <Text style={{ left: '150px', fontSize: 12, marginTop: 10, fontWeight: 900, color: 'black' }}>
              Packing Slip
            </Text>
            <View style={{ marginTop: 15 }}>
              <Text style={styles.mergedSlipDetailsText}>{el?.from_name || 'No sender name available.'}</Text>
              <Text style={styles.mergedSlipDetailsText}>{el?.from_street1 || 'No street address available.'}</Text>
              {el?.from_street2 ? (
                <Text style={styles.mergedSlipDetailsText}>{el.from_street2}</Text>
              ) : (
                <Text style={styles.mergedSlipDetailsText}>No additional street address available.</Text>
              )}
              <Text style={styles.mergedSlipDetailsText}>{el?.from_city || 'No city available.'}</Text>
              <Text style={styles.mergedSlipDetailsText}>{`${el?.from_state || 'No state available'} ${el?.from_zip || 'No ZIP available'} ${el?.from_country || 'No country available'}`}</Text>
            </View>
            <View style={{ marginTop: 20, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <View style={{ display: 'flex', flexDirection: 'row', width: '50%', left: '20px' }}>
                <Text style={{ fontSize: 10, fontWeight: 'bold', color: 'black', marginRight: 5 }}>Ship To:</Text>
                <Text style={{ fontSize: 10, fontWeight: 400, marginLeft: 10, width: '117px', color: 'black' }}>
                  {`${el.to_street1 || 'No street address available.'}${el.to_street2 ? ' ' + el.to_street2 : ''} ${el.to_city || 'No city available'}, ${el.to_state || 'No state available'} ${el.to_zip || 'No ZIP available'} ${el.to_country || 'No country available'}`}
                </Text>
              </View>
              <View style={{ width: '100%', marginLeft: '80px', gap: '6px', display: 'flex', flexDirection: 'row' }}>
                <View>
                  <Text style={styles.tags}>Order #</Text>
                  <Text style={{ fontSize: 9, fontWeight: 'bold', color: 'black', marginTop: 10 }}>Date</Text>
                  <Text style={styles.tags}>User</Text>
                  <Text style={styles.tags}>Ship Date</Text>
                </View>
                <View style={{ width: '2px', height: '80px', borderLeftWidth: 1, borderLeftColor: 'black' }} />
                <View style={{ width: '140px' }}>
                  <Text style={{ fontSize: 9, color: 'black' }}>{el.order_number || 'No order number available.'}</Text>
                  <Text style={{ fontSize: 9, color: 'black', marginTop: 10 }}>{el.order_date || 'No order date available.'}</Text>
                  <Text style={{ fontSize: 9, color: 'black', marginTop: 13 }}>{el.to_name || 'No recipient name available.'}</Text>
                  <Text style={{ fontSize: 9, color: 'black', marginTop: 13 }}>{el.shipment_date || 'No shipment date available.'}</Text>
                </View>
              </View>
            </View>
            <View style={{ width: '98%', marginHorizontal: 'auto' }}>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-evenly',
                  padding: 10,
                  width: '100%',
                  marginTop: 10,
                }}
              >
                <View style={{ padding: '6px 0px 0px 5px', border: '1px 0px 1px 1px solid black', width: '25%' }}>
                  <Text style={{ fontSize: 9 }}>Item</Text>
                </View>
                <View style={{ padding: '6px 0px 0px 5px', border: '1px 0px 1px 1px solid black', width: '25%' }}>
                  <Text style={{ fontSize: 9 }}>SKU</Text>
                </View>
                <View style={{ padding: '6px 0px 0px 5px', border: '1px 0px 1px 1px solid black', width: '30%' }}>
                  <Text style={{ fontSize: 9 }}>Description</Text>
                </View>
                <View style={{ padding: '6px 0px 0px 5px', border: '1px 0px 1px 1px solid black', width: '15%' }}>
                  <Text style={{ fontSize: 9 }}>Price</Text>
                </View>
                <View style={{ padding: '6px 0px 0px 5px', border: '1px 0px 1px 1px solid black', width: '10%' }}>
                  <Text style={{ fontSize: 9 }}>Qty</Text>
                </View>
                <View style={{ padding: '6px 0px 0px 5px', border: '1px 1px 1px 1px solid black', width: '20%' }}>
                  <Text style={{ fontSize: 9 }}>Ext. Price</Text>
                </View>
              </View>
              {el.items && el.items.length > 0 ? (
                el.items.map((ell, index) => (
                  <View
                    key={index}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-evenly',
                      padding: 5,
                      width: '100%',
                      marginTop: 3,
                    }}
                  >
                    <View style={{ width: '25%', paddingLeft: 10 }}>
                      <Text style={{ fontSize: 9, color: 'black' }}>{ell.product_identifier || 'No item identifier available.'}</Text>
                    </View>
                    <View style={{ width: '25%', paddingLeft: 10 }}>
                      <Text style={{ fontSize: 9, color: 'black' }}>{ell.product_sku || 'No SKU available.'}</Text>
                    </View>
                    <View style={{ width: '30%', paddingLeft: 10 }}>
                      <Text style={{ fontSize: 9, padding: 2 }}>{ell.description || 'No description available.'}</Text>
                    </View>
                    <View style={{ width: '15%' }}>
                      <Text style={{ fontSize: 9, textAlign: 'center', color: 'black', padding: 2 }}>${ell.price || '0.00'}</Text>
                    </View>
                    <View style={{ width: '10%' }}>
                      <Text style={{ fontSize: 9, textAlign: 'center', padding: 2 }}>{ell.quantity || '0'}</Text>
                    </View>
                    <View style={{ width: '20%' }}>
                      <Text style={{ fontSize: 9, textAlign: 'center', color: 'black', padding: 2 }}>${ell.total_price || '0.00'}</Text>
                    </View>
                  </View>
                ))
              ) : (
                <Text>No items available.</Text>
              )}
              <View style={styles.line}></View>
            </View>
            <View style={{ display: 'flex', marginLeft: '60%', marginTop: 15, width: '40%' }}>
              <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', fontSize: 9 }}>
                <View>
                  <View style={{ marginTop: 5 }}>
                    <Text style={{ fontWeight: 'bold' }}>Sub Total:</Text>
                  </View>
                  <View style={{ marginTop: 5 }}>
                    <Text style={{ fontWeight: 'bold' }}>Shipping:</Text>
                  </View>
                  <View style={{ marginTop: 5 }}>
                    <Text style={{ fontWeight: 'bold' }}>Total:</Text>
                  </View>
                </View>
                <View>
                  <View style={{ marginTop: 5 }}>
                    <Text>{el.sub_total || 'No subtotal available.'}</Text>
                  </View>
                  <View style={{ marginTop: 5 }}>
                    <Text>{el.shipping || 'No shipping cost available.'}</Text>
                  </View>
                  <View style={{ marginTop: 5 }}>
                    <Text>{el.total || 'No total available.'}</Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </Page>
      ))}
    </Document>
  )
}
export const MergedPackingSlipTemplate2 = ({ mergedPackingSlipTemplete2 }) => {
  return (
    <Document>
      {mergedPackingSlipTemplete2.map((el, i) => (
        <Page size={[384, 520]} style={styles.page} key={i}>
          <View>
            <View
              style={{
                marginTop: "10px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                padding: "0px 10px",
              }}
            >
              <View
                style={{
                  width: "25%",
                }}
              >
                <Text
                  style={{
                    fontSize: 7,
                    fontWeight: "bold",
                  }}
                >
                  SHIP TO
                </Text>
                <Text
                  style={{
                    fontSize: 7,
                    fontWeight: "bold",
                  }}
                >
                  {el.to_street1}
                  {el.to_street2 ? " " + el.to_street2 : null}
                </Text>
                <Text
                  style={{
                    fontSize: 7,
                    fontWeight: "bold",
                  }}
                >
                  {el.to_city}
                </Text>
                <Text
                  style={{
                    fontSize: 7,
                    fontWeight: "bold",
                  }}
                >
                  {el.to_state}
                </Text>
                <Text
                  style={{
                    fontSize: 7,
                    fontWeight: "bold",
                  }}
                >
                  {el.to_zip}
                </Text>
                <Text
                  style={{
                    fontSize: 7,
                    fontWeight: "bold",
                  }}
                >
                  {el.to_country}
                </Text>
              </View>
              <View
                style={{
                  width: "50%",
                  textAlign: "center",
                }}
              >
                <Text
                  style={{
                    fontSize: 7,
                    fontWeight: "bold",
                  }}
                >
                  ORDER# {el.order_number}
                </Text>
                <Text
                  style={{
                    fontSize: 7,
                    fontWeight: "bold",
                  }}
                >
                  REFERENCE# {el.reference_number ? el.reference_number : "-"}
                </Text>
                <Text
                  style={{
                    fontSize: 7,
                    fontWeight: "bold",
                  }}
                >
                  ORDER DATE - {el.order_date}
                </Text>
                <Text
                  style={{
                    fontSize: 7,
                    fontWeight: "bold",
                  }}
                >
                  {el.to_name}
                </Text>
                <Line
                  style={{
                    width: "100%",
                    borderBottom: "1px solid gray",
                  }}
                ></Line>
              </View>
              <View
                style={{
                  width: "25%",
                  textAlign: "right",
                }}
              >
                <Text
                  style={{
                    fontSize: 7,
                    fontWeight: "bold",
                  }}
                >
                  BILL TO
                </Text>
                {el.bill_to_street1 &&
                  el.bill_to_city &&
                  el.bill_to_zip &&
                  el.bill_to_country ? (
                  <>
                    <Text
                      style={{
                        fontSize: 7,
                        fontWeight: "bold",
                      }}
                    >
                      {el.bill_to_street1}
                      {el.bill_to_street2 ? " " + el.bill_to_street2 : null}
                    </Text>
                    <Text
                      style={{
                        fontSize: 7,
                        fontWeight: "bold",
                      }}
                    >
                      {el.bill_to_city}
                    </Text>
                    <Text
                      style={{
                        fontSize: 7,
                        fontWeight: "bold",
                      }}
                    >
                      {el.bill_to_state}
                    </Text>
                    <Text
                      style={{
                        fontSize: 7,
                        fontWeight: "bold",
                      }}
                    >
                      {el.bill_to_zip}
                    </Text>
                    <Text
                      style={{
                        fontSize: 7,
                        fontWeight: "bold",
                      }}
                    >
                      {el.bill_to_country}
                    </Text>
                  </>
                ) : (
                  <>
                    <Text
                      style={{
                        fontSize: 7,
                        fontWeight: "bold",
                      }}
                    >
                      {el.to_street1}
                      {el.to_street2 ? " " + el.to_street2 : null}
                    </Text>
                    <Text
                      style={{
                        fontSize: 7,
                        fontWeight: "bold",
                      }}
                    >
                      {el.to_city}
                    </Text>
                    <Text
                      style={{
                        fontSize: 7,
                        fontWeight: "bold",
                      }}
                    >
                      {el.to_state}
                    </Text>
                    <Text
                      style={{
                        fontSize: 7,
                        fontWeight: "bold",
                      }}
                    >
                      {el.to_zip}
                    </Text>
                    <Text
                      style={{
                        fontSize: 7,
                        fontWeight: "bold",
                      }}
                    >
                      {el.to_country}
                    </Text>
                  </>
                )}
              </View>
            </View>
            <View
              style={{
                marginTop: "10px",
                padding: "0px 10px",
              }}
            >
              <View style={tableStyles.table}>
                <View style={tableStyles.tableRow}>
                  <View style={tableStyles.tableHeaderSKUCol}>
                    <Text style={tableStyles.tableHeader}>SKU</Text>
                  </View>
                  <View style={tableStyles.tableHeaderItemCol}>
                    <Text style={tableStyles.tableHeader}>ITEM</Text>
                  </View>
                  <View style={tableStyles.tableHeaderQuantityCol}>
                    <Text style={tableStyles.tableHeader}>QUANTITY</Text>
                  </View>
                  <View style={tableStyles.tableHeaderReturnCodeCol}>
                    <Text style={tableStyles.tableHeader}>RETURN CODE</Text>
                  </View>
                </View>
                {el.items.length > 0
                  ? el.items.map((ell, index) => (
                    <View key={index} style={tableStyles.tableRow}>
                      <View style={tableStyles.tableSKUCol}>
                        <Text style={tableStyles.tableLeftCell}>
                          {ell.product_sku}
                        </Text>
                      </View>
                      <View style={tableStyles.tableItemCol}>
                        <Text style={tableStyles.tableLeftCell}>
                          {ell.description}
                        </Text>
                      </View>
                      <View style={tableStyles.tableQuantityCol}>
                        <Text style={tableStyles.tableCell}>
                          {ell.quantity}
                        </Text>
                      </View>
                      <View style={tableStyles.tableReturnCol}>
                        <Text style={tableStyles.tableCell}></Text>
                      </View>
                    </View>
                  ))
                  : ""}
              </View>
              {el.extra_row}
            </View>
            <View
              style={{
                marginTop: "10px",
                padding: "0px 10px",
              }}
            >
              <Text style={tableStyles.centerTextHeading}>RETURN CODES</Text>
              <Text style={tableStyles.centerText}>
                01- TOO BIG 02-TO SMALL 03-DAMAGED/DEFECTIVE 04-NOT AS PICTURED
                05-CHANGED MIND 06-WRONG COLOR/SIZE
              </Text>

              <Text style={tableStyles.centerTextHeading}>
                RETURN INFORMATION
              </Text>
              <Text style={tableStyles.centerText}>
                1. Complete this return form and include it inside your return
                shipment.
              </Text>
              <Text style={tableStyles.centerText}>
                2. Ship your package using a trackable, insured shipping method.
              </Text>
              <Text style={tableStyles.centerText}>
                3. We cannot accept responsibility for packages we do not
                receive.
              </Text>
              <Text style={tableStyles.centerText}>
                4. Please keep the tracking details for your records.
              </Text>
              <Text style={tableStyles.centerText}>
                5. Please send your return to:
              </Text>
              <Text style={tableStyles.centerText}>{el.from_name}</Text>
              <Text style={tableStyles.centerText}>
                {el.from_street1}
                {el.from_street2 ? " " + el.from_street2 : ""}
              </Text>
              <Text style={tableStyles.centerText}>
                {el.from_city}, {el.from_state} {el.from_zip}
              </Text>
              <Text style={tableStyles.centerText}>
                6. You will receive an email once your return has been
                processed.
              </Text>
              <Text style={tableStyles.centerText}>
                7. Please allow 5 business days from the time we receive your
                return for it to be processed.
              </Text>

              <Text style={tableStyles.centerTextHeading}>RETURN POLICY</Text>
              <Text style={tableStyles.centerText}>
                1. Merchandise must be returned within 14 days of shipment
                delivery.
              </Text>
              <Text style={tableStyles.centerText}>
                2. Merchandise must not be worn, altered, or washed.
              </Text>
              <Text style={tableStyles.centerText}>
                3. Merchandise must have all tags attached.
              </Text>

              <Text style={tableStyles.centerTextHeading}>CONTACT INFO</Text>
              <Text style={tableStyles.centerText}>
                Call us at or email us at
              </Text>
              <Text style={tableStyles.centerText}>1-866-531-1773</Text>
              <Text style={tableStyles.centerText}>
                SUPPORT@FANTASTICSPORTSSTORE.COM
              </Text>
            </View>
          </View>
        </Page>
      ))}
    </Document>
  )
}
