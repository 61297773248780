import React from "react"
import globe from "../../assets/images/globe.svg"
import "../../pages/Authentication/stylesheet.css"
const AuthFooter = () => {
  return (
    <div className="mt-3 d-flex align-items-center justify-content-between">
      <p style={{
        display: 'flex',
        alignItems: 'center',
        gap: '5px'
      }}>
        <span
          style={{
            fontSize: "20px",
            color:"#535866"
          }}
        >
          &copy;
        </span>
        {new Date().getFullYear()} NullShip
      </p>
      <div style={{display:"none"}} className="language-selector">
        <div className="selector-container">
          <span className="globe-icon">
            <img src={globe} alt="languages" />
          </span>
          <select className="langSelect">
            <option value="ENG" className="selectLangoption">
              ENG
            </option>
            <option value="ESP" className="selectLangoption">
              ESP
            </option>
            <option value="FRA" className="selectLangoption">
              FRA
            </option>
          </select>
        </div>
      </div>
    </div>
  )
}

export default AuthFooter
