const customDarkStyles = {
  headCells: {
    style: {
      border:"1px solid #0a0a0a",
      background:"#0a0a0a",
    },
  },
  rows: {
    style: {
      // minHeight: '35px',
      // maxHeight: '35px',
    },
  },
};
export default customDarkStyles;