import React from 'react';
import { Modal, ModalHeader, ModalBody, Form } from 'reactstrap';

const NotesModal = ({ isOpen, toggle, onClosed, handleUpdateNotes, notesValue, setNotesValue, modalTitle, placeholder, customerRef1, customerRef2, setCustomerRef1, setCustomerRef2 }) => {
  // const isReadOnly = localStorage.getItem("store") != "0";
  const isReadOnly = false;

  return (
    <Modal isOpen={isOpen} role="dialog" autoFocus={true} centered={true} className="exampleModal modal" tabIndex="-1" toggle={toggle} onClosed={onClosed}>
      <div>
        <ModalHeader toggle={toggle}>{modalTitle}</ModalHeader>
        <ModalBody>
          <Form onSubmit={e => { e.preventDefault(); handleUpdateNotes(); }}>
            <label>Notes</label>
            <textarea type="text" readOnly={isReadOnly} placeholder={placeholder} onChange={e => setNotesValue(e.target.value)} className="form-control" style={{height:"6rem"}} value={notesValue} />

            {
              modalTitle != "To Buyer Notes" && (
                <>
                  <label style={{ marginTop: "10px" }}>Custom Ref 1</label>
                  <textarea type="text" readOnly={isReadOnly} placeholder={placeholder} onChange={e => setCustomerRef1(e.target.value)} className="form-control" style={{height:"6rem"}} value={customerRef1} />

                  <label style={{ marginTop: "10px" }}>Custom Ref 2</label>
                  <textarea type="text" readOnly={isReadOnly} placeholder={placeholder} onChange={e => setCustomerRef2(e.target.value)} className="form-control" style={{height:"6rem"}} value={customerRef2} />
                </>
              )
            }


            <br />

            {!isReadOnly && (<button className="btn btn-sm btn-success" type="submit" style={{ float: "right" }}>Save</button>)}
          </Form>
          <br />
          <br />
        </ModalBody>
      </div>
    </Modal>
  );
};

export default NotesModal;
