// src/components/filter.
import React, { useEffect, useState } from "react"
// import PropTypes from 'prop-types';
// import { Link } from "react-router-dom";
//import components
import Breadcrumbs from '../../../../components/Common/Breadcrumb';
import { post, get } from "../../../../helpers/api_helper"
import {toast} from "react-hot-toast";
import moment from "moment-timezone"
// import { useFormik } from "formik"
// import * as Yup from "yup"
// import { useDispatch } from "react-redux"
import {Row,Col,Card,CardBody,Label,CardTitle, Modal, ModalHeader, ModalBody} from "reactstrap"
// import Select from "react-select/base"
// import { error } from "pdf-lib"
import DataTableExtensions from "react-data-table-component-extensions"
import DataTable, { createTheme } from "react-data-table-component"
import customStyles from "../../../../assets/css/customTableStyle"
import customDarkStyles from "../../../../assets/css/customTableDarkStyle"
// import shopify from "../../../../assets/images/integrations/shopify.svg"
// import ebay from "../../../../assets/images/integrations/ebay.svg"
// import walmart from "../../../../assets/images/integrations/walmart.svg"
// import zenventory from "../../../../assets/images/integrations/1651688363447.jfif"
// import sellercloud from "../../../../assets/images/integrations/sellercloud.png"
// import shipstation from "../../../../assets/images/integrations/shipstation.png"
// import veeqo from "../../../../assets/images/integrations/veeqo.png"
// import shiphero from "../../../../assets/images/integrations/shiphero.jpg"
// import orderDeskLogo from "../../../../assets/images/integrations/orderDeskLogo.png"
import config from "../../../../config"
import { CopyBlock, irBlack } from "react-code-blocks"


function Webhook() {
  const headers = {"Content-Type": "application/json","Authorization": `Bearer ${localStorage.getItem("token")}`,};

  const localhostHostnames = ['http:', 'localhost', '127.0.0.1'];
  const [webhookUrl, setWebhookUrl] = useState("");
  const [pending, setPending] = useState(false);
  const [webhookLogs, setWebhookLogs] = useState([]);
  const [webhookLogDetails, setWebhookLogDetails] = useState(null);
  const [webhookLogDetailsModal, setWebhookLogDetailsModal] = useState(false);

  const retrieveWebhook = async () => {
    try {
      const body = {
        user_id: JSON.parse(localStorage.getItem("authUser")).id
      };

      get(`/webhook/get-api-webhook/${body.user_id}`, {headers}).then((response) => {
        if(response.status === 200) {
          setWebhookUrl(response?.webhook?.webhook_url ? response?.webhook?.webhook_url : "");
        }
        else {
          console.log(response.message,'erorr in retrieve webhook')
          // toast.error(response.message);
        }
      })
    }
    catch (e) {
      // console.log(e)
    }
  }

  const columns = [
    {
      name: 'Webhook Date',
      sortable: true,
      selector: (row) => moment(row.created_at).tz(moment.tz.guess()).format("MMM DD, YYYY hh:mm A")
    },
    {
      name: 'Batch ID',
      selector: row => row.batch_id,
      sortable: true,
      width: '350px'
    },
    {
      name: 'Event Type',
      selector: row => row.event_type,
      sortable: true
    },
    {
      name: 'Status',
      selector: row => (
        <>
          {row.status === 0 ? <span className={'badge badge-light'}>Pending</span> : ''}
          {row.status === 1 ? <span className={"badge badge-success"}>Success</span> : ''}
          {row.status === 2 ? <span className={"badge badge-danger"}>Error</span> : ''}
        </>
      ),
      sortable: true,
      width: '100px'
    },
    {
      name: 'Actions',
      selector: row => (
        <>
          <button className={'btn btn-sm btn-success'} onClick={() => handleViewDetails(row.id)}>View Details</button>
          {row.recieve_status === 0 &&<button className={'btn btn-sm btn-success'} style={{marginLeft:"10px"}} onClick={() => handleResendWebhookResponse(row.id)}>Resend Response</button>}
        </>
      ),
      width: '250px'
    },
  ]

  const retrieveWebhookLogs = async () => {
    try {
      setPending(true);
      const body = {
        user_id: JSON.parse(localStorage.getItem("authUser")).id
      };

      get(`/webhook/get-api-webhook-logs/${body.user_id}`, {headers}).then((response) => {
        if(response.status === 200) {
          setWebhookLogs({columns, data: response.data});
        }
        else {
          console.log(response.message,'error in else block at retrieve webhook logs');
        }

        setPending(false);
      });
    }
    catch (e) {
      console.log(e,'error in retrieve webhook logs')
    }
  }

  const handleViewDetails = (id) => {
    setWebhookLogDetails(null);
    get(`/webhook/get-api-webhook-log-by-id/${id}`, {headers}).then((response) => {
      if(response.status === 200) {
        setWebhookLogDetails(response.data.length > 0 ? response.data[0] : null);
        setWebhookLogDetailsModal(true);
      }
      else {
        toast.error(response.message);
      }
    });
  }

  const handleResendWebhookResponse = (id) => {
    let loader = toast.loading("Sending Response")
    post(`/v1/resend-webhook-response`,{webhookId:id},{headers})
    .then((response) => {
      console.log(response,'response')
      if(response.msg.includes("Webhook response sent succesfully") || response.msg=="Webhook response sent succesfully") {
        toast.dismiss(loader)
        retrieveWebhookLogs()
        toast.success("Response Sent");
      }
    })
    .catch((error)=>{
      console.log(error,'error in catch block')
      toast.dismiss(loader);
      toast.error("Something went wrong");
    })
  }

  useEffect(() => {
    retrieveWebhook();
    retrieveWebhookLogs();
  }, []);

  const handleUpdateWebhook = (e) => {
    e.preventDefault();
    try {
      const isLocalhost = localhostHostnames.map(el => webhookUrl.includes(el));
      if(!isLocalhost.some(el => el === true)) {
        const body = {
          user_id: JSON.parse(localStorage.getItem("authUser")).id,
          webhook_url: webhookUrl
        };

        post('/webhook/update-api-webhook', body, {headers}).then((response) => {
          if(response.status === 200) {
            toast.success(response.message);
          }
          else {
            toast.error(response.message);
          }
        });
      }
      else {
        toast.error('Invalid URL');
      }
    }
    catch (e) {
      console.error(e);
    }
  }

  //meta title
  document.title = "API Webhook | NullShip";

  createTheme('solarized', {
    text: {
      primary: '#fffff',
      secondary: '#fffff',
    },
    background: {
      default: '#0f0f0f',
    },
    context: {
      background: '#cb4b16',
      text: '#0f0f0f',
    },
    divider: {
      default: '#282828',
    },
    action: {
      button: 'rgba(0,0,0,.54)',
      hover: 'rgba(0,0,0,.08)',
      disabled: 'rgba(0,0,0,.12)',
    },
  }, 'dark');

  const formatJson = (json_string) => {
    return json_string.toString().replaceAll('",', '",\n').replaceAll(',"', ',\n"').replaceAll('{', "{\n").replaceAll('}', "\n}");
  }


  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="NullShip" sourceItem="API" breadcrumbItem="Webhook" />
        {/* <Table columns={columns} data={data} /> */}
        <Row className="justify-content-center">
          <Col md={8} lg={6} xl={5}>
            <Card className="overflow-hidden">
              <CardBody>
                <CardTitle className="mb-4">API Webhook</CardTitle>
                <form onSubmit={handleUpdateWebhook}>
                  <div className="mb-3">
                    <Label className="form-label">Webhook URL</Label>
                    <div className={"hstack gap-3 mb-3"}>
                      <input
                        className="form-control me-auto"
                        type="text"
                        placeholder="Enter Webhook URL"
                        value={webhookUrl}
                        required={true}
                        onChange={(e) => setWebhookUrl(e.target.value)}
                      />
                    </div>
                  </div>

                  <Row className="mb-3">
                    <Col className="text-end">
                      <button
                        className="btn btn-success w-md "
                        type="submit"
                      >
                        Save
                      </button>
                    </Col>
                  </Row>
                </form>
              </CardBody>
            </Card>
          </Col>
          <Col md={12}>
            <Card className="overflow-hidden">
              <CardBody>
                <CardTitle className="mb-4">API Webhooks Logs</CardTitle>
                <CardBody>
                  <DataTableExtensions
                    {...webhookLogs}
                    exportHeaders={true}
                    filterPlaceholder={`Filter Webhook Logs...`}
                  >
                    <DataTable columns={columns} data={webhookLogs.data} pagination={30} paginationPerPage={30} progressPending={pending} progressComponent={<h4 className={"mt-5 mb-5 text-center"}>Loading data, this may take a few moments...</h4>}
                               highlightOnHover={true}
                               theme={localStorage.getItem("theme")=="dark"?"solarized":""} customStyles={localStorage.getItem("theme")=="dark"?customDarkStyles:customStyles}
                    />
                  </DataTableExtensions>
                </CardBody>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>


      {/*Connection Modal*/}
      <Modal
        isOpen={webhookLogDetailsModal}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal modal-xl"
        tabIndex="-1"
        toggle={() => {
          setWebhookLogDetailsModal(!webhookLogDetailsModal)
        }}
      >
        <div>
          <ModalHeader
            toggle={() => {
              setWebhookLogDetailsModal(!webhookLogDetailsModal)
            }}
          >
            Webhook Details
          </ModalHeader>
          <ModalBody>
            <div className={"row"}>
              <div className={'col-md-6'}>
                <span style={{float:'right'}}>{webhookLogDetails?.created_at ? moment(webhookLogDetails.created_at).tz(moment.tz.guess()).format("MMM DD, YYYY hh:mm A") : ''}</span>
                <h5>Webhook Request</h5>
                <CopyBlock
                  text={webhookLogDetails?.webhook_request ? formatJson(webhookLogDetails.webhook_request) : ''}
                  language={"json"}
                  showLineNumbers={false}
                  theme={irBlack}
                  wrapLines={false}
                  codeBlock
                />
              </div>
              <div className={'col-md-6'}>
                <span style={{float:'right'}}>{(webhookLogDetails?.webhook_response && webhookLogDetails?.updated_at) ? moment(webhookLogDetails.updated_at).tz(moment.tz.guess()).format("MMM DD, YYYY hh:mm A") : ''}</span>
                <h5>Webhook Response</h5>
                <CopyBlock
                  text={webhookLogDetails?.webhook_response ? formatJson(webhookLogDetails.webhook_response) : 'No Response Found'}
                  language={"json"}
                  showLineNumbers={false}
                  theme={irBlack}
                  wrapLines={true}
                  codeBlock
                />
              </div>
            </div>
          </ModalBody>
        </div>
      </Modal>
      {/*Connection Modal*/}

    </div>
  );
}

Webhook.propTypes = {
};

export default Webhook;